
import {defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import {UserRole} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";

export default defineComponent({
	name: "RoleUserForm",
	components: {
		Dropdown,
	},
	props: {
		role: {
			type: Object as PropType<UserRole>,
			required: true,
		},
		allRoles: {
			type: Array as PropType<UserRole[]>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<UserRole>({});

		/** Determines the required rules for validation */
		const rules = {
			id: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.role);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.role, () => {
			Object.assign(value, props.role);
		})

		/**
		 * Gets the Label for Columns. Is used in the Dropdowns for the Columns
		 * @param column Current-Column
		 */
		const getColumnLabel = (column: UserRole): string => {
			return column.description + " (" + column.title + ")";
		}

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			loading,
			value,
			props,
			v$,
			update,
			getColumnLabel,
		}
	},
});

