import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11fc0fa2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid p-mx-2" }
const _hoisted_2 = { class: "p-col-12 p-py-2 p-px-4 p-d-none p-d-md-flex sticky-header" }
const _hoisted_3 = { class: "p-col-12 p-lg-12 p-xl-8 p-xxl-8 dashboard-container" }
const _hoisted_4 = { class: "p-col-12 p-lg-12 p-xl-4 p-xxl-4 dashboard-container search-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QueueTimeline = _resolveComponent("QueueTimeline")!
  const _component_QueueNavigatorView = _resolveComponent("QueueNavigatorView")!
  const _component_DocumentClassSearchMask = _resolveComponent("DocumentClassSearchMask")!
  const _component_DashboardTile = _resolveComponent("DashboardTile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_QueueTimeline, { allDocumentClasses: _ctx.allDocumentClasses }, null, 8, ["allDocumentClasses"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_QueueNavigatorView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DashboardTile, {
        class: "search-content",
        title: _ctx.$t('Platform.Search.Title')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DocumentClassSearchMask)
        ]),
        _: 1
      }, 8, ["title"])
    ])
  ]))
}