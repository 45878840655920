import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75350a5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-6" }
const _hoisted_3 = { for: "maxDocumentAge" }
const _hoisted_4 = { class: "p-field p-col-6" }
const _hoisted_5 = { for: "maxLogAge" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputIcon = _resolveComponent("InputIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_IconField = _resolveComponent("IconField")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.System.MaxDocumentAge')), 1),
      _withDirectives((_openBlock(), _createBlock(_component_IconField, null, {
        default: _withCtx(() => [
          _createVNode(_component_InputIcon, {
            class: _normalizeClass(_ctx.props.loading ? 'mdi mdi-spin mdi-loading' : 'mdi mdi-comment-question-outline')
          }, null, 8, ["class"]),
          _createVNode(_component_InputText, {
            id: "maxDocumentAge",
            modelValue: _ctx.props.systemCleanUpConfig.maxDocumentAge,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.systemCleanUpConfig.maxDocumentAge) = $event)),
            class: "readonly",
            readonly: true
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })), [
        [
          _directive_tooltip,
          _ctx.$t('Squeeze.System.MaxDocumentAgeDescription'),
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Squeeze.System.MaxLogAge')), 1),
      _withDirectives((_openBlock(), _createBlock(_component_IconField, null, {
        default: _withCtx(() => [
          _createVNode(_component_InputIcon, {
            class: _normalizeClass(_ctx.props.loading ? 'mdi mdi-spin mdi-loading' : 'mdi mdi-comment-question-outline')
          }, null, 8, ["class"]),
          _createVNode(_component_InputText, {
            id: "maxLogAge",
            modelValue: _ctx.props.systemCleanUpConfig.maxLogAge,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.props.systemCleanUpConfig.maxLogAge) = $event)),
            class: "readonly",
            readonly: true
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })), [
        [
          _directive_tooltip,
          _ctx.$t('Squeeze.System.MaxLogAgeDescription'),
          void 0,
          { bottom: true }
        ]
      ])
    ])
  ]))
}