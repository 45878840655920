import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid p-input-filled p-formgrid p-grid p-pb-2 p-px-2" }
const _hoisted_2 = { class: "p-field p-col-4 p-ml-auto" }
const _hoisted_3 = { class: "p-field p-col-12" }
const _hoisted_4 = { for: "user" }
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = { for: "password" }
const _hoisted_7 = { class: "p-field p-col-12" }
const _hoisted_8 = { for: "tenant" }
const _hoisted_9 = { class: "p-col-12 p-mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BlockUI = _resolveComponent("BlockUI")!

  return (_openBlock(), _createBlock(_component_BlockUI, { blocked: _ctx.blocked }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.selectedLanguage,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
            options: _ctx.languages,
            optionLabel: "name",
            optionValue: "code",
            placeholder: _ctx.$t('System-Administration.Language.Selection'),
            onChange: _ctx.changeLanguage
          }, null, 8, ["modelValue", "options", "placeholder", "onChange"])
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('System-Administration.General.LoginDescription')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('System-Administration.Login.UserName')), 1),
          _createVNode(_component_InputText, {
            id: "user",
            modelValue: _ctx.user,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user) = $event)),
            modelModifiers: { trim: true },
            required: "true",
            onKeydown: _ctx.onKeyDown,
            autofocus: ""
          }, null, 8, ["modelValue", "onKeydown"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('System-Administration.Login.Password')), 1),
          _createVNode(_component_InputText, {
            id: "password",
            modelValue: _ctx.serverPassword,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.serverPassword) = $event)),
            modelModifiers: { trim: true },
            required: "true",
            onKeydown: _ctx.onKeyDown
          }, null, 8, ["modelValue", "onKeydown"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('System-Administration.Tenants.Tenant')), 1),
          _createVNode(_component_InputText, {
            id: "tenant",
            modelValue: _ctx.tenant,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tenant) = $event)),
            modelModifiers: { trim: true },
            required: "true",
            onKeydown: _ctx.onKeyDown
          }, null, 8, ["modelValue", "onKeydown"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_Button, {
            label: _ctx.$t('System-Administration.General.Send'),
            onClick: _ctx.sendAuth
          }, null, 8, ["label", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["blocked"]))
}