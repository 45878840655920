import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cf74ce7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-ml-2 p-p-2" }
const _hoisted_2 = { class: "header p-component" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckList = _resolveComponent("CheckList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('Squeeze.System.SystemCheck')), 1),
    _createVNode(_component_CheckList, {
      checks: _ctx.checks,
      allSystemCheckResults: _ctx.allSystemCheckResults,
      loading: _ctx.loading
    }, null, 8, ["checks", "allSystemCheckResults", "loading"])
  ]))
}