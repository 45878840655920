import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "login" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "p-field p-col-6" }
const _hoisted_6 = { for: "firstName" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = { class: "p-field p-col-6" }
const _hoisted_9 = { for: "lastName" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { for: "email" }
const _hoisted_13 = {
  key: 0,
  class: "p-error"
}
const _hoisted_14 = { class: "p-field p-col-6" }
const _hoisted_15 = { for: "password" }
const _hoisted_16 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Password = _resolveComponent("Password")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Login.Login')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "login",
        modelValue: _ctx.value.login,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.login) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.login.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update,
        disabled: _ctx.user.id || _ctx.user.externalUser
      }, null, 8, ["modelValue", "class", "onChange", "disabled"]),
      (_ctx.v$.login.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Login.Login')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.UserManagement.Firstname')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "firstName",
        modelValue: _ctx.value.firstName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.firstName) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.firstName.$invalid && _ctx.showErrorMessage}),
        disabled: _ctx.user.externalUser,
        onChange: _ctx.update
      }, null, 8, ["modelValue", "class", "disabled", "onChange"]),
      (_ctx.v$.firstName.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.UserManagement.Firstname')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('Squeeze.UserManagement.Lastname')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "lastName",
        modelValue: _ctx.value.lastName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.lastName) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.lastName.$invalid && _ctx.showErrorMessage}),
        disabled: _ctx.user.externalUser,
        onChange: _ctx.update
      }, null, 8, ["modelValue", "class", "disabled", "onChange"]),
      (_ctx.v$.lastName.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.UserManagement.Lastname')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "p-field p-col-6",
      disabled: _ctx.user.id
    }, [
      _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('Squeeze.UserManagement.Email')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "email",
        modelValue: _ctx.value.email,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.email) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.email.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update,
        disabled: !_ctx.store.state.scopes.sqzUsersManage || _ctx.user.externalUser,
        style: {"text-transform":"lowercase"}
      }, null, 8, ["modelValue", "class", "onChange", "disabled"]),
      (_ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_13, [
            (_ctx.v$.email.email.$invalid)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Forms.Val.Email')), 1)
                ], 64))
              : (_ctx.v$.email.$invalid)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.UserManagement.Email')})), 1)
                  ], 64))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_11),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('Squeeze.Login.Password')) + " *", 1),
      _createVNode(_component_Password, {
        id: "password",
        modelValue: _ctx.value.password,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.password) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.password.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update,
        feedback: false,
        toggleMask: "",
        disabled: _ctx.user.id || _ctx.user.externalUser
      }, null, 8, ["modelValue", "class", "onChange", "disabled"]),
      (_ctx.v$.password.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Login.Password')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}