import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-748834ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-text-bold" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.allData[_ctx.currentHelpFieldName] && Array.isArray(_ctx.allData[_ctx.currentHelpFieldName].data))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("table", null, [
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allData[_ctx.currentHelpFieldName].data, (info, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: index,
                  class: "p-p-2"
                }, [
                  _createElementVNode("td", _hoisted_2, _toDisplayString(info.version) + ": ", 1),
                  _createElementVNode("td", null, _toDisplayString(info.description), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.allData[_ctx.currentHelpFieldName].data), 1)),
    _createElementVNode("b", null, [
      _createElementVNode("a", {
        class: "urlText",
        href: 'https://docs.squeeze.one/books/squeeze-2-admin-handbuch/page/stapelklasseneigenschaften-ocr-texterkennung',
        target: "_blank"
      }, " (" + _toDisplayString(_ctx.$t('Squeeze.General.LinkToDocumentation')) + ") ", 9, _hoisted_4)
    ])
  ], 64))
}