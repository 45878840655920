
import {defineComponent, PropType} from 'vue';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from "primevue/inputtext";
import {SystemCleanupConfig} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";

export default defineComponent({
	name: "SystemCleanUpConfig",
	components: {
		IconField,
		InputIcon,
		InputText,
	},
	props: {
		systemCleanUpConfig: {
			type: Object as PropType<SystemCleanupConfig>,
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	setup(props) {
		return {props};
	},
});

