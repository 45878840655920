
import {defineComponent, reactive, ref, PropType} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {DocumentLocator, DocumentTable, DocumentTableColumn} from "@dex/squeeze-client-ts";
import EntryDialog from "@/components/EntryDialog.vue";
import TableColumnForm from "@/apps/administration/components/documentclasses/TableColumnForm.vue";
import DocumentClassTableColumns from "@/apps/administration/components/documentclasses/DocumentClassTableColumns.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import {ToastManager} from "@/util/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: 'DocumentClassTableColumnsSubView',
	components: {
		DialogDelete,
		TableColumnForm,
		DocumentClassTableColumns,
		EntryDialog,
	},
	props: {
		documentClassTables: {
			type: Array as PropType<DocumentTable[]>,
			default: () => [],
		},
		/** All rows */
		columns: {
			type: Array as PropType<DocumentTableColumn[]>,
			default: () => [],
		},
		/** All available Locators */
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: () => [],
		},
		tableId: {
			type: Number,
			default: 0,
		},
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	emits: ["getDocumentClassTables"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Service for getting the documentclass-data */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Component of the current form */
		const columnFormElement = ref<any>();

		/** Should the Entry-Dialog for a Column be shown? */
		const showCurrentTableColumnDialog = ref<boolean>(false);

		/** Should the Delete-Dialog for a Column be shown? */
		const showDeleteTableColumnDialog = ref<boolean>(false);

		/** List of all current table Columns */
		const currentTableColumns = ref<any[]>([]);

		/** Entry Data for Table-Column */
		const documentClassTableColumnEntry = reactive<DocumentTableColumn>({
			name: '',
			description: '',
			headerLocatorId: 0,
			valueLocatorId: 0,
			mandatory: false,
			readonly: false,
			hidden: false,
			forceValidation: false,
			externalName: '',
			maxlength: 0,
			truncateType: DocumentTableColumn.TruncateTypeEnum.None,
		});

		/** Show the loading for Save-Button of Column? */
		const loadingSaveColumn = ref<boolean>(false);

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Message To Show in Entry-Dialogs */
		const message = ref<string>('');

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref<boolean>(false);

		/** Current invalid tab of tableForm */
		const currentInValidTab = ref<number>(0);

		/** Current tab index of tableForm dialog */
		const currentDialogTabIndex = ref<number>(0);

		/**
		 * Triggered on update of attribute-form field
		 * @param {DocumentTable} data
		 * @param {boolean} valid
		 * @param {number} activeTabInValid
		 */
		const onUpdateTableColumn = (data: DocumentTable, valid: boolean, activeTabInValid: number) => {
			currentInValidTab.value = activeTabInValid;
			isInvalid.value = valid;
			Object.assign(documentClassTableColumnEntry, data);
		}

		/**
		 *  Is triggered when an entry should be edited/inserted from the Table
		 *  @param {DocumentTableColumn} rowData Row to Save
		 */
		const openCurrentTableColumnDialog = (rowData: DocumentTableColumn) => {
			if (rowData.id) {
				headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.TableColumn') });
			} else {
				headerText.value = t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.DocumentClasses.TableColumn') });
			}

			Object.assign(documentClassTableColumnEntry, rowData);
			message.value = "";
			if (props.documentClassTables && documentClassTableColumnEntry) {
				const currentTable = props.documentClassTables.find(table => table.id === documentClassTableColumnEntry.tableId);
				if (currentTable) {
					currentTableColumns.value = currentTable.columns!;
				}
			}

			showCurrentTableColumnDialog.value = true;
		}

		/**
		 * Saves a new field
		 * @param {boolean} reloadTableData Is the Save triggered from outside the Entry-Dialog?
		 * @param {boolean} keepDialogOpen
		 */
		const saveTableColumn = (reloadTableData: boolean, keepDialogOpen: boolean = false) => {
			if (isInvalid.value && reloadTableData) {
				showErrorMessage.value = true;

				// check current inValid tab
				if(currentInValidTab.value === 1 && currentDialogTabIndex.value !== 1) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.DocumentClasses.ErrorInputAssistance'));
				} else if (currentInValidTab.value === 0 && currentDialogTabIndex.value !== 0) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.DocumentClasses.ErrorFieldAttributes'));
				}

				return;
			}

			if (documentClassTableColumnEntry) {
				// set a default value when entry is null
				if (!documentClassTableColumnEntry.headerLocatorId) {
					documentClassTableColumnEntry.headerLocatorId = 0;
				}
				if (!documentClassTableColumnEntry.valueLocatorId) {
					documentClassTableColumnEntry.valueLocatorId = 0;
				}

				// The Api can't save null values, therefore use default 0
				if (documentClassTableColumnEntry.lookup?.tableId == null) {
					documentClassTableColumnEntry.lookup!.tableId = 0;
				}

				if (documentClassTableColumnEntry.lookup?.minInputLength == null) {
					documentClassTableColumnEntry.lookup!.minInputLength = 1;
				}

				// set always the maxLength to zero (0) as default
				if (documentClassTableColumnEntry.maxlength == null) {
					documentClassTableColumnEntry.maxlength = 0;
				}
			}

			showErrorMessage.value = false;
			loadingSaveColumn.value = true;

			if (documentClassTableColumnEntry && documentClassTableColumnEntry.tableId && documentClassTableColumnEntry.id) {
				documentClassService.putDocumentClassTableColumn(props.documentClassId, documentClassTableColumnEntry.tableId, documentClassTableColumnEntry.id, documentClassTableColumnEntry)
					.then(() => {
						showCurrentTableColumnDialog.value = false;

						if (reloadTableData) {
							emit("getDocumentClassTables", false);
						}
					})
					.catch(response => response.json().then((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.message);
					})).finally(() => {
						loadingSaveColumn.value = false;
					})
			} else if (documentClassTableColumnEntry && documentClassTableColumnEntry.tableId) {
				const table = props.documentClassTables.find(table => table.id === documentClassTableColumnEntry!.tableId);
				if (table) {
					const sortOrders = table.columns!.map(column => column.sortOrder);
					// Get highest sort order number and increase it by one
					if (sortOrders.length > 0) {
						const highestSortID = Math.max.apply(0, sortOrders as number[]) + 1;
						documentClassTableColumnEntry!.sortOrder! = highestSortID;
					}
				}

				documentClassService.postDocumentClassTableColumn(props.documentClassId, documentClassTableColumnEntry.tableId, documentClassTableColumnEntry)
					.then(() => {
						if (!keepDialogOpen) {
							showCurrentTableColumnDialog.value = false;
						} else {
							documentClassTableColumnEntry!.name = "";
							documentClassTableColumnEntry!.description = "";
							columnFormElement!.value.$el.querySelector('input').focus();
							isInvalid.value = true;
						}

						if (reloadTableData) {
							emit("getDocumentClassTables", false);
						}
					})
					.catch(response => response.json().then((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.message);
					})).finally(() => {
						loadingSaveColumn.value = false;
					})
			}
			else {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + t('Squeeze.General.UnexpectedError'));
			}
		}

		/**
		 * Is triggered when a checkbox in the Field-Table is clicked. When such a checkbox is clicked, simply save the entry
		 * @param {DocumentTableColumn} rowData Row to Save
		 * @param {string} fieldName Current field of checkboxes
		 */
		const changeCheckboxTableColumn = (rowData: DocumentTableColumn, fieldName: string) => {
			switch(fieldName) {
			case 'mandatory':
			case 'forceValidation': {
				// If a field is mandatory or "force Validation", it shouldn't be hidden
				if (rowData.mandatory === true || rowData.forceValidation === true) {
					rowData.hidden = false;
				}
				break;
			}
			case 'hidden': {
				// If a field is hidden, forcing the Validation can cause a falsy behavior in the validation
				if (rowData.hidden) {
					rowData.mandatory = false;
					rowData.forceValidation = false;
				}
				break;
			}
			}

			Object.assign(documentClassTableColumnEntry, rowData);
			saveTableColumn(false);
		}

		/**
		 * Opens the Delete-Dialog for the Table-Columns
		 * @param {DocumentTableColumn} rowData
		 */
		const openDeleteTableColumn = (rowData: DocumentTableColumn) => {
			Object.assign(documentClassTableColumnEntry, rowData);
			showDeleteTableColumnDialog.value = true;
		}

		/**
		 * Saves a Field from the dialog and emits if the dialog should be kept open
		 * @param {boolean} keepDialogOpen
		 */
		const saveColumnFromDialog = (keepDialogOpen: boolean) => {
			saveTableColumn(true, keepDialogOpen);
		}

		/** Deletes a Table-Column */
		const deleteTableColumn = () => {
			loadingSaveColumn.value = true;
			if (documentClassTableColumnEntry && documentClassTableColumnEntry.tableId && documentClassTableColumnEntry.id) {
				documentClassService.deleteDocumentClassTableColumn(props.documentClassId, documentClassTableColumnEntry.tableId, documentClassTableColumnEntry.id)
					.then(() => {
						emit("getDocumentClassTables", true);
						showDeleteTableColumnDialog.value = false;
					})
					.catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.DeleteError') + ": " + err.message);
					}).finally(() => {
						loadingSaveColumn.value = false;
					})
			}
		}

		/**
		 * Changes the order of document class fields
		 * @param {number[]} columns
		 * @param {number} tableId
		 * @param {boolean} isFilterActive
		 */
		const onChangeSortOrder = (columns: number[], tableId: number, isFilterActive: boolean) => {
			if (isFilterActive) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.ChangeError') + ": " + t('Squeeze.DocumentClasses.ActiveFilter'));
				return;
			}

			loadingSaveColumn.value = true; // FIXME: change and check the loading prop
			documentClassService.putDocumentClassTableColumnOrder(props.documentClassId, tableId, {elements: columns})
				.then(() => {
					emit("getDocumentClassTables", false);
				}).catch(response => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.message);
				})).finally(() => {
					loadingSaveColumn.value = false;
				})
		}

		/**
		 * Triggered on change tab
		 * @param {number} activeTabIndex
		 */
		const onTabChange = (activeTabIndex: number) => {
			currentDialogTabIndex.value = activeTabIndex;
		}

		return {
			t,
			toast,
			columnFormElement,
			showCurrentTableColumnDialog,
			showDeleteTableColumnDialog,
			currentTableColumns,
			documentClassTableColumnEntry,
			loadingSaveColumn,
			headerText,
			message,
			showErrorMessage,
			isInvalid,
			currentInValidTab,
			currentDialogTabIndex,
			onUpdateTableColumn,
			openCurrentTableColumnDialog,
			changeCheckboxTableColumn,
			saveColumnFromDialog,
			openDeleteTableColumn,
			deleteTableColumn,
			onChangeSortOrder,
			onTabChange,
		};
	},
});

