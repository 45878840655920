import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-167a898c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"left!important","white-space":"nowrap!important"} }
const _hoisted_2 = { class: "column-cell" }
const _hoisted_3 = { style: {"white-space":"nowrap!important"} }
const _hoisted_4 = { style: {"white-space":"nowrap!important"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    class: "p-shadow-2 p-datatable-sm",
    value: _ctx.queueEntries,
    lazy: true,
    paginator: true,
    loading: !_ctx.loaded || _ctx.lockTable,
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    rows: _ctx.paginationInfo.pageSize,
    totalRecords: _ctx.paginationInfo.total,
    columnResizeMode: "fit",
    responsiveLayout: "scroll",
    scrollable: true,
    scrollHeight: "flex",
    selection: _ctx.selectedRows,
    "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRows) = $event)),
    onRowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRowClick($event))),
    onPage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPage($event))),
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords} ',
    rowHover: true,
    metaKeySelection: false
  }, {
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Queue.Filter.NoEntries')), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _withDirectives(_createVNode(_component_Button, {
        type: "button",
        icon: "mdi mdi-refresh",
        class: "p-button-text",
        onClick: _ctx.onReload,
        disabled: !_ctx.loaded
      }, null, 8, ["onClick", "disabled"]), [
        [_directive_tooltip, _ctx.$t('Squeeze.General.Refresh')]
      ]),
      (_ctx.store.state.isAdminUser)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            type: "button",
            icon: "mdi mdi-file-refresh-outline",
            class: "p-button-text",
            label: _ctx.$t('Squeeze.Queue.Requeue.Requeue'),
            onClick: _ctx.openMultiRequeueMenu,
            disabled: !_ctx.selectedRows.length,
            "aria-haspopup": "true",
            "aria-controls": "multi_entry_menu"
          }, null, 8, ["label", "onClick", "disabled"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Menu, {
        id: "multi_entry_menu",
        ref: "multiEntryMenu",
        model: _ctx.steps,
        popup: true
      }, null, 8, ["model"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      (_ctx.store.state.isAdminUser)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            headerClass: "header-center",
            style: {"min-width":"2rem","max-width":"2rem","text-align":"center"},
            selectionMode: "multiple"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Column, {
        field: "id",
        header: _ctx.$t('Squeeze.Queue.Fields.id'),
        style: {"flex":"0 0 4rem","padding":"0.6rem"}
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "status",
        header: _ctx.$t('Squeeze.Queue.Fields.status'),
        style: {"padding":"0.6rem","max-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", {
              class: _normalizeClass(['status-badge ' + slotProps.data.workflowContext.status.toLowerCase(), "p-py-1"])
            }, _toDisplayString(_ctx.$t("Squeeze.Queue.States." + slotProps.data.workflowContext.status)), 3)
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "errorText",
        header: _ctx.$t('Squeeze.General.Comment'),
        style: {"padding":"0.6rem","min-width":"4rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(slotProps.data.workflowContext.errorText), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.workflowContext.errorText,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "createdTs",
        header: _ctx.$t('Squeeze.WorkflowContext.createdTs'),
        style: {"padding":"0.6rem"}
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatDate(slotProps.data.workflowContext.createdTs)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "modifiedTs",
        header: _ctx.$t('Squeeze.WorkflowContext.modifiedTs'),
        style: {"padding":"0.6rem"}
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formatDate(slotProps.data.workflowContext.modifiedTs)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, { style: {"min-width":"7rem","max-width":"7rem","text-align":"right"} }, {
        body: _withCtx((slotProps) => [
          (_ctx.store.state.isAdminUser)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                icon: "mdi mdi-file-refresh-outline",
                class: "p-button p-button-rounded p-button-success p-button-text",
                onClick: ($event: any) => (_ctx.openRequeueMenu($event, slotProps.data)),
                "aria-haspopup": "true",
                "aria-controls": "single_entry_menu"
              }, null, 8, ["onClick"])), [
                [_directive_tooltip, _ctx.$t('Squeeze.Queue.Requeue.Requeue')]
              ])
            : _createCommentVNode("", true),
          _createVNode(_component_Menu, {
            id: "single_entry_menu",
            ref: "singleEntryMenu",
            model: _ctx.steps,
            popup: true
          }, null, 8, ["model"]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button p-button-rounded p-button-danger p-button-text",
            disabled: ""
          }, null, 512), [
            [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "totalRecords", "selection", "currentPageReportTemplate"]))
}