import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "documentClassId" }
const _hoisted_4 = { class: "p-field p-col-12" }
const _hoisted_5 = { for: "active" }
const _hoisted_6 = { class: "p-field p-col-12" }
const _hoisted_7 = { for: "path" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "p-field p-col-6" }
const _hoisted_10 = { for: "indexFileType" }
const _hoisted_11 = { class: "p-field p-col-6" }
const _hoisted_12 = { for: "indexFileExtension" }
const _hoisted_13 = {
  key: 0,
  class: "p-error"
}
const _hoisted_14 = { class: "p-field p-col-6" }
const _hoisted_15 = { for: "signalFileName" }
const _hoisted_16 = {
  key: 0,
  class: "p-error"
}
const _hoisted_17 = { class: "p-field p-col-6" }
const _hoisted_18 = { for: "imageFileExtension" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.DocumentClass')), 1),
      _createVNode(_component_Dropdown, {
        id: "documentClassId",
        modelValue: _ctx.value.documentClassId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.documentClassId) = $event)),
        options: _ctx.documentClasses,
        loading: _ctx.loading,
        optionValue: "documentClassId",
        optionLabel: "description",
        onChange: _ctx.update,
        placeholder: _ctx.$t('Squeeze.General.None'),
        showClear: true
      }, null, 8, ["modelValue", "options", "loading", "onChange", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Squeeze.Imports.Active')), 1),
      _createVNode(_component_Dropdown, {
        id: "active",
        modelValue: _ctx.value.active,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.active) = $event)),
        options: _ctx.active,
        loading: _ctx.loading,
        optionValue: "id",
        optionLabel: "name",
        onChange: _ctx.update
      }, null, 8, ["modelValue", "options", "loading", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Squeeze.Imports.Path')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "path",
        modelValue: _ctx.value.path,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.path) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.path.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update
      }, null, 8, ["modelValue", "class", "onChange"]),
      (_ctx.v$.path.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Imports.Path')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('Squeeze.Imports.FileType')), 1),
      _createVNode(_component_Dropdown, {
        id: "indexFileType",
        modelValue: _ctx.value.indexFileType,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.indexFileType) = $event)),
        options: _ctx.indexFileType,
        loading: _ctx.loading,
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.update
      }, null, 8, ["modelValue", "options", "loading", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('Squeeze.Imports.IndexFileExtension')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "indexFileExtension",
        modelValue: _ctx.value.indexFileExtension,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.indexFileExtension) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.indexFileExtension.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update
      }, null, 8, ["modelValue", "class", "onChange"]),
      (_ctx.v$.indexFileExtension.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Imports.IndexFileExtension')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('Squeeze.Imports.FileName')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "signalFileName",
        modelValue: _ctx.value.signalFileName,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.signalFileName) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.signalFileName.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update
      }, null, 8, ["modelValue", "class", "onChange"]),
      (_ctx.v$.signalFileName.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Imports.FileName')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('Squeeze.Imports.ImageFileExtension')), 1),
      _createVNode(_component_Dropdown, {
        id: "imageFileExtension",
        modelValue: _ctx.value.imageFileExtension,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.value.imageFileExtension) = $event)),
        options: _ctx.imageFileExtension,
        loading: _ctx.loading,
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.update
      }, null, 8, ["modelValue", "options", "loading", "onChange"])
    ])
  ]))
}