import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-component p-fluid p-formgrid p-grid" }
const _hoisted_2 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_5 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_6 = { class: "p-field p-d-flex p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('Freeze.General.DownloadEntries', {total: _ctx.totalEntries, selectedLength: _ctx.selectedEntries.length})), 1),
      (_ctx.totalEntries > 100)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('Freeze.General.Note')), 1),
            _createTextVNode(": " + _toDisplayString(_ctx.$t('Freeze.General.DownloadInfo')), 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Dropdown, {
        id: "downloadSelection",
        modelValue: _ctx.downloadSelectValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.downloadSelectValue) = $event)),
        options: _ctx.downloadSelection,
        optionValue: "id",
        optionLabel: "name"
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('Freeze.General.HitList')), 1),
      _createVNode(_component_Dropdown, {
        id: "active",
        modelValue: _ctx.selectedMask,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedMask) = $event)),
        options: _ctx.searchMasks,
        optionLabel: "desc",
        placeholder: _ctx.$t('Freeze.General.HitList'),
        filter: true,
        autoFilterFocus: true,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "placeholder", "onShow"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('Freeze.General.Separator')), 1),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.selectedDelimiter,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedDelimiter) = $event)),
        options: _ctx.delimiter,
        optionLabel: "name",
        filter: true,
        autoFilterFocus: true,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "onShow"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.Download'),
        class: "p-button p-align-end",
        icon: "mdi mdi-download",
        onClick: _ctx.startDownload
      }, null, 8, ["label", "onClick"])
    ])
  ]))
}