import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-791a70a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container p-p-2" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecordList = _resolveComponent("RecordList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RecordList, {
        loading: _ctx.loading,
        pagination: _ctx.paginationOption,
        totalSize: _ctx.paginationOption.total,
        records: _ctx.records,
        searchFields: _ctx.searchFields,
        storeId: _ctx.storeId,
        filterOptions: _ctx.filters,
        firstEntry: (_ctx.paginationOption.page * _ctx.paginationOption.pageSize),
        searchMaskId: _ctx.searchMaskId,
        searchQuery: _ctx.searchQuery,
        sortField: _ctx.sortField,
        sortOrder: _ctx.sortOrder,
        fullText: _ctx.fullText,
        "onUpdate:fullText": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fullText) = $event)),
        onOnReload: _ctx.onReload,
        onOnRowSelect: _ctx.onRowSelect,
        onOnPage: _ctx.onPage,
        onOnSort: _ctx.onSort,
        onOnFilter: _ctx.onFilter,
        onClearSearch: _ctx.clearSearch
      }, null, 8, ["loading", "pagination", "totalSize", "records", "searchFields", "storeId", "filterOptions", "firstEntry", "searchMaskId", "searchQuery", "sortField", "sortOrder", "fullText", "onOnReload", "onOnRowSelect", "onOnPage", "onOnSort", "onOnFilter", "onClearSearch"])
    ])
  ]))
}