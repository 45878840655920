import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f27e8262"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"left!important","white-space":"nowrap!important"} }
const _hoisted_2 = { class: "mdi mdi-archive-outline" }
const _hoisted_3 = {
  key: 0,
  class: "mdi mdi-archive-outline"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { style: {"text-align":"left!important","white-space":"nowrap!important"} }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  class: "column-cell",
  style: {"white-space":"nowrap!important"}
}
const _hoisted_10 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputIcon = _resolveComponent("InputIcon")!
  const _component_IconField = _resolveComponent("IconField")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_QueueDeleteForm = _resolveComponent("QueueDeleteForm")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _component_HelpFunctionalityList = _resolveComponent("HelpFunctionalityList")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      ref: "documentList",
      class: "p-shadow-2 p-datatable-documents",
      value: _ctx.documents,
      lazy: true,
      paginator: true,
      loading: !_ctx.loaded || _ctx.lockTable,
      paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
      rowsPerPageOptions: [25,50,100],
      rows: _ctx.paginationInfo.pageSize,
      totalRecords: _ctx.paginationInfo.total,
      columnResizeMode: "fit",
      responsiveLayout: "scroll",
      scrollable: true,
      scrollHeight: "flex",
      selection: _ctx.selection,
      "onUpdate:selection": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selection) = $event)),
      rowHover: true,
      onRowClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onRowClick($event))),
      onPage: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onPage($event))),
      onFilter: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onFilter($event.filters))),
      filters: _ctx.filters,
      "onUpdate:filters": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filters) = $event)),
      filterDisplay: "row",
      sortMode: "multiple",
      onSort: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onSort($event))),
      removableSort: true,
      first: (_ctx.paginationInfo.page * _ctx.paginationInfo.pageSize),
      currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords} ',
      metaKeySelection: false
    }, {
      loading: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Queue.Filter.Loading')), 1)
      ]),
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Queue.Filter.NoEntries')), 1)
      ]),
      paginatorstart: _withCtx(() => [
        _withDirectives(_createVNode(_component_Button, {
          type: "button",
          icon: "mdi mdi-refresh",
          class: "p-button-text p-ml-r",
          onClick: _ctx.onReload,
          disabled: !_ctx.loaded
        }, null, 8, ["onClick", "disabled"]), [
          [_directive_tooltip, _ctx.$t('Squeeze.General.Refresh')]
        ]),
        _withDirectives(_createVNode(_component_Button, {
          type: "button",
          icon: "mdi mdi-filter-variant-remove",
          class: "p-button-text p-ml-r p-mr-2",
          onClick: _ctx.clearSearch,
          disabled: !_ctx.loaded
        }, null, 8, ["onClick", "disabled"]), [
          [_directive_tooltip, _ctx.$t('Squeeze.General.ClearSearch')]
        ]),
        _createVNode(_component_InputText, {
          style: {"width":"auto"},
          class: "filter-field",
          type: "text",
          value: _ctx.fullText,
          onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.emit('update:fullText', $event.target.value))),
          onKeydown: _withKeys(_ctx.onReload, ["enter"]),
          placeholder: _ctx.$t('Squeeze.General.Search') + '...'
        }, null, 8, ["value", "onKeydown", "placeholder"]),
        (_ctx.store.state.featureSet.savedDocumentSearches)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 0,
              type: "button",
              icon: "mdi mdi-filter-plus",
              class: "p-button-text p-ml-r p-ml-2",
              onClick: _ctx.showSaveSearch,
              disabled: !_ctx.loaded
            }, null, 8, ["onClick", "disabled"])), [
              [_directive_tooltip, _ctx.$t('Squeeze.General.SaveSearchList')]
            ])
          : _createCommentVNode("", true),
        (_ctx.store.state.featureSet.savedDocumentSearches && _ctx.showSelectSearch)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 1,
              type: "button",
              icon: "mdi mdi-filter-menu",
              class: "p-button-text p-ml-r",
              onClick: _ctx.openSelectSearch,
              disabled: !_ctx.loaded
            }, null, 8, ["onClick", "disabled"])), [
              [_directive_tooltip, _ctx.$t('Squeeze.General.OpenSearch')]
            ])
          : _createCommentVNode("", true),
        (_ctx.store.state.scopes.sqzRequeue && (_ctx.$route.name === 'DocumentListValidation' || _ctx.$route.name === 'DocumentListValidationWithSearch'))
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 2,
              type: "button",
              icon: "mdi mdi-file-refresh-outline",
              class: "p-button-text",
              label: _ctx.$t('Squeeze.Queue.Requeue.Requeue'),
              onClick: _ctx.openMultiRequeueMenu,
              disabled: !_ctx.selection.length,
              "aria-haspopup": "true",
              "aria-controls": "multi_entry_menu"
            }, null, 8, ["label", "onClick", "disabled"]))
          : _createCommentVNode("", true),
        (_ctx.store.state.scopes.sqzRequeue &&  (_ctx.$route.name === 'DocumentListValidation' || _ctx.$route.name === 'DocumentListValidationWithSearch'))
          ? (_openBlock(), _createBlock(_component_Menu, {
              key: 3,
              id: "multi_entry_menu",
              ref: "multiEntryMenu",
              model: _ctx.queueEntrySteps,
              popup: true
            }, null, 8, ["model"]))
          : _createCommentVNode("", true)
      ]),
      paginatorend: _withCtx(() => []),
      default: _withCtx(() => [
        (_ctx.store.state.scopes.sqzRequeue && (_ctx.$route.name === 'DocumentListValidation' || _ctx.$route.name === 'DocumentListValidationWithSearch'))
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              selectionMode: "multiple",
              headerClass: "header-center",
              style: {"min-width":"4rem","max-width":"4rem","text-align":"center"}
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "id",
          header: _ctx.$t('Squeeze.Queue.Fields.id'),
          style: {"min-width":"4rem","max-width":"4rem"},
          showFilterMenu: false,
          sortable: true
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"3rem","max-width":"4rem"},
              class: "filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              placeholder: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        (_ctx.$route.name !== 'DocumentListValidation' && _ctx.$route.name !== 'DocumentListValidationWithSearch')
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 1,
              field: "step",
              header: _ctx.$t('Squeeze.Queue.Fields.Step'),
              style: {"min-width":"10rem","max-width":"10rem"},
              filterField: "queueStep",
              showFilterMenu: false
            }, {
              body: _withCtx((document) => [
                _createElementVNode("div", _hoisted_1, [
                  (document.data.workflowContext.step !== 'validation' && document.data.exports && document.data.exports.find(exportData => exportData.interfaceName === 'EAS'))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t("Squeeze.Queue.StepsList.ARCHIVE")) + " ", 1),
                        _withDirectives(_createElementVNode("i", _hoisted_2, null, 512), [
                          [_directive_tooltip, _ctx.getExportsForToolTip(document.data.exports)]
                        ])
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t("Squeeze.Queue.StepsList." + document.data.workflowContext.step.toUpperCase())) + " ", 1),
                        (document.data.exports && document.data.exports.length > 0)
                          ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_3, null, 512)), [
                              [_directive_tooltip, _ctx.getExportsForToolTip(document.data.exports)]
                            ])
                          : _createCommentVNode("", true)
                      ], 64))
                ])
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  onChange: ($event: any) => (filterCallback()),
                  options: _ctx.steps,
                  placeholder: "",
                  class: "p-column-filter filter-field",
                  showFilterMenu: false,
                  showClear: true,
                  style: {"height":"2.16rem","min-width":"9rem","max-width":"9rem"}
                }, {
                  value: _withCtx((document) => [
                    (document.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("Squeeze.Queue.StepsList." + document.value.toUpperCase())), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(document.placeholder), 1))
                  ]),
                  option: _withCtx((document) => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Squeeze.Queue.Steps." + document.option)), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
              ]),
              _: 1
            }, 8, ["header"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "status",
          header: _ctx.$t('Squeeze.Queue.Fields.status'),
          style: {"min-width":"10rem","max-width":"10rem"},
          filterField: "queueStatus",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((document) => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", {
                class: _normalizeClass(['status-badge ' + document.data.workflowContext.status.toLowerCase(), "p-py-1"])
              }, _toDisplayString(_ctx.$t("Squeeze.Queue.States." + document.data.workflowContext.status)), 3)
            ])
          ]),
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onChange: ($event: any) => (filterCallback()),
              options: _ctx.statuses,
              placeholder: "",
              class: "p-column-filter filter-field",
              showClear: true,
              style: {"height":"2.16rem","min-width":"9rem","max-width":"9rem"}
            }, {
              value: _withCtx((document) => [
                (document.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("Squeeze.Queue.States." + document.value)), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(document.placeholder), 1))
              ]),
              option: _withCtx((document) => [
                _createElementVNode("span", {
                  class: _normalizeClass('status-badge ' + document.option.toLowerCase())
                }, _toDisplayString(_ctx.$t("Squeeze.Queue.States." + document.option)), 3)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "create_ts",
          filterField: "queueCreateTs",
          header: _ctx.$t('Squeeze.WorkflowContext.createdTs'),
          style: {"min-width":"12rem","max-width":"12rem"},
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((document) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createTextVNode(_toDisplayString(_ctx.formatDate(document.data.createdAt)), 1)
            ])), [
              [
                _directive_tooltip,
                _ctx.formatDate(document.data.createdAt),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_IconField, null, {
              default: _withCtx(() => [
                (_ctx.showDocumentationDialog && _ctx.currentFilterFieldName === 'queueCreateTs')
                  ? (_openBlock(), _createBlock(_component_InputIcon, {
                      key: 0,
                      class: "mdi mdi-comment-question-outline",
                      style: {"cursor":"pointer"},
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleOverlay($event)))
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_InputText, {
                  style: {"min-width":"11rem","max-width":"11rem"},
                  class: "filter-field",
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
                  placeholder: "",
                  onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickFilterFieldHelpIcon('queueCreateTs', 'date')))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "errorText",
          header: _ctx.$t('Squeeze.General.Comment'),
          style: {"min-width":"12rem","max-width":"12rem","overflow":"hidden"},
          filterField: "queueErrorText",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((document) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createTextVNode(_toDisplayString(document.data.workflowContext.errorText), 1)
            ])), [
              [
                _directive_tooltip,
                document.data.workflowContext.errorText,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_IconField, null, {
              default: _withCtx(() => [
                (_ctx.showDocumentationDialog && _ctx.currentFilterFieldName === 'queueErrorText')
                  ? (_openBlock(), _createBlock(_component_InputIcon, {
                      key: 0,
                      class: "mdi mdi-comment-question-outline",
                      style: {"cursor":"pointer"},
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleOverlay($event)))
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_InputText, {
                  style: {"min-width":"11rem","max-width":"11rem"},
                  class: "filter-field",
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
                  placeholder: "",
                  onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onClickFilterFieldHelpIcon('queueErrorText', 'text')))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["header"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldColumns, (column) => {
          return (_openBlock(), _createBlock(_component_Column, {
            field: column.name,
            filterField: '' + column.id,
            header: column.header,
            key: column.id,
            style: _normalizeStyle(column.type.toLowerCase() === 'amount' ? 'min-width: 12rem; overflow: hidden;' : 'min-width: 15rem; overflow: hidden;'),
            showFilterMenu: true,
            sortable: true
          }, {
            body: _withCtx((document) => [
              _withDirectives((_openBlock(), _createElementBlock("div", {
                style: _normalizeStyle(column.type.toLowerCase() === 'amount' ? 'text-align:right!important;' : 'text-align:left;'),
                class: "column-cell"
              }, [
                (document.data.fields['' + column.id] && document.data.fields['' + column.id].value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.formatValue(document.data.fields['' + column.id].value, column.type)), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 4)), [
                [
                  _directive_tooltip,
                  document.data.fields['' + column.id] && document.data.fields['' + column.id].value ? _ctx.formatValue(document.data.fields['' + column.id].value, column.type) : '',
                  void 0,
                  { bottom: true }
                ]
              ])
            ]),
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_IconField, null, {
                default: _withCtx(() => [
                  (_ctx.showDocumentationDialog && _ctx.currentFilterFieldName === column.name)
                    ? (_openBlock(), _createBlock(_component_InputIcon, {
                        key: 0,
                        class: "mdi mdi-comment-question-outline",
                        style: {"cursor":"pointer"},
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleOverlay($event)))
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_InputText, {
                    style: _normalizeStyle(column.type.toLowerCase() === 'amount' ? 'min-width: 12rem;' : 'min-width: 14rem;'),
                    class: "filter-field",
                    type: "text",
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
                    placeholder: "",
                    onFocus: ($event: any) => (_ctx.onClickFilterFieldHelpIcon(column.name, column.type))
                  }, null, 8, ["style", "modelValue", "onUpdate:modelValue", "onKeydown", "onFocus"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["field", "filterField", "header", "style"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "loading", "rows", "totalRecords", "selection", "filters", "first", "currentPageReportTemplate"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDelete,
      onOnClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showDelete = false, _ctx.isValidationInvalid = true, _ctx.showValidationMessage = false)),
      onOnConfirm: _cache[13] || (_cache[13] = ($event: any) => (_ctx.requeueSelectedEntries('Delete', _ctx.comment))),
      loading: !_ctx.loaded || _ctx.lockTable,
      headerText: _ctx.deleteHeaderText,
      saveButtonText: _ctx.$t('Squeeze.General.Delete')
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: false }, {
          default: _withCtx(() => [
            _createVNode(_component_QueueDeleteForm, {
              queueEntries: _ctx.selection,
              showErrorMessage: _ctx.showValidationMessage,
              onUpdate: _ctx.onUpdate
            }, null, 8, ["queueEntries", "showErrorMessage", "onUpdate"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show", "loading", "headerText", "saveButtonText"]),
    _createVNode(_component_OverlayPanel, { ref: "overlayFunctionalityList" }, {
      default: _withCtx(() => [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('Squeeze.Queue.InfoSearchOptions')), 1),
        _createVNode(_component_HelpFunctionalityList, { currentFilterFieldType: _ctx.currentFilterFieldType }, null, 8, ["currentFilterFieldType"])
      ]),
      _: 1
    }, 512)
  ], 64))
}