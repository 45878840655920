import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "value" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = { for: "description" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Locators.Value')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "value",
        modelValue: _ctx.value.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.value) = $event)),
        onChange: _ctx.update,
        class: _normalizeClass({'p-invalid':_ctx.v$.value.$invalid && _ctx.showErrorMessage}),
        autofocus: ""
      }, null, 8, ["modelValue", "onChange", "class"]),
      (_ctx.v$.value.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Locators.Value')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.Locators.Description')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "description",
        modelValue: _ctx.value.description,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.description) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.description.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update
      }, null, 8, ["modelValue", "class", "onChange"]),
      (_ctx.v$.description.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Locators.Description')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}