import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterListView = _resolveComponent("FilterListView")!
  const _component_RoleFieldConditionsView = _resolveComponent("RoleFieldConditionsView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FilterListView, {
      onOnListChange: _ctx.onChangeValue,
      roleId: _ctx.roleId
    }, null, 8, ["onOnListChange", "roleId"]),
    (_ctx.roleId && _ctx.filterId)
      ? (_openBlock(), _createBlock(_component_RoleFieldConditionsView, {
          key: 0,
          roleId: _ctx.roleId,
          filterId: _ctx.filterId,
          documentClassId: _ctx.documentClassId
        }, null, 8, ["roleId", "filterId", "documentClassId"]))
      : _createCommentVNode("", true)
  ], 64))
}