import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QueueNavigator = _resolveComponent("QueueNavigator")!
  const _component_DashboardTile = _resolveComponent("DashboardTile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isQueueDocClassesEmpty)
      ? (_openBlock(), _createBlock(_component_DashboardTile, {
          key: 0,
          title: _ctx.$t('Squeeze.Queue.Steps.Validation'),
          style: {"max-height":"calc(100vh - 14rem)","overflow-y":"auto"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_QueueNavigator, {
              step: _ctx.queueStep,
              customTiles: _ctx.customTilesValidation
            }, null, 8, ["step", "customTiles"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.store.state.featureSet.savedDocumentSearches && _ctx.customTiles.length > 0)
      ? (_openBlock(), _createBlock(_component_DashboardTile, {
          key: 1,
          title: _ctx.$t('Squeeze.Queue.Steps.Other'),
          style: {"max-height":"calc(100vh - 14rem)","overflow-y":"auto"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_QueueNavigator, {
              step: _ctx.emptyQueueStep,
              customTiles: _ctx.customTiles,
              isValidation: false
            }, null, 8, ["step", "customTiles"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}