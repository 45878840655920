
import Panel from 'primevue/panel';
import {computed, defineComponent, onMounted, ref} from "vue";
import RecoverPasswordForm from "@/components/RecoverPasswordForm.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import BlockUI from "primevue/blockui";
import router from "@/router";

export default defineComponent({
	name: "RecoverPassword",
	components: {Panel, RecoverPasswordForm, BlockUI},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		/** Should the ui be blocked? */
		const loading = ref(false);

		/** Service for public requests */
		const publicApi = ClientManager.getInstance().squeeze.public;

		/** Service for getting the theme-data */
		const themeService = ClientManager.getInstance().squeeze.theme;

		/** URL of client background image */
		const urlClientBackground = ref<string>('');

		/** Styles of background Image in login */
		const loginBackgroundImageStyles = computed(() => {
			return {
				'background-image': 'url('+urlClientBackground.value+')',
				'height': '100%',
				'background-position': 'center',
				'background-repeat': 'no-repeat',
				'background-size': 'cover',
			}
		})

		/** Sends a mail to reset the password to a specific user */
		const sendMail = (userName: string) => {
			loading.value = true;
			publicApi.sendResetPasswortEmail(userName).then(() => {
				ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Login.SendMailSuccess'));
				router.push({name: "Login"});
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/** Get background image of client */
		const getBackgroundImage = () => {
			themeService.getThemeFile('loginBackground.jpg')
				.then(data => {
					urlClientBackground.value = data.url;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		onMounted(() => {
			getBackgroundImage();
		})

		return {
			loading,
			urlClientBackground,
			loginBackgroundImageStyles,
			sendMail,
			getBackgroundImage,
		}
	},
})
