
import {computed, defineComponent, PropType, ref, watch} from "vue";
import FileUpload from "@/components/DexFileUpload.vue";
import {useI18n} from "vue-i18n";
import {UploadFile} from "@/shims-prime-vue";
import Dropdown from "primevue/dropdown";
import DialogDelete from "@/components/DialogDelete.vue";

export default defineComponent({
	name: "SystemThemeUploadForm",
	components: {
		FileUpload,
		Dropdown,
		DialogDelete,
	},
	props: {
		showErrorMessage: {
			type: Boolean,
		},
		selectedFiles: {
			type: Array as PropType<UploadFile[]>,
			default: () => [],
		},
	},
	emits: ['fileUploader', 'deleteThemeFile'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** List with Available File-Types */
		const fileTypes = computed(() => {
			return [
				{
					id: 'png',
					description: t('Squeeze.System.LoginLogoImage'),
				},
				{
					id: 'jpg',
					description: t('Squeeze.System.LoginBackgroundImage'),
				},
			]
		});

		/** Currently active File-Type */
		const activeFileType = ref('png');

		/** List of all files */
		const files = ref<UploadFile[]>([]);

		/** Progress of all uploaded documents */
		const progress = ref(0);

		/** Label of the Upload */
		const uploadLabel = ref(t("Squeeze.General.Upload"));

		/** Delete dialog */
		const deleteDialog = ref<boolean>(false);

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.selectedFiles, () => {
			files.value = props.selectedFiles;
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";
		})

		/** Triggered when Files are selected */
		const onSelectFiles =  async (event: any) => {
			// Reset array and only take last entry of the selected files-array which is too big if selected multiple times
			files.value = [];
			files.value[0] = event.files[event.files.length - 1];
			uploadLabel.value = t("Squeeze.General.Upload") + " (0/1)";
		}

		/**
		 * Triggered when the File-Upload is triggered.
		 * @param event
		 */
		const fileUploader = (event: any) => {
			emit("fileUploader", event.files, activeFileType.value);
		}

		/** Is triggered when the "clear" button is pressed in the Upload-Component */
		const clearFiles = () => {
			uploadLabel.value = t("Squeeze.General.Upload");
		}

		/**
		 * Is triggered when a single file is removed from upload
		 * @param event
		 */
		const removeFile = (event: any) => {
			files.value = event.files;
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";
		}

		/** Open the delete Dialog */
		const openDeleteDialog = () => {
			deleteDialog.value = true;
		}

		/** Set default settings in viewer  */
		const deleteThemeFile = () => {
			emit("deleteThemeFile", activeFileType.value);
		}

		return {
			t,
			props,
			progress,
			fileTypes,
			activeFileType,
			files,
			uploadLabel,
			deleteDialog,
			removeFile,
			fileUploader,
			onSelectFiles,
			clearFiles,
			openDeleteDialog,
			deleteThemeFile,
		}
	},
});
