import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-formgrid p-grid p-input-filled p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-6" }
const _hoisted_3 = { for: "company" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "p-field p-col-6" }
const _hoisted_6 = { for: "creditor" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = { class: "p-field p-col-12" }
const _hoisted_9 = { for: "documentclassid" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}
const _hoisted_11 = { class: "p-field p-col-12" }
const _hoisted_12 = { for: "fieldName" }
const _hoisted_13 = {
  key: 0,
  class: "p-error"
}
const _hoisted_14 = { class: "p-field p-col-12" }
const _hoisted_15 = { for: "xpath" }
const _hoisted_16 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.XmlMapper.Company')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "company",
        modelValue: _ctx.v$.company.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.company.$model) = $event)),
        modelModifiers: { trim: true },
        class: _normalizeClass({'p-invalid':_ctx.v$.company.$invalid && _ctx.showErrorMessage}),
        onKeyup: _ctx.update,
        required: "true",
        autofocus: ""
      }, null, 8, ["modelValue", "class", "onKeyup"]),
      (_ctx.v$.company.required.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.XmlMapper.Company')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.XmlMapper.Creditor')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "creditor",
        modelValue: _ctx.v$.creditor.$model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.creditor.$model) = $event)),
        modelModifiers: { trim: true },
        class: _normalizeClass({'p-invalid':_ctx.v$.creditor.$invalid && _ctx.showErrorMessage}),
        onKeyup: _ctx.update,
        required: "true"
      }, null, 8, ["modelValue", "class", "onKeyup"]),
      ((_ctx.v$.creditor.$invalid && _ctx.showErrorMessage))
        ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.XmlMapper.Creditor')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('Squeeze.XmlMapper.DocumentClass')) + " *", 1),
      _createVNode(_component_Dropdown, {
        id: "documentclassid",
        modelValue: _ctx.value.documentclassid,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.documentclassid) = $event)),
        options: _ctx.documentClasses,
        loading: _ctx.loading,
        class: _normalizeClass({'p-invalid':_ctx.v$.documentclassid.$invalid && _ctx.showErrorMessage}),
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.updateDocumentClass
      }, null, 8, ["modelValue", "options", "loading", "class", "onChange"]),
      (_ctx.v$.documentclassid.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.XmlMapper.DocumentClass')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('Squeeze.XmlMapper.FieldName')) + " *", 1),
      _createVNode(_component_Dropdown, {
        id: "fieldName",
        modelValue: _ctx.value.fieldName,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.fieldName) = $event)),
        options: _ctx.documentClassFields,
        loading: _ctx.loading,
        class: _normalizeClass({'p-invalid':_ctx.v$.documentclassid.$invalid && _ctx.showErrorMessage}),
        optionValue: "name",
        optionLabel: _ctx.getFieldDescription,
        onChange: _ctx.update,
        filter: true,
        autoFilterFocus: true,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "loading", "class", "optionLabel", "onChange", "onShow"]),
      (_ctx.v$.fieldName.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.XmlMapper.FieldName')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('Squeeze.XmlMapper.XPath')) + " *", 1),
      _createVNode(_component_Textarea, {
        id: "xpath",
        modelValue: _ctx.v$.xpath.$model,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$.xpath.$model) = $event)),
        modelModifiers: { trim: true },
        class: _normalizeClass({'p-invalid':_ctx.v$.xpath.$invalid && _ctx.showErrorMessage}),
        onKeyup: _ctx.update,
        autoResize: true,
        rows: "5",
        required: "true",
        style: {"width":"100%"}
      }, null, 8, ["modelValue", "class", "onKeyup"]),
      ((_ctx.v$.xpath.$invalid && _ctx.showErrorMessage))
        ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.XmlMapper.XPath')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}