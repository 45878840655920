import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e789c3f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.exportStates,
    loading: _ctx.loading,
    class: "p-datatable-sm",
    columnResizeMode: "fit",
    lazy: true,
    onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event))),
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    rows: _ctx.paginationInfo.pageSize,
    totalRecords: _ctx.paginationInfo.total,
    paginator: true,
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords} ',
    autoLayout: true,
    scrollable: true,
    scrollHeight: "flex"
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.DocumentClasses.ExportStates')})), 1)
    ]),
    paginatorstart: _withCtx(() => []),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "id",
        header: "ID",
        style: {"min-width":"4rem","max-width":"4rem"}
      }),
      _createVNode(_component_Column, {
        field: "documentId",
        header: _ctx.$t('Squeeze.DocumentClasses.Document'),
        style: {"min-width":"7rem","max-width":"7rem"}
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "interfaceId",
        header: _ctx.$t('Squeeze.DocumentClasses.ExportInterface'),
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.getNameFromExportInterface(slotProps.data.interfaceId)), 1)
          ])), [
            [
              _directive_tooltip,
              _ctx.getNameFromExportInterface(slotProps.data.interfaceId),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "status",
        header: _ctx.$t('Squeeze.DocumentClasses.Status'),
        style: {"min-width":"10rem"}
      }, null, 8, ["header"])
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "totalRecords", "currentPageReportTemplate"]))
}