
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentTable, DocumentTableColumn, XmlColumnFieldTraining} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import {DocumentClass} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import XmlMapperPositionList from "@/apps/administration/components/xmlmapper/XmlMapperPositionList.vue";
import XmlMappingPositionForm from "@/apps/administration/components/xmlmapper/XmlMappingPositionForm.vue";

export default defineComponent({
	name: "XmlPositionDataView",
	components: {
		DialogDelete,
		EntryDialog,
		XmlMapperPositionList: XmlMapperPositionList,
		XmlMappingPositionForm,
	},
	props: {},
	setup() {
		/** Document-Class-Api */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** XML-Class-Api */
		const xmlApi = ClientManager.getInstance().squeeze.xml;

		/** List of all Document-Classes */
		const documentClasses = ref<DocumentClass[]>([]);

		/** List of all tables */
		const documentClassTables = ref<DocumentTable[]>([]);

		/** List of all Columns of Table with th name LineItems */
		const allColumnsOfTableLineItems = ref<DocumentTableColumn[]>([]);

		/** List of all XML Mappings */
		const allMappingColumns = ref<XmlColumnFieldTraining[]>([]);
		const mappingColumns = ref<XmlColumnFieldTraining[]>([]);
		const mappingColumn = reactive<XmlColumnFieldTraining>({
			columnName: '',
			xpath: '',
		});

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Show loading in table? */
		const loading = ref<boolean>(false);

		/** Should the Entry-Dialog for classifications be shown? */
		const showDialog = ref<boolean>(false);

		/** Show the loading for Save-Button of classifications? */
		const loadingDialog = ref<boolean>(false);

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		const {t} = useI18n();
		const toast = useToast();

		/** List of all Document-Classes they not selected */
		const checkDocumentClass = ref<DocumentClass[]>([]);

		/** Triggered when (all) column values are valid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref(true);

		/** Show the global mapping? */
		const showGlobalMapping = ref(true);

		/**
		 * Triggered when document class changed
		 * @param data
		 * @param valid
		 * @param documentClassId
		 */
		const onChangeDocumentClass = (data: XmlColumnFieldTraining, valid: boolean, documentClassId: number) => {
			mappingColumn.columnName = "";
			isInvalid.value = valid;
			Object.assign(mappingColumn, data);
			if (documentClassId !== 0) {
				const tables = documentClassTables.value.filter(table => String(table.documentClassId) === String(documentClassId));
				const tableLineItems = tables.find(table => table.name === 'LineItems');
				allColumnsOfTableLineItems.value = tableLineItems && tableLineItems.columns ? tableLineItems.columns : [];
			} else {
				// find all tables with the name 'LineItems' and get they columns
				const tablesWithTheNameLineItems = documentClassTables.value.filter(table => table.name === 'LineItems');
				tablesWithTheNameLineItems.forEach(table => {
					if (table.columns) {
						if (allColumnsOfTableLineItems.value.length > 0) {
							allColumnsOfTableLineItems.value = allColumnsOfTableLineItems.value.concat(table.columns);
						} else {
							allColumnsOfTableLineItems.value = table.columns;
						}
					}
				});

				// Remove double entries
				allColumnsOfTableLineItems.value = allColumnsOfTableLineItems.value.filter((item, index, self) =>
					index === self.findIndex((t) => t.name === item.name)
				);
			}
		}

		/**
		 * Triggered on update of form
		 * @param data
		 * @param valid
		 */
		const onUpdate = (data: XmlColumnFieldTraining, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(mappingColumn, data);
		}

		// Define the priority based on defined table by P. Langer
		const priorityTable = [
			{ company: 'defined', creditor: 'defined', documentclassid: 'defined' }, // Prio 1
			{ company: 'defined', creditor: 'defined', documentclassid: '*' },        // Prio 2
			{ company: '*', creditor: 'defined', documentclassid: 'defined' },        // Prio 3
			{ company: '*', creditor: 'defined', documentclassid: '*' },              // Prio 4
			{ company: 'defined', creditor: '*', documentclassid: 'defined' },        // Prio 5
			{ company: '*', creditor: '*', documentclassid: 'defined' },              // Prio 6
			{ company: 'defined', creditor: '*', documentclassid: '*' },              // Prio 7
			{ company: '*', creditor: '*', documentclassid: '*' },                    // Prio 8
		];

		// Helper function to check if a value is "defined" (not "*")
		const isDefined = (value: any) => value !== '*';

		// Function to get the priority index
		const getPriorityIndex = (item: any) => {
			return priorityTable.findIndex(priority =>
				(priority.company === 'defined' ? isDefined(item.company) : priority.company === '*') &&
				(priority.creditor === 'defined' ? isDefined(item.creditor) : priority.creditor === '*') &&
				(priority.documentclassid === 'defined' ? isDefined(item.documentclassid) : priority.documentclassid === '*')
			);
		};

		/**
		 * Sorts entries by priority
		 */
		const sortEntriesByPriority = () => {
			mappingColumns.value.sort((a, b) => {
				const priorityA = getPriorityIndex(a);
				const priorityB = getPriorityIndex(b);

				// First compare based on the main priority (company, creditor, documentclassid)
				if (priorityA !== priorityB) {
					return priorityA - priorityB;
				}

				// If priorities are the same, sort by readonly (false has higher priority than true)
				if (a.readonly !== b.readonly) {
					return a.readonly === false ? -1 : 1;
				}

				return 0; // If all are equal
			});
		}

		/**
		 * Show global mapping or not?
		 * @param showGlobalMappingSet
		 */
		const setShowGlobalMapping = (showGlobalMappingSet: boolean)  => {
			if (showGlobalMappingSet) {
				mappingColumns.value = allMappingColumns.value;
				showGlobalMapping.value = true;
			} else {
				mappingColumns.value = allMappingColumns.value.filter(column => !column.readonly);
				showGlobalMapping.value = false;
			}

			sortEntriesByPriority();
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;

			const promise1 = documentClassApi.getAllDocumentClasses();
			const promise2 = documentClassApi.getDocumentClassTablesGlobal();
			const promise3 = xmlApi.getAllXmlColumnFieldTrainings();

			// Wait until promises are finished
			Promise.all([promise1, promise2, promise3]).then(values => {
				documentClasses.value = values[0] as DocumentClass[];
				documentClasses.value.unshift({id: 0, name: '*', description: t('Squeeze.XmlMapper.AnyValue')});
				documentClassTables.value = values[1] as DocumentTable[];
				// find all tables with the name 'LineItems' and get they columns
				const tablesWithTheNameLineItems = documentClassTables.value.filter(table => table.name === 'LineItems');
				tablesWithTheNameLineItems.forEach(table => {
					if (table.columns) {
						if (allColumnsOfTableLineItems.value.length > 0) {
							allColumnsOfTableLineItems.value = allColumnsOfTableLineItems.value.concat(table.columns);
						} else {
							allColumnsOfTableLineItems.value = table.columns;
						}
					}
				});

				mappingColumns.value = values[2] as XmlColumnFieldTraining[];
				allMappingColumns.value = values[2] as XmlColumnFieldTraining[];
				setShowGlobalMapping(showGlobalMapping.value);
				sortEntriesByPriority();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		onMounted(() =>{
			reloadData();
		});

		/** Triggered when entry button clicked */
		const onEntrySelect = (row: XmlColumnFieldTraining) => {
			if (row.id) {
				headerText.value = t('Squeeze.XmlMapper.EditXmlMapping');
			} else {
				headerText.value = t('Squeeze.XmlMapper.NewXmlMapping');
			}

			Object.assign(mappingColumn, row);
			showDialog.value = true;

			if (Number(row.documentclassid) !== 0) {
				const tables = documentClassTables.value.filter(table => String(table.documentClassId) === String(row.documentclassid));
				const tableLineItems = tables.find(table => table.name === 'LineItems');
				allColumnsOfTableLineItems.value = tableLineItems && tableLineItems.columns ? tableLineItems.columns : [];
			} else {
				// Remove double entries
				allColumnsOfTableLineItems.value = allColumnsOfTableLineItems.value.filter((item, index, self) =>
					index === self.findIndex((t) => t.name === item.name)
				);
			}
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: XmlColumnFieldTraining) => {
			deleteDialog.value = true;
			Object.assign(mappingColumn, row);
		}

		/** Deletes the selected entry */
		const deleteEntry = () => {
			loading.value = true;
			if (mappingColumn.id) {
				xmlApi.deleteXmlColumnFieldTraining(mappingColumn.id)
					.then(() => {
						reloadData();
					}).catch((err) => {
						loading.value = false;
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.statusText);
					})
			}
		}

		/** Saves a mapping */
		const saveMapping = () => {
			showErrorMessage.value = false;
			if (!isInvalid.value) {
				loadingDialog.value = true;
				const saveMapping = mappingColumn as any;

				if (saveMapping.documentclassid === 0) {
					saveMapping.documentclassid = "*"
				} else {
					saveMapping.documentclassid = String (saveMapping.documentclassid);
				}

				if (mappingColumn.id) {
					xmlApi.updateXmlColumnFieldTraining(mappingColumn.id, mappingColumn as any)
						.then(() => {
							reloadData();
							showDialog.value = false;
						}).catch(response => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						})).finally(() => {
							loadingDialog.value = false;
						})
				}
				else {
					xmlApi.createXmlColumnFieldTraining(saveMapping as any)
						.then(() => {
							reloadData();
							showDialog.value = false;
						}).catch(response => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						})).finally(() => {
							loadingDialog.value = false;
						})
				}
			} else {
				showErrorMessage.value = true;
			}
		}

		return {
			loading,
			deleteDialog,
			documentClasses,
			documentClassTables,
			allColumnsOfTableLineItems,
			showDialog,
			loadingDialog,
			checkDocumentClass,
			showErrorMessage,
			isInvalid,
			mappingColumns,
			mappingColumn,
			headerText,
			openDeleteDialog,
			deleteEntry,
			reloadData,
			onEntrySelect,
			onUpdate,
			saveMapping,
			onChangeDocumentClass,
			setShowGlobalMapping,
		}
	},
})
