
import {defineComponent, onBeforeMount, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import Dropdown from "primevue/dropdown";
import { Chart } from "chart.js";
import moment from "moment";
import {Report} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import {showDropdownOverlay} from "@/util/StylesHelper";

interface NameValuePair {
	name: string;
	value: number;
}

interface NameValueOptionalPair {
	name: string;
	value?: number;
}

export default defineComponent({
	name: "DocumentReportExportedDocuments",
	methods: {showDropdownOverlay},
	components: {
		Dropdown,
	},
	setup() {
		const {t, locale} = useI18n();
		const toast = useToast();

		/** Current year */
		const currentYear = ref<number>(moment().year());

		/** Current month */
		const currentMonth = ref<number|undefined>(moment().month() +1);

		/** Selected month */
		const selectedMonth = ref<NameValueOptionalPair>({name: ''});

		/** Selected year */
		const selectedYear = ref<NameValuePair>({name: currentYear.value.toString(), value: currentYear.value});

		/** List of all months */
		const months = ref<NameValuePair[]>([]);

		/** Lit of all years */
		const years = ref<NameValuePair[]>([]);

		/** Chart data */
		const chartData = ref<number[]>([]);

		/** Chart labels */
		const chartLabels = ref<string[]>([]);

		/** Label of data set */
		const dataSetLabel = ref<string>('');

		/** Service for queue api */
		const queueAPI = ClientManager.getInstance().squeeze.queue;

		/** Get all months in current language */
		const getMonths = () => {
			const allMonths = moment.months();
			for (let i = 0; i < allMonths.length; i++) {
				const month = allMonths[i];
				months.value.push({name: month, value: i +1});
			}
		}

		/** Create the chart */
		const createChart = () => {
			const canvas = document.getElementById('exportedDocumentsReportChart') as HTMLCanvasElement;
			const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
			const currentColor = window.getComputedStyle(canvas).getPropertyValue('--dex-secondary-dark-color');
			new Chart(ctx, {
				type: 'line',
				data: {
					labels: chartLabels.value,
					datasets: [{
						label: 'Report ' + dataSetLabel.value,
						data: chartData.value,
						borderWidth: 3,
						borderColor: currentColor,
						backgroundColor: currentColor,
					}],
				},
				options: {
					scales: {
						y: {
							beginAtZero: true,
						},
					},
				},
			});
		}

		/** Get current chart data */
		const getChartData = async () => {
			try {
				let queueReportData: Report | null = null;
				if (currentMonth.value != undefined) {
					queueReportData = await queueAPI.getQueueReportExportedDocuments(currentYear.value, currentMonth.value);
				} else {
					queueReportData = await queueAPI.getQueueReportExportedDocuments(currentYear.value);
				}

				// Build labels for x axis
				const labels: string[] = [];
				if (queueReportData.labels) {
					for (const i of queueReportData.labels) {
						if (queueReportData.labels.length <= 13) {
							labels.push(moment(i, "MM").format("MMM"));
						} else {
							labels.push(String(i));
						}
					}
				}
				chartLabels.value = labels;

				// Build data
				if (queueReportData.datasets && queueReportData.datasets.length > 0
					&& queueReportData.datasets[0] && queueReportData.datasets[0].data && queueReportData.datasets[0].data.length > 0) {
					chartData.value = queueReportData.datasets[0].data;
				}

				// Build Year and Month format
				if (currentMonth.value == null) {
					currentYear.value = parseInt(moment(currentYear.value, "YYYY").format("YYYY"));
				} else {
					currentYear.value = parseInt(moment(currentYear.value && currentMonth.value, "YYYY MM").format("MMMM YY"));
				}

				// Build Chart
				const presentChart = Chart.getChart("exportedDocumentsReportChart");
				if (presentChart) {
					presentChart.destroy();
					createChart();
				} else {
					dataSetLabel.value = selectedMonth.value.name + ' ' + selectedYear.value.name;
					createChart();
				}
			} catch (err: any) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), err);
			}
		}

		/** Triggered when a year is selected to load the report of a year */
		const loadYearReport = () => {
			currentYear.value = selectedYear.value.value;
			currentMonth.value = undefined;
			dataSetLabel.value = selectedYear.value.name;
			getChartData();
		}

		/** Triggered when month is selected to load the report of month */
		const loadMonthReport = () => {
			currentYear.value = selectedYear.value.value;
			currentMonth.value = selectedMonth.value.value;
			dataSetLabel.value = selectedMonth.value.name + ' ' + selectedYear.value.name;
			getChartData();
		}

		/**
		 * Triggered when a month is changed
		 * @param event
		 */
		const onChangeMonths = (event: { originalEvent: PointerEvent; value: { name: string; value: number } }) => {
			selectedMonth.value = event.value;
			if (selectedMonth.value) {
				loadMonthReport();
			} else {
				loadYearReport();
			}
		}

		/**
		 * Triggered when a year is changed
		 * @param event
		 */
		const onChangeYears = (event: { originalEvent: PointerEvent; value: { name: string; value: number } }) => {
			selectedYear.value = event.value;
			currentYear.value = selectedYear.value.value;
			dataSetLabel.value = selectedYear.value.name;
			getChartData();
		}

		onBeforeMount(async () => {
			await getChartData();
			getMonths();

			// set current month
			selectedMonth.value = {name: moment().format("MMMM"), value: currentMonth.value};

			for (let i = 0; i < 31; i++) {
				const year = moment().add(-i, 'years').format('YYYY');
				years.value.push({name: year, value: parseInt(year)});
			}
		})

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(locale, () => {
			months.value.splice(0);
			getMonths();

			selectedMonth.value = {name: moment().format("MMMM"), value: currentMonth.value};
			dataSetLabel.value = selectedMonth.value.name + ' ' + selectedYear.value.name;

			const presentChart = Chart.getChart("exportedDocumentsReportChart");
			if (presentChart) {
				presentChart.destroy();
				createChart();
			}
		})

		return {
			t,
			locale,
			toast,
			currentYear,
			currentMonth,
			selectedMonth,
			selectedYear,
			months,
			years,
			chartData,
			chartLabels,
			dataSetLabel,
			loadYearReport,
			loadMonthReport,
			onChangeMonths,
			onChangeYears,
		};
	},
});

