import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6133fc8f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "p-grid nested-grid p-p-2 p-m-0",
  style: {"height":"100vh"}
}
const _hoisted_2 = { class: "p-col-2 p-col-fixed p-shadow-4 p-p-2" }
const _hoisted_3 = { class: "container p-col-10 p-pt-0 p-pr-0 p-pb-0" }
const _hoisted_4 = { class: "header p-col-12 p-mb-2 p-p-2" }
const _hoisted_5 = { class: "content p-col-12 p-p-0 p-shadow-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_SqueezeBreadcrumb = _resolveComponent("SqueezeBreadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Menu, {
        model: _ctx.items,
        class: "dex_menu_admin p-mb-2"
      }, {
        submenuheader: _withCtx(({ item }) => [
          _createElementVNode("span", null, _toDisplayString(item.label), 1)
        ]),
        item: _withCtx(({item}) => [
          (item.to)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.to,
                class: "p-menuitem-link"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["p-menuitem-icon", item.icon])
                  }, null, 2),
                  _createElementVNode("span", {
                    class: _normalizeClass(["p-menuitem-text", item.to.name === 'MigrationView' ? 'migration-badge' : ''])
                  }, [
                    _createTextVNode(_toDisplayString(item.label) + " ", 1),
                    (item.to.name === 'MigrationView' && _ctx.store.state.errorMigrations.length > 0)
                      ? (_openBlock(), _createBlock(_component_Badge, {
                          key: 0,
                          value: _ctx.store.state.errorMigrations.length,
                          severity: "danger"
                        }, null, 8, ["value"]))
                      : _createCommentVNode("", true)
                  ], 2)
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SqueezeBreadcrumb)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.route.name !== 'QueueEntryView'
				&& _ctx.route.name !== 'DocumentClassView'
				&& _ctx.route.name !== 'DocumentClassListView'
				&& _ctx.route.name !== 'DocumentClassFieldsSubView'
				&& _ctx.route.name !== 'DocumentClassTablesSubView'
				&& _ctx.route.name !== 'DocumentClassExportInterfaceSubView'
				&& _ctx.route.name !== 'DocumentClassExportStatesSubView'
				&& _ctx.route.name !== 'MapperView'
				&& _ctx.route.name !== 'MigrationView'
				&& _ctx.route.name !== 'MailView'
				&& _ctx.route.name !== 'MailSubView'
				&& _ctx.route.name !== 'PasswordListView'
				&& _ctx.route.name !== 'ApiDocumentationView'
				&& _ctx.route.name !== 'UserManagementRolesView'
				&& _ctx.route.name !== 'UserManagementRolesTabsView'
				&& _ctx.route.name !== 'UserManagementView'
				&& _ctx.route.name !== 'UserTabsView'
				&& _ctx.route.name !== 'MasterDataListView'
				&& _ctx.route.name !== 'MasterDataView'
				&& _ctx.route.name !== 'LocatorListView'
				&& _ctx.route.name !== 'LocatorSubView'
				&& _ctx.route.name !== 'BatchClassListView'
				&& _ctx.route.name !== 'ClassificationListView'
				&& _ctx.route.name !== 'OcrConfigurationInterfaceView'
				&& _ctx.route.name !== 'BatchClassAttributesView'
				&& _ctx.route.name !== 'BatchClassSubView' ? 'scroll-content' : 'full-height-container')
        }, [
          _createVNode(_component_router_view)
        ], 2)
      ])
    ])
  ]))
}