/* eslint max-lines: off */
import {NavigationGuardNext, RouteRecordRaw} from "vue-router";
import App from "../App.vue";
import DashboardView from "../views/Dashboard.vue";
import Administration from "@/apps/administration/App.vue"
import DocumentClassFieldGroupsSubView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassFieldGoupsSubView.vue";
import DocumentClassView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassView.vue";
import DocumentClassAttributesSubView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassAttributesSubView.vue";
import DocumentClassExportInterfaceSubView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassExportInterfaceSubView.vue";
import LocatorListView from "@/apps/administration/views/squeeze/locators/LocatorListView.vue";
import LocatorView from "@/apps/administration/views/squeeze/locators/LocatorView.vue";
import LocatorSubView  from "@/apps/administration/views/squeeze/locators/LocatorSubView.vue";
import LocatorAttributesSubView from "@/apps/administration/views/squeeze/locators/LocatorAttributesSubView.vue";
import DocumentClassListView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassListView.vue";
import DocumentClassSubView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassSubView.vue";
import DocumentClassTablesSubView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassTablesSubView.vue";
import BatchClassListView from "@/apps/administration/views/squeeze/batchclasses/BatchClassListView.vue";
import BatchClassView from "@/apps/administration/views/squeeze/batchclasses/BatchClassView.vue";
import BatchClassSubView from "@/apps/administration/views/squeeze/batchclasses/BatchClassSubView.vue";
import ClassificationListView from "@/apps/administration/views/squeeze/batchclasses/ClassificationListView.vue";
import BatchClassAttributesView from "@/apps/administration/views/squeeze/batchclasses/BatchClassAttributesView.vue";
import SubstitutionsView from "@/apps/administration/views/squeeze/locators/tabs/SubstitutionsView.vue";
import RegexView from "@/apps/administration/views/squeeze/locators/tabs/RegexView.vue";
import MasterDataListView from "@/apps/administration/views/squeeze/mastedata/MasterDataListView.vue";
import MasterDataView from "@/apps/administration/views/squeeze/mastedata/MasterDataView.vue";
import PasswordListView from "@/apps/administration/views/squeeze/Imports/PasswordListView.vue";
import MailSubView from "@/apps/administration/views/squeeze/Imports/MailSubView.vue";
import MailView from "@/apps/administration/views/squeeze/Imports/MailView.vue";
import FileSystemView from "@/apps/administration/views/squeeze/Imports/FileSystemView.vue";
import UserListView from "@/apps/administration/views/squeeze/users/UserListView.vue";
import RolesListView from "@/apps/administration/views/squeeze/roles/RolesListView.vue";
import RoleTabsView from "@/apps/administration/views/squeeze/roles/RoleTabsView.vue";
import UserTabsView from "@/apps/administration/views/squeeze/users/UserTabsView.vue";
import MigrationView from "@/apps/administration/views/squeeze/system/MigrationView.vue";
import CheckView from "@/apps/administration/views/squeeze/system/CheckView.vue";
import SystemResetView from "@/apps/administration/views/squeeze/system/SystemResetView.vue";
import QueueEntryView from "@/apps/administration/views/squeeze/queue/QueueEntryView.vue";
import DocumentClassExportStatesSubView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassExportStatesSubView.vue";
import JobManagementView from "@/apps/administration/views/squeeze/system/JobManagementView.vue";
import JobRunsView from "@/apps/administration/views/squeeze/system/JobRunsView.vue";
import ScriptsView from "@/apps/administration/views/squeeze/system/ScriptsView.vue";
import ApiDocumentationView from "@/apps/administration/views/squeeze/system/ApiDocumentationView.vue";
import SystemView from "@/apps/administration/views/squeeze/system/SystemView.vue";
import LanguagesView from "@/apps/administration/views/squeeze/system/LanguagesView.vue";
//import TestXRechnungView from "@/apps/administration/views/squeeze/documentclasses/TestXRechnungView.vue";
import {squeezeStore} from "@/apps/squeeze/store";
import ConfigurationsView from "@/apps/administration/views/squeeze/system/ConfigurationsView.vue";
import OcrConfigurationInterfaceView from "@/apps/administration/views/squeeze/batchclasses/OcrConfigurationInterfaceView.vue";
import ReportResetView from "@/apps/administration/views/squeeze/system/ReportResetView.vue";
import MapperView from "@/apps/administration/views/squeeze/documentclasses/MapperView.vue";
import XmlMapperView from "@/apps/administration/views/squeeze/xmlmapper/XmlMapperView.vue";
import XmlHeadDataView from "@/apps/administration/views/squeeze/xmlmapper/XmlHeadDataView.vue";
import XmlPositionDataView from "@/apps/administration/views/squeeze/xmlmapper/XmlPositionDataView.vue";

/** Check the route permission
 * @param next The navigation guard next
 * @param permission the route permission of scopes or feature flags
 */
const checkRoutePermission = (next: NavigationGuardNext, permission: boolean) => {
	if (permission) {
		next();
	} else {
		next({ name: 'ErrorPage', params: { reason: 'forbidden' }, query: { status: '401' }});
	}
};

/**
 * @param pathBase URL path that defines this app.
 */
export function createRoutes(pathBase: string): Array<RouteRecordRaw> {
	return [
		{
			path: pathBase,
			name: "Administration",
			component: App,
			redirect: { name: 'QueueEntryView', params: {stepName: "Validation"} },
			children: [
				{
					path: "/",
					component: DashboardView,
				},
				{
					path: 'squeeze',
					name: "Administration",
					component: Administration,
				},
				{
					path: 'documentclasses',
					name: "DocumentClassListView",
					component: DocumentClassListView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzDocumentClasses);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'DocumentClasses',
									to: {name: 'DocumentClassListView'},
								},
							]
						},
					},
				},
				{
					path: 'documentclasses/:documentClassId',
					name: "DocumentClassView",
					component: DocumentClassView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzDocumentClasses);
					},
					props: function(route) {
						return {
							documentClassId: Number(route.params.documentClassId), // Params are always passed as string
						};
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'DocumentClasses',
									to: {name: 'DocumentClassListView'},
								},
								{
									label: '',
									to: {name: 'DocumentClassView'},
									customTranslation: true,
									customDropdown: true,
								},
							]
						},
					},
					redirect: {
						name: "DocumentClassSubView",
					},
					children: [
						{
							path: 'general',
							name: 'DocumentClassSubView',
							component: DocumentClassSubView,
							props: function(route) {
								return {
									documentClassId: Number(route.params.documentClassId), // Params are always passed as string
								};
							},
						},
						{
							path: 'fields',
							name: "DocumentClassFieldGroupsSubView",
							component: DocumentClassFieldGroupsSubView,
							props: function(route) {
								return {
									documentClassId: Number(route.params.documentClassId), // Params are always passed as string
								};
							},
						},
						{
							path: 'tables',
							name: "DocumentClassTablesSubView",
							component: DocumentClassTablesSubView,
							props: function(route) {
								return {
									documentClassId: Number(route.params.documentClassId), // Params are always passed as string
								};
							},
						},
						{
							path: 'exportinterfaces',
							name: "DocumentClassExportInterfaceSubView",
							component: DocumentClassExportInterfaceSubView,
							props: function(route) {
								return {
									documentClassId: Number(route.params.documentClassId), // Params are always passed as string
								};
							},
						},
						{
							path: 'exportinterfaces/:exportId/exportstates',
							name: "DocumentClassExportStatesSubView",
							component: DocumentClassExportStatesSubView,
							props: function(route) {
								return {
									documentClassId: Number(route.params.documentClassId), // Params are always passed as string
									exportId: Number(route.params.exportId), // Params are always passed as string
								};
							},
						},
						{
							path: 'attributes',
							name: "DocumentClassAttributesSubView",
							component: DocumentClassAttributesSubView,
							props: function(route) {
								return {
									documentClassId: Number(route.params.documentClassId), // Params are always passed as string
								};
							},
						},
						{
							path: 'xml-mapper',
							name: "MapperView",
							component: MapperView,
							beforeEnter(to, from, next) {
								checkRoutePermission(next, (squeezeStore.state.featureSet.xmlEditor) ?? false);
							},
							props: function(route) {
								return {
									documentClassId: Number(route.params.documentClassId), // Params are always passed as string
								};
							},
						},
					],
				},
				/*{
					path: 'documentclasses/xrechnung',
					name: "TestXRechnungView",
					component: TestXRechnungView,
					meta: {
						breadCrumb() {
							return [
								{
									label: 'XRechnung',
									to: {name: 'TestXRechnungView'},
								},
							]
						},
					},
				},*/
				{
					path: 'locators',
					name: "LocatorListView",
					component: LocatorListView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzLocators);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Locators',
									to: {name: 'LocatorListView'},
								},
							]
						},
					},
				},
				{
					path: "locators/:locatorId",
					name: "LocatorLoadView",
					component: LocatorView,
					props: function(route) {
						return {
							locatorId: Number(route.params.locatorId), // Params are always passed as string
						};
					},
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzLocators);
					},
					redirect: {
						name: "LocatorView",
					},
				},
				{
					path: "locators/:locatorId",
					name: "LocatorView",
					component: LocatorView,
					props: function(route) {
						return {
							locatorId: Number(route.params.locatorId), // Params are always passed as string
						};
					},
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzLocators);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Locators',
									to: {name: 'LocatorListView'},
								},
								{
									label: '',
									to: {name: 'LocatorView'},
									customTranslation: true,
									customDropdown: true,
								},
							]
						},
					},
					redirect: {
						name: "LocatorSubView",
					},
					children: [
						{
							path: 'general',
							name: "LocatorSubView",
							component: LocatorSubView,
							props: function(route) {
								return {
									locatorId: Number(route.params.locatorId), // Params are always passed as string
								};
							},
						},
						{
							path: 'attributes',
							name: "LocatorAttributesSubView",
							component: LocatorAttributesSubView,
							props: function(route) {
								return {
									locatorId: Number(route.params.locatorId), // Params are always passed as string
								};
							},
						},
						{
							path: 'substitutions',
							name: "SubstitutionsView",
							component: SubstitutionsView,
							props: function(route) {
								return {
									locatorId: Number(route.params.locatorId), // Params are always passed as string
								};
							},
						},
						{
							path: 'regex',
							name: "RegexView",
							component: RegexView,
							props: function(route) {
								return {
									locatorId: Number(route.params.locatorId), // Params are always passed as string
								};
							},
						},
					],
				},
				{
					path: 'queue/:stepName?',
					name: "QueueEntryView",
					component: QueueEntryView,
					props: function(route) {
						return {
							stepName: route.params.stepName,
							status: route.params.status,
						};
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Queue',
									to: {name: 'QueueEntryView', params: {
										stepName: 'Validation',
									}},
								},
							]
						},
					},
				},
				{
					path: 'bc',
					name: "BatchClassListView",
					component: BatchClassListView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzBatchClasses);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'BatchClasses',
									to: {name: 'BatchClassListView'},
								},
							]
						},
					},
				},
				{
					path: 'bc/:batchClassId',
					name: "BatchClassView",
					component: BatchClassView,
					props: function(route) {
						return {
							batchClassId: Number(route.params.batchClassId), // Params are always passed as string
						};
					},
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzBatchClasses);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'BatchClasses',
									to: {name: 'BatchClassListView'},
								},
								{
									label: '',
									to: {name: 'BatchClassView'},
									customTranslation: true,
									customDropdown: true,
								},
							]
						},
					},
					redirect: {
						name: "BatchClassSubView",
					},
					children: [
						{
							path: 'general',
							name: 'BatchClassSubView',
							component: BatchClassSubView,
							props: function(route) {
								return {
									batchClassId: Number(route.params.batchClassId), // Params are always passed as string
								};
							},
						},
						{
							path: 'ocr',
							name: 'OcrConfigurationInterfaceView',
							component: OcrConfigurationInterfaceView,
							props: function(route) {
								return {
									batchClassId: Number(route.params.batchClassId), // Params are always passed as string
								};
							},
						},
						{
							path: 'classifications',
							name: 'ClassificationListView',
							component: ClassificationListView,
							props: function(route) {
								return {
									batchClassId: Number(route.params.batchClassId), // Params are always passed as string
								};
							},
						},
						{
							path: 'attributes',
							name: 'BatchClassAttributesView',
							component: BatchClassAttributesView,
							props: function(route) {
								return {
									batchClassId: Number(route.params.batchClassId), // Params are always passed as string
								};
							},
						},
					],
				},
				{
					path: 'masterdata',
					name: 'MasterDataListView',
					component: MasterDataListView,
					meta: {
						breadCrumb() {
							return [
								{
									label: 'MasterDataList',
									to: {name: 'MasterDataListView'},
								},
							]
						},
					},
				},
				{
					path: 'masterdata/:masterDataTableId',
					name: 'MasterDataView',
					component: MasterDataView,
					props: function(route) {
						return {
							masterDataTableId: Number(route.params.masterDataTableId), // Params are always passed as string
						};
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'MasterDataList',
									to: {name: 'MasterDataListView'},
								},
								{
									label: '',
									to: {name: 'MasterDataView'},
									customTranslation: true,
								},
							]
						},
					},
				},
				{
					path: 'xmlmapper',
					name: 'XmlMapperView',
					component: XmlMapperView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, (squeezeStore.state.featureSet.xmlTraining) ?? false);
					},
					meta: {},
					redirect: {
						name: "XmlHeadDataView",
					},
					children: [
						{
							path: 'head',
							name: 'XmlHeadDataView',
							component: XmlHeadDataView,
							meta: {
								translate: true,
								breadCrumb() {
									return [
										{
											label: 'XmlMapping',
											to: {name: 'XmlHeadDataView'},
										},
									]
								},
							},
						},
						{
							path: 'position',
							name: 'XmlPositionDataView',
							component: XmlPositionDataView,
							meta: {
								translate: true,
								breadCrumb() {
									return [
										{
											label: 'XmlMapping',
											to: {name: 'XmlPositionDataView'},
										},
									]
								},
							},
						},
					],
				},
				{
					path: 'xmlmapperhead',
					name: 'XmlHeadDataView2',
					component: XmlHeadDataView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, (squeezeStore.state.featureSet.xmlTraining) ?? false);
					},
					meta: {},
				},
				{
					path: 'imports/mail',
					name: 'MailView',
					component: MailView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzMail);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Mail',
									to: {name: 'MailView'},
								},
							]
						},
					},
					redirect: {
						name: "MailSubView",
					},
					children: [
						{
							path: 'configurations',
							name: 'MailSubView',
							component: MailSubView,
						},
						{
							path: 'passwords',
							name: 'PasswordListView',
							component: PasswordListView,
						},
					],
				},
				{
					path: 'imports/fileSystem',
					name: "FileSystemView",
					component: FileSystemView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzFileSystem);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'FileSystem',
									to: {name: 'FileSystemView'},
								},
							]
						},
					},
				},
				{
					path: 'user',
					name: "UserManagementView",
					component: UserListView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzUsersManage);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'User',
									to: {name: 'UserManagementView'},
								},
							]
						},
					},
				},
				{
					path: 'user/:userId',
					name: "UserTabsView",
					component: UserTabsView,
					props: function(route) {
						return {
							userId: Number(route.params.userId), // Params are always passed as string
						};
					},
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzUsersManage);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'User',
									to: {name: 'UserManagementView'},
								},
							]
						},
					},
				},
				{
					path: 'roles',
					name: "UserManagementRolesView",
					component: RolesListView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzRoleManage);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Roles',
									to: {name: 'UserManagementRolesView'},
								},
							]
						},
					},
				},
				{
					path: 'roles/:roleId',
					name: 'UserManagementRolesTabsView',
					component: RoleTabsView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzRoleManage);
					},
					props: function(route) {
						return {
							roleId: Number(route.params.roleId), // Params are always passed as string
						};
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Roles',
									to: {name: 'UserManagementRolesView'},
								},
								{
									label: '',
									to: {name: 'UserManagementRolesTabsView'},
									customTranslation: true,
								},
							]
						},
					},
				},
				{
					path: 'system/migration',
					name: "MigrationView",
					component: MigrationView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzMigrations);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Migrations',
									to: {name: 'MigrationView'},
								},
							]
						},
					},
				},
				{
					path: 'system/check',
					name: "CheckView",
					component: CheckView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzSystemCheck);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'SystemCheck',
									to: {name: 'CheckView'},
								},
							]
						},
					},
				},
				{
					path: 'system/reset',
					name: "SystemResetView",
					component: SystemResetView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzReset);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'SystemReset',
									to: {name: 'SystemResetView'},
								},
							]
						},
					},
				},
				{
					path: 'report/reset',
					name: "ReportResetView",
					component: ReportResetView,
					meta: {
						breadCrumb() {
							return [
								{
									label: 'ReportReset',
									to: {name: 'ReportResetView'},
								},
							]
						},
					},
				},
				{
					path: 'system/scripts',
					name: "ScriptsView",
					component: ScriptsView,
					beforeEnter(to, from, next) {
						const permission = squeezeStore.state.scopes.sqzScriptsExecute || squeezeStore.state.scopes.sqzScriptsManage;
						checkRoutePermission(next, permission);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Scripts',
									to: {name: 'ScriptsView'},
								},
							]
						},
					},
				},
				{
					path: 'system/jobManagement',
					name: "JobManagementView",
					component: JobManagementView,
					beforeEnter(to, from, next) {
						const permission = squeezeStore.state.featureSet.jobManagement && (squeezeStore.state.scopes.sqzJobsManage || squeezeStore.state.scopes.sqzJobsRead);
						checkRoutePermission(next, permission ?? false);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'JobManagement',
									to: {name: 'JobManagementView'},
								},
							]
						},
					},
				},
				{
					path: 'system/jobManagement/:jobId/runs',
					name: "JobRunsView",
					component: JobRunsView,
					beforeEnter(to, from, next) {
						const permission = squeezeStore.state.featureSet.jobManagement && (squeezeStore.state.scopes.sqzJobsManage || squeezeStore.state.scopes.sqzJobsRead);
						checkRoutePermission(next, permission ?? false);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'JobManagement',
									to: {name: 'JobManagementView'},
								},
							]
						},
					},
					props: function(route) {
						return {
							jobId: Number(route.params.jobId),
						};
					},
				},
				{
					path: 'system/apiDocumentation',
					name: "ApiDocumentationView",
					component: ApiDocumentationView,
					meta: {
						breadCrumb() {
							return [
								{
									label: 'ApiDocumentation',
									to: {name: 'ApiDocumentationView'},
								},
							]
						},
					},
				},
				{
					path: 'system/system',
					name: "SystemView",
					component: SystemView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.scopes.sqzSystem);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'System',
									to: {name: 'SystemView'},
								},
							]
						},
					},
				},
				{
					path: 'system/languages',
					name: "LanguagesView",
					component: LanguagesView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, squeezeStore.state.featureSet.translationAdministration ?? false);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Languages',
									to: {name: 'LanguagesView'},
								},
							]
						},
					},
				},
				{
					path: 'system/configurations',
					name: "ConfigurationsView",
					component: ConfigurationsView,
					beforeEnter(to, from, next) {
						checkRoutePermission(next, (squeezeStore.state.featureSet.uiConfigImport && squeezeStore.state.isAdminUser) ?? false);
					},
					meta: {
						breadCrumb() {
							return [
								{
									label: 'Configurations',
									to: {name: 'ConfigurationsView'},
								},
							]
						},
					},
				},
			],
		},
	];
}
