
import {defineComponent, onMounted, reactive, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from "primevue/usetoast";
import Tree from 'primevue/tree';
import Checkbox from 'primevue/checkbox';
import Button from "primevue/button";

export default defineComponent({
	name: "ProcessItemTemplates",
	components: {
		Tree,
		Checkbox,
		Button,
	},
	emits: ['update', 'goToPreviousOrNextStep'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Is loading? */
		const loading = ref<boolean>(false);

		/** Array with templates */
		const templates = ref<any[]>([
			{
				key: '0',
				label: t('System-Administration.Templates.InvoicePackage'),
				checked: true,
				children: [
					{key: '0-0', label: t('System-Administration.Templates.Template', {template: t('System-Administration.Templates.Invoice')}),  data: 'invoiceTestDocument', checked: true, type: 'child'},
				],
			},
			/*{
				key: '1',
				label: t('System-Administration.Templates.OrderConfirmation'),
				checked: false,
				children: [
					{key: '1-0', label: t('System-Administration.Templates.Template',{template: t('System-Administration.Templates.OrderConfirmation')}),  data: 'testDocument', checked: false, type: 'child'},
				],
			},
			{
				key: '2',
				label: t('System-Administration.Templates.DeliveryBill'),
				checked: false,
				children: [
					{key: '2-0', label: t('System-Administration.Templates.Template',{template: t('System-Administration.Templates.DeliveryBill')}), data: 'testDocument', checked: false, type: 'child'},
				],
			},*/
		]);

		/** All expanded keys of templates */
		const expandedKeys = reactive<any>({});

		/**
		 * Expand all template children
		 * @param template
		 */
		const expandChildren = (template: any) => {
			expandedKeys[template.key] = true;
			if (template.children && template.children.length) {
				template.children.forEach((child: any) => {
					expandChildren(child);
				})
			}
		}

		/** Expand all templates */
		const expandAll = () => {
			templates.value.forEach((template: any) => {
				expandChildren(template);
			})

			Object.assign(expandedKeys, {...expandedKeys})
		}

		/**
		 * Triggered when template change
		 * @param template
		 */
		const onChangeTemplate = (template: any) => {
			if(!template.checked) {
				templates.value[template.key].children.forEach((child: any) => {
					child.checked = false;
				})
			} else {
				templates.value[template.key].children.forEach((child: any) => {
					child.checked = true;
				})
			}
		}

		/** Unselected all templates */
		const unselectedAll = () => {
			templates.value.filter((template: any) => {
				if(template.checked === true) {
					template.checked = false;
				}
				onChangeTemplate(template);
			});
		}

		/** Selected all templates */
		const selectedAll = () => {
			templates.value.filter((template: any) => {
				if(template.checked === false) {
					template.checked = true;
				}
				onChangeTemplate(template);
			});
		}

		/** Triggered on click to go to the previous step */
		const goToPreviousStep = () => {
			emit('goToPreviousOrNextStep', 0);
		}

		/** Triggered on click to go to the next step */
		const goToNextStep = () => {
			emit('goToPreviousOrNextStep', 2);
		}

		onMounted(() => {
			expandAll();
			emit("update", templates.value);
		})

		/** Triggered on change of any field */
		const update = (event: any, templateKey: string) => {
			if (templateKey.length >= 2) {
				// check if template child is checked and parentTemplate not checked
				const changedTemplate = templates.value.find((template: any) => template.key === templateKey.charAt(0));
				if (changedTemplate && event.target.ariaChecked) {
					changedTemplate.checked = true;
				}
			} else if (templateKey.length === 1) {
				// check if template is not checked, so that set all children to false
				const changedTemplate = templates.value.find((template: any) => template.key === templateKey);
				if (changedTemplate && !event.target.ariaChecked) {
					changedTemplate.children.forEach((childTemplate: any) => {
						if (childTemplate.checked) {
							childTemplate.checked = false;
						}
					})
				}
			}

			emit("update", templates.value);
		}

		return {
			t,
			toast,
			loading,
			templates,
			expandedKeys,
			expandAll,
			onChangeTemplate,
			goToPreviousStep,
			goToNextStep,
			unselectedAll,
			selectedAll,
			update,
		}
	},
})
