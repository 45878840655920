
import {defineComponent} from "vue";
import Skeleton from 'primevue/skeleton';

export default defineComponent({
	name: "ValidationFieldSetSkeleton",
	components: {
		Skeleton,
	},
	setup() {
		return {}
	},
});
