import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-p-2 content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentClassList = _resolveComponent("DocumentClassList")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DocumentClassList, {
        loading: _ctx.loading,
        documentClasses: _ctx.documentClasses,
        isAdministrationPathOpen: _ctx.isAdministrationPathOpen,
        onOpenEntryDialog: _ctx.openEntryDialog,
        onOpenDeleteDialog: _ctx.openDeleteDialog,
        onOnRowSelect: _ctx.onRowSelect
      }, null, 8, ["loading", "documentClasses", "isAdministrationPathOpen", "onOpenEntryDialog", "onOpenDeleteDialog", "onOnRowSelect"])
    ]),
    (_ctx.isAdministrationPathOpen)
      ? (_openBlock(), _createBlock(_component_DialogDelete, {
          key: 0,
          showDialog: _ctx.deleteDialog,
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDialog = false)),
          onOnConfirm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteEntry()))
        }, null, 8, ["showDialog"]))
      : _createCommentVNode("", true)
  ], 64))
}