import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba4de47c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-ml-2 p-p-2 p-component" }
const _hoisted_2 = { class: "header p-component" }
const _hoisted_3 = { class: "reset-data-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SystemResetForm = _resolveComponent("SystemResetForm")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('Squeeze.System.Reset')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.System.ResetTakeOverMessage')), 1),
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('Squeeze.System.DocumentsDatabase')), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('Squeeze.System.DocumentsRepository')), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('Squeeze.System.DocumentsSearchIndexes')), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('Squeeze.System.SearchIndexes')), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('Squeeze.System.ReportData')), 1)
      ]),
      _createVNode(_component_Button, {
        type: "button",
        label: _ctx.$t('Squeeze.System.Reset'),
        icon: "mdi mdi-refresh",
        onClick: _ctx.openEntryDialog,
        disabled: _ctx.loading
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = false, _ctx.isValidationInvalid = true, _ctx.showValidationMessage = false, _ctx.showInvalidPassword = false)),
      onOnConfirm: _ctx.runReset,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: _ctx.loadingDialog }, {
          default: _withCtx(() => [
            _createVNode(_component_SystemResetForm, {
              showErrorMessage: _ctx.showValidationMessage,
              invalidPassword: _ctx.showInvalidPassword,
              onUpdate: _ctx.onUpdate
            }, null, 8, ["showErrorMessage", "invalidPassword", "onUpdate"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText"])
  ], 64))
}