
import DashboardTile from "@/components/DashboardTile.vue";
import StoresSearchMask from "@/apps/freeze/components/StoresSearchMask.vue";
import {defineComponent, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useProductStore from "@/store/product-store";
import FreezeUpload from "@/apps/freeze/components/FreezeUpload.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import {FreezeSearchMask} from "@/apis/freeze/Types";

export default defineComponent({
	name: 'ArchiveSearchView',
	components: {
		DashboardTile,
		StoresSearchMask,
		FreezeUpload,
		EntryDialog,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const storeProduct = useProductStore();

		/** Should the dialog upload be shown? */
		const showUploadDialog = ref(false);

		/** Currently selected Store */
		const selectedStore = ref('');

		/** Currently selected Store */
		const selectedMask = ref<FreezeSearchMask>({
			name: '',
			desc: '',
			fields: [],
		});

		const onChangeStore = (store: string) => {
			selectedStore.value = store;
		}

		const onChangeHitList = (hitList: any) => {
			selectedMask.value = hitList;
		}

		return {
			t, toast, storeProduct, showUploadDialog, selectedStore, selectedMask, onChangeStore, onChangeHitList,
		};
	},
});
