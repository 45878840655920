/**
 * This type contains helper methods to handle the export of data.
 */

import {ToastManager} from "@/util/ToastManager";

/**
 * Copy all data to clipboard
 * @param t
 * @param toast
 * @param data
 */
export const copyToClipboard = (t: any, toast: any, data: string) => {
	if(navigator && navigator.clipboard) {
		navigator.clipboard.writeText(data)
			.then(() => {
				ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.DataCopied'));
			});
	}
}

/**
 * Download all locator data on click of the button
 * Source: https://stackoverflow.com/questions/3916191/download-data-url-file
 * @param fileName
 * @param jsonString
 */
export const downloadData = (fileName: string, jsonString: string) => {
	const link = document.createElement("a");
	link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonString));
	link.setAttribute('download', 'squeeze_' + fileName + ".json");
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
