import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15a1668a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "p-grid nested-grid p-mx-2 p-pt-2"
}
const _hoisted_2 = { class: "p-col-12 p-lg-12 p-xl-8 p-xxl-8" }
const _hoisted_3 = { class: "p-grid" }
const _hoisted_4 = { class: "p-col-12 p-lg-6 p-xl-6 p-xxl-6" }
const _hoisted_5 = { class: "p-col-12 p-lg-6 p-xl-6 p-xxl-6" }
const _hoisted_6 = { class: "p-col-12 p-lg-6 p-xl-4 p-xxl-4" }
const _hoisted_7 = { class: "p-col-12 p-lg-6 p-xl-6 p-xxl-6" }
const _hoisted_8 = { class: "p-col-12 p-lg-12 p-xl-6 p-xxl-6" }
const _hoisted_9 = {
  key: 1,
  class: "p-grid nested-grid p-mx-2 p-pt-2"
}
const _hoisted_10 = { class: "p-col-12 p-xxl-8" }
const _hoisted_11 = { class: "p-col-12 p-pl-0 p-pr-0 p-mb-2" }
const _hoisted_12 = { class: "p-grid" }
const _hoisted_13 = { class: "p-col-12 p-xxl-6" }
const _hoisted_14 = { class: "p-col-12 p-xxl-6" }
const _hoisted_15 = { class: "p-col-12 p-xxl-4 p-pt-3" }
const _hoisted_16 = { class: "p-grid" }
const _hoisted_17 = { class: "p-col-12" }
const _hoisted_18 = { class: "p-col-12" }
const _hoisted_19 = { class: "p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentReportOrigin = _resolveComponent("DocumentReportOrigin")!
  const _component_DashboardTile = _resolveComponent("DashboardTile")!
  const _component_DocumentReportExports = _resolveComponent("DocumentReportExports")!
  const _component_DocumentReportProcessed = _resolveComponent("DocumentReportProcessed")!
  const _component_DocumentReportExportedDocuments = _resolveComponent("DocumentReportExportedDocuments")!
  const _component_DocumentReportAutovalidation = _resolveComponent("DocumentReportAutovalidation")!
  const _component_DocumentReportFieldSelectionQuality = _resolveComponent("DocumentReportFieldSelectionQuality")!

  return (!_ctx.store.state.featureSet.reportValidatedDocuments)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DocumentReportOrigin)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DocumentReportExports)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
            default: _withCtx(() => [
              _createVNode(_component_DocumentReportProcessed)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
            default: _withCtx(() => [
              _createVNode(_component_DocumentReportExportedDocuments)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
            default: _withCtx(() => [
              _createVNode(_component_DocumentReportAutovalidation)
            ]),
            _: 1
          })
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
              default: _withCtx(() => [
                _createVNode(_component_DocumentReportFieldSelectionQuality)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DocumentReportExportedDocuments)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DocumentReportExports)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DocumentReportAutovalidation)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DocumentReportProcessed)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_DashboardTile, { class: "dashboard-tile" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DocumentReportOrigin)
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]))
}