
import {computed, defineComponent, onMounted, PropType, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import Dropdown from "primevue/dropdown";
import {BatchClassClassification} from '@dex/squeeze-client-ts';

export default defineComponent({
	name: "DocumentClassChangeForm",
	components: {
		Dropdown,
	},
	props: {
		/** List of all Classification Classes */
		classificationClasses: {
			type: Array as PropType<BatchClassClassification[]>,
			default: () => [],
		},
		/** Should the Document be trained? */
		trainDocument: {
			type: Boolean,
			default: true,
		},
	},
	emits: ["onChange"],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Currently active Classification Class */
		const documentClass = ref<number>(0);

		/** Should the Document be trained (Component-Prop) */
		const trainDocumentComponent = ref<boolean>(true);

		/** List Values for Train Document */
		const trainDocumentOptions = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			]
		});

		/** Triggered when the Document-Class is changed */
		const onChangeDocumentClass = () => {
			emit("onChange", documentClass.value, trainDocumentComponent.value);
		}

		onMounted(() => {
			// Pre Select first active Classification-Class
			if (props.classificationClasses[0] && props.classificationClasses[0].documentClassId) {
				documentClass.value = props.classificationClasses[0].documentClassId;
				emit("onChange", documentClass.value, trainDocumentComponent.value);
			}
		});

		watch(() => props.trainDocument, () => {
			trainDocumentComponent.value = props.trainDocument;
		})

		return {
			t,
			documentClass,
			trainDocumentComponent,
			trainDocumentOptions,
			onChangeDocumentClass,
		}
	},
});
