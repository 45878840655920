
import {
	computed,
	defineComponent, inject, onMounted, PropType, ref
} from 'vue';
import Dropdown from "primevue/dropdown";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ToastManager} from "@/util/ToastManager";
import {FreezeSearchMask, FreezeSearchMaskField, FreezeStore} from "@/apis/freeze/Types";
import {showDropdownOverlay} from "@/util/StylesHelper";
import FileUpload from "@/components/DexFileUpload.vue";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import EntryDialog from "@/components/EntryDialog.vue";
import {EasClientKey, FreezeClientKey} from "@/apps/freeze/DI";
import {EASSpoolItem} from "@/apis/freeze/EasClient";
export interface FreezeUploadFile {
	uploadFinished: boolean;
	loading: boolean;
	errorText: string;
	error: boolean;
	attachmentId: string;
	name: string;
}

export default defineComponent({
	name: 'FreezeUpload',
	props: {
		defaultStore: {
			type: String,
			default: '',
		},
		defaultHitList: {
			type: Object as PropType<FreezeSearchMask>,
			default: null,
		},
	},
	components: {
		Dropdown, FileUpload, InputText, Message, EntryDialog,
	},
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Currently selected Store */
		const selectedStore = ref('');

		/** List of all Stores */
		const stores = ref<FreezeStore[]>([]);

		const freezeClient = inject(FreezeClientKey)!;
		const easApi = inject(EasClientKey)!;

		/** Current Progress of upload */
		const progress = ref(0);

		/** List of files */
		const files = ref<FreezeUploadFile[]>([]);

		/** Label of Upload */
		const uploadLabel = computed(() => {
			if (files.value.length > 0) {
				return t("Freeze.General.CreateDocument") + " ("  + t("Freeze.General.Attachments") + ": " + files.value.length + ")";
			}

			return t("Freeze.General.CreateDocument");
		});

		/** List of all Search Masks */
		const searchMasks = ref<any[]>([]);

		/** Is the upload loading? */
		const loading = ref(false);

		/** Show message? */
		const showMessage = ref(false);
		const messageText = ref('');
		const messageSeverity = ref("info");

		/** List of all fields */
		const searchFields = ref<FreezeSearchMaskField[]>([]);

		/** Currently selected Search Mask */
		const selectedMask = ref<FreezeSearchMask>({
			name: '',
			desc: '',
			fields: [],
		});

		/** Search-Mask-Fields */
		const searchMask = computed(() => {
			return {
				id: selectedMask.value,
				fields: searchFields.value.map((field: any) => {
					return {
						id: field.name,
						name: field.desc,
						type: 'string',
						value: '',
					};
				}),
			} as any
		});


		/** Values of all fields */
		const fields = computed(() => {
			return searchMask.value.fields.map((field: any) => {
				return {
					name: field.id,
					desc: field.name,
					type: 'string',
					value: '',
				};
			});
		})

		/**
		 * Get All Stores
		 */
		const getAllStores = async () => {
			try {
				const data = await freezeClient.getAllStores();
				stores.value = data;

				if (stores.value[0]) {
					selectedStore.value = stores.value[0].name;
				}

				const defaultStore = stores.value.find(store => store.name === props.defaultStore);
				if (defaultStore) {
					selectedStore.value = defaultStore.name;
				}
			} catch (e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			}
		}

		/**
		 * Get Search Mask
		 * @param name
		 */
		const getSearchMask = async (name: string) => {
			try {
				const data = await freezeClient.getSearchMask(name);
				if (data) {
					searchFields.value = data.fields;
				}
			} catch(e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			}
		}

		/**
		 * Get all Search Masks
		 */
		const getAllSearchMasks = async () => {
			try {
				const data = await freezeClient.getAllSearchMasks();
				searchMasks.value = data;

				if (searchMasks.value[0]) {
					selectedMask.value = searchMasks.value[0];
					await getSearchMask(searchMasks.value[0].name);
				}

				if (props.defaultHitList) {
					selectedMask.value = props.defaultHitList;
					await getSearchMask(selectedMask.value.name);
				}
			} catch(e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			}
		}

		/** Uploads the files from the file-uploader */
		const fileUploader = async (event: any) => {
			loading.value = true;

			messageSeverity.value = "info";
			messageText.value = t('Freeze.General.Loading');

			files.value.forEach((file, index) => {
				file.loading = true;
				file.error = false;
				file.errorText = "";
				file.loading = true;
				file.attachmentId = "attachment" + (index + 1);
			})

			files.value = [...files.value];

			try {
				let spool: EASSpoolItem[] = [];

				// Upload files to spool endpoint if any file is given.
				// This is skipped if no files are given.
				if (files.value.length > 0) {
					const fileAttachments = await easApi.spoolDocumentToStore(selectedStore.value, files.value);
					files.value.forEach(file => {
						file.uploadFinished = true;
						file.loading = false;
					})

					files.value = [...files.value];

					const finished = files.value.filter(file => file.uploadFinished);
					progress.value = Math.round((finished.length * 100) / files.value.length);

					spool = fileAttachments.spool;
					spool.forEach(spoolFile => {
						const uploadFile = files.value.find(file => file.attachmentId === spoolFile.id);
						if (uploadFile) {
							spoolFile.fileName = uploadFile.name;
						}
					})
				}

				await easApi.archiveRecord(selectedStore.value, fields.value, spool);
				files.value = [];
				showMessage.value = true;
				messageText.value = t('Freeze.General.CreateSuccess');
				messageSeverity.value = "success";
			}catch(err) {
				messageSeverity.value = "error";
				showMessage.value = true;
				messageText.value = t('Squeeze.General.Error') + ": " + err
			}

			loading.value = false;
		}

		/**
		 * Is triggered when the "clear" button is pressed in the Upload-Component
		 * @param event
		 */
		const clearFiles = ()  => {
			files.value = [];
		}

		/**
		 * Is triggered when a single file is removed from upload
		 * @param event
		 */
		const removeFile = (event: any) => {
			files.value = event.files;
		}

		/**
		 * Is triggered when files are selected from the Component
		 * @param event File-Select event
		 */
		const onSelectFiles = (event: any) => {
			files.value = event.files;
		}

		/** set the selected search mask */
		const selectSearchMask = (event: { value: FreezeSearchMask }) => {
			selectedMask.value = event.value;
			getSearchMask(event.value.name);
		}

		onMounted(() => {
			/** Get all stores and fields */
			getAllStores();
			getAllSearchMasks();
		})

		return {
			stores, selectedStore, files, uploadLabel, progress, messageSeverity,
			searchMasks, selectedMask, searchMask, fields, showMessage, messageText,
			showDropdownOverlay, selectSearchMask, loading,
			clearFiles, removeFile, onSelectFiles, fileUploader,
		};
	},
});

