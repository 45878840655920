
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tooltip from "primevue/tooltip";
import {defineComponent, PropType} from "vue";
import {MailZipPassword} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "PasswordList",
	components: {
		DataTable, Column,
	},
	props: {
		passwords: {
			type: Array as PropType<MailZipPassword[]>,
			default: () => [],
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onEntrySelect", "onClickDelete",
	],
	setup(props, {emit}) {
		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				email: '',
				password: '',
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: MailZipPassword) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MailZipPassword) => {
			emit("onClickDelete", row);
		}

		return {
			onNewEntryClick, openEditDialog, openDeleteDialog,
		}
	},
});
