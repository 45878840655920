import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "id" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.UserManagement.Role')) + " *", 1),
      _createVNode(_component_Dropdown, {
        id: "id",
        modelValue: _ctx.value.id,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.id) = $event)),
        options: _ctx.allRoles,
        onChange: _ctx.update,
        loading: _ctx.loading,
        optionValue: "id",
        optionLabel: _ctx.getColumnLabel
      }, null, 8, ["modelValue", "options", "onChange", "loading", "optionLabel"]),
      (_ctx.v$.id.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.UserManagement.Role')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}