
import {defineComponent, onMounted, PropType, reactive, ref, watch} from 'vue';
import { useI18n } from 'vue-i18n';
/*import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";*/
import FileUpload from "@/components/DexFileUpload.vue";
import { UploadFile } from "@/shims-prime-vue";
import {required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
	name: "SystemImport",
	components: {
		//Dropdown,
		//InputText,
		//Checkbox,
		FileUpload,
	},
	props: {
		showErrorMessage: {
			type: Boolean,
			default: false,
		},
		selectedFiles: {
			type: Array as PropType<UploadFile[]>,
			default: () => [],
		},
		fileTypes: {
			type: Array as any,
			default: () => [],
		},
		fileType: {
			type: String,
			default: 'csv',
		},
	},
	emits: ['update', 'fileUploader'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Current Object of all input-fields */
		const value = reactive<any>({csvSeparator: ';', fileType: props.fileType, skipErrors: true});

		/** Determines the required rules for validation */
		const rules = {
			csvSeparator: { required },
		}

		/** List of all files */
		const files = ref<UploadFile[]>([]);

		/** Progress of all uploaded documents */
		const progress = ref(0);

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		/** Label of the Upload */
		const uploadLabel = ref(t("Squeeze.General.Upload"));

		/** Should the upload button be disabled?  */
		const showUploadButton = ref<boolean>(false);

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.selectedFiles, () => {
			files.value = props.selectedFiles;
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";
		})

		onMounted(async () => {
			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Triggered on change of any field */
		const update = () => {
			showUploadButton.value = v$.value.$invalid;
			emit("update", value, v$.value.$invalid);
		}

		/**
		 * Triggered when Files are selected
		 */
		const onSelectFiles =  async (event: any) => {
			// Reset array and only take last entry of the selected files-array which is too big if selected multiple times
			files.value = [];
			files.value[0] = event.files[event.files.length - 1];
			uploadLabel.value = t("Squeeze.General.Upload") + " (0/1)";
		}

		/**
		 * Triggered when the File-Upload is triggered.
		 * @param event
		 */
		const fileUploader = (event: any) => {
			emit("fileUploader", event.files);
		}

		/**
		 * Is triggered when the "clear" button is pressed in the Upload-Component
		 */
		const clearFiles = () => {
			uploadLabel.value = t("Squeeze.General.Upload");
		}

		/**
		 * Is triggered when a single file is removed from upload
		 * @param event
		 */
		const removeFile = (event: any) => {
			files.value = event.files;
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";
		}

		return {
			value,
			props,
			v$,
			progress,
			files,
			uploadLabel,
			showUploadButton,
			update,
			removeFile,
			fileUploader,
			onSelectFiles,
			clearFiles,
		}
	},
})
