
import {defineComponent, onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";
import FileUpload from "@/components/DexFileUpload.vue";
import {BatchClass, BatchClassClassification, DocumentClass} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useSqueezeStore} from "@/apps/squeeze/store";
import Message from "primevue/message";

export default defineComponent({
	name: 'BatchClassTraining',
	components: {
		Dropdown, FileUpload, Message,
	},
	props: {
		batchClassId: {
			type: Number,
			default: 0,
		},
		classificationClassId: {
			type: Number,
			default: 0,
		},
		showDropdowns: {
			type: Boolean,
			default: true,
		},
		showTesting: {
			type: Boolean,
			default: true,
		},
		showTraining: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useSqueezeStore();

		/** List of all Batch-Class */
		const batchClasses = ref<BatchClass[]>([]);

		/** Currently active Batch-Class */
		const activeBatchClass = ref<number>(0);

		/** List of all Batch-Class Classifications */
		const batchClassClassifications = ref<BatchClassClassification[]>([]);

		/** Currently active Batch-Class Classification */
		const activeClassification = ref<number>(0);

		/** Show loading in Batch-Class Dropdown? */
		const loadingBatchClass = ref<boolean>(false);

		/** Show loading in Classification Dropdown? */
		const loadingClassification = ref<boolean>(false);

		/** Service for Batch-Classes */
		const batchClassService = ClientManager.getInstance().squeeze.batchClass;

		/** Service for Document-Classes */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass

		/** Current Progress of upload */
		const progress = ref<number>(0);

		/** Message that shows the number of the currently uploaded documents  */
		const uploadTrainLabel = ref<string>(t('Squeeze.Training.Train'));

		/** Label of the Training-Button */
		const trainingLabel = ref<string>(t('Squeeze.Training.Testing'));

		/** Should the training-button be disabled? */
		const disableTraining = ref(false);

		/** Should the Testing button be disabled? */
		const disableTesting = ref (false);

		/** List of all files to be uploaded */
		const files = ref<any>([{
			uploadFinished: false,
			loading: false,
			errorText: '',
			error: false,
		}]);

		const documentClasses = ref<DocumentClass[]>([])

		/** Get all Document Classes */
		const getDocumentClasses = () => {
			documentClassService.getAllDocumentClasses().then(data => {
				documentClasses.value = data;
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			}))
		}

		/** Get all Batch-Class */
		const getAllBatchClasses = async () => {
			if (props.showDropdowns === false) {
				return;
			}

			loadingBatchClass.value = true;
			await batchClassService.getAllBatchClasses()
				.then(data => {
					batchClasses.value = data;

					// Pre-Select first valid entry
					if (data[0] && data[0].id) {
						activeBatchClass.value = data[0].id;
					}
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loadingBatchClass.value = false
				})
		}

		/** Get all Batch-Class Classifications */
		const getBatchClassClassifications = async () => {
			if (props.showDropdowns === false) {
				return;
			}

			loadingClassification.value = true;
			await batchClassService.getBatchClassClassifications(activeBatchClass.value)
				.then(classifications => {
					batchClassClassifications.value = classifications;

					// Pre-Select first valid entry
					if (classifications[0] && classifications[0].id) {
						activeClassification.value = classifications[0].id;
					}
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loadingClassification.value = false
				})
		}

		onMounted(async () => {
			if (props.batchClassId === 0) {
				await getAllBatchClasses();
			} else {
				// If the batch class-ID is set by the Parent, use it
				activeBatchClass.value = props.batchClassId;
			}

			// If the classification class id is given by the parent, use it
			if (props.classificationClassId === 0) {
				await getBatchClassClassifications();
			} else {
				activeClassification.value = props.classificationClassId;
			}

			getDocumentClasses();
		});

		/** Watch if change the active Batch-Class */
		watch(() => activeBatchClass.value, async () => {
			await getBatchClassClassifications();
		});

		/**
		 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
		 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
		 * @param file
		 * @returns Object with the id of the created document
		 */
		const manualFileUpload = async (file: File) => {
			// Todo: Once the generated client works, make this function deprecated and use the client function:
			const body = new FormData();
			body.set("file", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() + "/batchClasses/" + activeBatchClass.value + "/classifications/" + activeClassification.value + "/train", {
				method: "POST",
				body: body,
			});

			if (response.status !== 200 && response.status !== 204) {
				const responseJSON = await response.json();
				throw new Error("Unexpected status " + responseJSON.message);
			}
		}


		/**
		 * Manual file upload and batch class testing to the Squeeze API. This has been programmed because the generated API client does not
		 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
		 * @param file
		 * @returns Object with the id of the created document
		 */
		const manualFileUploadTesting = async (file: File) => {
			const body = new FormData();
			body.set("file", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() + "/batchClasses/" + activeBatchClass.value + "/testClassification", {
				method: "POST",
				body: body,
			});

			if (response.status !== 200 && response.status !== 204) {
				const responseJSON = await response.json();
				throw new Error("Unexpected status " + responseJSON.message);
			}

			if (response.status === 200) {
				return response.json();
			}
		}

		/** Uploads the files from the file-uploader */
		const fileUploader = (event: any) => {
			disableTesting.value = true;
			files.value = event.files;

			if (activeBatchClass.value === 0 || activeClassification.value === 0) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + t('Squeeze.BatchClasses.InvalidClassification'));
				return;
			}

			progress.value = 0;
			// Calculate progress
			uploadTrainLabel.value = t('Squeeze.Training.Train') + " (" + files.value.filter((file: any) => file.uploadFinished).length + "/" + files.value.length + ")";

			// TODO: Limit the amount of uploads running in parallel (?)
			event.files.forEach((file: any, index: number) => {
				if (!file.uploadFinished) { // Files that are already finished shouldn't be uploaded again
					const idx = index;
					files.value[idx].error = false;
					files.value[idx].errorText = "";
					files.value[idx].loading = true;
					files.value = [...files.value];

					manualFileUpload(file)
						.then(() => {
							files.value[idx].uploadFinished = true;
						})
						.catch(err => {
							files.value[idx].error = true;
							files.value[idx].errorText = err.message;
						})
						.finally(() => {
							files.value[idx].loading = false;
							files.value = [...files.value];

							// Calculate progress
							const finished = files.value.filter((file: any) => file.uploadFinished);
							progress.value = Math.round((finished.length * 100) / files.value.length);
							uploadTrainLabel.value = t("Squeeze.Training.Train") + " (" + finished.length + "/" + files.value.length + ")";
						});
				}
			})
		}

		/**
		 * Get Document Class Description By Name
		 * @param documentClassName
		 */
		const getDocumentClassDescriptionByName = (documentClassName: string) => {
			if (documentClasses.value) {
				const documentClass = documentClasses.value.find(documentClass => documentClass.name === documentClassName)
				if (documentClass) {
					return documentClass.description;
				}
			}

			return documentClassName;
		}


		/** Uploads the files from the file-uploader and triggers the testing for this document */
		const fileUploaderTesting = (filesSet: any) => {
			disableTraining.value = true;
			files.value = filesSet;

			progress.value = 0;
			// Calculate progress
			trainingLabel.value = t('Squeeze.MasterData.Testing') + " (" + files.value.filter((file: any) => file.uploadFinished).length + "/" + files.value.length + ")";

			// TODO: Limit the amount of uploads running in parallel (?)
			files.value.forEach((file: any, index: number) => {
				if (!file.uploadFinished) { // Files that are already finished shouldn't be uploaded again
					const idx = index;
					files.value[idx].error = false;
					files.value[idx].errorText = "";
					files.value[idx].loading = true;
					files.value = [...files.value];

					manualFileUploadTesting(file)
						.then((data: any) => {
							files.value[idx].message = getDocumentClassDescriptionByName(data.documentClassName) + " (ID: " +  data.documentClassId + ")";
							files.value[idx].uploadFinished = true;
						})
						.catch(err => {
							files.value[idx].error = true;
							files.value[idx].errorText = err.message;
						})
						.finally(() => {
							files.value[idx].loading = false;
							files.value = [...files.value];

							// Calculate progress
							const finished = files.value.filter((file: any) => file.uploadFinished);
							progress.value = Math.round((finished.length * 100) / files.value.length);
							trainingLabel.value = t('Squeeze.Training.Testing') + " (" + files.value.filter((file: any) => file.uploadFinished).length + "/" + files.value.length + ")";
						});
				}
			})
		}

		/**
		 * Is triggered when files are selected from the Component
		 * @param event File-Select event
		 */
		const onSelectFiles = (event: any) => {
			files.value = event.files;
			uploadTrainLabel.value = t("Squeeze.Training.Train") + " (" + files.value.filter((file: any) => file.uploadFinished).length + "/" + files.value.length + ")";
			trainingLabel.value = t("Squeeze.Training.Testing") + " (" + files.value.filter((file: any) => file.uploadFinished).length + "/" + files.value.length + ")";
		}

		/**
		 * Is triggered when the "clear" button is pressed in the Upload-Component
		 */
		const clearFiles = () => {
			disableTraining.value = false;
			disableTesting.value = false;
			uploadTrainLabel.value = t("Squeeze.Training.Train");
			trainingLabel.value = t('Squeeze.Training.Testing');
		}

		/**
		 * Is triggered when a single file is removed from upload
		 * @param event
		 */
		const removeFile = (event: any) => {
			files.value = event.files;
			uploadTrainLabel.value = t("Squeeze.Training.Train") + " (" + files.value.filter((file: any) => file.uploadFinished).length + "/" + files.value.length + ")";
			trainingLabel.value = t("Squeeze.Training.Testing") + " (" + files.value.filter((file: any) => file.uploadFinished).length + "/" + files.value.length + ")";
		}

		return {
			t,
			toast,
			batchClasses,
			activeBatchClass,
			batchClassClassifications,
			activeClassification,
			loadingBatchClass,
			loadingClassification,
			batchClassService,
			progress,
			uploadTrainLabel,
			trainingLabel,
			files,
			store,
			disableTraining,
			disableTesting,
			getAllBatchClasses,
			getBatchClassClassifications,
			onSelectFiles,
			fileUploader,
			manualFileUpload,
			clearFiles,
			removeFile,
			fileUploaderTesting,
		}
	},
});
