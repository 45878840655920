
import {defineComponent, onMounted, reactive, ref} from 'vue';
import InputSwitch from 'primevue/inputswitch';
import Slider from 'primevue/slider';
import DialogDelete from "@/components/DialogDelete.vue";
import { useSqueezeStore } from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";
import {UserSettings} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: 'ViewSettings',
	components: {
		InputSwitch,
		Slider,
		DialogDelete,
	},
	emits: ['clickSettingDialog'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Object with user settings */
		const userSettings = reactive<any>({
			viewSettings: {
				smallDistanceBetweenFields: false,
				fontSize: 0,
				v2Viewer: true,
				viewerSettings: {
					highlightOptions: {
						drawColor: '',
						draw: false,
						borderColor: '',
						borderStyle: '',
						border: false,
					},
					zoomOptions: {
						fullWidth: false,
						fullHeight: false,
						customZoom: false,
						customSlider: 0,
					},
				},
			},
		});

		/** Boolean for border style is dashed */
		const isBorderStyleDashed = ref<boolean>(false);

		/** Confirm dialog */
		const confirmDialog = ref<boolean>(false);

		onMounted(() => {
			// get user settings from store
			if (typeof store.state.userSettings.viewSettings === 'string' || store.state.userSettings.viewSettings instanceof String) {
				store.state.userSettings.viewSettings = JSON.parse(store.state.userSettings.viewSettings as any);
				Object.assign(userSettings, store.state.userSettings);
			} else {
				Object.assign(userSettings, store.state.userSettings);
			}

			// check if borderStyle dashed
			if(userSettings!.viewSettings!.viewerSettings!.highlightOptions!.borderStyle === 'dashed') {
				isBorderStyleDashed.value = true;
			} else {
				isBorderStyleDashed.value = false;
			}
		})

		/** Set userSettings in store and save it */
		const setUserSettings = () => {
			store.commit('setUserSettings', userSettings);

			// stringify the current user settings
			const currentUserSettings: UserSettings = {
				viewSettings: JSON.stringify(userSettings.viewSettings),
				tableSettings: userSettings.tableSettings,
			};

			userApi.putAllUserProperties(currentUserSettings)
				.catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					if (err.statusCode === 501) {
						localStorage.setItem('userSettings', JSON.stringify(userSettings));
						return;
					} else {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}
				}));
		}

		/** Emit of click on close button */
		const clickSettingDialog = () => {
			emit('clickSettingDialog');
		}

		/** On Change the font size */
		const onChangeFontSize = () => {
			localStorage.setItem('fontSize', JSON.stringify(userSettings!.viewSettings!.fontSize));
			document.documentElement.style.setProperty("--global-font-size", userSettings!.viewSettings!.fontSize + 'rem');
		}

		/** On slide end of the font size or custom slider */
		const onSlideEnd = () => {
			setUserSettings();
		}

		/** On Change the draw */
		const onChangeDraw = () => {
			if(!userSettings.viewSettings!.viewerSettings!.highlightOptions!.draw && !userSettings!.viewSettings!.viewerSettings!.highlightOptions!.border) {
				userSettings!.viewSettings!.viewerSettings!.highlightOptions!.border = true;
			}
			setUserSettings();
		}

		/** On Change the border */
		const onChangeBorder = () => {
			if(!userSettings!.viewSettings!.viewerSettings!.highlightOptions!.draw && !userSettings!.viewSettings!.viewerSettings!.highlightOptions!.border) {
				userSettings!.viewSettings!.viewerSettings!.highlightOptions!.draw = true;
			}
			setUserSettings();
		}

		/** On Change the draw color in Viewer
		 * @param drawColor
		 */
		const onChangeDrawColor = (drawColor: string) => {
			userSettings!.viewSettings!.viewerSettings!.highlightOptions!.drawColor = drawColor;
			setUserSettings();
		}

		/** On Change the border color in Viewer
		 * @param borderColor
		 */
		const onChangeBorderColor = (borderColor: string) => {
			userSettings!.viewSettings!.viewerSettings!.highlightOptions!.borderColor = borderColor;
			setUserSettings();
		}

		/** On Change the border style in Viewer
		 * @param borderStyleDashed
		 */
		const onChangeBorderStyle = (borderStyleDashed: boolean) => {
			if(borderStyleDashed) {
				userSettings!.viewSettings!.viewerSettings!.highlightOptions!.borderStyle = 'dashed';
			} else {
				userSettings!.viewSettings!.viewerSettings!.highlightOptions!.borderStyle = 'solid';
			}
			setUserSettings();
		}

		/** Open the confirm Dialog to restore the default settings  */
		const openConfirmDialog = () => {
			confirmDialog.value = true;
		}

		/** Set default settings in viewer */
		const setDefaultSettings = () => {
			userSettings!.viewSettings!.viewerSettings = {
				highlightOptions: {
					drawColor: '#ffff00',
					draw: true,
					borderColor: '#ffff00',
					borderStyle: 'solid',
					border: false,
				},
				zoomOptions: {
					fullWidth: true,
					fullHeight: false,
					customZoom: false,
					customSlider: 1,
				},
			}
		}

		/** On Change the Zoom options
		 * @param zoomInput
		 */
		const onChangeZoom = (zoomInput: string) => {
			switch(zoomInput) {
			case 'fullWidth': {
				if (userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullWidth) {
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullHeight = false;
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.customZoom = false;
				}
				break;
			}
			case 'fullHeight': {
				if (userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullHeight) {
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullWidth = false;
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.customZoom = false;
				}
				break;
			}
			case 'custom': {
				if (userSettings!.viewSettings!.viewerSettings!.zoomOptions!.customZoom) {
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullWidth = false;
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullHeight = false;
				}
				break;
			}
			}

			// when all zoomOptions false
			if (!userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullHeight && !userSettings!.viewSettings!.viewerSettings!.zoomOptions!.customZoom) {
				userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullWidth = true;
			}
			setUserSettings();
		}

		return { store, userSettings, isBorderStyleDashed, confirmDialog, clickSettingDialog, onChangeFontSize, onSlideEnd, onChangeDraw, onChangeBorder, onChangeDrawColor, onChangeBorderColor, onChangeBorderStyle, openConfirmDialog, setDefaultSettings, onChangeZoom, setUserSettings };
	},
});

