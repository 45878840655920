import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "p-grid",
  style: {"height":"18.125rem"}
}
const _hoisted_2 = {
  key: 0,
  class: "p-col-12"
}
const _hoisted_3 = { class: "p-col-12 p-field p-input-filled" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.$t(_ctx.locales.header),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw', '640px': '100vw'},
    style: {width: '30vw'},
    onHide: _ctx.closeDialog,
    modal: true
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t(_ctx.locales.buttons.abort),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showDialog = false)),
        icon: "mdi mdi-close",
        class: "p-button-text"
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t(_ctx.locales.buttons.confirm),
        onClick: _ctx.confirm,
        icon: "mdi mdi-check",
        autofocus: ""
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.options.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.locales.notice.selectReason)), 1),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.selectedOption,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOption) = $event)),
                options: _ctx.options,
                optionLabel: "label",
                placeholder: _ctx.$t(_ctx.locales.selectPlaceholder),
                style: {"width":"100%"},
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeOption($event)))
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.locales.notice.comment)), 1),
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: _ctx.value.comment,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.comment) = $event)),
            style: {"width":"100%"},
            disabled: _ctx.selectedOption.value !== 'misc' && _ctx.options.length > 0,
            class: _normalizeClass({'p-invalid':_ctx.v$.comment.$invalid && _ctx.confirmed})
          }, null, 8, ["modelValue", "disabled", "class"]),
          ((_ctx.v$.comment.$invalid && _ctx.confirmed) || _ctx.v$.comment.$pending.$response)
            ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Squeeze.Validation.Dialogs.General.MissingComment')), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}