
import {defineComponent, PropType} from 'vue';
import Tree from 'primevue/tree';
import Badge from 'primevue/badge';
import Message from 'primevue/message';
import {SystemCheckResultDto} from "@dex/squeeze-client-ts";
import Skeleton from 'primevue/skeleton';

export default defineComponent({
	name: "CheckList",
	components: {Tree, Badge, Message, Skeleton},
	props: {
		checks: {
			type: Array as any,
		},
		allSystemCheckResults: {
			type: Object as PropType<SystemCheckResultDto>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const countChildren = (parent: any, children: any) => {
			if(parent.key === 'fail') {
				return props.allSystemCheckResults!.failCount
			}
			if(parent.key === 'warning') {
				return props.allSystemCheckResults!.warningCount
			}
			if(parent.key === 'skipped') {
				return props.allSystemCheckResults!.skippedCount
			}
			if(parent.key === 'unknown') {
				return props.allSystemCheckResults!.unknownCount
			}
			if(parent.key === 'ok') {
				return props.allSystemCheckResults!.okCount
			}
			return children ? children.length : 0
		}

		return {countChildren};
	},
});

