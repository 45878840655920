import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dfcb7616"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"left!important","white-space":"nowrap!important"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "column-cell"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.jobRuns,
    loading: _ctx.loading,
    class: "p-datatable-sm p-datatable-documents",
    columnResizeMode: "fit",
    lazy: true,
    onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event))),
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    rows: _ctx.paginationInfo.pageSize,
    totalRecords: _ctx.paginationInfo.total,
    paginator: true,
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords} ',
    autoLayout: true,
    scrollable: true,
    scrollHeight: "flex",
    onFilter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onFilter($event.filters))),
    filters: _ctx.filters,
    "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
    filterDisplay: "row"
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.System.JobRuns')})), 1)
    ]),
    paginatorstart: _withCtx(() => []),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "status",
        header: _ctx.$t('Squeeze.System.Status'),
        style: {"min-width":"15rem","max-width":"15rem"},
        filterField: "status",
        showFilterMenu: false
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", {
              class: _normalizeClass(['status-badge ' + slotProps.data.status.toLowerCase(), "p-py-1"])
            }, _toDisplayString(_ctx.$t("Squeeze.System.States." + slotProps.data.status.toUpperCase())), 3)
          ])
        ]),
        filter: _withCtx(({filterModel,filterCallback}) => [
          _createVNode(_component_Dropdown, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onChange: ($event: any) => (filterCallback()),
            options: _ctx.statuses,
            placeholder: "",
            class: "p-column-filter filter-field",
            style: {"height":"2.138rem"},
            showClear: true
          }, {
            value: _withCtx((slotProps) => [
              (slotProps.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("Squeeze.System.States." + slotProps.value.toUpperCase())), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(slotProps.placeholder), 1))
            ]),
            option: _withCtx((slotProps) => [
              _createElementVNode("span", {
                class: _normalizeClass('status-badge ' + slotProps.option.toLowerCase())
              }, _toDisplayString(_ctx.$t("Squeeze.System.States." + slotProps.option)), 3)
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "error",
        header: _ctx.$t('Squeeze.System.ErrorMessage'),
        style: {"min-width":"30rem","max-width":"30rem"}
      }, {
        body: _withCtx((slotProps) => [
          (slotProps.data.error)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(JSON.parse(slotProps.data.error).message), 1)
              ])), [
                [
                  _directive_tooltip,
                  JSON.parse(slotProps.data.error).message,
                  void 0,
                  { bottom: true }
                ]
              ])
            : (_openBlock(), _createElementBlock("div", _hoisted_5, " - - "))
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "startedAt",
        header: _ctx.$t('Squeeze.System.StartedAt'),
        style: {"min-width":"12rem"}
      }, {
        body: _withCtx((slotProps) => [
          (slotProps.data.startedAt)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.formatDate(slotProps.data.startedAt)), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, " - - "))
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "finishedAt",
        header: _ctx.$t('Squeeze.System.FinishedAt'),
        style: {"min-width":"12rem"}
      }, {
        body: _withCtx((slotProps) => [
          (slotProps.data.finishedAt)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.formatDate(slotProps.data.finishedAt)), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, " - - "))
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "totalRecords", "currentPageReportTemplate", "filters"]))
}