
import { defineComponent } from 'vue';
import useSqueezeStore from "@/apps/squeeze/store";
import DocumentReportAutovalidation from "@/apps/squeeze/components/DocumentReportAutovalidation.vue";
import DocumentReportFieldSelectionQuality from "@/apps/squeeze/components/DocumentReportFieldSelectionQuality.vue";

export default defineComponent({
	name: "EmbeddableReports",
	components: {
		DocumentReportFieldSelectionQuality,
		DocumentReportAutovalidation,
	},
	setup() {
		/** Vuex Store */
		const store = useSqueezeStore();

		return {
			store,
		};
	},
});

