import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a230bda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_UserTakeOverRolesView = _resolveComponent("UserTakeOverRolesView")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      value: _ctx.roles,
      loading: _ctx.loading,
      class: "p-datatable-sm editable-cells-table",
      autoLayout: true,
      columnResizeMode: "fit",
      selection: _ctx.selection,
      "onUpdate:selection": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selection) = $event)),
      selectionMode: _ctx.selectionMode,
      onRowSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onRowSelect($event.data))),
      paginator: true,
      rows: _ctx.tableListPagination.pageSize,
      paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
      rowsPerPageOptions: [25,50,100],
      currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
      scrollable: true,
      scrollHeight: "flex",
      rowHover: true,
      onPage: _ctx.onChangePage
    }, {
      loading: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
      ]),
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.UserManagement.Roles') })), 1)
      ]),
      paginatorstart: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('Squeeze.General.NewEntry'),
          type: "button",
          icon: "mdi mdi-plus",
          class: "p-button",
          onClick: _ctx.onNewEntryClick
        }, null, 8, ["label", "onClick"]),
        (_ctx.route.name === 'UserTabsView' && _ctx.userId !== 1)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('Squeeze.UserManagement.TakeOverRoles'),
              type: "button",
              icon: "mdi mdi-account-key-outline",
              class: "p-button-text",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTakeOverRolesDialog = true))
            }, null, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      paginatorend: _withCtx(() => []),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "id",
          header: "ID",
          sortable: false,
          class: "id-column",
          style: {"min-width":"4rem","max-width":"6rem"}
        }),
        _createVNode(_component_Column, {
          field: "title",
          header: _ctx.$t('Squeeze.BatchClasses.Name'),
          sortable: false,
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(slotProps.data.title), 1)
            ])), [
              [
                _directive_tooltip,
                slotProps.data.title,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "description",
          header: _ctx.$t('Squeeze.BatchClasses.Description'),
          sortable: false,
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(slotProps.data.description), 1)
            ])), [
              [
                _directive_tooltip,
                slotProps.data.description,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "parentRoleId",
          header: '',
          sortable: false,
          style: {"min-width":"4rem","max-width":"6rem"}
        }, {
          body: _withCtx((user) => [
            (user.data.parentRoleId > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.UserManagement.Inherited')), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          class: "buttonColumn",
          style: {"min-width":"3rem","max-width":"3rem"}
        }, {
          body: _withCtx((role) => [
            _withDirectives(_createVNode(_component_Button, {
              class: _normalizeClass([{ invisible: role.data.isInherited }, "p-button-lg p-button-rounded p-button-danger p-button-text"]),
              disabled: _ctx.AdminRoleIds.includes(role.data.id) && _ctx.selectionMode,
              icon: "mdi mdi-delete-outline",
              onClick: ($event: any) => (_ctx.openDeleteDialog(role.data))
            }, null, 8, ["class", "disabled", "onClick"]), [
              [
                _directive_tooltip,
                _ctx.$t('Squeeze.Validation.Buttons.Delete'),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "loading", "selection", "selectionMode", "rows", "currentPageReportTemplate", "onPage"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showTakeOverRolesDialog,
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showTakeOverRolesDialog = false)),
      onOnConfirm: _ctx.executeTakeOverRoles,
      loading: _ctx.loadingTakeOverRoles,
      headerText: _ctx.headerTextOfTakeOverRoles,
      showKeepDialogOpen: false
    }, {
      content: _withCtx(() => [
        _createVNode(_component_UserTakeOverRolesView, {
          userId: _ctx.userId,
          usersTakeOverRoles: _ctx.usersTakeOverRoles,
          showErrorMessage: _ctx.showValidationMessage,
          onUpdate: _ctx.onUpdate
        }, null, 8, ["userId", "usersTakeOverRoles", "showErrorMessage", "onUpdate"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText"])
  ], 64))
}