import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d2077e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid p-flex-column p-m-0 p-p-4 login-container p-jc-center p-ai-center" }
const _hoisted_2 = { class: "p-col-12 p-md-6 p-lg-4 p-text-center" }
const _hoisted_3 = { class: "p-component view-title" }
const _hoisted_4 = { class: "p-col-12 p-md-6 p-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResetPasswordForm = _resolveComponent("ResetPasswordForm")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_BlockUI = _resolveComponent("BlockUI")!

  return (_openBlock(), _createBlock(_component_BlockUI, { blocked: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Login.SQUEEZE')), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Panel, { class: "p-shadow-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_ResetPasswordForm, {
                onOnClickSend: _ctx.resetPassword,
                loading: _ctx.loading
              }, null, 8, ["onOnClickSend", "loading"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["blocked"]))
}