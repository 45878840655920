
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {LocatorSetting} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {useRoute} from "vue-router";

export default defineComponent({
	name: 'LocatorAttributes',
	components: {
		DataTable,
		Column,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** Currently active document-class */
		locatorId: {
			type: Number,
			default: 0,
		},
		/** All available Locators */
		locatorSettings: {
			type: Array as PropType<LocatorSetting[]>,
			default: () => [],
		},
		/** All Valid Settings */
		supportedLocatorSettings: {
			type: Array as PropType<LocatorSetting[]>,
			default: () => [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "openDelete"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** Data of selected row */
		const rowData = reactive<any>({});

		/**
		 * Opens the Edit-Dialog
		 * @param {LocatorSetting} data Data to edit
		 */
		const openEditDialog = (data: LocatorSetting) => {
			Object.assign(rowData, data);
			emit("openEntryDialog", rowData, props.locatorId);
		}

		/**
		 * Opens the Delete Dialog
		 * @param {LocatorSetting} data Data from the Document-Class that should be deleted
		 */
		const openDeleteDialog = (data: LocatorSetting) => {
			Object.assign(rowData, data);
			emit("openDelete", rowData);
		}

		/**
		 * Get description of setting
		 * @param {string} settingName
		 */
		const getSettingsDescription = (settingName: string) => {
			if (!props.supportedLocatorSettings.length) {
				return settingName;
			}

			const locatorType = props.supportedLocatorSettings.find((locatorSetting) => {
				return locatorSetting.setting === settingName;
			});

			if (!locatorType) {
				return settingName;
			}

			return locatorType.setting;
		}

		/**
		 * Triggered when page is changed
		 * @param event
		 */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		onMounted(() =>{
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		})

		return {
			t,
			toast,
			route,
			store,
			tableListPagination,
			rowData,
			openEditDialog,
			openDeleteDialog,
			getSettingsDescription,
			onChangePage,
		};
	},
});

