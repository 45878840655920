import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "keyword" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.KeyWords')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "keyword",
        modelValue: _ctx.value.keyword,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.keyword) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.keyword.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update,
        required: "true",
        autofocus: ""
      }, null, 8, ["modelValue", "class", "onChange"]),
      (_ctx.v$.keyword.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Locators.Tabs.KeyWords')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}