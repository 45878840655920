import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8876ba3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 full-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScriptsList = _resolveComponent("ScriptsList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ScriptsList, {
      loading: _ctx.loading,
      allScripts: _ctx.allScripts,
      onExecuteScript: _ctx.executeScript
    }, null, 8, ["loading", "allScripts", "onExecuteScript"])
  ]))
}