import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.files,
    loading: _ctx.loading,
    class: "p-datatable-sm editable-cells-table",
    autoLayout: true,
    columnResizeMode: "fit",
    onRowDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditDialog($event.data)))
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.Imports.FileSystemImport') })), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button",
        onClick: _ctx.onNewEntryClick
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "documentClassId",
        header: _ctx.$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.DocumentClass'),
        sortable: false
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(_ctx.getDescriptionFromDocumentClass(slotProps.data.documentClassId)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "active",
        header: _ctx.$t('Squeeze.Imports.Active'),
        sortable: false
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(_ctx.$t("Squeeze.General.Boolean." + slotProps.data.active)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "path",
        header: _ctx.$t('Squeeze.Imports.Path'),
        sortable: false
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "indexFileType",
        header: _ctx.$t('Squeeze.Imports.FileType'),
        sortable: false
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "indexFileExtension",
        header: _ctx.$t('Squeeze.Imports.IndexFileExtension'),
        sortable: false
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "signalFileName",
        header: _ctx.$t('Squeeze.Imports.FileName'),
        sortable: false
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "imageFileExtension",
        header: _ctx.$t('Squeeze.Imports.ImageFileExtension'),
        sortable: false
      }, null, 8, ["header"]),
      _createVNode(_component_Column, { class: "buttonColumn" }, {
        body: _withCtx((files) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.openEditDialog(files.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(files.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading"]))
}