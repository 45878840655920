import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15b3db34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2" }
const _hoisted_2 = { class: "p-fluid p-formgrid p-grid p-jc-end p-p-2" }
const _hoisted_3 = { class: "p-field p-col-3" }
const _hoisted_4 = { class: "p-field p-col-3" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "p-fluid p-formgrid p-grid p-jc-end" }
const _hoisted_8 = { class: "p-field p-col-12" }
const _hoisted_9 = { class: "p-field p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocatorForm = _resolveComponent("LocatorForm")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BlockUI, { blocked: _ctx.loading }, {
        default: _withCtx(() => [
          _createVNode(_component_LocatorForm, {
            locator: _ctx.locator,
            locators: _ctx.locators,
            locatorId: _ctx.locatorId,
            locatorTypes: _ctx.locatorTypes,
            showErrorMessage: _ctx.showErrorMessage,
            showSingleLinedForm: _ctx.showSingleLinedForm,
            onUpdate: _ctx.onUpdate
          }, null, 8, ["locator", "locators", "locatorId", "locatorTypes", "showErrorMessage", "showSingleLinedForm", "onUpdate"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.store.state.featureSet.uiConfigExport)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    disabled: _ctx.loading || !_ctx.locator.id,
                    label: "Export",
                    type: "button",
                    icon: "mdi mdi-database-export",
                    class: "p-button",
                    onClick: _ctx.exportLocator
                  }, null, 8, ["disabled", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Button, {
                label: _ctx.$t('Squeeze.General.Save'),
                type: "button",
                icon: "mdi mdi-content-save-outline",
                class: "p-button",
                onClick: _ctx.saveLocator
              }, null, 8, ["label", "onClick"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["blocked"])
    ]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showExportDialog,
      closable: !_ctx.showProcessBar,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showExportDialog = false)),
      headerText: "Export",
      showSaveButton: false,
      showAbortButton: !_ctx.showProcessBar,
      abortKeydownEvent: _ctx.showProcessBar,
      abortButtonText: _ctx.$t('Squeeze.General.Abort')
    }, {
      content: _withCtx(() => [
        (_ctx.showProcessBar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_ProgressBar, {
                mode: "indeterminate",
                style: {"height":"6px"}
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.General.SuccessfulExport', { entryName: _ctx.$t('Squeeze.DocumentClasses.Locator')})), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Button, {
                    label: _ctx.$t('Squeeze.General.CopyToClipboard'),
                    icon: "mdi mdi-content-copy",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.$t, _ctx.$toast, _ctx.locatorData), _ctx.locatorData = '', _ctx.showExportDialog = false))
                  }, null, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_Button, {
                    label: _ctx.$t('Squeeze.General.Download'),
                    icon: "mdi mdi-file-download-outline",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downloadData(_ctx.locator.name, _ctx.locatorData), _ctx.locatorData = '', _ctx.showExportDialog = false))
                  }, null, 8, ["label"])
                ])
              ])
            ]))
      ]),
      _: 1
    }, 8, ["show", "closable", "showAbortButton", "abortKeydownEvent", "abortButtonText"])
  ], 64))
}