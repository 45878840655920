import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-313bc3f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  class: "p-my-2 p-pl-3",
  style: {"display":"inline"}
}
const _hoisted_3 = {
  key: 1,
  class: "p-my-2 p-pl-3 error-count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_Chip, {
      class: "p-shadow-4",
      onMouseleave: _ctx.onMouseLeave
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass((_ctx.step.name === 'Validation' && _ctx.step.count > 0) || (_ctx.store.state.scopes.sqzRequeue && _ctx.step.count > 0) || (_ctx.$route.name === 'QueueEntryView' && !_ctx.store.state.scopes.sqzRequeue && _ctx.step.count > 0) ? 'count-chip' : 'entry-chip'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickQueueStep(_ctx.step.name, '!ERROR')))
        }, [
          _createVNode(_Transition, {
            name: "flip",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock("i", {
                key: _ctx.step.count,
                class: _normalizeClass(_ctx.icon),
                style: {"fontSize":"1rem"}
              }, null, 2))
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_Transition, {
              name: "flip",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock("p", {
                  key: _ctx.step.count,
                  class: "p-m-0"
                }, _toDisplayString(_ctx.step?.count == null ? "?" : _ctx.step.count), 1))
              ]),
              _: 1
            })
          ])
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass((_ctx.step.name === 'Validation' && _ctx.step.count > 0) || (_ctx.store.state.scopes.sqzRequeue && _ctx.step.count > 0) || (_ctx.$route.name === 'QueueEntryView' && !_ctx.store.state.scopes.sqzRequeue && _ctx.step.count > 0) ? 'count-chip' : 'entry-chip'),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickQueueStep(_ctx.step.name, 'ERROR')))
        }, [
          (_ctx.step.errorCount)
            ? (_openBlock(), _createBlock(_Transition, {
                key: 0,
                name: "flip",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("i", {
                    key: _ctx.step.errorCount,
                    class: "mdi mdi-progress-alert error-icon"
                  }))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.step.errorCount)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_Transition, {
                  name: "flip",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock("p", {
                      key: _ctx.step.errorCount,
                      class: "p-m-0"
                    }, _toDisplayString(_ctx.step?.errorCount == null ? "?" : _ctx.step.errorCount), 1))
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["onMouseleave"])), [
      [_directive_tooltip, _ctx.step.errorCount && !_ctx.store.state.isAdminUser ? _ctx.$t('Squeeze.Queue.ErrorMessageByErrorCount') : _ctx.$t('Squeeze.Queue.Steps.' + _ctx.step.name)]
    ])
  ]))
}