import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QueueTimelineElement = _resolveComponent("QueueTimelineElement")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_Timeline = _resolveComponent("Timeline")!

  return (_openBlock(), _createBlock(_component_Timeline, {
    value: _ctx.queueSteps,
    align: "alternate",
    class: "customized-timeline p-px-4",
    layout: "horizontal"
  }, {
    marker: _withCtx((slotProps) => [
      (_ctx.loaded)
        ? (_openBlock(), _createBlock(_component_QueueTimelineElement, {
            key: 0,
            step: slotProps.item,
            allDocumentClasses: _ctx.allDocumentClasses,
            onOnClickQueueStep: _ctx.onClickQueueStep
          }, null, 8, ["step", "allDocumentClasses", "onOnClickQueueStep"]))
        : (_openBlock(), _createBlock(_component_Skeleton, {
            key: 1,
            width: "6rem",
            height: "2.25rem",
            borderRadius: "1rem"
          }))
    ]),
    _: 1
  }, 8, ["value"]))
}