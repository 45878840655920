import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-6" }
const _hoisted_3 = { for: "id" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "p-field p-col-6" }
const _hoisted_6 = { for: "operator" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = { class: "p-field p-col-12" }
const _hoisted_9 = { for: "filterValue" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.UserManagement.Field')) + " *", 1),
      _createVNode(_component_Dropdown, {
        id: "id",
        modelValue: _ctx.value.fieldId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.fieldId) = $event)),
        options: _ctx.documentClassFields,
        onChange: _ctx.update,
        loading: _ctx.loading,
        optionValue: "id",
        optionLabel: "description",
        filter: true,
        autoFilterFocus: true,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "onChange", "loading", "onShow"]),
      (_ctx.v$.fieldId.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.UserManagement.Field')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.UserManagement.Operator')) + " *", 1),
      _createVNode(_component_Dropdown, {
        id: "operator",
        modelValue: _ctx.value.operator,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.operator) = $event)),
        options: _ctx.operators,
        onChange: _ctx.update,
        loading: _ctx.loading,
        optionValue: "id",
        optionLabel: "description",
        filter: true,
        autoFilterFocus: true,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "onChange", "loading", "onShow"]),
      (_ctx.v$.operator.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.UserManagement.Operator')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('Squeeze.UserManagement.FilterValue')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "filterValue",
        modelValue: _ctx.value.filterValue,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.filterValue) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.filterValue.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update
      }, null, 8, ["modelValue", "class", "onChange"]),
      (_ctx.v$.filterValue.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.UserManagement.FilterValue')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}