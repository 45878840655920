import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createBlock(_component_Message, {
    severity: "info",
    closable: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Squeeze.System.Info')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('Squeeze.System.ServerTime')) + " " + _toDisplayString(_ctx.serverTime.date) + " " + _toDisplayString(_ctx.serverTime.time) + " " + _toDisplayString(_ctx.serverTime.timezone), 1)
    ]),
    _: 1
  }))
}