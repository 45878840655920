
import {defineComponent} from 'vue';
import Timeline from 'primevue/timeline';

export default defineComponent({
	name: "ProcessTimeline",
	components: {
		Timeline,
	},
	props: {
		processItems: {
			type: Array,
		},
	},
	setup() {
		return {}
	},
})
