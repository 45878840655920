import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-component" }
const _hoisted_2 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_3 = {
  key: 0,
  class: "p-field p-input-filled p-col-12"
}
const _hoisted_4 = {
  key: 1,
  class: "p-field p-input-filled p-col-12"
}
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = {
  key: 2,
  class: "p-field p-col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Message = _resolveComponent("Message")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showDropdowns)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.BatchClass')), 1),
            _createVNode(_component_Dropdown, {
              id: "active",
              loading: _ctx.loadingBatchClass,
              modelValue: _ctx.activeBatchClass,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeBatchClass) = $event)),
              options: _ctx.batchClasses,
              optionValue: "id",
              optionLabel: "description",
              placeholder: _ctx.$t('Squeeze.BatchClasses.PleaseSelect')
            }, null, 8, ["loading", "modelValue", "options", "placeholder"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showDropdowns)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.Classification')), 1),
            _createVNode(_component_Dropdown, {
              id: "",
              loading: _ctx.loadingClassification,
              modelValue: _ctx.activeClassification,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeClassification) = $event)),
              options: _ctx.batchClassClassifications,
              optionValue: "id",
              optionLabel: "description",
              placeholder: _ctx.$t('Squeeze.BatchClasses.SelectClassification')
            }, null, 8, ["loading", "modelValue", "options", "placeholder"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_FileUpload, {
          onSelect: _ctx.onSelectFiles,
          name: "documentFile",
          customUpload: true,
          onUploader: _ctx.fileUploader,
          multiple: true,
          maxFileSize: 100000000,
          showCancelButton: true,
          uploadFiles: _ctx.files,
          cancelLabel: _ctx.$t('Squeeze.General.Clear'),
          chooseLabel: _ctx.$t('Squeeze.General.Choose'),
          customProgress: _ctx.progress,
          fileContentHeight: '15.625rem',
          uploadLabel: _ctx.uploadTrainLabel,
          customUploadDisabled: _ctx.disableTraining,
          showUploadButton: _ctx.showTraining,
          onClear: _ctx.clearFiles,
          onRemoveFile: _ctx.removeFile,
          invalidFileSizeMessage: '{0}: ' + _ctx.$t('Squeeze.Validation.General.InvalidFileSize') + ' ' + '{1}',
          accept: ".pdf, .tif, .tiff"
        }, {
          content: _withCtx(({files}) => [
            (_ctx.store.state.isAdminUser && _ctx.showTesting)
              ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  disabled: files.length === 0 || _ctx.disableTesting,
                  label: _ctx.trainingLabel,
                  icon: "mdi mdi-upload",
                  class: "p-button-icon p-button-icon-left",
                  onClick: ($event: any) => (_ctx.fileUploaderTesting(files))
                }, null, 8, ["disabled", "label", "onClick"])), [
                  [_directive_tooltip, _ctx.$t('Squeeze.Training.TestingWarning')]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onSelect", "onUploader", "uploadFiles", "cancelLabel", "chooseLabel", "customProgress", "uploadLabel", "customUploadDisabled", "showUploadButton", "onClear", "onRemoveFile", "invalidFileSizeMessage"])
      ]),
      (_ctx.showTraining)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Message, {
              severity: "info",
              closable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.InfoTraining')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.InfoTraining2')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.InfoTraining3')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.InfoTraining4')), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}