export interface FreezeSchema {
	name: string;
	desc: string;
	fields: Array<FreezeSearchMaskField>;
}

export interface FreezeSchemaField {
	name: string;
	desc: string;
	dataType: "date" | "text" | "number";
}

/**
 * Defines a field that can be searched in
 */
export type FreezeSearchMaskField = FreezeSchemaField;

/**
 * Defines a search mask that can be displayed
 */
export type FreezeSearchMask = FreezeSchema;

/** Defines eas stores */
export interface FreezeStore {
	name: string;
	desc: string;
	links: {
		api: string;
	};
}
/** Defined a Freeze Document Export */
export interface FreezeExportDocuments {
	filter: {
		ids: Array<string>;
		queryString: string;
	};
	csv: {
		fields: Array<string>;
	};
}

/** Defines the possible download types */
export const enum FreezeDocumentExportType {
	Selected,
	All,
}
