import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18ea2ad6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.masterDataTables,
    loading: _ctx.loading,
    class: "p-datatable-sm p-datatable-documents",
    autoLayout: true,
    columnResizeMode: "fit",
    selection: _ctx.selection,
    "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selection) = $event)),
    selectionMode: "single",
    onRowSelect: _ctx.onRowSelect,
    scrollable: true,
    scrollHeight: "flex",
    rowHover: true,
    removableSort: true,
    filterDisplay: "row",
    filters: _ctx.filters,
    "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
    onFilter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFilter($event)))
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.MasterData.MasterData') })), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button",
        onClick: _ctx.onNewEntryClick,
        disabled: !_ctx.store.state.scopes.sqzMasterDataManage
      }, null, 8, ["label", "onClick", "disabled"]),
      (_ctx.showBtnClearFilter)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            label: _ctx.$t('Squeeze.General.ClearFilters'),
            type: "button",
            icon: "mdi mdi-filter-off-outline",
            class: "p-button p-ml-2",
            onClick: _ctx.clearFilters
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.store.state.featureSet.uiConfigExport && _ctx.store.state.isAdminUser)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            disabled: _ctx.loading || !_ctx.masterDataTables.length,
            label: _ctx.$t('Squeeze.MasterData.Export'),
            type: "button",
            icon: "mdi mdi-database-export",
            class: "p-button p-ml-2",
            onClick: _ctx.exportAllMasterData
          }, null, 8, ["disabled", "label", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "id",
        header: "ID",
        filterField: "id",
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"4rem","max-width":"4rem"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.data.id), 1)
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            style: {"min-width":"3rem"},
            class: "p-column-filter filter-field",
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "name",
        filterField: "name",
        header: _ctx.$t('Squeeze.MasterData.Name'),
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(slotProps.data.name), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.name,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            style: {"min-width":"10rem"},
            class: "p-column-filter filter-field",
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "description",
        filterField: "description",
        header: _ctx.$t('Squeeze.MasterData.Description'),
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(slotProps.data.description), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.description,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            style: {"min-width":"10rem"},
            class: "p-column-filter filter-field",
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
        ]),
        _: 1
      }, 8, ["header"]),
      (!_ctx.store.state.featureSet.masterDataTablePermissions && _ctx.store.state.scopes.sqzAdmin)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            style: {"min-width":"7rem","max-width":"7rem","text-align":"right"}
          }, {
            body: _withCtx((slotProps) => [
              _withDirectives(_createVNode(_component_Button, {
                icon: "mdi mdi-delete-outline",
                class: "p-button-lg p-button-rounded p-button-danger p-button-text",
                onClick: ($event: any) => (_ctx.openDeleteDialog(slotProps.data))
              }, null, 8, ["onClick"]), [
                [
                  _directive_tooltip,
                  _ctx.$t('Squeeze.Validation.Buttons.Delete'),
                  void 0,
                  { bottom: true }
                ]
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["value", "loading", "selection", "onRowSelect", "filters"]))
}