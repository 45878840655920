import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c0c7392"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }
const _hoisted_3 = { class: "column-cell" }
const _hoisted_4 = { class: "column-cell" }
const _hoisted_5 = { class: "column-cell" }
const _hoisted_6 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    class: "p-shadow-2",
    value: _ctx.locatorValues,
    rowHover: true,
    paginator: true,
    rows: _ctx.tableListPagination.pageSize,
    loading: _ctx.loading,
    paginatorTemplate: "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
    autoLayout: true,
    columnResizeMode: "fit",
    selectionMode: "single",
    onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onMarkWord($event))),
    onRowHover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMarkWord($event))),
    onPage: _ctx.onChangePage,
    scrollable: true,
    scrollHeight: "flex"
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.General.Values') })), 1)
    ]),
    paginatorstart: _withCtx(() => []),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "value",
        header: _ctx.$t('Squeeze.Locators.Value'),
        style: {"min-width":"6rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(slotProps.data.value), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.value,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "boundingBox.page",
        header: _ctx.$t('Squeeze.Locators.Site'),
        headerClass: "header-center",
        style: {"min-width":"4rem","max-width":"6rem","text-align":"center"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(slotProps.data.boundingBox.page), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.boundingBox.page,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "boundingBox.x0",
        header: "x0",
        style: {"min-width":"4rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(slotProps.data.boundingBox.x0), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.boundingBox.x0,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "boundingBox.y0",
        header: "y0",
        style: {"min-width":"4rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(slotProps.data.boundingBox.y0), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.boundingBox.y0,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "boundingBox.x1",
        header: "x1",
        style: {"min-width":"4rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(slotProps.data.boundingBox.x1), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.boundingBox.x1,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "boundingBox.y1",
        header: "y1",
        style: {"min-width":"4rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createTextVNode(_toDisplayString(slotProps.data.boundingBox.y1), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.boundingBox.y1,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "rows", "loading", "currentPageReportTemplate", "onPage"]))
}