
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentField, XmlHeadFieldTraining} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import {ToastManager} from "@/util/ToastManager";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import {DocumentClass} from "@dex/squeeze-client-ts";
import EntryDialog from "@/components/EntryDialog.vue";
import XmlMapperHeadList from "@/apps/administration/components/xmlmapper/XmlMapperHeadList.vue";
import XmlMappingHeadForm from "@/apps/administration/components/xmlmapper/XmlMappingHeadForm.vue";

export default defineComponent({
	name: "XmlHeadDataView",
	components: {
		DialogDelete, EntryDialog, XmlMapperHealList: XmlMapperHeadList, XmlMappingHeadForm,
	},
	props: {},
	setup() {
		/** Document-Class-Api */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** XML-Class-Api */
		const xmlApi = ClientManager.getInstance().squeeze.xml;

		/** List of all Document-Classes */
		const documentClasses = ref<DocumentClass[]>([]);

		/** List of all Document-Class-Fields */
		const allDocumentClassFields = ref<DocumentField[]>([]);
		const documentClassFields = ref<DocumentField[]>([]);

		/** List of all XML Mappings */
		const allMappingFields = ref<XmlHeadFieldTraining[]>([]);
		const mappingFields = ref<XmlHeadFieldTraining[]>([]);
		const mappingField = reactive<XmlHeadFieldTraining>({
			fieldName: '',
			xpath: '',
		});

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Show loading in table? */
		const loading = ref<boolean>(false);

		/** Should the Entry-Dialog for classifications be shown? */
		const showDialog = ref<boolean>(false);

		/** Show the loading for Save-Button of classifications? */
		const loadingDialog = ref<boolean>(false);

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		const {t} = useI18n();
		const toast = useToast();

		/** List of all Document-Classes they not selected */
		const checkDocumentClass = ref<DocumentClass[]>([]);

		/** Triggered when (all) field values are valid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref(true);

		/** Show the global mapping? */
		const showGlobalMapping = ref(true);

		const onChangeDocumentClass = (data: XmlHeadFieldTraining, valid: boolean, documentClassId: number) => {
			mappingField.fieldName = "";
			isInvalid.value = valid;
			Object.assign(mappingField, data);
			if (documentClassId !== 0) {
				documentClassFields.value = allDocumentClassFields.value.filter(field => String(field.documentClassId) === String(documentClassId))
			} else {
				documentClassFields.value = allDocumentClassFields.value.filter((item, index, self) =>
					index === self.findIndex((t) => t.name === item.name)
				);
			}
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: XmlHeadFieldTraining, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(mappingField, data);
		}

		// Define the priority based on defined table by P. Langer
		const priorityTable = [
			{ company: 'defined', creditor: 'defined', documentclassid: 'defined' }, // Prio 1
			{ company: 'defined', creditor: 'defined', documentclassid: '*' },        // Prio 2
			{ company: '*', creditor: 'defined', documentclassid: 'defined' },        // Prio 3
			{ company: '*', creditor: 'defined', documentclassid: '*' },              // Prio 4
			{ company: 'defined', creditor: '*', documentclassid: 'defined' },        // Prio 5
			{ company: '*', creditor: '*', documentclassid: 'defined' },              // Prio 6
			{ company: 'defined', creditor: '*', documentclassid: '*' },              // Prio 7
			{ company: '*', creditor: '*', documentclassid: '*' },                    // Prio 8
		];

		// Helper function to check if a value is "defined" (not "*")
		const isDefined = (value: any) => value !== '*';

		// Function to get the priority index
		const getPriorityIndex = (item: any) => {
			return priorityTable.findIndex(priority =>
				(priority.company === 'defined' ? isDefined(item.company) : priority.company === '*') &&
				(priority.creditor === 'defined' ? isDefined(item.creditor) : priority.creditor === '*') &&
				(priority.documentclassid === 'defined' ? isDefined(item.documentclassid) : priority.documentclassid === '*')
			);
		};

		/**
		 * Sorts entries by priority
		 */
		const sortEntriesByPriority = () => {
			mappingFields.value.sort((a, b) => {
				const priorityA = getPriorityIndex(a);
				const priorityB = getPriorityIndex(b);

				// First compare based on the main priority (company, creditor, documentclassid)
				if (priorityA !== priorityB) {
					return priorityA - priorityB;
				}

				// If priorities are the same, sort by readonly (false has higher priority than true)
				if (a.readonly !== b.readonly) {
					return a.readonly === false ? -1 : 1;
				}

				return 0; // If all are equal
			});
		}

		/**
		 * Show global mapping or not?
		 * @param showGlobalMappingSet
		 */
		const setShowGlobalMapping = (showGlobalMappingSet: boolean)  => {
			if (showGlobalMappingSet) {
				mappingFields.value = allMappingFields.value;
				showGlobalMapping.value = true;
			} else {
				mappingFields.value = allMappingFields.value.filter(field => !field.readonly);
				showGlobalMapping.value = false;
			}

			sortEntriesByPriority();
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;

			const promise1 = documentClassApi.getAllDocumentClasses();
			const promise2 = documentClassApi.getAllFields();
			const promise3 = xmlApi.getAllXmlHeadFieldTrainings();

			// Wait until promises are finished
			Promise.all([promise1, promise2, promise3]).then(values => {
				documentClasses.value = values[0] as DocumentClass[];
				documentClasses.value.unshift({id: 0, name: '*', description: t('Squeeze.XmlMapper.AnyValue')});
				documentClassFields.value = values[1] as DocumentField[];
				allDocumentClassFields.value = values[1] as DocumentField[];

				mappingFields.value = values[2] as XmlHeadFieldTraining[];
				allMappingFields.value = values[2] as XmlHeadFieldTraining[];
				setShowGlobalMapping(showGlobalMapping.value);
				sortEntriesByPriority();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/**
		 * Gets all Classification-Classes on Mounted
		 */
		onMounted(() =>{
			reloadData();
		});

		/** Triggered when entry button clicked */
		const onEntrySelect = (row: XmlHeadFieldTraining) => {
			if (row.id) {
				headerText.value = t('Squeeze.XmlMapper.EditXmlMapping');
			} else {
				headerText.value = t('Squeeze.XmlMapper.NewXmlMapping');
			}

			Object.assign(mappingField, row);
			showDialog.value = true;

			if (Number(row.documentclassid) !== 0) {
				documentClassFields.value = allDocumentClassFields.value.filter(field => String(field.documentClassId) === String(row.documentclassid))
			} else {
				// Remove double entries
				documentClassFields.value = allDocumentClassFields.value.filter((item, index, self) =>
					index === self.findIndex((t) => t.name === item.name)
				);
			}
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: XmlHeadFieldTraining) => {
			deleteDialog.value = true;
			Object.assign(mappingField, row);
		}

		/** Deletes the selected entry */
		const deleteEntry = () => {
			loading.value = true;
			if (mappingField.id) {
				xmlApi.deleteXmlHeadFieldTraining(mappingField.id).then(() => {
					reloadData();
				}).catch((err) => {
					loading.value = false;
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.statusText);
				})
			}
		}

		/** Saves a mapping */
		const saveMapping = () => {
			showErrorMessage.value = false;
			if (!isInvalid.value) {
				loadingDialog.value = true;
				const saveMapping = mappingField as any;

				if (saveMapping.documentclassid === 0) {
					saveMapping.documentclassid = "*"
				} else {
					saveMapping.documentclassid = String (saveMapping.documentclassid);
				}

				if (mappingField.id) {
					xmlApi.updateXmlHeadFieldTraining(mappingField.id, mappingField as any)
						.then(() => {
							reloadData();
							showDialog.value = false;
						}).catch(response => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						})).finally(() => {
							loadingDialog.value = false;
						})
				}
				else {
					xmlApi.createXmlHeadFieldTraining(saveMapping as any)
						.then(() => {
							reloadData();
							showDialog.value = false;
						}).catch(response => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						})).finally(() => {
							loadingDialog.value = false;
						})
				}
			} else {
				showErrorMessage.value = true;
			}
		}

		return {
			loading,
			deleteDialog,
			documentClasses,
			showDialog,
			loadingDialog,
			checkDocumentClass,
			showErrorMessage,
			isInvalid,
			documentClassFields,
			mappingFields,
			mappingField,
			allDocumentClassFields,
			headerText,
			openDeleteDialog,
			deleteEntry,
			reloadData,
			onEntrySelect,
			onUpdate,
			saveMapping,
			onChangeDocumentClass,
			setShowGlobalMapping,
		}
	},
})
