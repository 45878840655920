import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_DocumentClassFieldsSubView = _resolveComponent("DocumentClassFieldsSubView")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_FieldGroupForm = _resolveComponent("FieldGroupForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TabView, {
      activeIndex: _ctx.activeTabIndex,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTabIndex) = $event)),
      onTabClick: _ctx.onTabClick
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldGroups, (fieldGroup) => {
          return (_openBlock(), _createBlock(_component_TabPanel, {
            key: fieldGroup.id
          }, {
            header: _withCtx(() => [
              _createTextVNode(_toDisplayString(fieldGroup.description) + "  ", 1),
              _withDirectives(_createVNode(_component_Button, {
                onClick: ($event: any) => (_ctx.openEntryDialogFieldGroup(fieldGroup)),
                icon: "mdi mdi-pencil-outline",
                class: "p-button-rounded p-button-plain p-button-text reduceHeight"
              }, null, 8, ["onClick"]), [
                [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
              ]),
              _withDirectives(_createVNode(_component_Button, {
                onClick: ($event: any) => (_ctx.openDeleteFieldGroup(fieldGroup)),
                disabled: _ctx.fieldsPerFieldGroup[fieldGroup.id].length,
                icon: "mdi mdi-delete-outline",
                class: "p-button-rounded p-button-plain p-button-text reduceHeight"
              }, null, 8, ["onClick", "disabled"]), [
                [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_DocumentClassFieldsSubView, {
                documentClassId: _ctx.documentClassId,
                activeFieldGroup: _ctx.activeFieldGroup,
                fieldGroups: _ctx.fieldGroups,
                fieldGroupId: fieldGroup.id,
                fieldsPerFieldGroup: _ctx.fieldsPerFieldGroup[fieldGroup.id],
                documentClassFields: _ctx.documentClassFields,
                locators: _ctx.locators,
                activeTabIndex: _ctx.activeTabIndex,
                onGetDocumentClassFields: _ctx.getDocumentClassFields
              }, null, 8, ["documentClassId", "activeFieldGroup", "fieldGroups", "fieldGroupId", "fieldsPerFieldGroup", "documentClassFields", "locators", "activeTabIndex", "onGetDocumentClassFields"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_ctx.entryFieldGroup)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 0 }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.DocumentClasses.NewFieldGroup')) + "  ", 1),
                _createVNode(_component_Button, {
                  icon: "mdi mdi-plus",
                  class: "p-button-rounded p-button-plain p-button-text reduceHeight"
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["activeIndex", "onTabClick"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showCurrentFieldGroupDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCurrentFieldGroupDialog = false, _ctx.showErrorMessage= false, _ctx.isInvalid = true)),
      onOnConfirm: _ctx.saveFieldGroup,
      loading: _ctx.loadingSaveFieldGroup,
      message: _ctx.message,
      messageType: _ctx.messageType,
      headerText: _ctx.headerText
    }, {
      content: _withCtx(() => [
        _createVNode(_component_FieldGroupForm, {
          fieldEntry: _ctx.entryFieldGroup,
          showErrorMessage: _ctx.showErrorMessage,
          onUpdate: _ctx.onUpdateFieldGroup
        }, null, 8, ["fieldEntry", "showErrorMessage", "onUpdate"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "message", "messageType", "headerText"]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.showDialogFieldGroup,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialogFieldGroup = false)),
      onOnConfirm: _ctx.deleteFieldGroup
    }, null, 8, ["showDialog", "onOnConfirm"])
  ]))
}