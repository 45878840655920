
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import useSqueezeStore from "@/apps/squeeze/store";
import DocumentClassForm from "@/apps/administration/components/documentclasses/DocumentClassForm.vue";
import BlockUI from "primevue/blockui";
import EntryDialog from "@/components/EntryDialog.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentClass} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import router from "@/router";
import {Clients, ConsoleLogger, Exporter, SqueezeConfig} from "@dex/squeeze-configframework";
import {copyToClipboard, downloadData} from "@/util/Export";
import ProgressBar from "primevue/progressbar";

export default defineComponent({
	name: 'DocumentClassSubView',
	methods: {copyToClipboard, downloadData},
	components: {
		ProgressBar,
		DocumentClassForm,
		BlockUI,
		EntryDialog,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Vuex-Store */
		const store = useSqueezeStore();

		/** Service for Document-Classes */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Currently active Document-Class */
		const documentClass = reactive<DocumentClass>({
			name: '',
			description: '',
		});

		/** Is the page currently loading its data? */
		const loading = ref<boolean>(false);

		/** Show button to rebuild index only if not already loading or document class does not yet exist */
		const disableButton = computed(() => {
			return loading.value || !props.documentClassId;
		});

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref<boolean>(true);

		/** Current save-button text */
		const saveButtonText = ref<string>('');

		/** Exported document class data as string  */
		const documentClassData = ref<string>('');

		/** Is Export Dialog visible? */
		const showExportDialog = ref<boolean>(false);

		/** Is the Loading Process Bar visible? */
		const showProcessBar = ref<boolean>(false);

		const getTranslateOfSaveButton = () => {
			if (!props.documentClassId) {
				saveButtonText.value = t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.DocumentClasses.DocumentClass') });
			} else {
				saveButtonText.value = t('Squeeze.General.Save');
			}
		}

		/** Gets the Data of a Document-Class */
		const getDocumentClass = () => {
			if (props.documentClassId) {
				loading.value = true;
				documentClassService.getDocumentClassById(props.documentClassId)
					.then(data => {
						Object.assign(documentClass, data);
						loading.value = false;
					})
					.catch((err: { statusText: string }) => {
						loading.value = false;
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.DocumentClasses.DocumentClass') + ": " + err.statusText);
					});
			}
		}

		/**
		 * Triggered on update of attribute-form
		 * @param {DocumentClass} data
		 * @param {boolean} valid
		 */
		const onUpdate = (data: DocumentClass, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(documentClass, data);
		}

		/** Rebuild the search index */
		const rebuildSearchIndex = async () => {
			if (!props.documentClassId) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError'));
				return;
			}

			loading.value = true;

			try {
				await documentClassService.rebuildDocumentClassSearchIndex(props.documentClassId);
			} catch (error) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), error);
			} finally {
				loading.value = false;
			}
		}

		/** Saves a document-class */
		const saveDocumentClass = () => {
			if (isInvalid.value) {
				showErrorMessage.value = true;
				return;
			}

			let promise;

			showErrorMessage.value = false;
			loading.value = true;

			if (!documentClass.id) {
				// Create New Entry
				promise = documentClassService.postDocumentClass(documentClass)
					.then(data => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.CreateSuccess'));
						if (data.id) {
							router.push({ name: 'DocumentClassFieldGroupsSubView', params: { documentClassId: data.id}})
						}
					})
			} else {
				// Update Entry
				promise = documentClassService.putDocumentClass(props.documentClassId, documentClass)
					.then(() => ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess')));
			}

			promise
				.catch((err: { statusText: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.statusText);
				})
				.finally(() => {
					loading.value = false;
				});
		}

		/** Run export of document class */
		const runExportOfDocumentClass = async (exporter: Exporter) => {
			showExportDialog.value = true;
			showProcessBar.value = true;
			loading.value = true;
			documentClassData.value = '';

			try {
				const docClassData: SqueezeConfig = await exporter.run()
					.finally(() => {
						showProcessBar.value = false;
					});
				documentClassData.value = JSON.stringify(docClassData, null, 2);
			} catch (error) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), error);
			} finally {
				loading.value = false;
			}
		}

		/** Export of document class */
		const exportDocumentClass = async () => {
			const clients: Clients = {
				documentClass: documentClassService,
				masterData: ClientManager.getInstance().squeeze.masterData,
				locator: ClientManager.getInstance().squeeze.locator,
			}
			const exporter = new Exporter(clients, new ConsoleLogger());

			if (documentClass.name) {
				exporter.addDocumentClass(documentClass.name);

				await runExportOfDocumentClass(exporter);
			}
		}

		onMounted(() =>{
			getDocumentClass();
			getTranslateOfSaveButton();
		})

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.documentClassId, () => {
			getDocumentClass();
		})

		return {
			t,
			toast,
			store,
			disableButton,
			documentClass,
			loading,
			showErrorMessage,
			isInvalid,
			saveButtonText,
			documentClassData,
			showExportDialog,
			showProcessBar,
			getTranslateOfSaveButton,
			getDocumentClass,
			onUpdate,
			rebuildSearchIndex,
			saveDocumentClass,
			runExportOfDocumentClass,
			exportDocumentClass,
		};
	},
});

