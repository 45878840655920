
import {defineComponent, onMounted} from "vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: 'ErrorPage',
	components: {},
	props: {
		apps: {
			type: Array,
		},
	},
	setup() {
		const { locale } = useI18n({ useScope: 'global' });
		const route = useRoute();

		onMounted(() => {
			// check current language
			if (localStorage.getItem('language')) {
				locale.value = localStorage.getItem('language')!;
			}
		})

		return { route }
	},
});
