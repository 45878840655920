/** Get the embedding URL Params for the embeddable frontend */

// params
const params = new URLSearchParams(window.location.search);

// get apiKey from url params
export const apiKeyUrlParam = function(): string|null {
	// also allow lower case version
	return params.get('apiKey') ? params.get('apiKey') : params.get('apikey');
}

// get language from url params
export const languageUrlParam = function(): string|null {
	// also allow short version
	return params.get('language') ? params.get('language') : params.get('lang');
}

// get page from url params
export const pageUrlParam = function(): string|null {
	return params.get('page');
}

// get parameter from url params
export const parameterUrlParam = function(): string {
	return params.get('parameter') ? params.get('parameter')! : '';
}
