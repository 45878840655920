
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import {useI18n} from "vue-i18n";
import Dropdown from "primevue/dropdown";
import InputNumber from '@/components/DexInputNumber.vue';
import Message from "primevue/message";
import Tag from 'primevue/tag';
import {showDropdownOverlay} from "@/util/StylesHelper";

export interface AmountSeparator {
	thousandSeparator: string;
	decimalSeparator: string;
	decimalPlaces: number;
}

export default defineComponent({
	name: "AmountSeparatorsForm",
	methods: {showDropdownOverlay},
	components: {
		Tag,
		Message,
		InputNumber,
		Dropdown,
	},
	props: {
		amountSeparatorData: {
			type: Object as PropType<AmountSeparator>,
			required: true,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Current field value */
		const amountSeparators = reactive<AmountSeparator>(props.amountSeparatorData);

		/** List of all thousand separators */
		const thousandSeparators = computed(() => {
			return [
				{
					separator: ' ',
					name: t('Squeeze.General.Space'),
				},
				{
					separator: '.',
					name: t('Squeeze.General.Dot'),
				},
				{
					separator: ',',
					name: t('Squeeze.General.Comma'),
				},
				{
					separator: "'",
					name: t('Squeeze.General.Apostrophe'),
				},
				{
					separator: "´",
					name: t('Squeeze.General.AcuteAccent'),
				},
				{
					separator: "`",
					name: t('Squeeze.General.GraveAccent'),
				},
			]
		});

		/** List of all decimal separators */
		const decimalSeparators = computed(() => {
			return [
				{
					separator: '.',
					name: t('Squeeze.General.Dot'),
				},
				{
					separator: ',',
					name: t('Squeeze.General.Comma'),
				},
			]
		});

		onMounted(() => {
			Object.assign(amountSeparators, props.amountSeparatorData);
			emit("update", amountSeparators);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.amountSeparatorData, () => {
			Object.assign(amountSeparators, props.amountSeparatorData);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", amountSeparators);
		}

		/**
		 * Get all available list of amount separators
		 * @param {string} currentSeparator the current separator
		 */
		const getAvailableAmountSeparators = (currentSeparator: string) => {
			if (currentSeparator === 'decimalSeparator') {
				const availableSeparators = decimalSeparators.value.filter(sep => sep.separator !== amountSeparators.thousandSeparator);
				if (availableSeparators) {
					return availableSeparators;
				}
				return decimalSeparators;
			} else if (currentSeparator === 'thousandSeparator') {
				const availableSeparators = thousandSeparators.value.filter(sep => sep.separator !== amountSeparators.decimalSeparator);
				if (availableSeparators) {
					// when the decimal separator is empty, then not allow the comma separator for the thousand separator
					// because the comma is the default value of the decimal separator
					if (!amountSeparators.decimalSeparator) {
						return thousandSeparators.value.filter(sep => sep.separator !== ',');
					}
					return availableSeparators;
				}
				return thousandSeparators;
			}

			return [];
		}

		return {
			t,
			amountSeparators,
			thousandSeparators,
			decimalSeparators,
			update,
			getAvailableAmountSeparators,
		}
	},
});
