import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentClassFieldTable = _resolveComponent("DocumentClassFieldTable")!
  const _component_DocumentClassFieldsLayoutView = _resolveComponent("DocumentClassFieldsLayoutView")!
  const _component_FieldForm = _resolveComponent("FieldForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.showFieldsLayout)
      ? (_openBlock(), _createBlock(_component_DocumentClassFieldTable, {
          key: 0,
          rows: _ctx.fieldsPerFieldGroup,
          locators: _ctx.locators,
          fieldGroups: _ctx.fieldGroups,
          fieldGroupId: _ctx.fieldGroupId,
          loading: _ctx.loading,
          onOpenEntryDialog: _ctx.openCurrentFieldDialog,
          onDeleteEntry: _ctx.deleteField,
          onOnChangeCheckbox: _ctx.changeCheckboxField,
          onOnChangeSortOrder: _ctx.onChangeSortOrder,
          onOpenFieldsLayout: _ctx.openFieldsLayout
        }, null, 8, ["rows", "locators", "fieldGroups", "fieldGroupId", "loading", "onOpenEntryDialog", "onDeleteEntry", "onOnChangeCheckbox", "onOnChangeSortOrder", "onOpenFieldsLayout"]))
      : (_openBlock(), _createBlock(_component_DocumentClassFieldsLayoutView, {
          key: 1,
          documentClassId: _ctx.documentClassId,
          rows: _ctx.fieldsPerFieldGroup,
          fieldGroupId: _ctx.fieldGroupId,
          onOnChangeSortOrder: _ctx.onChangeSortOrder,
          onIsLayoutViewVisible: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFieldsLayout = false))
        }, null, 8, ["documentClassId", "rows", "fieldGroupId", "onOnChangeSortOrder"])),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showCurrentFieldDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCurrentFieldDialog = false, _ctx.showErrorMessage= false, _ctx.isInvalid = true)),
      onOnConfirm: _ctx.saveFieldFromDialog,
      loading: _ctx.loading,
      message: _ctx.message,
      messageType: _ctx.messageType,
      headerText: _ctx.headerText,
      showKeepDialogOpen: !_ctx.documentClassFieldsEntry.id,
      width: '60rem'
    }, {
      content: _withCtx(() => [
        _createVNode(_component_FieldForm, {
          ref: "fieldFormElement",
          fieldEntry: _ctx.documentClassFieldsEntry,
          locators: _ctx.locators,
          fieldGroups: _ctx.fieldGroups,
          showErrorMessage: _ctx.showErrorMessage,
          onUpdate: _ctx.onUpdateField,
          onOnTabChange: _ctx.onTabChange
        }, null, 8, ["fieldEntry", "locators", "fieldGroups", "showErrorMessage", "onUpdate", "onOnTabChange"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "message", "messageType", "headerText", "showKeepDialogOpen"])
  ], 64))
}