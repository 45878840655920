
import {computed, defineComponent, onBeforeMount, onMounted, PropType, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {DocumentField, DocumentFieldValue, FieldTraining} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import {useToast} from "primevue/usetoast";
import {showDropdownOverlay} from "@/util/StylesHelper";
import DocumentFieldTrainingTable from "@/apps/squeeze/components/DocumentFieldTrainingTable.vue";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

export interface FieldTrainingWithName extends FieldTraining {
	fieldName?: string;
	dataType?: string;
}

export default defineComponent({
	name: "HeadTraining",
	methods: {showDropdownOverlay},
	components: {
		Button,
		InputText,
		Dropdown,
		DocumentFieldTrainingTable,
	},
	props: {
		documentId: {
			type: Number,
			required: true,
		},
		documentClassId: {
			type: Number,
			required: true,
		},
		fieldTrainingValue: {
			type: Object as PropType<FieldTraining>,
			default: () => ({}),
		},
		documentFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		trainingKeyField: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
		},
	},
	emits: [
		'onFocusField',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current training key field */
		const trainingKey = reactive<DocumentField>({});

		/** Current Object of field training (prop) */
		const value = reactive<FieldTraining>({
			documentClassId: 0,
			fieldId: 0,
			trainingKeyValue: '',
			keyWordPattern: '',
			valuePattern: '',
			valueRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			keyWordRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			valueIgnoreWhitespace: false,
			replaceValue: '',
		});

		/** List of all training values of FieldTraining */
		const tableTrainingValues = ref<FieldTrainingWithName[]>([]);

		/** List of all testing values of FieldTraining */
		const fieldTestingValues = ref<FieldTrainingWithName[]>([]);

		/** List of all DocumentFields of document class */
		const documentClassFields = ref<DocumentField[]>([]);

		/** List with document field values for testing */
		const testingValues = reactive<DocumentFieldValue>({
			id: 0,
			value: "-",
			boundingBox: {
				page: 1,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			confidence: 0,
			subFieldName: "",
			state: "",
			errorText: "",
		});

		/** Show Button-Text Train */
		const showTrainDocument = ref<boolean>(true);

		/** Show Testing-Text in table */
		const clickOnTestingButton = ref<boolean>(false);

		/** Show Amount-Warning? */
		const warnAmount = ref<boolean>(false);

		/** Block Training Button? */
		const blockButtonTraining = ref<boolean>(false);

		/** Show Loading on load data */
		const loading = ref(false);

		/** List Values for Train Document */
		const trainWhitespaceOptions = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			]
		});

		/** Document Class API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Resets the form so a new training can be added */
		const resetForm = () => {
			value.id = undefined;
			value.keyWordPattern = '';
			value.valuePattern = '';
			value.replaceValue = '';
			//value.valueIgnoreWhitespace = false;
		}

		/** Get data of training
		 * Promise1 to get all document field values
		 * Promise2 to get all training values for training table
		 * */
		const getDataOfTraining = () => {
			if (!trainingKey.value!.value) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Viewer.Error.NoCreditorField'));
				return;
			}

			loading.value = true;

			const promise1 = documentClassApi.getAllDocumentClassFields(props.documentClassId);
			const promise2 = documentClassApi.getAllDocumentClassFieldTrainings(props.documentClassId, trainingKey.value!.value);

			// Wait until promises are finished
			Promise.all([promise1, promise2]).then(values => {
				documentClassFields.value = values[0];

				const fieldTrainingsWithName: FieldTrainingWithName[] = values[1];
				fieldTrainingsWithName.sort(function(firstValue: any, secondValue: any) {
					return firstValue.fieldId - secondValue.fieldId;
				})
				warnAmount.value = false;
				fieldTrainingsWithName.forEach((values) => {
					documentClassFields.value.forEach((field: DocumentField) => {
						if (values.fieldId === field.id) {
							values.fieldName = field.description;
							values.dataType = field.dataType;
						}
						if (value.fieldId === field.id && field.dataType === 'Amount') {
							warnAmount.value = true;
							blockButtonTraining.value = true;
						}
					})
				})

				tableTrainingValues.value = fieldTrainingsWithName;
				clickOnTestingButton.value = false;
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/** Get all field values */
		const trainFieldValues = () => {
			value.trainingKeyValue = trainingKey.value!.value!;
			const rowIds: any[] = [];

			if (value.fieldId !== 0) {
				tableTrainingValues.value.forEach((row: FieldTrainingWithName) => {
					rowIds.push(row.id);
				})
				if (value.id && rowIds.includes(value.id)) {
					documentClassApi.retrainDocumentClassField(props.documentClassId, value.fieldId, value.id, value, true)
						.then(() => {
							showTrainDocument.value = true;
							getDataOfTraining();
							resetForm();
						})
						.catch(response => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						}))
				} else {
					value.id = undefined;
					documentClassApi.trainDocumentClassField(props.documentClassId, value.fieldId, value, false)
						.then(() => {
							getDataOfTraining();
							resetForm();
						})
						.catch(response => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						}))
				}
			}
		}

		/** Testing all training values for training table */
		const testingFieldValues = () => {
			clickOnTestingButton.value = true;
			loading.value = true;
			fieldTestingValues.value = [];
			documentApi.testFieldTrainings(props.documentId, trainingKey.value!.value!)
				.then((data) => {
					data.sort(function(firstValue: any, secondValue: any) {
						return firstValue.fieldId - secondValue.fieldId;
					})
					data.forEach((dataField: FieldTraining & { fieldName?: string; dataType?: string }) => {
						if (!dataField.keyWord) {
							dataField.keyWord = testingValues;
						}
						if (!dataField.value) {
							dataField.value = testingValues;
						}
						documentClassFields.value.forEach((field: DocumentField) => {
							if (dataField.fieldId === field.id) {
								dataField.fieldName = field.description;
								dataField.dataType = field.dataType;
							}
						})
						const keyWordPage = dataField.keyWord.boundingBox!.page;
						const valuePage = dataField.value.boundingBox!.page;
						dataField.keyWordRegion.page = keyWordPage;
						dataField.valueRegion.page = valuePage;
						fieldTestingValues.value.push(dataField);
					});
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Triggered when a field is focused
		 * @param {string} fieldName
		 */
		const onFocusField = (fieldName: string) => {
			emit("onFocusField", fieldName);
		}

		/**
		 * Deletes a row of the training table
		 * @param {FieldTrainingWithName} rowData
		 */
		const deleteTrainingRow = (rowData: FieldTrainingWithName) => {
			documentClassApi.deleteDocumentClassFieldTraining(props.documentClassId, rowData.fieldId, rowData.id!)
				.then(() => {
					showTrainDocument.value = true;
					resetForm();
					getDataOfTraining();
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/**
		 * Change a training value
		 * @param {FieldTrainingWithName} rowData
		 */
		const changeTrainingRow = (rowData: FieldTrainingWithName) => {
			const editingRow = rowData;

			if (editingRow.dataType === "Amount" || editingRow.dataType === "Date") {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Validation.Dialogs.Training'));
				return;
			}

			showTrainDocument.value = false;

			if (editingRow) {
				documentClassApi.retrainDocumentClassField(editingRow.documentClassId, editingRow.fieldId, editingRow.id!, editingRow, true)
					.then(() => {
						value.keyWordPattern = editingRow.keyWordPattern;
						value.fieldId = editingRow.fieldId;
						value.valuePattern = editingRow.valuePattern;
						value.replaceValue = editingRow.replaceValue;
						value.id = editingRow.id;
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
			} else {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Training.ChangeTrainingError'));
			}
		}

		/**
		 * Set marker regions by clicking the Testing-Row
		 * @param event
		 */
		const onMarkRegion = (event: any) => {
			const row = event.data;
			if (row.keyWord && row.keyWord.boundingBox) {
				row.keyWord.boundingBox.page = row.keyWordRegion.page;
			}
			const keyWordRegion = row.keyWord.boundingBox;
			if (row.value && row.value.boundingBox) {
				row.value.boundingBox.page = row.valueRegion.page;
			}
			const valueRegion = row.value.boundingBox;
			const markRegions = [keyWordRegion, valueRegion];
			emit("onMarkRegion", markRegions);
		}

		/** Triggered when the document-field is changed */
		const onChangeDocumentField = () => {
			showTrainDocument.value = true;
			resetForm();
			getDataOfTraining();
		}

		watch(() => props.fieldTrainingValue.keyWordPattern, () => {
			value.keyWordPattern = props.fieldTrainingValue.keyWordPattern;
			value.keyWordRegion = props.fieldTrainingValue.keyWordRegion;
		})

		watch(() => props.fieldTrainingValue.valuePattern, () => {
			value.valuePattern = props.fieldTrainingValue.valuePattern;
			value.valueRegion = props.fieldTrainingValue.valueRegion;
		})

		onBeforeMount(() => {
			Object.assign(trainingKey, props.trainingKeyField);
		})

		onMounted(() => {
			Object.assign(value, props.fieldTrainingValue);
			resetForm();
			getDataOfTraining();
		})

		return {
			t,
			toast,
			trainingKey,
			value,
			tableTrainingValues,
			fieldTestingValues,
			documentClassFields,
			testingValues,
			showTrainDocument,
			clickOnTestingButton,
			warnAmount,
			blockButtonTraining,
			loading,
			trainWhitespaceOptions,
			resetForm,
			getDataOfTraining,
			trainFieldValues,
			testingFieldValues,
			onFocusField,
			deleteTrainingRow,
			changeTrainingRow,
			onMarkRegion,
			onChangeDocumentField,
		}
	},
});
