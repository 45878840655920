
import {computed, defineComponent, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import InputNumber from '@/components/DexInputNumber.vue';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import {BatchClass, BatchClassClassification, MailImportConfig} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ProtocolEnum = MailImportConfig.ProtocolEnum;
import EncryptionEnum = MailImportConfig.EncryptionEnum;
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

interface MailImportMicrosoftConfig extends MailImportConfig {
	clientId?: string;
	tenantId?: string;
}

export default defineComponent({
	name: "MailForm",
	components: {
		InputText, InputNumber, Dropdown, Password,
	},
	props: {
		mail: {
			type: Object as PropType<MailImportMicrosoftConfig>,
			required: true,
		},
		batchClassId: {
			type: Number,
			default: 1,
			required: true,
		},
		batchClasses: {
			type: Array as PropType<BatchClass[]>,
			default: () => [],
			required: true,
		},
		documentClasses: {
			type: Array as PropType<BatchClassClassification[]>,
			default: () => [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Array with the Protocols */
		const protocols = computed(() => {
			return [
				{
					id: 'imap',
					description: 'IMAP',
				},
				{
					id: 'ews',
					description: 'EWS',
				},
				{
					id: 'microsoft',
					description: 'Microsoft Graph API',
				},
				{
					id: 'microsoft_delegated',
					description: 'Microsoft Graph API Delegated',
				},
				{
					id: 'inactive',
					description: t('Squeeze.Imports.Inactive'),
				},
			]
		});

		/** Array with the Encryptions */
		const encryptions = computed(() => {
			return [
				{
					id: 'ssl',
					description: 'SSL',
				},
				{
					id: 'tls',
					description: 'TLS',
				},
				{
					id: 'notls',
					description: t('Squeeze.General.None'),
				},
			]
		});

		/** List of checkCertificates booleans */
		const checkCertificates = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			]
		});

		/** Current Object of all input-fields */
		const value = reactive<MailImportMicrosoftConfig>({
			batchClassId: props.batchClassId,
			documentClassId: undefined,
			server: '',
			port: undefined,
			protocol: ProtocolEnum.Imap,
			encryption: EncryptionEnum.Ssl,
			checkCertificate: false,
			user: '',
			password: '',
			clientId: '0',
			tenantId: '0',
			inboxFolder: '',
			validFolder: '',
			errorFolder: '',
		});

		/** Determines the required rules for validation */
		const rules = {
			//batchClassId: { required },
			server: { required },
			port: { required },
			protocol: { required },
			encryption: { required },
			checkCertificate: { required },
			user: { required },
			password: { required },
			clientId: { required },
			tenantId: { required },
			inboxFolder: { required },
			validFolder: { required },
			errorFolder: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.mail);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.mail, () => {
			Object.assign(value, props.mail);
		})

		/** Triggered on change of any field */
		const update = async () => {
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		}

		/** Triggered on change of protocol dropdown field */
		const onChangeProtocol = (event: { originalEvent: PointerEvent; value: string }) => {
			if (event.value === 'inactive' && (value.clientId && value.clientId.length > 1) && (value.tenantId && value.tenantId.length > 1)) {
				// check if inactive protocol selected and clientId & tenantId filled
				value.user = value.user + '/' + value.clientId + '/' + value.tenantId;
			} else if ((event.value === 'microsoft' || event.value === 'microsoft_delegated') && value.user.includes('/')) {
				// check if microsoft or microsoft_delegated protocol selected and user include a slash
				const mailUser = value.user.split('/');
				value.user = mailUser[0];
				value.clientId = mailUser[1];
				value.tenantId = mailUser[2];
			} else if (event.value === 'microsoft' || event.value === 'microsoft_delegated') {
				// remove default value when microsoft or microsoft_delegated selected
				if (value.clientId === '0') {
					value.clientId = '';
				}
				if (value.tenantId === '0') {
					value.tenantId = '';
				}
			} else {
				// set default value
				value.clientId = '0';
				value.tenantId = '0';
			}

			update();
		}

		return {
			t,
			toast,
			loading,
			protocols,
			encryptions,
			checkCertificates,
			value,
			props,
			v$,
			InputText,
			Dropdown,
			update,
			onChangeProtocol,
		}
	},
});

