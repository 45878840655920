
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {DocumentClass, DocumentTableColumn, XmlColumnFieldTraining} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Dropdown from "primevue/dropdown";
import {useI18n} from "vue-i18n";
import {showDropdownOverlay} from "@/util/StylesHelper";
import Textarea from "primevue/textarea";

export default defineComponent({
	name: "XmlMappingPositionForm",
	components: {
		InputText, Dropdown, Textarea,
	},
	props: {
		columnEntry: {
			type: Object as PropType<XmlColumnFieldTraining>,
			default: () => ({}),
		},
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: () => [],
		},
		allColumnsOfTableLineItems: {
			type: Array as PropType<DocumentTableColumn[]>,
			default: () => [],
		},
		showErrorMessage: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update', 'onChangeDocumentClass'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Entry-Data of the column field training */
		const value = reactive<XmlColumnFieldTraining>({
			company: '',
			creditor: '',
			documentclassid: '',
			xpath: '',
			columnName: '',
			readonly: false,
		});

		/** Determines the required rules for validation */
		const rules = {
			company: { required },
			creditor: { required },
			documentclassid: { required },
			xpath: { required },
			columnName: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.columnEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.columnEntry, () => {
			Object.assign(value, props.columnEntry);
		})

		/** Triggered when input-field is changed */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		/** Triggered when a document class changes **/
		const updateDocumentClass = async (data: any)  => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("onChangeDocumentClass", value, v$.value.$invalid, data.value);
		}

		/**
		 * Get the description of a column
		 * @param row
		 */
		const getColumnDescription = (row: any) => {
			if (props.allColumnsOfTableLineItems) {
				const columns = props.allColumnsOfTableLineItems.filter(column => column.name === row.name);

				if (columns.length > 1) {
					return row.name + " (" + columns[0].description + " (" + t('Squeeze.XmlMapper.FurtherEntries') +"))";
				} else if (columns.length === 1) {
					return row.name + " (" + columns[0].description + ")";
				}
			}

			return row.name;
		}

		return {
			value,
			v$,
			getColumnDescription,
			updateDocumentClass,
			showDropdownOverlay,
			update,
		}
	},
});
