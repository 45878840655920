import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-771f5f47"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 child-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XmlMapperHealList = _resolveComponent("XmlMapperHealList")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _component_XmlMappingHeadForm = _resolveComponent("XmlMappingHeadForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_XmlMapperHealList, {
        mappingFields: _ctx.mappingFields,
        documentClasses: _ctx.documentClasses.filter(documentClass => documentClass.id !== 0),
        documentClassFields: _ctx.allDocumentClassFields,
        onOnClickDelete: _ctx.openDeleteDialog,
        onOnEntrySelect: _ctx.onEntrySelect,
        onChangeShowGlobalMapping: _ctx.setShowGlobalMapping
      }, null, 8, ["mappingFields", "documentClasses", "documentClassFields", "onOnClickDelete", "onOnEntrySelect", "onChangeShowGlobalMapping"])
    ]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDialog = false)),
      onOnConfirm: _ctx.deleteEntry
    }, null, 8, ["showDialog", "onOnConfirm"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false, _ctx.showErrorMessage = false, _ctx.isInvalid = true)),
      onOnConfirm: _ctx.saveMapping,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText
    }, {
      content: _withCtx(() => [
        _createVNode(_component_XmlMappingHeadForm, {
          onUpdate: _ctx.onUpdate,
          onOnChangeDocumentClass: _ctx.onChangeDocumentClass,
          fieldEntry: _ctx.mappingField,
          documentClasses: _ctx.documentClasses,
          documentClassFields: _ctx.documentClassFields,
          showErrorMessage: _ctx.showErrorMessage,
          loading: _ctx.loadingDialog
        }, null, 8, ["onUpdate", "onOnChangeDocumentClass", "fieldEntry", "documentClasses", "documentClassFields", "showErrorMessage", "loading"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText"])
  ], 64))
}