import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b1b2ed8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 child-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MasterDataRows = _resolveComponent("MasterDataRows")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _component_MasterDataRowsForm = _resolveComponent("MasterDataRowsForm")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MasterDataRows, {
        masterDataColumns: _ctx.masterDataColumns,
        masterDataRows: _ctx.masterDataRows,
        loading: _ctx.loading,
        onOnClickDelete: _ctx.openDeleteDialog,
        onOnEntrySelect: _ctx.onEntrySelect,
        onOnPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event))),
        paginationInfo: _ctx.pagination,
        onOnChangeInputField: _ctx.onChangeInputField,
        filterOptions: _ctx.filters,
        onOnFilter: _ctx.onFilter,
        onOnSort: _ctx.onSort,
        permissions: _ctx.permissions,
        onStartExportOfMasterData: _ctx.startExportOfMasterData
      }, null, 8, ["masterDataColumns", "masterDataRows", "loading", "onOnClickDelete", "onOnEntrySelect", "paginationInfo", "onOnChangeInputField", "filterOptions", "onOnFilter", "onOnSort", "permissions", "onStartExportOfMasterData"])
    ]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteDialog = false)),
      onOnConfirm: _ctx.deleteEntry
    }, null, 8, ["showDialog", "onOnConfirm"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = false)),
      onOnConfirm: _ctx.saveMasterDataColumn,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText,
      showKeepDialogOpen: !_ctx.masterDataRow.id
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: _ctx.loadingDialog }, {
          default: _withCtx(() => [
            _createVNode(_component_MasterDataRowsForm, {
              ref: "masterDataRowForm",
              masterDataRow: _ctx.masterDataRow,
              onUpdate: _ctx.onUpdate,
              masterDataColumns: _ctx.masterDataColumns
            }, null, 8, ["masterDataRow", "onUpdate", "masterDataColumns"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText", "showKeepDialogOpen"])
  ], 64))
}