export default class SinglePromise {

	private currentPromise: Promise<any>|null = null;

	/**
	 * Executes the latest promise that is passed to this Class
	 * @param externalPromise
	 */
	public async execute(externalPromise: Promise<any>): Promise<any> {
		const promise = externalPromise;
		this.currentPromise = promise;

		return new Promise((resolve, reject) => {
			promise.then(result => {
				if (promise == this.currentPromise) {
					this.currentPromise = null;
					resolve(result);
				}
			}).catch(reject);
		});
	}

}
