import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58bf50f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-mb-2 p-p-2 inherit-height p-d-flex p-flex-column" }
const _hoisted_2 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_3 = { class: "p-field p-col-12" }
const _hoisted_4 = { for: "masterDataTableId" }
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = { for: "masterDataTableColumn" }
const _hoisted_7 = { class: "p-field p-col-4" }
const _hoisted_8 = { for: "tableKeyColumnFilter" }
const _hoisted_9 = { class: "p-field p-col-4" }
const _hoisted_10 = { for: "tableKeyFieldFilterId" }
const _hoisted_11 = { class: "p-field p-col-4" }
const _hoisted_12 = { for: "tableKeyLocatorFilterId" }
const _hoisted_13 = { class: "p-grid p-jc-end p-p-2" }
const _hoisted_14 = {
  key: 0,
  class: "p-mt-2 child-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DBLinkedData = _resolveComponent("DBLinkedData")!
  const _component_Message = _resolveComponent("Message")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _component_DBLinkedDataForm = _resolveComponent("DBLinkedDataForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BlockUI, {
      blocked: _ctx.loading,
      class: "inherit-height"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Table')), 1),
              _createVNode(_component_Dropdown, {
                id: "masterDataTableId",
                modelValue: _ctx.masterDataTableId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.masterDataTableId) = $event)),
                options: _ctx.masterDataTables,
                filter: true,
                autoFilterFocus: true,
                optionValue: "id",
                optionLabel: "description",
                onChange: _ctx.onChangeMasterDataTable,
                onShow: _ctx.showDropdownOverlay
              }, null, 8, ["modelValue", "options", "onChange", "onShow"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.MasterData.Column')), 1),
              _createVNode(_component_Dropdown, {
                id: "masterDataTableColumn",
                modelValue: _ctx.masterDataTableColumnId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.masterDataTableColumnId) = $event)),
                options: _ctx.masterDataColumns,
                filter: true,
                autoFilterFocus: true,
                optionValue: "id",
                optionLabel: "description",
                onChange: _ctx.onChangeMasterDataTable,
                onShow: _ctx.showDropdownOverlay
              }, null, 8, ["modelValue", "options", "onChange", "onShow"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('Squeeze.Locators.KeyColumnFilter')), 1),
              _createVNode(_component_Dropdown, {
                id: "masterDataTableColumn",
                modelValue: _ctx.tableKeyColumnFilterId,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tableKeyColumnFilterId) = $event)),
                options: _ctx.masterDataColumns,
                filter: true,
                autoFilterFocus: true,
                optionValue: "id",
                optionLabel: "description",
                onShow: _ctx.showDropdownOverlay,
                "show-clear": true
              }, null, 8, ["modelValue", "options", "onShow"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('Squeeze.Locators.KeyFieldFilter')), 1),
              _createVNode(_component_Dropdown, {
                id: "tableKeyFieldFilterId",
                modelValue: _ctx.tableKeyFieldFilterId,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tableKeyFieldFilterId) = $event)),
                options: _ctx.documentClassFields,
                filter: true,
                autoFilterFocus: true,
                optionValue: "id",
                optionLabel: _ctx.getFieldLabel,
                onShow: _ctx.showDropdownOverlay,
                "show-clear": true
              }, null, 8, ["modelValue", "options", "optionLabel", "onShow"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('Squeeze.Locators.LocatorFilter')), 1),
              _createVNode(_component_Dropdown, {
                id: "tableKeyLocatorFilterId",
                modelValue: _ctx.tableKeyLocatorFilterId,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tableKeyLocatorFilterId) = $event)),
                options: _ctx.allLocators,
                filter: true,
                autoFilterFocus: true,
                optionValue: "id",
                optionLabel: "description",
                onShow: _ctx.showDropdownOverlay,
                "show-clear": true
              }, null, 8, ["modelValue", "options", "onShow"])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_Button, {
              label: _ctx.$t('Squeeze.General.Save'),
              onClick: _ctx.saveSourceTable
            }, null, 8, ["label", "onClick"])
          ]),
          (_ctx.sourceTable.tableId === _ctx.masterDataTableId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_DBLinkedData, {
                  DbLinkedDataEntries: _ctx.DbLinkedDataEntries,
                  allLocators: _ctx.allLocators,
                  masterDataColumns: _ctx.masterDataColumns,
                  showErrorMessage: _ctx.showValidationMessage,
                  onOnClickDelete: _ctx.openDeleteDialog,
                  onOnEntrySelect: _ctx.onEntrySelect,
                  onOnChangeSortOrder: _ctx.onChangeSortOrder
                }, null, 8, ["DbLinkedDataEntries", "allLocators", "masterDataColumns", "showErrorMessage", "onOnClickDelete", "onOnEntrySelect", "onOnChangeSortOrder"])
              ]))
            : (_openBlock(), _createBlock(_component_Message, {
                key: 1,
                class: "p-m-0",
                severity: "warn",
                closable: false
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Squeeze.System.Warning')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.WarningMessageDBLinkedData')), 1)
                ]),
                _: 1
              }))
        ])
      ]),
      _: 1
    }, 8, ["blocked"]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteDialog = false)),
      onOnConfirm: _ctx.deleteEntry
    }, null, 8, ["showDialog", "onOnConfirm"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDialog = false, _ctx.isValidationInvalid = true, _ctx.showValidationMessage = false)),
      onOnConfirm: _ctx.saveDbLinkLocator,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText,
      showKeepDialogOpen: !_ctx.DBLinkedData.id
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: _ctx.loadingDialog }, {
          default: _withCtx(() => [
            _createVNode(_component_DBLinkedDataForm, {
              DBLinkedData: _ctx.DBLinkedData,
              allLocators: _ctx.allLocators,
              masterDataColumns: _ctx.masterDataColumns,
              onUpdate: _ctx.onUpdate,
              showErrorMessage: _ctx.showValidationMessage
            }, null, 8, ["DBLinkedData", "allLocators", "masterDataColumns", "onUpdate", "showErrorMessage"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText", "showKeepDialogOpen"])
  ], 64))
}