import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-pb-2 p-component" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserTakeOverRolesForm = _resolveComponent("UserTakeOverRolesForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.UserManagement.TakeOverRolesDescription')), 1)
    ]),
    _createVNode(_component_UserTakeOverRolesForm, {
      loading: _ctx.loading,
      usersFrom: _ctx.usersFrom,
      usersTo: _ctx.usersTo,
      usersTakeOverRoles: _ctx.usersTakeOverRoles,
      showErrorMessage: _ctx.showErrorMessage,
      onUpdate: _ctx.onUpdate
    }, null, 8, ["loading", "usersFrom", "usersTo", "usersTakeOverRoles", "showErrorMessage", "onUpdate"])
  ], 64))
}