
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, PropType} from "vue";
import {ApiKey} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "ApiKeyList",
	components: {
		DataTable, Column,
	},
	props: {
		apiKeys: {
			type: Array as PropType<ApiKey[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		selectionMode: {
			type: String,
			default: null,
		},
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onRowSelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: ApiKey) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				name: '',
				value: '',
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: ApiKey) => {
			emit("onEntrySelect", row)
		}

		/** Triggered when a row is selected */
		const onRowSelect = (event: any) => {
			emit("onRowSelect", event.data)
		}

		/** Triggered on click of the button to copy */
		const onClickCopy = (apiKey: string) => {
			if(apiKey && navigator && navigator.clipboard) {
				navigator.clipboard.writeText(apiKey).then(
					() => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.UserManagement.ApiKeyCopied'));
					}
				);
			}
		}

		return {
			openDeleteDialog, onNewEntryClick, onRowSelect, onClickCopy, openEditDialog,
		}
	},
});

