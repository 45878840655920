
import {computed, defineComponent, PropType, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {DocumentField, FormTrainingHeadField, FormTrainingRegionAnchor} from "@dex/squeeze-client-ts";
import {showDropdownOverlay} from "@/util/StylesHelper";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormTrainingHeadRegionAnchor from "@/apps/squeeze/components/FormTrainingHeadRegionAnchor.vue";
import FormTrainingHeadFields from "@/apps/squeeze/components/FormTrainingHeadFields.vue";

export default defineComponent({
	name: "FormHeadTraining",
	methods: {showDropdownOverlay},
	components: {
		FormTrainingHeadFields,
		FormTrainingHeadRegionAnchor,
		TabView,
		TabPanel,
	},
	props: {
		documentId: {
			type: Number,
			required: true,
		},
		documentClassId: {
			type: Number,
			required: true,
		},
		documentFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		trainingKeyField: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
		},
		headFieldOfRegionInFormTraining: {
			type: Object as PropType<FormTrainingHeadField>,
			default: () => ({}),
		},
		regionAnchorOfFormTraining: {
			type: Object as PropType<FormTrainingRegionAnchor>,
			default: () => ({}),
		},
	},
	emits: [
		'onFocusField',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current active tab option */
		const activeTabOption = ref(0);

		/** All tab options of a file */
		const tabOptions = ref([
			{id: 1, value: t('Squeeze.Training.Region') + ' ' + t('Squeeze.Training.Anchor')},
			{id: 2, value: t('Squeeze.Training.Fields')},
		]);

		/** Show Loading on load data */
		const loading = ref(false);

		/** List of boolean options */
		const booleanOptions = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			]
		});

		/** List of all training values of form-based Head Training */
		const listOfTrainingValues = ref<any[]>([]);

		/**
		 * Triggered when a field is focused
		 * @param {string} fieldName
		 */
		const onFocusField = (fieldName: string) => {
			emit("onFocusField", fieldName);
		}

		/**
		 * Set marker regions by clicking a row
		 * @param region
		 */
		const onMarkRegion = (region: any) => {
			emit("onMarkRegion", region);
		}

		return {
			t,
			toast,
			activeTabOption,
			tabOptions,
			loading,
			booleanOptions,
			listOfTrainingValues,
			onFocusField,
			onMarkRegion,
		}
	},
});
