
import {defineComponent, onMounted, reactive, ref} from "vue";
import InputText from "primevue/inputtext";
import Message from 'primevue/message';
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "SystemResetForm",
	components: {
		InputText, Message,
	},
	props: {
		showErrorMessage: {
			type: Boolean,
			default: false,
		},
		invalidPassword: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<{ password: string }>({
			password: '',
		});

		/** Determines the required rules for validation */
		const rules = {
			password: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Triggered on change of any field */
		const update = async () => {
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			loading,
			value,
			props,
			v$,
			update,
		}
	},
});

