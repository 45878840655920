import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "comment" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Message, {
        severity: "info",
        closable: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.warningText), 1)
        ]),
        _: 1
      }),
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Queue.General.CommentToDelete')), 1),
      _createVNode(_component_InputText, {
        id: "comment",
        modelValue: _ctx.value.comment,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.comment) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.comment.$invalid && _ctx.showErrorMessage}),
        onKeyup: _ctx.update,
        onBlur: _ctx.update,
        autofocus: ""
      }, null, 8, ["modelValue", "class", "onKeyup", "onBlur"]),
      (_ctx.v$.comment.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Queue.General.Comment')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}