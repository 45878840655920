
import { defineComponent } from 'vue';
import { Handle, Position } from '@vue-flow/core';

export default defineComponent({
	name: 'LabelNode',
	components: {
		Handle,
	},
	props: {
		label: {
			type: String,
		},
	},
	setup() {
		return {
			Position,
		};
	},
});

