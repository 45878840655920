
import {defineComponent, onMounted, PropType, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {getSqueezeStepIconMap} from "@/apps/squeeze/App.vue";
import {useConfirm} from "primevue/useconfirm";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Menu from "primevue/menu";
import {DocumentField, PaginationDto, QueueEntry} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {DateTimeOptions} from "@/util/DateTimeOptions";
import {FilterMatchMode} from "primevue/api";
import {DEFAULT_LOCALE} from "@/lang";
import {i18n} from "@/main";
import Tooltip from "primevue/tooltip";

interface MenuItem {
	label: string;
	items: MenuSubItem[];
}

interface MenuSubItem {
	label: string;
	icon: string;
	command: {};
}

export default defineComponent({
	name: "QueueEntries",
	components: {
		DataTable,
		Column,
		Button,
		Menu,
	},
	props: {
		/** ID of document class */
		documentClassId: {
			type: Number,
			required: true,
		},
		/** Name of document class */
		documentClassName: {
			type: String,
			required: true,
		},
		/** List of all QueueEntries in QueueStep Validation */
		queueEntries: {
			type: Array as PropType<QueueEntry[]>,
			default: () => [],
		},
		/** Indicates end of request */
		loaded: {
			type: Boolean,
			default: false,
		},
		/** Pagination information */
		paginationInfo: {
			type: Object as PropType<PaginationDto>,
			default: () => ({}),
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onRowSelect", "onReload", "onPage"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const confirm = useConfirm();

		/** Current Vuex Store */
		const store = useSqueezeStore();

		const filters = reactive<any>({
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"create_ts": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"status": {value: null, matchMode: FilterMatchMode.EQUALS},
			"errorText": {value: null, matchMode: FilterMatchMode.CONTAINS},
		});

		/** Requeue steps to choose in menu */
		const steps = ref<MenuItem[]>([]);

		/** Currently selected row */
		const selection = reactive<QueueEntry>({});

		/** All selection rows */
		const selectedRows = ref<QueueEntry[]>([]);

		/** Is table locked? */
		const lockTable = ref<boolean>(false);

		/** Current locale */
		const lang = ref(DEFAULT_LOCALE);

		/** Ref of single entry menu */
		const singleEntryMenu = ref(null);

		/** Ref of multi entry menu */
		const multiEntryMenu = ref(null);

		/** Queue API endpoint */
		const queueApi = ClientManager.getInstance().squeeze.queue;

		/**
		 * Triggered when a row is clicked
		 * @param event
		 */
		const onRowClick = (event: { originalEvent: MouseEvent; data: QueueEntry; index: number }) => {
			/* Prevent the row-click in the selection-column with the checkbox
			* Use the selectionMode 'multiple' only at the respective column and not at the table, when you also use the row-click event
			* If the selectionMode is set on the table and the row click event is active, these two events overwrite each other
			*/
			if (event.originalEvent
				&& !(event.originalEvent.target as HTMLElement).matches('.p-selection-column')
				&& !(event.originalEvent.target as HTMLElement).matches('.p-checkbox *')) {
				emit("onRowSelect", event.data);
			}
			return;
		}

		/**
		 * Triggered when the next page is selected
		 * @param event
		 */
		const onPage = (event: any) => {
			emit("onPage", event)
		}

		/** Triggered when table content should be reloaded */
		const onReload = () => {
			emit("onReload");
		}

		/**
		 * Opens requeue menu
		 * @param {MouseEvent} event
		 * @param {QueueEntry} data
		 */
		const openRequeueMenu = (event: MouseEvent, data: QueueEntry) => {
			Object.assign(selection, data);

			const singleMenu = singleEntryMenu.value as any;
			if (singleMenu) {
				singleMenu.toggle(event);
			}
		}

		/**
		* Opens the Requeue Menu
		* @param event
		*/
		const openMultiRequeueMenu = (event: any) => {
			if (!selectedRows.value.length) {
				return;
			}

			const multiMenu = multiEntryMenu.value as any;
			if (multiMenu) {
				multiMenu.toggle(event);
			}
		}

		/**
		* Opens the Requeue Menu
		* @param event
		*/
		const openGoToMenu = (event: any) => {
			const multiMenu = multiEntryMenu.value as any;
			if (multiMenu) {
				multiMenu.toggle(event);
			}
		}

		/**
		 * Requeue selected QueueEntry to specified step
		 * @param {string} step
		 */
		const requeueEntry = (step: string) => {
			if(selection && selection.id) {
				// REQUEUE API ENDPOINT
				lockTable.value = true;
				queueApi.requeueEntry(selection.id, step).then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.Queue.Requeue.Success'), t('Squeeze.Queue.Steps.' + step));
				}).catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), reason);
				}).finally(() => {
					Object.assign(selection, {});
					lockTable.value = false;
					onReload();
				})
			} else {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Select.Entry.One'));
			}
		}

		/**
		 * Requeue the selected entries
		 * @param {string} step
		 */
		const requeueSelectedEntries = (step: string) => {
			if (!selectedRows.value || !selectedRows.value.length) {
				return;
			}

			lockTable.value = true;
			selectedRows.value.reduce((chain, { id }) => {
				if (id == undefined) {
					return chain;
				}
				return chain.finally(() => queueApi.requeueEntry(id, step).catch(() => { /* swallow errors */ }))
			}, Promise.resolve()).finally(() => {
				ToastManager.showSuccess(toast, t('Squeeze.Queue.Requeue.Success'), t('Squeeze.Queue.Steps.' + step));
				selectedRows.value = [];
				lockTable.value = false;
				onReload();
			});
		}

		/**
		 * Formats date string to desired locale string
		 * @param {string} dateToFormat
		 */
		const formatDate = (dateToFormat: string) => {
			if (dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if (!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: DateTimeOptions = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
					lang.value = i18n.global.locale;
					return date.toLocaleDateString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), options);
				}
			}
			return "";
		}

		/**
		 * Parses an amount (string) to locale string
		 * @param {string} value
		 */
		const parseAmounts = (value: string) => {
			value = value.replace(/[^0-9.,]/g, "");

			if (value.indexOf(",") != -1) {
				value = value.replaceAll(".", "").replace(",", ".");
			}

			if (value.length > 0) {
				return parseFloat(value).toLocaleString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), {minimumFractionDigits: 2});
			} else {
				return "0,00";
			}
		}

		/**
		 * Returns field value of passed DocumentField array
		 * @param {DocumentField[]} fields
		 * @param {number} fieldId
		 * @param {string} type
		 */
		const getFieldValue = (fields: DocumentField[], fieldId: number, type: string) => {
			if (fields && fieldId) {
				const fieldValue = fields.find((field) => {
					return field.id === fieldId;
				});

				if (fieldValue != undefined) {
					if (fieldValue.value && fieldValue.value.value) {
						switch(type.toLowerCase()) {
						case "amount": return parseAmounts(fieldValue.value.value);
						case "date": return formatDate(fieldValue.value.value);
						default: return fieldValue.value.value;
						}
					}
				}
			}

			return "";
		}

		onMounted(() => {
			steps.value = [{
				label: t('Squeeze.Queue.General.Steps'),
				items: [],
			}];
			getSqueezeStepIconMap().forEach((icon: string, step: string) => {
				steps.value[0].items.push({
					label: t('Squeeze.Queue.Steps.' + step),
					icon: icon,
					command: (ev: { item: any; originalEvent: any }) => {
						let messageText = '';
						let acceptFn = null;

						// get parent element of target
						const el = ev.originalEvent.target as HTMLElement;
						const closestParent = el.closest('div');

						if (closestParent && closestParent.matches('#multi_entry_menu')) {
							// If entry in multi_menu is clicked (Requeue-Button under table for multi-selection)
							messageText = t('Squeeze.Queue.Requeue.ForwardEntries');
							acceptFn = () => {
								requeueSelectedEntries(step);
							};
						}
						else if ((ev.originalEvent.target as HTMLElement).matches('#single_entry_menu *')) {
							// If entry in single_menu is clicked (Requeue-Button in every single row)
							messageText = t('Squeeze.Queue.Requeue.ForwardEntry');
							acceptFn = () => {
								requeueEntry(step);
							};
						}
						else {
							return;
						}

						confirm.require({
							message: messageText + ": " + t('Squeeze.Queue.Steps.' + step) + " ?",
							header: t('Squeeze.General.Confirmation'),
							icon: 'pi pi-exclamation-triangle',
							accept: acceptFn,
							reject: () => { /**/ },
						});
					},
				});
			});
		});

		return {
			t,
			toast,
			store,
			filters,
			steps,
			selection,
			selectedRows,
			lockTable,
			lang,
			singleEntryMenu,
			multiEntryMenu,
			onRowClick,
			onPage,
			onReload,
			openRequeueMenu,
			openMultiRequeueMenu,
			openGoToMenu,
			requeueEntry,
			requeueSelectedEntries,
			formatDate,
			parseAmounts,
			getFieldValue,
		};
	},
});

