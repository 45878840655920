
import {defineComponent, onMounted, ref} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import {MasterDataTable} from "@dex/squeeze-client-ts";
import MasterDataTableList from "@/apps/administration/components/masterdata/MasterDataTableList.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import ProgressBar from 'primevue/progressbar';
import {ToastManager} from "@/util/ToastManager";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import router from "@/router";
import {copyToClipboard, downloadData} from "@/util/Export";
import {Clients, ConsoleLogger} from "@dex/squeeze-configframework";
import {MasterDataExporter} from "@dex/squeeze-configframework/build/export/MasterDataExporter";
import {MasterDataConfig} from "@dex/squeeze-configframework/build/export/MasterDataConfig";

export default defineComponent({
	name: "MasterDataListView",
	components: {
		MasterDataTableList,
		DialogDelete,
		EntryDialog,
		ProgressBar,
	},
	methods: {copyToClipboard, downloadData},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Api for master data */
		const masterDataApi = ClientManager.getInstance().squeeze.masterData;

		/** List of all Master data tables */
		const masterDataTables = ref<MasterDataTable[]>([]);

		/** Are the entries currently loading? */
		const loading = ref<boolean>(false);

		/** Should the delete dialog be shown? */
		const deleteDialog = ref<boolean>(false);

		/** Current master table entry */
		const masterDataTable = ref<MasterDataTable>({name: "", description: ''});

		/** Exported all master data as string  */
		const allMasterData = ref<string>('');

		/** Is Export Dialog visible? */
		const showExportDialog = ref<boolean>(false);

		/** Is the Loading Process Bar visible? */
		const showProcessBar = ref<boolean>(false);

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			masterDataApi.getAllMasterDataTables().then(data => {
				masterDataTables.value = data;
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/**
		 * Gets all Batch-Classes on Mounted
		 */
		onMounted(() =>{
			reloadData();
		});

		/** Triggered when a row is selected */
		const onEntrySelect = (masterDataTableId: number) => {
			router.push({ name: 'MasterDataView', params: { masterDataTableId: masterDataTableId }})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: MasterDataTable) => {
			deleteDialog.value = true;
			masterDataTable.value = row;
		}

		/**
		 * Deletes and entry
		 */
		const deleteEntry = () => {
			loading.value = true;

			if (masterDataTable.value.id) {
				masterDataApi.deleteMasterDataTable(masterDataTable.value.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/** Run export of all master data */
		const runExportOfAllMasterData = async (exporter: MasterDataExporter) => {
			showExportDialog.value = true;
			showProcessBar.value = true;
			loading.value = true;
			allMasterData.value = "";

			try {
				const data: MasterDataConfig[] = await exporter.run()
					.finally(() => {
						showProcessBar.value = false;
					});
				allMasterData.value = JSON.stringify({masterDataTables: data}, null, 2);
			} catch (error) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), error);
			} finally {
				loading.value = false;
			}
		}

		/** Export all master data */
		const exportAllMasterData = async () => {
			/** Api-Clients */
			const clients: Clients = {
				documentClass: ClientManager.getInstance().squeeze.documentClass,
				masterData: ClientManager.getInstance().squeeze.masterData,
				locator: ClientManager.getInstance().squeeze.locator,
			};

			const masterDataExporter = new MasterDataExporter(clients, new ConsoleLogger());
			await runExportOfAllMasterData(masterDataExporter);
		}

		return {
			masterDataTables,
			loading,
			deleteDialog,
			allMasterData,
			showExportDialog,
			showProcessBar,
			openDeleteDialog,
			deleteEntry,
			reloadData,
			onEntrySelect,
			exportAllMasterData,
		}
	},
})
