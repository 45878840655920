
import {computed, defineComponent, onBeforeMount, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import BlockUI from 'primevue/blockui';
import Dropdown from 'primevue/dropdown';
import {ToastManager} from "@/util/ToastManager";

export default defineComponent({
	name: "SetupForm",
	components: {
		InputText, Button, BlockUI, Dropdown,
	},
	props: {
		clearTenantField: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["sendAuth", "verifyKey"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const { locale } = useI18n({ useScope: 'global' })

		/** Server Password */
		const serverPassword = ref<string>('');

		/** Tenant */
		const tenant = ref<string>('');

		/** User */
		const user = ref<string>('');

		/** Should the ui be blocked? */
		const blocked = ref<boolean>(false);

		/** Currently selected language */
		const selectedLanguage = ref<string|null>(null);

		/** Languages for UI */
		const languages = computed(() => {
			return [
				{ name: t('System-Administration.Language.German'), code: 'de' },
				{ name: t('System-Administration.Language.English'), code: 'en' },
			]
		});

		/** Triggered to check the auth */
		const sendAuth = () => {
			blocked.value = true;

			if (serverPassword.value === '' || tenant.value === '' || user.value === '') {
				ToastManager.showError(toast, t("System-Administration.Login.LoginFailed"), 'Login-Daten fehlerhaft'); // TODO: add translation
			} else {
				emit("sendAuth", user.value, serverPassword.value, tenant.value);
			}

			blocked.value = false;
		}

		/**
		 * Triggered on keypress
		 * @param event
		 */
		const onKeyDown = (event: {key: string}) => {
			if (event.key === "Enter") {
				sendAuth();
			}
		}

		/** Check Language by first load */
		const checkLanguage = () => {
			if (localStorage.getItem('language')) {
				const language = languages.value.find(language => language.code ===  localStorage.getItem('language'));
				if (language) {
					locale.value = language.code;
					selectedLanguage.value = language.code;
				}
			} else {
				// Check browser language if no local storage language
				const language = languages.value.find(language => language.code === navigator.language.substring(0, 2));
				if (language) {
					locale.value = language.code;
					selectedLanguage.value = language.code;
					localStorage.setItem('language', locale.value);
				}
			}
		}

		/** Triggered when a language is selected */
		const changeLanguage = () => {
			const language = languages.value.find(language => language.code === selectedLanguage.value);
			if (language) {
				locale.value = language.code;
				selectedLanguage.value = language.code;
			}
		}

		watch(() => props.clearTenantField, () => {
			// check if the prop is true, so that clear the tenant field
			if (props.clearTenantField) {
				tenant.value = '';
			}
		})

		onBeforeMount(() => {
			// check language
			checkLanguage();
		})

		return {
			t,
			serverPassword,
			tenant,
			user,
			blocked,
			selectedLanguage,
			languages,
			sendAuth,
			onKeyDown,
			changeLanguage,
		};
	},
});

