
import {computed, defineComponent, PropType, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import Tooltip from "primevue/tooltip";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import DialogDelete from "@/components/DialogDelete.vue";
import Badge from "primevue/badge";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import {DocumentField, DocumentFieldGroup, DocumentLocator} from "@dex/squeeze-client-ts";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: 'DocumentClassFieldTable',
	components: {
		DataTable,
		Column,
		Checkbox,
		DialogDelete,
		Badge,
		InputText,
		Dropdown,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All rows */
		rows: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		/** All available Locators */
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: () => [],
		},
		/** All Field-Groups for the Document-Class */
		fieldGroups: {
			type: Array as PropType<DocumentFieldGroup[]>,
			default: () => [],
		},
		fieldGroupId: {
			type: Number,
			default: -1,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "onChangeCheckbox", "onChangeSortOrder", "openFieldsLayout"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Vuex-Store */
		const store = useSqueezeStore();

		/** show Delete Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Data of selected row */
		const rowData = reactive<DocumentField>({});

		/** Is the Search activated? */
		const searchActive = ref<boolean>(false);

		/** Filters of list (Currently not activated) */
		const filters = ref<DocumentFilterObject>({});

		/** Is Filtering active? */
		const isFilterActive = ref<boolean>(false);

		/** List of Data-Types */
		const dataTypes = computed(() => {
			return [
				{
					id: 'Text',
					text: t('Squeeze.General.Text'),
				},
				{
					id: 'Date',
					text: t('Squeeze.General.Date'),
				},
				{
					id: 'Amount',
					text: t('Squeeze.General.Amount'),
				},
			];
		});

		/**
		 * Opens the Delete Dialog
		 * @param {DocumentField} data Data from the Document-Class that should be deleted
		 */
		const openDeleteDialog = (data: DocumentField) => {
			Object.assign(rowData, data);
			deleteDialog.value = true;
		}

		/**
		 * Opens the Edit-Dialog
		 * @param {DocumentField} data Data to edit
		 */
		const openEntryDialog = (data: DocumentField) => {
			if (!data.lookup) {
				data.lookup = {
					active: false,
					allowCustomValues: true,
					tableId: 0,
					resultKeyColumnId: 0,
					searchColumnIds: [],
					resultValueColumnIds: [],
					minInputLength: 1,
				};
			}

			// reset data
			if (!data.id) {
				data.id = undefined;
				data.name = undefined;
				data.description = undefined;
				data.dataType = undefined;
				data.documentClassId = undefined;
				data.locatorId = undefined;
				data.mandatory = undefined;
				data.readonly = undefined;
				data.hidden = undefined;
				data.forceValidation = undefined;
				data.sortOrder = undefined;
				data.fieldGroupId = undefined;
				data.externalName = undefined;
				data.value = undefined;
				data.defaultValue = undefined;
				data.sameLineAsPreviousField = undefined;
				data.subFieldName = undefined;
				data.state = undefined;
			}

			Object.assign(rowData, data);
			emit("openEntryDialog", rowData, props.fieldGroupId);
		}

		/**
		 * Gets the Description of a locator
		 * @param {number} locatorId Id of the Locator to get the Description from
		 * @return {string} Description of the Locator
		 */
		const getDescriptionFromLocator = (locatorId: number) => {
			if (!locatorId) {
				return "";
			}

			if (!props.locators) {
				return String(locatorId);
			}

			const locator = props.locators.find(locator => locator.id === locatorId);
			if (!locator) {
				return String(locatorId);
			}

			return locator.description;
		}

		/**
		 * Get the Translation of data type
		 * @param {string} type
		 * @return {string} Current Translation of data type
		 */
		const getTranslationFromDataType = (type: string): string => {
			switch (type) {
			case 'Text':
				return t('Squeeze.General.Text');
			case 'Date':
				return t('Squeeze.General.Date');
			case 'Amount':
				return t('Squeeze.General.Amount');
			default:
				return type;
			}
		}

		/**
		 * Gets the Description of a Field Group
		 * @param {number} fieldGroupId Id of the Locator to get the Description from
		 * @return {string} Description of the Locator
		 */
		const getDescriptionFromFieldGroup = (fieldGroupId: number) => {
			if (!props.fieldGroups) {
				return String(fieldGroupId)
			}

			const fieldGroup = props.fieldGroups.find(fieldGroup => fieldGroup.id === fieldGroupId);
			if (!fieldGroup) {
				return String(fieldGroupId);
			}
			return fieldGroup.description;
		}

		/** Emits the "deleteEntry"-Event */
		const deleteEntry = () => {
			emit("deleteEntry", rowData)
		}

		/** Triggered when a checkbox is clicked
		 * @param {DocumentField} data Data to edit
		 * @param {string} fieldName Current field name
		 * @package fieldName Name of the current field
		 */
		const onChangeCheckbox = (data: DocumentField, fieldName: string) => {
			emit("onChangeCheckbox", data, fieldName);
		}

		/**
		 * Triggered when the rows are reordered
		 * @param event
		 */
		const onRowReorder = (event: any) => {
			const orderedList: number[] = [];
			event.value.forEach((documentClass: DocumentField) => {
				orderedList.push(documentClass.id!);
			});

			emit("onChangeSortOrder", orderedList, props.fieldGroupId, isFilterActive.value);
		}

		/** Opens the fields layout */
		const openFieldsLayout = () => {
			emit("openFieldsLayout");
		}

		/**
		 * Triggered when a filter has been entered
		 * @param event
		 */
		const onFilter = (event: any) => {
			isFilterActive.value = event.filteredValue.length < props.rows.length;
		}

		/** Init filters */
		const initFilters = () => {
			filters.value = {
				"id": {value: null, matchMode: FilterMatchMode.EQUALS},
				"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"dataType": {value: null, matchMode: FilterMatchMode.EQUALS},
				"defaultValue": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"locatorId": {value: null, matchMode: FilterMatchMode.EQUALS},
				"subFieldName": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"externalName": {value: null, matchMode: FilterMatchMode.CONTAINS},
			};
		}

		/** Clear filters */
		const clearFilters = () => {
			initFilters();
		}

		initFilters();

		return {
			t,
			toast,
			store,
			deleteDialog,
			rowData,
			searchActive,
			filters,
			isFilterActive,
			dataTypes,
			showDropdownOverlay,
			openDeleteDialog,
			openEntryDialog,
			getDescriptionFromLocator,
			getTranslationFromDataType,
			getDescriptionFromFieldGroup,
			deleteEntry,
			onChangeCheckbox,
			onRowReorder,
			openFieldsLayout,
			onFilter,
			initFilters,
			clearFilters,
		};
	},
});

