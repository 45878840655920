import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a04c97c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid p-mx-2" }
const _hoisted_2 = { class: "p-col-12 p-lg-12 p-xl-8 p-xxl-8 dashboard-container p-mt-2" }
const _hoisted_3 = { class: "p-col-12 p-xl-8 p-xxl-6 dashboard-container search-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_StoresSearchMask = _resolveComponent("StoresSearchMask")!
  const _component_DashboardTile = _resolveComponent("DashboardTile")!
  const _component_FreezeUpload = _resolveComponent("FreezeUpload")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          label: _ctx.$t('Freeze.General.OpenUpload'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUploadDialog = true))
        }, null, 8, ["label"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_DashboardTile, {
          class: "search-content",
          title: _ctx.$t('Platform.Search.StoreTitle')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StoresSearchMask, {
              onOnChangeStore: _ctx.onChangeStore,
              onOnChangeHitList: _ctx.onChangeHitList
            }, null, 8, ["onOnChangeStore", "onOnChangeHitList"])
          ]),
          _: 1
        }, 8, ["title"])
      ])
    ]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showUploadDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUploadDialog = false)),
      headerText: _ctx.$t('Squeeze.General.Upload'),
      showSaveButton: false,
      showAbortButton: false,
      width: '80rem'
    }, {
      content: _withCtx(() => [
        _createVNode(_component_FreezeUpload, {
          defaultStore: _ctx.selectedStore,
          defaultHitList: _ctx.selectedMask
        }, null, 8, ["defaultStore", "defaultHitList"])
      ]),
      _: 1
    }, 8, ["show", "headerText"])
  ], 64))
}