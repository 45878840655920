import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07df9b14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentReportAutovalidation = _resolveComponent("DocumentReportAutovalidation")!
  const _component_DocumentReportFieldSelectionQuality = _resolveComponent("DocumentReportFieldSelectionQuality")!

  return (_ctx.$route.query.parameter === 'showReportAutovalidation')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DocumentReportAutovalidation)
      ]))
    : (_ctx.store.state.featureSet.reportValidatedDocuments && _ctx.$route.query.parameter === 'showReportFieldSelectionQuality')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_DocumentReportFieldSelectionQuality)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Reports.ReportsDisabled')), 1))
}