
import {computed, defineComponent, onMounted, watch} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "Viewer",
	props: {
		/** ID of Currently active Document */
		documentId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		/** Document API endpoint */
		const viewer = ClientManager.getInstance().viewer;

		/** Current viewer url */
		const viewerUrl = computed(() => {
			return `${viewer.basePath}/Viewer.php?aktion=showQueueEntryDetails&sourceSystem=documents&xdocid=${props.documentId}`;
		});

		onMounted(() => {
			const iframe = document.getElementById("SqueezeViewer") as any;
			if (iframe instanceof HTMLIFrameElement && iframe.contentWindow) {
				iframe.contentWindow.location.replace(viewerUrl.value);
			}
		})

		watch(() => props.documentId, () => {
			// https://stackoverflow.com/questions/2245883/browser-back-acts-on-nested-iframe-before-the-page-itself-is-there-a-way-to-av
			// Otherwise, the Browser-Back-Button will just change the PDF
			const iframe = document.getElementById("SqueezeViewer") as any;
			if (iframe instanceof HTMLIFrameElement && iframe.contentWindow) {
				iframe.contentWindow.location.replace(viewerUrl.value);
			}
		})

		return {
			viewer,
			viewerUrl,
		};
	},
});

