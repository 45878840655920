
import {defineComponent, onMounted, ref, watch} from "vue";
import Editor from "primevue/editor";

export default defineComponent({
	name: "EmailForm",
	components: {
		Editor,
	},
	props: {
		value: {
			type: String,
			required: true,
		},
	},
	emits: ['change'],
	setup(props, {emit}) {
		/** Value of the Form */
		const currentValue = ref<string>('');

		/** Triggered when the form body is changed */
		const onChange = () => {
			emit('change', currentValue.value);
		}

		onMounted(() => {
			currentValue.value = props.value;
		})

		watch(() => props.value, () => {
			currentValue.value = props.value;
		})

		return {
			currentValue,
			onChange,
		}
	},
});
