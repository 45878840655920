import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67105c0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container p-p-2" }
const _hoisted_2 = { class: "header p-d-none p-d-md-flex" }
const _hoisted_3 = { class: "header-headline p-component" }
const _hoisted_4 = { class: "inner-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QueueTimeline = _resolveComponent("QueueTimeline")!
  const _component_QueueList = _resolveComponent("QueueList")!
  const _component_LogView = _resolveComponent("LogView")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_QueueDeleteForm = _resolveComponent("QueueDeleteForm")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_QueueTimeline, { onOnClickQueueStep: _ctx.onClickQueueStep }, null, 8, ["onOnClickQueueStep"])
      ]),
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Queue.General.Queue') + ': ' + _ctx.$t('Squeeze.Queue.Steps.' + _ctx.queueStep)), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_QueueList, {
          queueEntries: _ctx.queueEntries,
          paginationInfo: _ctx.paginationOption,
          loading: _ctx.loading,
          batchClasses: _ctx.batchClasses,
          documentClasses: _ctx.documentClasses,
          onOnPage: _ctx.onPage,
          onOnReload: _ctx.onReload,
          onOpenLogDialog: _ctx.openLogDialog,
          onOnRowSelect: _ctx.onRowSelect,
          onDeleteDocuments: _ctx.showDeleteDialog,
          onGoToStep: _ctx.goToStep,
          stepName: _ctx.queueStep,
          ref: "queueEntryRef",
          filterOptions: _ctx.filters,
          onOnFilter: _ctx.onFilter,
          onOnSort: _ctx.onSort,
          onClearFilters: _ctx.clearFilters
        }, null, 8, ["queueEntries", "paginationInfo", "loading", "batchClasses", "documentClasses", "onOnPage", "onOnReload", "onOpenLogDialog", "onOnRowSelect", "onDeleteDocuments", "onGoToStep", "stepName", "filterOptions", "onOnFilter", "onOnSort", "onClearFilters"])
      ])
    ]),
    _createVNode(_component_Dialog, {
      header: _ctx.$t('Squeeze.Validation.Log.Log'),
      visible: _ctx.showLog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showLog) = $event)),
      modal: true,
      closable: true,
      dismissableMask: true,
      baseZIndex: 1000,
      breakpoints: {'960px': '75vw', '640px': '100vw'},
      style: {width: '50vw'}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LogView, { "document-id": _ctx.currentDocumentId }, null, 8, ["document-id"])
      ]),
      _: 1
    }, 8, ["header", "visible"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDelete,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDelete = false, _ctx.isValidationInvalid = true, _ctx.showValidationMessage = false)),
      onOnConfirm: _ctx.deleteDocuments,
      loading: _ctx.loading,
      headerText: _ctx.headerText,
      saveButtonText: _ctx.$t('Squeeze.General.Delete')
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: false }, {
          default: _withCtx(() => [
            _createVNode(_component_QueueDeleteForm, {
              queueEntries: _ctx.queueEntriesDelete,
              showErrorMessage: _ctx.showValidationMessage,
              onUpdate: _ctx.onUpdate
            }, null, 8, ["queueEntries", "showErrorMessage", "onUpdate"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText", "saveButtonText"]),
    _createVNode(_component_Dialog, {
      header: _ctx.$t('Squeeze.General.Error'),
      visible: _ctx.showErrorMessages,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showErrorMessages) = $event)),
      modal: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Message, {
          closable: false,
          severity: "error"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorMessages, (error) => {
              return (_openBlock(), _createElementBlock("p", { key: error }, _toDisplayString(error), 1))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}