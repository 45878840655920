
import {
	defineComponent, onMounted, ref
} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import {BatchClass} from "@dex/squeeze-client-ts";
import BatchClassList from "@/apps/administration/components/batchclasses/BatchClassList.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import {ToastManager} from "@/util/ToastManager";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default defineComponent({
	name: "BatchClassListView",
	components: {
		BatchClassList,DialogDelete,
	},
	setup() {
		const batchClassApi = ClientManager.getInstance().squeeze.batchClass;
		const batchClasses = ref<BatchClass[]>([]);
		const loading = ref<boolean>(false);
		const deleteDialog = ref<boolean>(false);
		const batchClass = ref<BatchClass>({name: "", description: ''});
		const {t} = useI18n();
		const toast = useToast();

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			batchClassApi.getAllBatchClasses().then(data => {
				batchClasses.value = data;
				loading.value = false;
			}).catch((err) => {
				loading.value = false
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.statusText);
			});
		}

		/**
		 * Gets all Batch-Classes on Mounted
		 */
		onMounted(() =>{
			reloadData();
		});

		/** Triggered when a row is selected */
		const onEntrySelect = (batchClassId: number) => {
			router.push({ name: 'BatchClassView', params: { batchClassId: batchClassId }})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: BatchClass) => {
			deleteDialog.value = true;
			batchClass.value = row;
		}

		const deleteEntry = () => {
			loading.value = true;
			if (batchClass.value.id) {
				batchClassApi.deleteBatchClassById(batchClass.value.id).then(() => {
					reloadData();
				}).catch((err) => {
					loading.value = false;
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.statusText);
				})
			}
		}

		return {
			batchClasses, loading, deleteDialog, openDeleteDialog, deleteEntry, reloadData, onEntrySelect,
		}
	},
})
