
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import JobManagementList from "@/apps/administration/components/system/JobManagementList.vue";
import JobManagementForm from "@/apps/administration/components/system/JobManagementForm.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import {ToastManager} from "@/util/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import {Job, ScriptDto} from "@dex/squeeze-client-ts";
import ServerTimeMessage from "@/apps/administration/views/squeeze/system/ServerTimeMessage.vue";

export default defineComponent({
	name: "JobManagementView",
	components: {
		ServerTimeMessage,
		JobManagementList,
		JobManagementForm,
		DialogDelete,
		EntryDialog,
		BlockUI,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		/** Api for job management in system */
		const jobsApi = ClientManager.getInstance().squeeze.jobs;

		/** Api for Scripting */
		const scriptingApi = ClientManager.getInstance().squeeze.scripting;

		/** Array with all scripts */
		const allScripts = ref<ScriptDto[]>([]);

		/** Should the delete dialog be shown? */
		const deleteDialog = ref<boolean>(false);

		/** Array with all jobs */
		const jobs = ref<Job[]>([]);

		/** Current job entry */
		const jobEntry = reactive<Job>({
			id: undefined,
			name: '',
			cronExpression: '',
			scriptId: '',
			active: true,
		});

		/** Should the Entry-Dialog for mails be shown? */
		const showDialog = ref<boolean>(false);

		/** Is the Form of the mails invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (jobEntry.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.System.Job') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.System.Job') });
		});

		/** Get all jobs data */
		const getAllJobs = () => {
			loading.value = true;
			jobsApi.getAllJobs()
				.then((data: Job[]) => {
					jobs.value = data;
				}).catch(response => response.json().then ((error: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		/** Triggered when a row is selected */
		const onEntrySelect = (job: Job) => {
			Object.assign(jobEntry, job);
			showDialog.value = true;
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: Job) => {
			deleteDialog.value = true;
			Object.assign(jobEntry, row);
		}

		/** Deletes an entry */
		const deleteEntry = () => {
			loading.value = true;
			if(jobEntry.id) {
				jobsApi.deleteJob(jobEntry.id)
					.then(() => {
						getAllJobs();
					}).catch(response => response.json().then ((error: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
					})).finally(() => {
						loading.value = false;
					})
			}
		}

		/** Trigged on update of attribute-form */
		const onUpdate = (data: Job, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(jobEntry, data);
		}

		/** Saves a job */
		const saveJob = () => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let jobsPromise = null;

			if (!jobEntry.id) {
				jobsPromise = jobsApi.postJob(jobEntry)
			} else {
				jobsPromise = jobsApi.putJob(jobEntry.id, jobEntry)
			}

			jobsPromise.then(() => {
				getAllJobs();
				showDialog.value = false;
			}).catch(response => response.json().then ((error: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/** Get all scripts */
		const getAllScripts = () => {
			loading.value = true;
			scriptingApi.getScripts()
				.then((data: ScriptDto[]) => {
					allScripts.value = data;
				}).catch(response => response.json().then ((error: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		/** Gets all jobs on Mounted */
		onMounted(() => {
			getAllJobs();
			getAllScripts();
		});

		return {
			t, toast, loading, allScripts, deleteDialog, jobs, jobEntry, showDialog, isValidationInvalid, showValidationMessage, loadingDialog, headerText,
			getAllJobs, onEntrySelect, openDeleteDialog, deleteEntry, onUpdate, saveJob,
		};
	},
});

