

interface DataTypes {
	id: string | undefined;
	text: string;
}

import {computed, defineComponent, PropType, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import DialogDelete from "@/components/DialogDelete.vue";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import {DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import router from "@/router";
import Tooltip from "primevue/tooltip";
import {FilterMatchMode} from "primevue/api";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {showDropdownOverlay} from "@/util/StylesHelper";
import useSqueezeStore from "@/apps/squeeze/store";

export default defineComponent({
	name: "LocatorList",
	components: {
		DataTable,
		Column,
		Checkbox,
		DialogDelete,
		InputText,
		Dropdown,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All available Locators */
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: () => [],
		},
		/** All available Locator-Types */
		locatorTypes: {
			type: Array as PropType<LocatorType[]>,
			default: () => [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "onChangeCheckbox", "exportAllLocators"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Vuex-Store */
		const store = useSqueezeStore();

		/** Data of selected row */
		const rowData = reactive<DocumentLocator>({});

		/** Show Delete Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Currently-Selected row */
		const selection = ref(null);

		/** Filters of list (Currently not activated) */
		const filters = ref<DocumentFilterObject>({});

		/** Should the clear filter button be shown in the table? */
		const showBtnClearFilter = ref<boolean>(false);

		/** List of Data-Types */
		const dataTypes = computed(() => {
			return [
				{
					id: 'Text',
					text: t('Squeeze.General.Text'),
				},
				{
					id: 'Date',
					text: t('Squeeze.General.Date'),
				},
				{
					id: 'Amount',
					text: t('Squeeze.General.Amount'),
				},
			];
		});

		/** Emits the "deleteEntry"-Event */
		const deleteEntry = () => {
			emit("deleteEntry", rowData)
		}

		/**
		 * Triggered when a checkbox is clicked
		 * @param {DocumentLocator} data Data to edit
		 */
		const onChangeCheckbox = (data: DocumentLocator) => {
			emit("onChangeCheckbox", data)
		}

		/**
		 * Gets the Description of a Locator-Type
		 * @param {number} locatorTypeId Id of the locator type
		 * @returns {String} Description of the Locator-Type or ID if not found
		 */
		const getLocatorTypeDescription = (locatorTypeId: number) => {
			if (props.locatorTypes.length > 0) {
				const locatorType: LocatorType|undefined = props.locatorTypes.find(type => type.value === locatorTypeId);
				if (locatorType) {
					return locatorType.description;
				}
			}

			return String(locatorTypeId)
		}

		/**
		 * Opens the Delete Dialog
		 * @param {DocumentLocator} data Locator that should be deleted
		 */
		const openDeleteDialog = (data: DocumentLocator) => {
			Object.assign(rowData, data);
			deleteDialog.value = true;
		}

		/**
		 * Triggered when a row is selected
		 * @param event
		 */
		const onRowSelect = (event: any) => {
			router.push({ name: 'LocatorSubView', params: { locatorId: event.data.id }})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEntryDialog = () => {
			emit("openEntryDialog");
		}

		/**
		 * Triggered when a filter has been entered
		 * @param event
		 */
		const onFilter = (event: any) => {
			showBtnClearFilter.value = event.filteredValue.length < props.locators.length;
		}

		/** Init filters */
		const initFilters = () => {
			filters.value = {
				"id": {value: null, matchMode: FilterMatchMode.EQUALS},
				"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"locatorType": {value: null, matchMode: FilterMatchMode.EQUALS},
				"dataType": {value: null, matchMode: FilterMatchMode.EQUALS},
				"active": {value: null, matchMode: FilterMatchMode.CONTAINS},
			};
		}

		/** Clear filters */
		const clearFilters = () => {
			initFilters();
		}

		/** Export all locators */
		const exportAllLocators = () => {
			emit("exportAllLocators");
		}

		initFilters();

		return {
			t,
			toast,
			store,
			rowData,
			deleteDialog,
			selection,
			filters,
			showBtnClearFilter,
			dataTypes,
			deleteEntry,
			onChangeCheckbox,
			getLocatorTypeDescription,
			openDeleteDialog,
			onRowSelect,
			openEntryDialog,
			showDropdownOverlay,
			onFilter,
			initFilters,
			clearFilters,
			exportAllLocators,
		};
	},
});

