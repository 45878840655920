import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-p-2 content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "p-fluid p-formgrid p-grid p-jc-end" }
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = { class: "p-field p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocatorList = _resolveComponent("LocatorList")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LocatorList, {
        locators: _ctx.locators,
        locatorTypes: _ctx.locatorTypes,
        loading: _ctx.loading,
        onOpenEntryDialog: _ctx.openEntryDialog,
        onDeleteEntry: _ctx.deleteEntry,
        onExportAllLocators: _ctx.exportAllLocators
      }, null, 8, ["locators", "locatorTypes", "loading", "onOpenEntryDialog", "onDeleteEntry", "onExportAllLocators"])
    ]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showExportDialog,
      closable: !_ctx.showProcessBar,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showExportDialog = false)),
      headerText: "Export",
      showSaveButton: false,
      showAbortButton: !_ctx.showProcessBar,
      abortKeydownEvent: _ctx.showProcessBar,
      abortButtonText: _ctx.$t('Squeeze.General.Abort')
    }, {
      content: _withCtx(() => [
        (_ctx.showProcessBar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ProgressBar, {
                mode: "indeterminate",
                style: {"height":"6px"}
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.General.SuccessfulExport', { entryName: _ctx.$t('Squeeze.Locators.Locators') })), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    label: _ctx.$t('Squeeze.General.CopyToClipboard'),
                    icon: "mdi mdi-content-copy",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.$t, _ctx.$toast, _ctx.allLocatorData), _ctx.allLocatorData = '', _ctx.showExportDialog = false))
                  }, null, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    label: _ctx.$t('Squeeze.General.Download'),
                    icon: "mdi mdi-file-download-outline",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downloadData('locators', _ctx.allLocatorData), _ctx.allLocatorData = '', _ctx.showExportDialog = false))
                  }, null, 8, ["label"])
                ])
              ])
            ]))
      ]),
      _: 1
    }, 8, ["show", "closable", "showAbortButton", "abortKeydownEvent", "abortButtonText"])
  ], 64))
}