
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, PropType} from "vue";
import {DocumentField, FieldLookupFilter, MasterDataColumn} from "@dex/squeeze-client-ts";
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: "LookupFilters",
	components: {
		DataTable, Column,
	},
	props: {
		lookupFilters: {
			type: Array as PropType<FieldLookupFilter[]>,
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		masterDataTableColumns: {
			type: Array as PropType<MasterDataColumn[]>,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: [
		"onClickDelete", "onEntrySelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: FieldLookupFilter) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				masterDataColumnId: undefined,
				valueFieldId: undefined,
				operand: "eq",
			})
		}

		/**
		 * Gets the Description of a Column
		 * @param fieldId Id of the documentClass to get the Description from
		 * @return Description of the documentClass
		 */
		const getDescriptionFromColumn  = (columnId: number) => {
			if (columnId == null) {
				return t('Squeeze.General.None');
			}

			const documentClassDescription = props.masterDataTableColumns!.find(i => i.id === columnId);

			if (!documentClassDescription) {
				return String(columnId)
			}

			return documentClassDescription.description
		}

		/**
		 * Gets the Description of a documentClass Field
		 * @param fieldId Id of the documentClass to get the Description from
		 * @return Description of the documentClass
		 */
		const getDescriptionFromDocumentClassField = (fieldId: number) => {
			if (fieldId == null) {
				return t('Squeeze.General.None');
			}

			const documentClassDescription = props.documentClassFields!.find(i => i.id === fieldId);

			if (!documentClassDescription) {
				return String(fieldId)
			}

			return documentClassDescription.description
		}

		return {
			openDeleteDialog,
			onNewEntryClick,
			getDescriptionFromDocumentClassField,
			getDescriptionFromColumn,
		}
	},
});

