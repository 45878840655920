import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a127f922"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }
const _hoisted_3 = { class: "column-cell" }
const _hoisted_4 = { class: "column-cell" }
const _hoisted_5 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.rows,
    loading: _ctx.loading,
    class: "p-datatable-sm p-datatable-documents",
    autoLayout: true,
    columnResizeMode: "fit",
    reorderableColumns: true,
    onRowReorder: _ctx.onRowReorder,
    onRowDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openEntryDialog($event.data))),
    scrollable: true,
    scrollHeight: "flex",
    removableSort: true,
    filterDisplay: "row",
    filters: _ctx.filters,
    "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
    onFilter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFilter($event)))
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.DocumentClasses.TableColumns') })), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEntryDialog({})))
      }, null, 8, ["label"]),
      (_ctx.isFilterActive)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            label: _ctx.$t('Squeeze.General.ClearFilters'),
            type: "button",
            icon: "mdi mdi-filter-off-outline",
            class: "p-button p-ml-2",
            onClick: _ctx.clearFilters
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        rowReorder: true,
        style: {"min-width":"2rem","max-width":"2rem"}
      }),
      _createVNode(_component_Column, {
        field: "id",
        header: "ID",
        filterField: "id",
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"4rem","max-width":"4rem"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.data.id), 1)
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            style: {"min-width":"3rem"},
            class: "p-column-filter filter-field",
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "name",
        header: _ctx.$t('Squeeze.DocumentClasses.Name'),
        filterField: "name",
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(slotProps.data.name), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.name,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            style: {"min-width":"10rem"},
            class: "p-column-filter filter-field",
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "description",
        header: _ctx.$t('Squeeze.DocumentClasses.Description'),
        filterField: "description",
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(slotProps.data.description), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.description,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            style: {"min-width":"10rem"},
            class: "p-column-filter filter-field",
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "dataType",
        header: _ctx.$t('Squeeze.DocumentClasses.DataType'),
        filterField: "dataType",
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"9rem","max-width":"9rem"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(_ctx.getTranslationFromDataType(slotProps.data.dataType)), 1)
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_Dropdown, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onChange: ($event: any) => (filterCallback()),
            options: _ctx.dataTypes,
            class: "p-column-filter filter-field",
            optionValue: "id",
            optionLabel: "text",
            showClear: true,
            style: {"min-width":"8rem","max-width":"8rem"}
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "headerLocatorId",
        header: _ctx.$t('Squeeze.DocumentClasses.HeaderLocator'),
        filterField: "headerLocatorId",
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.getDescriptionFromLocator(slotProps.data.headerLocatorId)), 1)
          ])), [
            [
              _directive_tooltip,
              _ctx.getDescriptionFromLocator(slotProps.data.headerLocatorId),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            style: {"min-width":"10rem"},
            class: "p-column-filter filter-field",
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "valueLocatorId",
        header: _ctx.$t('Squeeze.DocumentClasses.ValueLocator'),
        filterField: "valueLocatorId",
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.getDescriptionFromLocator(slotProps.data.valueLocatorId)), 1)
          ])), [
            [
              _directive_tooltip,
              _ctx.getDescriptionFromLocator(slotProps.data.valueLocatorId),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_Dropdown, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onChange: ($event: any) => (filterCallback()),
            options: _ctx.locators,
            class: "p-column-filter filter-field",
            optionValue: "id",
            optionLabel: "description",
            showClear: true,
            filter: true,
            autoFilterFocus: true,
            style: {"min-width":"10rem"},
            onShow: _ctx.showDropdownOverlay
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "onShow"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "externalName",
        header: _ctx.$t('Squeeze.DocumentClasses.ExternalName'),
        filterField: "externalName",
        sortable: true,
        showFilterMenu: false,
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(slotProps.data.externalName), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.externalName,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        filter: _withCtx(({filterModel, filterCallback}) => [
          _createVNode(_component_InputText, {
            style: {"min-width":"10rem"},
            class: "p-column-filter filter-field",
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "mandatory",
        header: _ctx.$t('Squeeze.DocumentClasses.Mandatory'),
        sortable: false,
        filterMatchMode: "contains",
        headerClass: "header-center",
        style: {"min-width":"6rem","max-width":"6rem","text-align":"center"}
      }, {
        body: _withCtx((slotProps) => [
          _createVNode(_component_Checkbox, {
            onChange: ($event: any) => (_ctx.onChangeCheckbox(slotProps.data, 'mandatory')),
            modelValue: slotProps.data.mandatory,
            "onUpdate:modelValue": ($event: any) => ((slotProps.data.mandatory) = $event),
            binary: true
          }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "readonly",
        header: _ctx.$t('Squeeze.DocumentClasses.ReadOnly'),
        sortable: false,
        filterMatchMode: "contains",
        headerClass: "header-center",
        style: {"min-width":"6rem","max-width":"6rem","text-align":"center"}
      }, {
        body: _withCtx((slotProps) => [
          _createVNode(_component_Checkbox, {
            onChange: ($event: any) => (_ctx.onChangeCheckbox(slotProps.data, 'readonly')),
            modelValue: slotProps.data.readonly,
            "onUpdate:modelValue": ($event: any) => ((slotProps.data.readonly) = $event),
            binary: true
          }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "hidden",
        header: _ctx.$t('Squeeze.DocumentClasses.Hidden'),
        sortable: false,
        filterMatchMode: "contains",
        headerClass: "header-center",
        style: {"min-width":"6rem","max-width":"6rem","text-align":"center"}
      }, {
        body: _withCtx((slotProps) => [
          _createVNode(_component_Checkbox, {
            onChange: ($event: any) => (_ctx.onChangeCheckbox(slotProps.data, 'hidden')),
            modelValue: slotProps.data.hidden,
            "onUpdate:modelValue": ($event: any) => ((slotProps.data.hidden) = $event),
            binary: true
          }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "forceValidation",
        header: _ctx.$t('Squeeze.DocumentClasses.ForceValidation'),
        sortable: false,
        filterMatchMode: "contains",
        headerClass: "header-center",
        style: {"min-width":"6rem","max-width":"6rem","text-align":"center"}
      }, {
        body: _withCtx((slotProps) => [
          _createVNode(_component_Checkbox, {
            onChange: ($event: any) => (_ctx.onChangeCheckbox(slotProps.data, 'forceValidation')),
            modelValue: slotProps.data.forceValidation,
            "onUpdate:modelValue": ($event: any) => ((slotProps.data.forceValidation) = $event),
            binary: true
          }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, { style: {"min-width":"7rem","max-width":"7rem","text-align":"right"} }, {
        body: _withCtx((slotProps) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.openEntryDialog(slotProps.data))
          }, null, 8, ["onClick"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Locators.Edit'),
              void 0,
              { bottom: true }
            ]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(slotProps.data))
          }, null, 8, ["onClick"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Validation.Buttons.Delete'),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "onRowReorder", "filters"]))
}