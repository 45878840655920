import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7530dfee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-content" }
const _hoisted_2 = { class: "scroll-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OCRWords = _resolveComponent("OCRWords")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_OCRLines = _resolveComponent("OCRLines")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createBlock(_component_TabView, null, {
    default: _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('Squeeze.DocumentClasses.OCRWords')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_OCRWords, {
              documentId: _ctx.documentId,
              onOnMarkWord: _ctx.onMarkWord
            }, null, 8, ["documentId", "onOnMarkWord"])
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('Squeeze.DocumentClasses.OCRLines')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_OCRLines, {
              documentId: _ctx.documentId,
              onOnMarkLines: _ctx.onMarkLines
            }, null, 8, ["documentId", "onOnMarkLines"])
          ])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 1
  }))
}