import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-mb-2 p-p-2" }
const _hoisted_2 = { class: "p-fluid p-formgrid p-grid p-jc-end p-p-2" }
const _hoisted_3 = { class: "p-field p-col-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchForLineItemsForm = _resolveComponent("SearchForLineItemsForm")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BlockUI = _resolveComponent("BlockUI")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BlockUI, {
      blocked: _ctx.loading || _ctx.saveLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SearchForLineItemsForm, {
          lineItemLocatorValue: _ctx.lineItemLocatorValue,
          loading: _ctx.loading,
          positionTables: _ctx.documentTables,
          showErrorMessage: _ctx.showErrorMessage,
          documentClasses: _ctx.documentClasses,
          onUpdate: _ctx.onUpdate
        }, null, 8, ["lineItemLocatorValue", "loading", "positionTables", "showErrorMessage", "documentClasses", "onUpdate"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              label: _ctx.$t('Squeeze.General.Save'),
              type: "button",
              icon: "mdi mdi-content-save-outline",
              class: "p-button",
              loading: _ctx.loading || _ctx.saveLoading,
              onClick: _ctx.saveLineItem
            }, null, 8, ["label", "loading", "onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["blocked"])
  ]))
}