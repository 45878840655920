
import {
	computed,
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {LocatorRegEx} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import RegexForm from "@/apps/administration/components/locators/tabs/RegexForm.vue";
import Regex from "@/apps/administration/components/locators/tabs/Regex.vue";
import BlockUI from 'primevue/blockui';

export default defineComponent({
	name: "RegexView",
	components: {
		RegexForm, DialogDelete, Regex, EntryDialog, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Component of the current form */
		const regexForm = ref<InstanceType<typeof RegexForm>>()

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the Regexes invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all Regexes */
		const regexes = ref<LocatorRegEx[]>([]);

		/** Should the Entry-Dialog for regexes be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active regex */
		const regex = reactive<LocatorRegEx>({
			regEx: '',
		});

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Trigged on update of attribute-form */
		const onUpdate = (data: LocatorRegEx, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(regex, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (regex.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.Locators.Tabs.Regex') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Locators.Tabs.Regex') });
		});

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: LocatorRegEx) => {
			Object.assign(regex, row);
			showDialog.value = true;
		}

		/** Reloads the table */
		const reloadData = async () => {
			loading.value = true;
			await locatorService.getLocatorRegExes(props.locatorId)
				.then(data => {
					regexes.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get Type of current locator on load */
		onMounted(() =>{
			reloadData();
		})

		/** Saves an Regex */
		const saveRegex = (keepDialogOpen: boolean = false, event: any = null) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let promise = null;

			// make sure locatorId always right
			regex.locatorId = props.locatorId;

			if (!regex.id) {
				promise = locatorService.postLocatorRegEx(props.locatorId, regex);
			} else {
				promise = locatorService.putLocatorRegEx(props.locatorId, regex.id, regex);
			}

			promise.then(async () => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				} else {
					// Empty values and focus first input-field
					regex.regEx = '';
					regexForm.value?.$el.querySelector('input').focus();
					isValidationInvalid.value = true;
				}

				await reloadData();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;

				if (event) {
					// Notify any listeners in case they need to wait for the completion of this request
					document.dispatchEvent(new CustomEvent('reloadDone', { detail: event }));
				}
			})
		}

		/**
		 * Triggered on change of attribute-form
		 * @param event
		 * @param isInvalid
		 */
		const onChangeRegex = (event: any, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(regex, event.newData);
			saveRegex(false, event);
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: LocatorRegEx) => {
			deleteDialog.value = true;
			Object.assign(regex, row);
		}

		/** Deletes a Regex */
		const deleteEntry = () => {
			if (regex && regex.id) {
				loading.value = true;
				locatorService.deleteLocatorRegEx(props.locatorId, regex.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {
			loading,
			isValidationInvalid,
			showValidationMessage,
			loadingDialog,
			showDialog,
			headerText,
			regexes,
			regex,
			deleteDialog,
			regexForm,
			deleteEntry,
			openDeleteDialog,
			onUpdate,
			onChangeRegex,
			onEntrySelect,
			saveRegex,
		};
	},
});

