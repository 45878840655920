import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32b8e937"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DexDataTable = _resolveComponent("DexDataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DexDataTable, {
    value: _ctx.regexes,
    loading: _ctx.loading,
    class: "p-datatable-sm editable-cells-table",
    autoLayout: true,
    columnResizeMode: "fit",
    editMode: "cell",
    onCellEditInit: _ctx.onCellEdit,
    onCellEditComplete: _ctx.onCellComplete,
    onRowDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditDialog($event.data))),
    paginator: true,
    rows: _ctx.tableListPagination.pageSize,
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
    scrollable: true,
    scrollHeight: "flex",
    onPage: _ctx.onChangePage
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.Locators.Tabs.Regex') })), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button",
        onClick: _ctx.onNewEntryClick
      }, null, 8, ["label", "onClick"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "regEx",
        header: _ctx.$t('Squeeze.Locators.Tabs.Regex'),
        style: {"min-width":"10rem","max-width":"20rem"},
        class: "regex-column"
      }, {
        body: _withCtx((regex) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(regex.data.regEx), 1)
          ])), [
            [
              _directive_tooltip,
              regex.data.regEx,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        editor: _withCtx(({ data }) => [
          _createVNode(_component_Textarea, {
            modelValue: data.regEx,
            "onUpdate:modelValue": ($event: any) => ((data.regEx) = $event),
            autoResize: true,
            rows: "1",
            cols: _ctx.showSingleLinedForm ? '50' : '100',
            onDblclick: ($event: any) => (_ctx.openEditDialog(data)),
            onKeyup: ($event: any) => (_ctx.changeEventInInput(data))
          }, null, 8, ["modelValue", "onUpdate:modelValue", "cols", "onDblclick", "onKeyup"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, { style: {"min-width":"16rem","max-width":"16rem","text-align":"right"} }, {
        body: _withCtx((regex) => [
          (_ctx.isInvalidRegex(regex.data))
            ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Locators.Tabs.Regex')})), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, { style: {"min-width":"7rem","max-width":"7rem","text-align":"right"} }, {
        body: _withCtx((regex) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.openEditDialog(regex.data))
          }, null, 8, ["onClick"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Locators.Edit'),
              void 0,
              { bottom: true }
            ]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(regex.data))
          }, null, 8, ["onClick"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Validation.Buttons.Delete'),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "onCellEditInit", "onCellEditComplete", "rows", "currentPageReportTemplate", "onPage"]))
}