import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-098a8f08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_2 = { class: "p-input-filled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (idy) => {
      return _createElementVNode("div", {
        key: idy,
        class: "p-field p-col-12"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Skeleton, {
            height: "1.193rem",
            width: "6.5rem"
          }),
          _createVNode(_component_Skeleton, {
            class: "validation-field p-input-filled",
            height: "2.816rem"
          })
        ])
      ])
    }), 64))
  ]))
}