
import TabMenu from "@/components/DexTabMenu.vue";
import {MenuItem} from "@/shims-prime-vue";
import {
	computed,
	defineComponent, onMounted, ref, watch
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentLocator} from "@dex/squeeze-client-ts";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import LocatorSubView from "@/apps/administration/views/squeeze/locators/LocatorSubView.vue";
import SubstitutionsView from "@/apps/administration/views/squeeze/locators/tabs/SubstitutionsView.vue";
import LocatorAttributesSubView from "@/apps/administration/views/squeeze/locators/LocatorAttributesSubView.vue";
import RegexView from "@/apps/administration/views/squeeze/locators/tabs/RegexView.vue";
import KeyWordsView from "@/apps/administration/views/squeeze/locators/tabs/KeyWordsView.vue";
import InvoiceAmountsView from "@/apps/administration/views/squeeze/locators/tabs/InvoiceAmountsView.vue";
import BarcodeView from "@/apps/administration/views/squeeze/locators/tabs/BarcodeView.vue";
import KeyWordsToValueView from "@/apps/administration/views/squeeze/locators/tabs/KeyWordsToValueView.vue";
import DBLinkedDataView from "@/apps/administration/views/squeeze/locators/tabs/DBLinkedDataView.vue";
import ValueFromRegExView from "@/apps/administration/views/squeeze/locators/tabs/ValueFromRegExView.vue";
import SearchForLineItemsView from "@/apps/administration/views/squeeze/locators/tabs/SearchForLineItemsView.vue";

export default defineComponent({
	name: "LocatorView",
	components: {
		TabMenu, TabView, TabPanel, LocatorSubView, SubstitutionsView, LocatorAttributesSubView, KeyWordsToValueView,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {t} = useI18n();

		/** ID of the current locator-type */
		const locatorTypeId = ref<number|null>(null);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** Currently active tab */
		const activeTab = ref(0);

		/** Array of the already loaded tabs. The first tab is always active */
		const loadedTabs = ref<number[]>([0]);

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.locatorId, () => {
			activeTab.value = 0;
			loadedTabs.value = [0];
		})

		/** List of all Tab-Items */
		const items = computed(() => {
			const itemsList: MenuItem[] = [
				{label: t('Squeeze.Locators.General'), to: LocatorSubView},
			]

			if (props.locatorId) {
				// Tabs for Regular Expression
				if (locatorTypeId.value === 0) {
					itemsList.push({label: t('Squeeze.Locators.Tabs.Substitution'), to: SubstitutionsView});
					itemsList.push({label: t('Squeeze.Locators.Tabs.Regex'), to: RegexView});
				}

				// Tabs for Keyword
				if (locatorTypeId.value === 1) {
					itemsList.push({label: t('Squeeze.Locators.Tabs.Substitution'), to: SubstitutionsView});
					itemsList.push({label: t('Squeeze.Locators.Tabs.KeyWords'), to: KeyWordsView});
				}

				// Invoice-Amounts
				if (locatorTypeId.value === 2) {
					itemsList.push({label: t('Squeeze.Locators.Tabs.InvoiceAmounts'), to: InvoiceAmountsView});
				}

				// KeyWord to Value
				if (locatorTypeId.value === 4) {
					itemsList.push({label: t('Squeeze.Locators.Tabs.Substitution'), to: SubstitutionsView});
					itemsList.push({label: t('Squeeze.Locators.Tabs.ValueList'), to: KeyWordsToValueView});
				}

				// Value next to Keyword
				if (locatorTypeId.value === 6) {
					itemsList.push({label: t('Squeeze.Locators.Tabs.Substitution'), to: SubstitutionsView});
					itemsList.push({label: t('Squeeze.Locators.Tabs.Regex'), to: RegexView});
					itemsList.push({label: t('Squeeze.Locators.Tabs.KeyWords'), to: KeyWordsView});
				}

				// Barcode
				if (locatorTypeId.value === 13) {
					itemsList.push({label: t('Squeeze.Locators.Tabs.Barcode'), to: BarcodeView});
				}

				// Value from Regular Expression
				if (locatorTypeId.value === 14) {
					itemsList.push({label: t('Squeeze.DocumentClasses.Table'), to: ValueFromRegExView});
				}


				// Search for line items
				if (locatorTypeId.value === 8) {
					itemsList.push({label: t('Squeeze.Locators.Tabs.Table'), to: SearchForLineItemsView});
				}

				// Search for DB linked data
				if (locatorTypeId.value === 9) {
					itemsList.push({label: t('Squeeze.Locators.Tabs.DBLinkedData'), to: DBLinkedDataView});
				}

				// All locators can have settings
				itemsList.push({label: t('Squeeze.Locators.Attributes'), to: LocatorAttributesSubView});
			}


			return itemsList;
		});

		/**
		 * Get Type of current locator on load
		 */
		onMounted(() =>{
			if (props.locatorId) {
				locatorService.getLocatorById(props.locatorId).then(data => {
					locatorTypeId.value = data.locatorType!;
				})
			}
		})

		/**
		 * Triggered when the Locator changes in the child-component
		 * @param locator
		 */
		const onLocatorChange = (locator: DocumentLocator) => {
			if (locatorTypeId.value !== locator.locatorType) {
				locatorTypeId.value = locator.locatorType!;
			}
		}

		/**
		 * Sets the already loaded tabs, so they don't load again.
		 * @param event
		 */
		const onTabClick = (event: any) => {
			loadedTabs.value.push(event.index);
		}

		return {items, t, locatorTypeId, activeTab, loadedTabs, onLocatorChange, onTabClick, RegexView};
	},
});

