import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34af14be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container p-p-2" }
const _hoisted_2 = { class: "header p-d-none p-d-md-flex" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QueueTimeline = _resolveComponent("QueueTimeline")!
  const _component_DocumentList = _resolveComponent("DocumentList")!
  const _component_QueueEntryList = _resolveComponent("QueueEntryList")!
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_QueueTimeline)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.initialLoaded)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.usingQueueEntryFallback)
              ? (_openBlock(), _createBlock(_component_DocumentList, {
                  key: 0,
                  documents: _ctx.documents,
                  documentClassId: _ctx.documentClassId,
                  documentClassName: _ctx.documentClassName,
                  fieldColumns: _ctx.fieldColumns,
                  filterOptions: _ctx.filters,
                  paginationInfo: _ctx.paginationOption,
                  loaded: _ctx.loaded,
                  onOnRowSelect: _ctx.onRowSelect,
                  onOnReload: _ctx.reloadData,
                  onOnPage: _ctx.onPage,
                  onOnFilter: _ctx.onFilter
                }, null, 8, ["documents", "documentClassId", "documentClassName", "fieldColumns", "filterOptions", "paginationInfo", "loaded", "onOnRowSelect", "onOnReload", "onOnPage", "onOnFilter"]))
              : (_openBlock(), _createBlock(_component_QueueEntryList, {
                  key: 1,
                  queueEntries: _ctx.queueEntries,
                  documentClassId: _ctx.documentClassId,
                  documentClassName: _ctx.documentClassName,
                  paginationInfo: _ctx.paginationOption,
                  loaded: _ctx.loaded,
                  onOnRowSelect: _ctx.onRowSelect,
                  onOnReload: _ctx.reloadData,
                  onOnPage: _ctx.onPage
                }, null, 8, ["queueEntries", "documentClassId", "documentClassName", "paginationInfo", "loaded", "onOnRowSelect", "onOnReload", "onOnPage"]))
          ], 64))
        : (_openBlock(), _createBlock(_component_Skeleton, {
            key: 1,
            class: "p-shadow-2",
            width: "100%",
            height: "100%"
          }))
    ])
  ]))
}