import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49b235a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-component p-fluid p-formgrid p-grid" }
const _hoisted_2 = { class: "p-col-2" }
const _hoisted_3 = { style: {"font-size":"1rem"} }
const _hoisted_4 = { class: "p-col-4" }
const _hoisted_5 = {
  key: 0,
  class: "p-col-2"
}
const _hoisted_6 = { class: "p-col-4 p-mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("Squeeze.DocumentClasses.Locator")), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["p-field p-input-filled", _ctx.showEditLocatorButton ? 'p-col-4' : 'p-col-6'])
    }, [
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.selectedLocator,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLocator) = $event)),
        options: _ctx.locators,
        optionLabel: "description",
        dataKey: "id",
        loading: _ctx.loading,
        filter: true,
        autoFilterFocus: true,
        placeholder: _ctx.$t('Squeeze.Locators.Placeholder'),
        onChange: _ctx.onChangeLocator,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "loading", "placeholder", "onChange", "onShow"])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.Locators.Testing'),
        onClick: _ctx.testLocator
      }, null, 8, ["label", "onClick"])
    ]),
    (_ctx.showEditLocatorButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _withDirectives(_createVNode(_component_Button, {
            class: "mdi mdi-pencil-outline",
            onClick: _ctx.openEntryLocator
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Locators.EditLocator')]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.Locators.ShowLog'),
        onClick: _ctx.showLog
      }, null, 8, ["label", "onClick"])
    ])
  ]))
}