import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-6" }
const _hoisted_3 = { for: "firstName" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "p-field p-col-6" }
const _hoisted_6 = { for: "lastName" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = { class: "p-field p-col-12" }
const _hoisted_9 = { for: "parentRoleId" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.Name')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "firstName",
        modelValue: _ctx.value.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.title) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.title.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update
      }, null, 8, ["modelValue", "class", "onChange"]),
      (_ctx.v$.title.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.BatchClasses.Name')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.Description')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "lastName",
        modelValue: _ctx.value.description,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.description) = $event)),
        class: _normalizeClass({'p-invalid':_ctx.v$.description.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update
      }, null, 8, ["modelValue", "class", "onChange"]),
      (_ctx.v$.description.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.BatchClasses.Description')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('Squeeze.UserManagement.RoleUnder')) + " *", 1),
      _createVNode(_component_Dropdown, {
        disabled: !!_ctx.value.id,
        id: "parentRoleId",
        modelValue: _ctx.value.parentRoleId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.parentRoleId) = $event)),
        options: _ctx.parentRoles,
        loading: _ctx.loading,
        class: _normalizeClass({'p-invalid':_ctx.v$.parentRoleId.$invalid && _ctx.showErrorMessage}),
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.update
      }, null, 8, ["disabled", "modelValue", "options", "loading", "class", "onChange"]),
      (_ctx.v$.parentRoleId.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.UserManagement.RoleUnder')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}