
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import EntryDialog from "@/components/EntryDialog.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import FieldGroupForm from "@/apps/administration/components/documentclasses/FieldGroupForm.vue";
import {ClientManager} from "@/singletons/ClientManager";
import useSqueezeStore from "@/apps/squeeze/store";
import {DocumentField, DocumentFieldGroup, DocumentLocator} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import DocumentClassFieldsSubView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassFieldsSubView.vue";

type FieldsPerGroupCollection = Record<number, DocumentField[]>;

export default defineComponent({
	name: 'DocumentClassFieldGroupsSubView',
	components: {
		DocumentClassFieldsSubView,
		TabView,
		TabPanel,
		EntryDialog,
		DialogDelete,
		FieldGroupForm,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** Service for getting the documentclass-data */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** Currently active field-group */
		const activeFieldGroup = ref<number>(-1);

		/** All field groups for this document-class */
		const fieldGroups =  ref<DocumentFieldGroup[]>([]);

		/** All Document-Class-Fields */
		const documentClassFields =  ref<DocumentField[]>([]);

		/** List of all locators **/
		const locators = ref<DocumentLocator[]>([]);

		/** Show Loading in view? */
		const loading = ref<boolean>(false);

		/** One Entry for a field-group */
		const entryFieldGroup = reactive<DocumentFieldGroup>({
			documentClassId: props.documentClassId,
			name: '',
			description: '',
			type: 0,
			tableField: '',
			sortOrder: 1,
		});

		/** Should the Entry-Dialog for field-groups be shown?  */
		const showCurrentFieldGroupDialog = ref<boolean>(false);

		/** Currently active tab */
		const activeTabIndex = ref<number>(0);

		/** Should the Delete-Dialog for Field-Groups be shown? */
		const showDialogFieldGroup = ref<boolean>(false);

		/** Message To Show in Entry-Dialogs */
		const message = ref<string>('');

		/** Message-Type to set when showing a message (see: Message-Component in PrimeVue */
		const messageType = ref<string>('none');

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Show the loading for Save-Button of Field-Groups? */
		const loadingSaveFieldGroup = ref<boolean>(false);

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref<boolean>(true);

		/** Resets the Default-Field-Group */
		const initFieldGroupItem = () => {
			message.value = '';
			messageType.value = 'none';

			Object.assign(entryFieldGroup, {
				id: 0,
				documentClassId: props.documentClassId,
				name: '',
				description: '',
				type: 0,
				tableField: '',
				sortOrder: 1,
			});
		}

		/**
		 * Is triggered when a tab is changed
		 * @param event
		 */
		const onTabClick = (event: { originalEvent: MouseEvent; index: number }) => {
			if (event.index === fieldGroups.value.length) {
				initFieldGroupItem();
				headerText.value = t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.DocumentClasses.Group') });
				showCurrentFieldGroupDialog.value = true;
			}
		}

		/**
		 * Gets the Fields for a Document-Class and shows them
		 * @param {number} documentClassId Id of the Document-Class to get the fields from
		 * @param {boolean} showLoadingAnimation Should the loading animation be shown?
		 */
		const getDocumentClassFields = (documentClassId: number, showLoadingAnimation: boolean) => {
			loading.value = true;

			/* Only when loading is active, emptying the currently shown fields is advisable, otherwise there are some unnecessary hiccups in the layout.
			* Use-Case: When changing the Document-Class, seeing the old-document-class-fields is confusing, so the entries are emptied and reloaded.
			* When Inserting/Updating an entry, this animation feels to restless
			*/
			if (showLoadingAnimation) {
				documentClassFields.value = [];
			}

			documentClassService.getAllDocumentClassFields(documentClassId)
				.then(data => {
					documentClassFields.value = data;
				}).catch(response => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.message);
				})).finally(() => loading.value = false)
		}

		/**
		 * Opens the Edit-Dialog for Field-Groups
		 * @param {DocumentFieldGroup} rowData Data of the row to edit
		 */
		const openEntryDialogFieldGroup = (rowData: DocumentFieldGroup) => {
			Object.assign(entryFieldGroup, rowData);
			headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.Group') });
			showCurrentFieldGroupDialog.value = true;
		}

		/**
		 * Opens the Edit-Dialog for Field-Groups
		 * @param {DocumentFieldGroup} rowData Data of the row to delete
		 */
		const openDeleteFieldGroup = (rowData: DocumentFieldGroup) => {
			Object.assign(entryFieldGroup, rowData);
			showDialogFieldGroup.value = true;
		}

		/**
		 * Triggered on update of attribute-form group
		 * @param {DocumentFieldGroup} data
		 * @param {boolean} valid
		 */
		const onUpdateFieldGroup = (data: DocumentFieldGroup, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(entryFieldGroup, data);
		}

		/** Reloads the full dataset needed for field-groups */
		const reloadData = () => {
			const promise1 = locatorService.getAllLocators();
			const promise2 = documentClassService.getAllFieldGroups(props.documentClassId);

			// Wait until promises are finished
			Promise.all([promise1, promise2]).then(values => {
				locators.value = values[0];
				// Only show Head-Fieldgroups
				fieldGroups.value = values[1].filter(fieldGroup => fieldGroup.type === 0);

				// If there is no field-group, show create dialog for field-group
				if (fieldGroups.value && fieldGroups.value.length === 0) {
					initFieldGroupItem();
					headerText.value = t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.DocumentClasses.Group') });
					showCurrentFieldGroupDialog.value = true;
				} else {
					getDocumentClassFields(props.documentClassId, true);
				}
			}).catch(response => response.json().then((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				activeFieldGroup.value = -1;
			}))
		}

		/** Opens the Edit-Dialog for Field-Groups */
		const deleteFieldGroup = () => {
			if (entryFieldGroup && entryFieldGroup.id) {
				loadingSaveFieldGroup.value = true;
				documentClassService.deleteDocumentClassFieldGroupById(props.documentClassId, Number(entryFieldGroup.id))
					.then(() =>{
						reloadData();
					}).finally(() => {
						loadingSaveFieldGroup.value = false;
					})
			}
		}

		/** Is triggered when a Field-Group triggers a save */
		const saveFieldGroup = () => {
			if (isInvalid.value) {
				showErrorMessage.value = true;
				return;
			}

			showErrorMessage.value = false;
			loadingSaveFieldGroup.value = true;
			if (entryFieldGroup && entryFieldGroup.id) {
				documentClassService.putDocumentClassFieldGroup(props.documentClassId, entryFieldGroup.id, entryFieldGroup)
					.then(() => {
						reloadData();
						showCurrentFieldGroupDialog.value = false;
					}).catch((err) => {
						message.value = err.statusText;
						messageType.value = 'error';
					}).finally(() => loadingSaveFieldGroup.value = false);
			} else {
				documentClassService.postFieldGroup(props.documentClassId, entryFieldGroup)
					.then(() => {
						reloadData();
						showCurrentFieldGroupDialog.value = false;
					}).catch((err) => {
						message.value = err.statusText;
						messageType.value = 'error';
					}).finally(() => loadingSaveFieldGroup.value = false);
			}
		}

		/** Get all field of a field group */
		const fieldsPerFieldGroup = computed(() => {
			const fieldsPerGroup: FieldsPerGroupCollection = fieldGroups.value.reduce((acc, fg) => {
				acc[fg.id || -1] = [];
				return acc;
			}, {} as FieldsPerGroupCollection);

			(documentClassFields.value || []).reduce((acc: FieldsPerGroupCollection, field: DocumentField) => {
				acc[field.fieldGroupId || -1] = [...(acc[field.fieldGroupId || -1] || []), field];
				return acc;
			}, fieldsPerGroup);

			return fieldsPerGroup;
		})

		onMounted(() => {
			reloadData();
		})

		return {
			t,
			toast,
			store,
			activeFieldGroup,
			fieldGroups,
			documentClassFields,
			locators,
			loading,
			entryFieldGroup,
			showCurrentFieldGroupDialog,
			activeTabIndex,
			showDialogFieldGroup,
			message,
			messageType,
			headerText,
			loadingSaveFieldGroup,
			showErrorMessage,
			isInvalid,
			initFieldGroupItem,
			onTabClick,
			getDocumentClassFields,
			openEntryDialogFieldGroup,
			openDeleteFieldGroup,
			onUpdateFieldGroup,
			reloadData,
			deleteFieldGroup,
			saveFieldGroup,
			fieldsPerFieldGroup,
		};
	},
});

