
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {LocatorRegEx} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
	name: "RegexForm",
	components: {
		InputText,
	},
	props: {
		regex: {
			type: Object as PropType<LocatorRegEx>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<LocatorRegEx>({regEx: ''});

		/** Determines the required rules for validation */
		const rules = {regEx: { required }}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.regex);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();

			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.regex, () => {
			Object.assign(value, props.regex);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
