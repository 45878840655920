import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65a1ce26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "big-container p-grid nested-grid" }
const _hoisted_2 = { class: "sidebar p-col-3 p-col-fixed p-p-2" }
const _hoisted_3 = { class: "p-grid p-flex-column" }
const _hoisted_4 = { class: "p-col p-pt-4 p-d-flex p-jc-center" }
const _hoisted_5 = { class: "p-col" }
const _hoisted_6 = { class: "p-d-flex p-jc-center" }
const _hoisted_7 = { class: "userinfo p-text-bold" }
const _hoisted_8 = { class: "p-d-flex p-jc-center" }
const _hoisted_9 = { class: "userinfo email" }
const _hoisted_10 = { class: "p-col" }
const _hoisted_11 = { class: "main-content p-col-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_UserAccount = _resolveComponent("UserAccount")!
  const _component_ViewSettings = _resolveComponent("ViewSettings")!
  const _component_UserApiKeysView = _resolveComponent("UserApiKeysView")!
  const _component_SystemInformation = _resolveComponent("SystemInformation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Avatar, {
            label: _ctx.loggedInUserInitials,
            class: "avatar",
            size: "xlarge",
            shape: "circle"
          }, null, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.store.state.user && _ctx.store.state.user.firstName) + " " + _toDisplayString(_ctx.store.state.user && _ctx.store.state.user.lastName), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.store.state.user && _ctx.store.state.user.email), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_Listbox, {
            modelValue: _ctx.selectedMenuItem,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMenuItem) = $event)),
            options: _ctx.settingMenuItems,
            optionLabel: "name",
            optionValue: "code"
          }, null, 8, ["modelValue", "options"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_ctx.selectedMenuItem === 'Account')
        ? (_openBlock(), _createBlock(_component_UserAccount, {
            key: 0,
            onClickSettingDialog: _ctx.clickSettingDialog
          }, null, 8, ["onClickSettingDialog"]))
        : _createCommentVNode("", true),
      (_ctx.selectedMenuItem === 'View')
        ? (_openBlock(), _createBlock(_component_ViewSettings, {
            key: 1,
            onClickSettingDialog: _ctx.clickSettingDialog
          }, null, 8, ["onClickSettingDialog"]))
        : _createCommentVNode("", true),
      (_ctx.selectedMenuItem === 'ApiKey')
        ? (_openBlock(), _createBlock(_component_UserApiKeysView, {
            key: 2,
            userId: _ctx.store.state.user.id,
            onClickSettingDialog: _ctx.clickSettingDialog
          }, null, 8, ["userId", "onClickSettingDialog"]))
        : _createCommentVNode("", true),
      (_ctx.selectedMenuItem === 'SystemInfo')
        ? (_openBlock(), _createBlock(_component_SystemInformation, {
            key: 3,
            showHeadline: true,
            systemInformation: _ctx.store.state.systemInformation.labels,
            onClickSettingDialog: _ctx.clickSettingDialog
          }, null, 8, ["systemInformation", "onClickSettingDialog"]))
        : _createCommentVNode("", true)
    ])
  ]))
}