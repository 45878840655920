/**
 * This type contains helper methods to handle colors.
 */
export class ColorGenerator {

	/**
	 * Build hex to hsl color
	 * @param color
	 */
	public static hexToHSL(color: string): string {
		color = color.replace(' ', '');
		// Convert hex to RGB first
		let r: any = 0, g: any = 0, b: any = 0;
		if (color.length == 4) {
			r = "0x" + color[1] + color[1];
			g = "0x" + color[2] + color[2];
			b = "0x" + color[3] + color[3];
		} else if (color.length == 7) {
			r = "0x" + color[1] + color[2];
			g = "0x" + color[3] + color[4];
			b = "0x" + color[5] + color[6];
		}
		// Then to HSL
		r /= 255;
		g /= 255;
		b /= 255;
		const cmin = Math.min(r,g,b),
			cmax = Math.max(r,g,b),
			delta = cmax - cmin;
		let	h = 0,
			s = 0,
			l = 0;

		if (delta == 0)
			h = 0;
		else if (cmax == r)
			h = ((g - b) / delta) % 6;
		else if (cmax == g)
			h = (b - r) / delta + 2;
		else
			h = (r - g) / delta + 4;

		h = Math.round(h * 60);

		if (h < 0)
			h += 360;

		l = (cmax + cmin) / 2;
		s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
		s = +(s * 100).toFixed(1);
		l = +(l * 100).toFixed(1);

		return "hsl(" + h + "," + s + "%," + l + "%)";
	}

	/**
	 * Build colors
	 * @param amount Count of the data entries
	 * @param hslColor Hsl color
	 */
	public static generateHslColors(amount: number, hslColor: string): string[] {
		// get hsl color elements
		const sep = hslColor.indexOf(",") > -1 ? "," : " ";
		const hslElements = hslColor.substr(4).split(")")[0].split(sep);
		let h: number,
			s: string,
			l: string;

		if (hslElements[0] && hslElements[1] && hslElements[2]) {
			h = Number(hslElements[0]);
			s = hslElements[1];
			l = hslElements[2];
		} else {
			// hsl color from dexpro
			h = 189;
			s = '60.2%';
			l = '43.3%';
		}

		const colors = [];
		const hueDelta = Math.trunc(360 / amount);
		for (let i = 0; i < amount; i++) {
			const hue = i * hueDelta + h;
			colors.push('hsl(' + hue + ',' + s + ',' + l + ')');
		}
		return colors;
	}

}
