import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36efc313"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-component" }
const _hoisted_2 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_3 = { for: 'input-fulltext' }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_6 = { class: "p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.fields.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["p-fluid p-formgrid p-grid", _ctx.maxFieldHeight ? 'fields-scroll' : '']),
          style: _normalizeStyle({maxHeight: _ctx.maxFieldHeight})
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.General.Fulltext')), 1),
            _createVNode(_component_InputText, {
              modelValue: _ctx.fullText,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fullText) = $event)),
              id: "input-fulltext",
              onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
            }, null, 8, ["modelValue"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
            return (_openBlock(), _createElementBlock("div", {
              key: field.id,
              class: "p-field p-input-filled p-col-12 p-md-6"
            }, [
              _createElementVNode("label", {
                for: 'input-' + field.id
              }, _toDisplayString(field.name), 9, _hoisted_4),
              _createVNode(_component_InputText, {
                modelValue: field.filter.value,
                "onUpdate:modelValue": ($event: any) => ((field.filter.value) = $event),
                id: 'input-' + field.id,
                onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.submit()), ["enter"]))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "id"])
            ]))
          }), 128))
        ], 6))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Button, {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submit())),
          disabled: !_ctx.canSubmit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Platform.Search.Title')), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ])
  ]))
}