import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createBlock(_component_TabView, {
    activeIndex: _ctx.activeTab,
    "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
    onTabClick: _ctx.onTabClick,
    class: "p-d-flex p-flex-column",
    style: {"height":"100%"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (tab, index) => {
        return (_openBlock(), _createBlock(_component_TabPanel, {
          key: tab.label,
          header: tab.label
        }, {
          default: _withCtx(() => [
            (index === 0 || tab.to === _ctx.RegexView)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.loadedTabs.indexOf(index) !== -1)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(tab.to), {
                        key: 0,
                        locatorId: _ctx.locatorId,
                        showSingleLinedForm: _ctx.showSingleLinedForm,
                        onOnLocatorChange: _ctx.onLocatorChange
                      }, null, 40, ["locatorId", "showSingleLinedForm", "onOnLocatorChange"]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.loadedTabs.indexOf(index) !== -1)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(tab.to), {
                        key: 0,
                        locatorId: _ctx.locatorId,
                        onOnLocatorChange: _ctx.onLocatorChange
                      }, null, 40, ["locatorId", "onOnLocatorChange"]))
                    : _createCommentVNode("", true)
                ], 64))
          ]),
          _: 2
        }, 1032, ["header"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["activeIndex", "onTabClick"]))
}