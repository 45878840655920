import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-351a5656"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid p-flex-column p-m-0 p-p-4 container p-jc-center p-ai-center" }
const _hoisted_2 = { class: "p-col-12 p-text-center" }
const _hoisted_3 = { class: "p-component view-title" }
const _hoisted_4 = {
  key: 0,
  class: "p-component"
}
const _hoisted_5 = {
  key: 1,
  class: "p-component"
}
const _hoisted_6 = {
  key: 2,
  class: "p-component"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Login.SQUEEZE')), 1),
      (_ctx.route.query.status === '403')
        ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.$t('ErrorPage.' + _ctx.route.query.status + '.Label', { position: _ctx.$t('ErrorPage.Position.Document')})), 1))
        : (_ctx.route.query.status === undefined)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(_ctx.$t('ErrorPage.404.Label')), 1))
          : (_openBlock(), _createElementBlock("h2", _hoisted_6, _toDisplayString(_ctx.$t('ErrorPage.' + _ctx.route.query.status + '.Label')), 1))
    ])
  ]))
}