import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13479b33"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DexDataTable = _resolveComponent("DexDataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DexDataTable, {
    class: "p-component p-shadow-2",
    value: _ctx.allElements,
    rowHover: true,
    autoLayout: true,
    paginator: true,
    lazy: true,
    loading: !_ctx.loaded,
    paginatorTemplate: "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    rows: _ctx.pagination.pageSize,
    totalRecords: _ctx.pagination.total,
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords} ',
    onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event))),
    onRowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMarkLines($event))),
    onRowHover: [
      _cache[2] || (_cache[2] = ($event: any) => (_ctx.onMarkLines($event))),
      ($event: any) => (true)
    ],
    columnResizeMode: "fit",
    scrollable: true,
    scrollHeight: "flex"
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.Validation.General.Position') })), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        type: "button",
        icon: "mdi mdi-refresh",
        class: "p-button-text",
        onClick: _ctx.onReload,
        disabled: !_ctx.loaded
      }, null, 8, ["onClick", "disabled"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "value",
        header: _ctx.$t('Squeeze.Locators.Value'),
        style: {"min-width":"5rem","max-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(slotProps.data.value), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.value,
              void 0,
              { top: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "line",
        header: _ctx.$t('Squeeze.DocumentClasses.Line')
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "boundingBox.page",
        header: _ctx.$t('Squeeze.Locators.Site')
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "boundingBox.x0",
        header: "x0"
      }),
      _createVNode(_component_Column, {
        field: "boundingBox.y0",
        header: "y0"
      }),
      _createVNode(_component_Column, {
        field: "boundingBox.x1",
        header: "x1"
      }),
      _createVNode(_component_Column, {
        field: "boundingBox.y1",
        header: "y1"
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "totalRecords", "currentPageReportTemplate"]))
}