
import {defineComponent, onBeforeMount, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import ButtonGroup from 'primevue/buttongroup';
import MultiSelect from 'primevue/multiselect';
import Calendar from "primevue/calendar";
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import moment from "moment";
import {DetailedReport, DocumentClass, DocumentField} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import {showDropdownOverlay} from "@/util/StylesHelper";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {useRoute} from "vue-router";
import useSqueezeStore from "@/apps/squeeze/store";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';

export default defineComponent({
	name: "DocumentReportFieldSelectionQuality",
	methods: {showDropdownOverlay},
	components: {
		IconField,
		InputIcon,
		InputText,
		DataTable,
		Column,
		Row,
		ColumnGroup,
		Calendar,
		MultiSelect,
		ButtonGroup,
	},
	setup() {
		const {t, locale} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** Show loading in table? */
		const loading = ref<boolean>(false);

		/** List of all detailed Reports */
		const reportData = ref<DetailedReport[]>([]);

		/** List of all DocumentClasses */
		const allDocumentClasses = ref<DocumentClass[]>([]);

		/** List of all Fields */
		const allFields = ref<DocumentField[]>([]);

		/** Currently selected Document-Classes by ID */
		const selectedDocumentClasses = ref<number[]>([]);

		/** Currently selected Document class fields by ID */
		const selectedFieldNames = ref<string[]>([]);

		/** Filter for creditor by creditor number */
		const creditorIds = ref<string>('');

		/** Selected date */
		const selectedDate = ref<string[]>([]);

		/** Date placeholder */
		const datePlaceholder = ref<string>('');

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** Service for User API */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Service for Document Class API */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** Service for report API */
		const reportApi = ClientManager.getInstance().squeeze.report;

		const getTableData = async (startDate: moment.Moment, endDate: moment.Moment) => {
			try {
				loading.value = true;
				datePlaceholder.value = startDate.format('DD.MM.YYYY') + ' - ' + endDate.format('DD.MM.YYYY');
				reportData.value = await reportApi.getDetailedExtractionReport(
					selectedDocumentClasses.value.toString(),
					creditorIds.value,
					selectedFieldNames.value.toString(),
					startDate.format(),
					endDate.format()
				);
			} catch (err: any) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), err);
			} finally {
				loading.value = false;
			}
		}

		/** Clear filters */
		const clearFilters = () => {
			selectedDate.value = [];
			selectedDocumentClasses.value = [];
			selectedFieldNames.value = [];
			creditorIds.value = '';
			getTableData(moment('01.01.', 'DD MM'), moment());
		}

		/** Triggered to filter */
		const filter = () => {
			if (selectedDate.value[1]) {
				getTableData(moment(selectedDate.value[0], 'LLLL'), moment(selectedDate.value[1], 'LLLL'));
			} else {
				getTableData(moment('01.01.', 'DD MM'), moment());
			}
		}

		/** Get all DocumentClasses */
		const getAllDocumentClasses = () => {
			documentClassApi.getAllDocumentClasses()
				.then((documentClasses) => {
					allDocumentClasses.value = documentClasses;
				})
				.catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), error.message);
				})
		}

		/** Get all Fields */
		const getAllFields = () => {
			documentClassApi.getAllFields()
				.then((fields) => {
					allFields.value = fields;
				})
				.catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), error.message);
				})
		}

		/**
		 * Triggered when the tooltip is shown
		 * for document class
		 * @param values
		 */
		const mapTooltipDcValues = (values: string[]) => {
			return values
				.map((value: string) => {
					return allDocumentClasses.value.find(docClass => docClass.id === Number(value))?.name;
				})
				.join(", ");
		}

		/**
		 * Get option label of selected document class
		 * @param options
		 */
		const getOptionDcLabel = (options: number[]) => {
			let label = '';
			options.forEach((option: any, index: number) => {
				if (index <= 2) {
					const currentDocClass = allDocumentClasses.value.find(docClass => docClass.id === option);
					if (currentDocClass) {
						label += currentDocClass.name;
						if (options.length > 1 && index < options.length - 1) {
							label += ', ';
						}
					}
				} else if (index === 3) {
					label += '...';
				}
			})

			return label;
		}

		/**
		 * Get option label of selected fields
 		 * @param options
		 */
		const getOptionFieldLabel = (options: string[]) => {
			let label = '';
			options.forEach((option: any, index: number) => {
				if (index <= 2) {
					label += option;
					if (options.length > 1 && index < options.length - 1) {
						label += ', ';
					}
				} else if (index === 3) {
					label += '...';
				}
			})

			return label;
		}

		/**
		 * Format value to three decimal places if one decimal place is available
		 * @param value
		 */
		const formatValue = (value: number) => {
			return Number.isSafeInteger(value) ? value : value.toFixed(3);
		}

		/**
		 * Triggered when page is changed
		 * @param event
		 */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
			getAllDocumentClasses();
			getAllFields();
		})

		onBeforeMount(async () => {
			await getTableData(moment('01.01.', 'DD MM'), moment());
		})

		return {
			t,
			locale,
			toast,
			route,
			store,
			loading,
			reportData,
			allDocumentClasses,
			allFields,
			selectedDocumentClasses,
			selectedFieldNames,
			creditorIds,
			selectedDate,
			datePlaceholder,
			tableListPagination,
			clearFilters,
			filter,
			mapTooltipDcValues,
			getOptionDcLabel,
			getOptionFieldLabel,
			formatValue,
			onChangePage,
		};
	},
});

