import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a0f4e7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid p-formgrid p-grid p-input-filled p-mr-0" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "thousandSeparator" }
const _hoisted_4 = { class: "flex align-items-center" }
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = { for: "decimalSeparator" }
const _hoisted_7 = { class: "flex align-items-center" }
const _hoisted_8 = { class: "p-field p-col-12" }
const _hoisted_9 = { for: "decimalPlaces" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Message, {
      severity: "info",
      closable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Squeeze.System.Info')), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.DefaultAmountFormat')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.ThousandsSeparator')), 1),
        _createVNode(_component_Dropdown, {
          id: "thousandSeparator",
          modelValue: _ctx.amountSeparators.thousandSeparator,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.amountSeparators.thousandSeparator) = $event)),
          options: _ctx.getAvailableAmountSeparators('thousandSeparator'),
          optionValue: "separator",
          optionLabel: "name",
          showClear: true,
          onChange: _ctx.update,
          onShow: _ctx.showDropdownOverlay,
          class: "p-ripple-disabled"
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(slotProps.option.name) + " ", 1),
                (slotProps.option.separator.trim())
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      class: "separatorTag"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(slotProps.option.separator), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "options", "onChange", "onShow"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.DecimalSeparator')), 1),
        _createVNode(_component_Dropdown, {
          id: "decimalSeparator",
          modelValue: _ctx.amountSeparators.decimalSeparator,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.amountSeparators.decimalSeparator) = $event)),
          options: _ctx.getAvailableAmountSeparators('decimalSeparator'),
          optionValue: "separator",
          optionLabel: "name",
          showClear: true,
          onChange: _ctx.update,
          onShow: _ctx.showDropdownOverlay
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(slotProps.option.name) + " ", 1),
                (slotProps.option.separator.trim())
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      class: "separatorTag"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(slotProps.option.separator), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "options", "onChange", "onShow"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.DecimalPlaces')), 1),
        _createVNode(_component_InputNumber, {
          id: "decimalPlaces",
          modelValue: _ctx.amountSeparators.decimalPlaces,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.amountSeparators.decimalPlaces) = $event)),
          useGrouping: false,
          max: 999,
          onChange: _ctx.update
        }, null, 8, ["modelValue", "onChange"])
      ])
    ])
  ], 64))
}