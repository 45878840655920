import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-mt-2 p-mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_TabMenu, {
        class: "removeBorderWidth",
        model: _ctx.items
      }, null, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ]))
}