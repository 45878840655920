import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-107a3ddf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 child-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassificationList = _resolveComponent("ClassificationList")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _component_ClassificationForm = _resolveComponent("ClassificationForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _component_BatchClassTraining = _resolveComponent("BatchClassTraining")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ClassificationList, {
        classifications: _ctx.classifications,
        loading: _ctx.loading,
        documentClasses: _ctx.documentClasses,
        batchClassId: _ctx.batchClassId,
        onOnClickDelete: _ctx.openDeleteDialog,
        onOnEntrySelect: _ctx.onEntrySelect,
        onOpenTraining: _ctx.openTraining,
        onOpenTesting: _ctx.openTesting
      }, null, 8, ["classifications", "loading", "documentClasses", "batchClassId", "onOnClickDelete", "onOnEntrySelect", "onOpenTraining", "onOpenTesting"])
    ]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDialog = false)),
      onOnConfirm: _ctx.deleteEntry
    }, null, 8, ["showDialog", "onOnConfirm"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false, _ctx.showErrorMessage = false, _ctx.isInvalid = true)),
      onOnConfirm: _ctx.saveClassification,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText
    }, {
      content: _withCtx(() => [
        _createVNode(_component_ClassificationForm, {
          documentClasses: _ctx.checkDocumentClass,
          classification: _ctx.classification,
          showErrorMessage: _ctx.showErrorMessage,
          onUpdate: _ctx.onUpdate
        }, null, 8, ["documentClasses", "classification", "showErrorMessage", "onUpdate"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showTrainingDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showTrainingDialog) = $event)),
      style: {width: '50rem'},
      header: _ctx.headerTrainingText,
      modal: true,
      onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showTrainingDialog = false)),
      position: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BatchClassTraining, {
          batchClassId: _ctx.batchClassId,
          classificationClassId: _ctx.classification.id,
          showDropdowns: false,
          showTesting: false,
          showTraining: true
        }, null, 8, ["batchClassId", "classificationClassId"])
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showTestingDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showTestingDialog) = $event)),
      style: {width: '50rem'},
      header: _ctx.headerTestingText,
      modal: true,
      onHide: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showTestingDialog = false)),
      position: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BatchClassTraining, {
          batchClassId: _ctx.batchClassId,
          showDropdowns: false,
          showTesting: true,
          showTraining: false
        }, null, 8, ["batchClassId"])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}