import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Tree = _resolveComponent("Tree")!
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.allSystemCheckResults.failCount)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 0,
              severity: "error",
              closable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Squeeze.System.Fail')), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('Squeeze.System.FailMessage')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.allSystemCheckResults.warningCount && !_ctx.allSystemCheckResults.failCount)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 1,
              severity: "warn",
              closable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Squeeze.System.Warning')), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('Squeeze.System.WarningMessage')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.allSystemCheckResults.okCount && !_ctx.allSystemCheckResults.warningCount && !_ctx.allSystemCheckResults.failCount)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 2,
              severity: "success",
              closable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Squeeze.System.Success')), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('Squeeze.System.SuccessMessage')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ((_ctx.allSystemCheckResults.unknownCount || _ctx.allSystemCheckResults.skippedCount) && !_ctx.allSystemCheckResults.failCount)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 3,
              severity: "info",
              closable: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Squeeze.System.Info')), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('Squeeze.System.InfoMessage', { stateNameOne: _ctx.$t('Squeeze.System.Skipped'), stateNameTwo: _ctx.$t('Squeeze.System.Unknown') })), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Tree, {
          value: _ctx.checks,
          expandedKeys: {'fail': true, 'warning': true}
        }, {
          default: _withCtx((slotProps) => [
            _createElementVNode("span", null, _toDisplayString(slotProps.node.label), 1),
            (_ctx.countChildren(slotProps.node, slotProps.node.children) !== 0)
              ? (_openBlock(), _createBlock(_component_Badge, {
                  key: 0,
                  value: _ctx.countChildren(slotProps.node, slotProps.node.children),
                  severity: "info",
                  class: "p-mr-2"
                }, null, 8, ["value"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["value"])
      ], 64))
    : (_openBlock(), _createBlock(_component_Skeleton, {
        key: 1,
        width: "100%",
        height: "20rem",
        borderRadius: "1rem"
      }))
}