import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "p-field-checkbox p-justify-end" }
const _hoisted_2 = {
  key: 1,
  class: "p-mr-2",
  for: "keepDialogOpen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.entryDialog,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entryDialog) = $event)),
    style: _normalizeStyle({width: _ctx.width}),
    header: _ctx.headerText,
    modal: true,
    onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog())),
    position: _ctx.position,
    maximizable: _ctx.maximizable,
    closeOnEscape: !_ctx.abortKeydownEvent,
    closable: _ctx.closable
  }, _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content"),
      (_ctx.message !== '')
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 0,
            severity: _ctx.messageType
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.message), 1)
            ]),
            _: 1
          }, 8, ["severity"]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.showKeepDialogOpen || _ctx.showAbortButton || _ctx.showSaveButton)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.showKeepDialogOpen)
                ? (_openBlock(), _createBlock(_component_Checkbox, {
                    key: 0,
                    id: "keepDialogOpen",
                    name: "keepDialogOpen",
                    binary: true,
                    modelValue: _ctx.keepDialogOpen,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keepDialogOpen) = $event))
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true),
              (_ctx.showKeepDialogOpen)
                ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.keepDialogOpenText || _ctx.$t('Squeeze.General.KeepDialogOpen')), 1))
                : _createCommentVNode("", true),
              (_ctx.showAbortButton)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 2,
                    label: _ctx.abortButtonText || _ctx.$t('Squeeze.General.Abort'),
                    icon: "mdi mdi-close",
                    class: "p-button-text",
                    onClick: _ctx.closeDialog
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true),
              (_ctx.showSaveButton)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 3,
                    loading: _ctx.loading,
                    label: _ctx.saveButtonText || _ctx.$t('Squeeze.General.Save'),
                    icon: "mdi mdi-check",
                    class: "p-button-text",
                    onClick: _ctx.saveEntry
                  }, null, 8, ["loading", "label", "onClick"]))
                : _createCommentVNode("", true)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["visible", "style", "header", "position", "maximizable", "closeOnEscape", "closable"]))
}