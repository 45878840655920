import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "sourceLocatorId" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = { for: "dbcolumn" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = { class: "p-field p-col-12" }
const _hoisted_9 = { for: "stopUnique" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.SourceLocator')) + " *", 1),
      _createVNode(_component_Dropdown, {
        id: "sourceLocatorId",
        modelValue: _ctx.value.sourceLocatorId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.sourceLocatorId) = $event)),
        options: _ctx.allLocators,
        loading: _ctx.loading,
        class: _normalizeClass({'p-invalid':_ctx.v$.sourceLocatorId.$invalid && _ctx.showErrorMessage}),
        filter: true,
        autoFilterFocus: true,
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.update,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "loading", "class", "onChange", "onShow"]),
      (_ctx.v$.sourceLocatorId.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Imports.Protocol')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.DBColumn')) + " *", 1),
      _createVNode(_component_Dropdown, {
        id: "dbcolumn",
        modelValue: _ctx.value.dbcolumn,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.dbcolumn) = $event)),
        options: _ctx.masterDataColumns,
        loading: _ctx.loading,
        class: _normalizeClass({'p-invalid':_ctx.v$.dbcolumn.$invalid && _ctx.showErrorMessage}),
        filter: true,
        autoFilterFocus: true,
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.update,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "loading", "class", "onChange", "onShow"]),
      (_ctx.v$.dbcolumn.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Imports.Encryption')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.Stop')), 1),
      _createVNode(_component_Dropdown, {
        id: "stopUnique",
        modelValue: _ctx.value.stopIfUnique,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.stopIfUnique) = $event)),
        options: _ctx.stopUnique,
        loading: _ctx.loading,
        class: _normalizeClass({'p-invalid':_ctx.v$.stopIfUnique.$invalid && _ctx.showErrorMessage}),
        optionValue: "id",
        optionLabel: "name",
        onChange: _ctx.update
      }, null, 8, ["modelValue", "options", "loading", "class", "onChange"]),
      (_ctx.v$.stopIfUnique.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.Locators.Tabs.Stop')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}