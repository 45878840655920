import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-026ef4f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "SqueezeViewer",
  style: {"width":"100%"},
  class: "viewer",
  src: ''
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", _hoisted_1))
}