import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-6" }
const _hoisted_3 = { for: "fileType" }
const _hoisted_4 = { class: "p-field p-col-6 p-d-flex p-as-end" }
const _hoisted_5 = { class: "p-field p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.System.ImagesToRetrieve')), 1),
        _createVNode(_component_Dropdown, {
          id: "fileType",
          modelValue: _ctx.activeFileType,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeFileType) = $event)),
          options: _ctx.fileTypes,
          filter: false,
          optionValue: "id",
          optionLabel: "description",
          showClear: false
        }, null, 8, ["modelValue", "options"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          label: _ctx.$t('Squeeze.System.DefaultTheme'),
          type: "button",
          icon: "mdi mdi mdi-restore",
          class: "p-button p-button-danger",
          onClick: _ctx.openDeleteDialog
        }, null, 8, ["label", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_FileUpload, {
          name: "documentFile",
          customUpload: true,
          multiple: false,
          maxFileSize: 10000000,
          showCancelButton: true,
          uploadFiles: _ctx.files,
          cancelLabel: _ctx.$t('Squeeze.General.Clear'),
          chooseLabel: _ctx.$t('Squeeze.General.Choose'),
          customProgress: _ctx.progress,
          uploadLabel: _ctx.uploadLabel,
          fileContentHeight: '5rem',
          invalidFileSizeMessage: '{0}: ' + _ctx.$t('Squeeze.Validation.General.InvalidFileSize') + ' ' + '{1}',
          onSelect: _ctx.onSelectFiles,
          onUploader: _ctx.fileUploader,
          onClear: _ctx.clearFiles,
          onRemoveFile: _ctx.removeFile,
          accept: '.' + _ctx.activeFileType
        }, null, 8, ["uploadFiles", "cancelLabel", "chooseLabel", "customProgress", "uploadLabel", "invalidFileSizeMessage", "onSelect", "onUploader", "onClear", "onRemoveFile", "accept"])
      ])
    ]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteDialog = false)),
      headerLabel: _ctx.$t('Squeeze.System.DeleteImage'),
      messageLabel: _ctx.$t('Squeeze.System.MessageDeleteImage'),
      onOnConfirm: _ctx.deleteThemeFile
    }, null, 8, ["showDialog", "headerLabel", "messageLabel", "onOnConfirm"])
  ], 64))
}