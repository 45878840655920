import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae1061de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.masterDataPermissions,
    loading: _ctx.loading,
    class: "p-datatable-sm",
    autoLayout: true,
    columnResizeMode: "fit",
    scrollable: true,
    scrollHeight: "flex",
    paginator: true,
    rows: _ctx.tableListPagination.pageSize,
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords} ',
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    rowHover: true,
    onRowDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditDialog($event.data))),
    onPage: _ctx.onChangePage
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.MasterData.Permissions') })), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button p-mr-2",
        onClick: _ctx.onNewEntryClick
      }, null, 8, ["label", "onClick"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "roleId",
        header: _ctx.$t('Squeeze.UserManagement.Role'),
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.getRoleDescription(slotProps.data)), 1)
          ])), [
            [
              _directive_tooltip,
              _ctx.getRoleDescription(slotProps.data),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "canManage",
        header: _ctx.$t('Squeeze.MasterData.TableManage'),
        headerClass: "header-center",
        style: {"min-width":"10rem","text-align":"center"}
      }, {
        body: _withCtx((slotProps) => [
          _createVNode(_component_Checkbox, {
            modelValue: slotProps.data.canManage,
            "onUpdate:modelValue": ($event: any) => ((slotProps.data.canManage) = $event),
            onChange: ($event: any) => (_ctx.onChangeCheckbox(slotProps.data)),
            binary: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "canEdit",
        header: _ctx.$t('Squeeze.MasterData.EditData'),
        headerClass: "header-center",
        style: {"min-width":"10rem","text-align":"center"}
      }, {
        body: _withCtx((slotProps) => [
          _createVNode(_component_Checkbox, {
            modelValue: slotProps.data.canEdit,
            "onUpdate:modelValue": ($event: any) => ((slotProps.data.canEdit) = $event),
            onChange: ($event: any) => (_ctx.onChangeCheckbox(slotProps.data)),
            binary: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        class: "buttonColumn",
        style: {"min-width":"7rem","max-width":"7rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.openEditDialog(slotProps.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(slotProps.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "currentPageReportTemplate", "onPage"]))
}