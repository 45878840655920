
import {defineComponent, onMounted, PropType, reactive, nextTick, watch} from "vue";
import Dropdown from "primevue/dropdown";
import {DocumentClass} from "@dex/squeeze-client-ts";
import {BatchClassClassification} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default defineComponent({
	name: "ClassificationForm",
	components: {
		Dropdown,
	},
	props: {
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
		},
		classification: {
			type: Object as PropType<BatchClassClassification>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ["update"],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive({id: 0, batchClassId: 0, documentClassId: ''});

		/** Determines the required rules for validation */
		const rules = {
			documentClassId: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(() => {
			Object.assign(value, props.classification);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.classification, () => {
			Object.assign(value, props.classification);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		return {value, v$, update};
	},
});
