import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.lookupFilters,
    loading: _ctx.loading,
    class: "p-datatable-sm editable-cells-table",
    autoLayout: true,
    columnResizeMode: "fit"
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.DocumentClasses.Filter') })), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button",
        onClick: _ctx.onNewEntryClick
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "masterDataColumnId",
        header: _ctx.$t('Squeeze.DocumentClasses.DatabaseColumn'),
        sortable: false
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(_ctx.getDescriptionFromColumn(slotProps.data.masterDataColumnId)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "masterDataColumnId",
        header: _ctx.$t('Squeeze.DocumentClasses.Operand'),
        sortable: false
      }, {
        body: _withCtx((slotProps) => [
          (slotProps.data.operand ==='eq')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Equal')), 1)
              ], 64))
            : (slotProps.data.operand ==='cn')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Contains')), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode(_toDisplayString(slotProps.data.operand), 1)
                ], 64))
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "rowBasedFilter",
        header: _ctx.$t('Squeeze.DocumentClasses.FilterType'),
        sortable: false
      }, {
        body: _withCtx((slotProps) => [
          (!slotProps.data.rowBasedFilter)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Head')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Position')), 1)
              ], 64))
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "valueFieldId",
        header: _ctx.$t('Squeeze.DocumentClasses.Field'),
        sortable: false
      }, {
        body: _withCtx((slotProps) => [
          (!slotProps.data.rowBasedFilter)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.getDescriptionFromDocumentClassField(slotProps.data.valueFieldId)), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.getDescriptionFromTableColumn(slotProps.data.valueFieldId)), 1)
              ], 64))
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, { class: "buttonColumn" }, {
        body: _withCtx((files) => [
          false
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                icon: "mdi mdi-pencil-outline",
                class: "p-button-lg p-button-rounded p-button-success p-button-text",
                onClick: ($event: any) => (_ctx.openEditDialog(files.data))
              }, null, 8, ["onClick"])), [
                [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
              ])
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(files.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading"]))
}