import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "p-p-2 p-ml-2 p-component",
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTrainingHeadRegionAnchor = _resolveComponent("FormTrainingHeadRegionAnchor")!
  const _component_FormTrainingHeadFields = _resolveComponent("FormTrainingHeadFields")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabView, {
      activeIndex: _ctx.activeTabOption,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTabOption) = $event)),
      lazy: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabOptions, (tab) => {
          return (_openBlock(), _createBlock(_component_TabPanel, {
            key: tab.value,
            style: {"height":"100%"}
          }, {
            header: _withCtx(() => [
              _createTextVNode(_toDisplayString(tab.value), 1)
            ]),
            default: _withCtx(() => [
              (tab.id === 1)
                ? (_openBlock(), _createBlock(_component_FormTrainingHeadRegionAnchor, {
                    key: 0,
                    trainingKeyField: _ctx.trainingKeyField,
                    documentClassId: _ctx.documentClassId,
                    regionAnchorOfFormTraining: _ctx.regionAnchorOfFormTraining,
                    booleanOptions: _ctx.booleanOptions,
                    onOnFocusField: _ctx.onFocusField,
                    onOnMarkRegion: _ctx.onMarkRegion
                  }, null, 8, ["trainingKeyField", "documentClassId", "regionAnchorOfFormTraining", "booleanOptions", "onOnFocusField", "onOnMarkRegion"]))
                : (tab.id === 2)
                  ? (_openBlock(), _createBlock(_component_FormTrainingHeadFields, {
                      key: 1,
                      trainingKeyField: _ctx.trainingKeyField,
                      documentClassId: _ctx.documentClassId,
                      headFieldOfRegionInFormTraining: _ctx.headFieldOfRegionInFormTraining,
                      booleanOptions: _ctx.booleanOptions,
                      documentFields: _ctx.documentFields,
                      onOnFocusField: _ctx.onFocusField,
                      onOnMarkRegion: _ctx.onMarkRegion
                    }, null, 8, ["trainingKeyField", "documentClassId", "headFieldOfRegionInFormTraining", "booleanOptions", "documentFields", "onOnFocusField", "onOnMarkRegion"]))
                  : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ]))
}