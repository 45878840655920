
import {computed, defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {MasterDataTable} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {technicalValidation} from "@/util/ValidationHelper";
import {useSqueezeStore} from "@/apps/squeeze/store";

export default defineComponent({
	name: "MasterDataTableForm",
	components: {
		InputText,
	},
	props: {
		masterDataTable: {
			type: Object as PropType<MasterDataTable>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
		permissions: {
			type: Object,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Use Store */
		const store = useSqueezeStore();

		/** Check masterData-Permissions */
		const checkPermissions = computed(() => {
			// check if user has the scope to can added a new entry
			if (store.state.scopes.sqzMasterDataManage) {
				return false
			} else if (props.permissions) {
				return props.permissions.permissionEdit && !props.permissions.permissionManage
				|| store.state.featureSet.masterDataTablePermissions && !props.permissions.permissionEdit && !props.permissions.permissionManage
				|| !store.state.featureSet.masterDataTablePermissions && !store.state.scopes.sqzAdmin;
			} else {
				return false
			}
		});

		/** Current Object of all input-fields */
		const value = reactive<MasterDataTable>({name: '', description: ''});

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.masterDataTable);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.masterDataTable, async () => {
			Object.assign(value, props.masterDataTable);

			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on keyup in input-field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			store,
			checkPermissions,
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
