import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = {
  key: 0,
  class: "p-field p-col-6"
}
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.masterDataColumns, (column) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: column.name
      }, [
        (column.name.toLowerCase() !== 'id')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("label", {
                for: column.name
              }, _toDisplayString(column.description), 9, _hoisted_3),
              _createVNode(_component_InputText, {
                id: column.name,
                modelValue: _ctx.value[column.name],
                "onUpdate:modelValue": ($event: any) => ((_ctx.value[column.name]) = $event),
                onChange: _ctx.update,
                onKeydown: _withKeys(_ctx.update, ["enter"]),
                autofocus: "",
                maxLength: "255"
              }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "onChange", "onKeydown"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}