
import {defineComponent, PropType, ref} from "vue";
import InputText from "primevue/inputtext";

export default defineComponent({
	name: "RecoverPasswordForm",
	props: {
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	components: {
		InputText,
	},
	emit: ['onClickSend'],
	setup(props, {emit}) {
		const userName = ref('');

		/** Triggered on click of send */
		const onClickSend = () => {
			emit('onClickSend', userName.value);
		}

		/**
		 * Triggered on keypress
		 * @param event
		 */
		const onKeyDown = (event: {key: string}) => {
			if (event.key === "Enter" && userName.value.length !== 0) {
				emit('onClickSend', userName.value);
			}
		}

		return {userName, onClickSend, onKeyDown};
	},
})
