import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withKeys as _withKeys, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5de29c1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MigrationsList = _resolveComponent("MigrationsList")!
  const _component_MigrationsForm = _resolveComponent("MigrationsForm")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MigrationsList, {
        migrations: _ctx.migrations,
        loading: _ctx.loading,
        onOpenEntryDialog: _ctx.openEntryDialog
      }, null, 8, ["migrations", "loading", "onOpenEntryDialog"])
    ]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = false, _ctx.isValidationInvalid = true, _ctx.showValidationMessage = false, _ctx.showInvalidPassword = false)),
      onOnConfirm: _ctx.runMigrations,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText,
      onKeydown: _withKeys(_ctx.runMigrations, ["enter"])
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: _ctx.loadingDialog }, {
          default: _withCtx(() => [
            _createVNode(_component_MigrationsForm, {
              showErrorMessage: _ctx.showValidationMessage,
              invalidPassword: _ctx.showInvalidPassword,
              onUpdate: _ctx.onUpdate
            }, null, 8, ["showErrorMessage", "invalidPassword", "onUpdate"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText", "onKeydown"])
  ], 64))
}