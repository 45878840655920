
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {ToastManager} from "@/util/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import {SystemCleanupConfig} from "@dex/squeeze-client-ts";
import SystemCleanUpConfig from "@/apps/administration/components/system/SystemCleanUpConfig.vue";
import SystemInformation from "@/apps/administration/components/system/SystemInformation.vue";
import Divider from 'primevue/divider';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import SystemThemeUploadView from "@/apps/administration/views/squeeze/system/SystemThemeUploadView.vue";
import SystemColorPickerView from "@/apps/administration/views/squeeze/system/SystemColorPickerView.vue";
import SystemCsvTranslationView from "@/apps/administration/views/squeeze/system/SystemCsvTranslationView.vue";

export default defineComponent({
	name: "SystemView",
	components: {
		SystemCsvTranslationView,
		SystemColorPickerView,
		SystemThemeUploadView,
		SystemInformation,
		SystemCleanUpConfig,
		Divider,
		Accordion,
		AccordionTab,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const store = useSqueezeStore();

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		/** Api for job management in system */
		const systemApi = ClientManager.getInstance().squeeze.system;

		/** String with system version */
		const systemVersion = ref<string | undefined>('');

		/** Object with systemCleanUpConfigs */
		const systemCleanUpConfig = reactive<SystemCleanupConfig>({
			maxDocumentAge:	undefined,
			maxLogAge: undefined,
		});

		/** Get System Version */
		const getSystemVersion = () => {
			loading.value = true;
			systemApi.getVersion()
				.then(data => {
					systemVersion.value = data.initialVersion;
				}).catch(response => response.json().then ((error: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		/** Get SystemCleanUpConfig */
		const getSystemCleanUpConfig = () => {
			loading.value = true;
			systemApi.getCleanupConfig()
				.then(data => {
					Object.assign(systemCleanUpConfig, data);
				}).catch(response => response.json().then ((error: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		onMounted(() => {
			getSystemVersion();
			getSystemCleanUpConfig();
		});

		return {
			t, toast, store, loading, systemVersion, systemCleanUpConfig, getSystemVersion, getSystemCleanUpConfig,
		};
	},
});

