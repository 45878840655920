import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-118fa869"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }
const _hoisted_3 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      value: _ctx.locators,
      loading: _ctx.loading,
      class: "p-datatable-sm p-datatable-documents",
      autoLayout: true,
      columnResizeMode: "fit",
      selection: _ctx.selection,
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selection) = $event)),
      selectionMode: "single",
      onRowSelect: _ctx.onRowSelect,
      scrollable: true,
      scrollHeight: "flex",
      rowHover: true,
      removableSort: true,
      filterDisplay: "row",
      filters: _ctx.filters,
      "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
      onFilter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFilter($event)))
    }, {
      loading: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
      ]),
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.Locators.Locators') })), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('Squeeze.General.NewEntry'),
          type: "button",
          icon: "mdi mdi-plus",
          class: "p-button",
          onClick: _ctx.openEntryDialog
        }, null, 8, ["label", "onClick"]),
        (_ctx.showBtnClearFilter)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('Squeeze.General.ClearFilters'),
              type: "button",
              icon: "mdi mdi-filter-off-outline",
              class: "p-button p-ml-2",
              onClick: _ctx.clearFilters
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.store.state.featureSet.uiConfigExport)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              disabled: _ctx.loading || !_ctx.locators.length,
              label: "Lokatoren exportieren",
              type: "button",
              icon: "mdi mdi-database-export",
              class: "p-button p-ml-2",
              onClick: _ctx.exportAllLocators
            }, null, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "id",
          header: "ID",
          filterField: "id",
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"4rem","max-width":"4rem"}
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.id), 1)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"3rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "name",
          filterField: "name",
          header: _ctx.$t('Squeeze.Locators.Name'),
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(slotProps.data.name), 1)
            ])), [
              [
                _directive_tooltip,
                slotProps.data.name,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"9rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "description",
          filterField: "description",
          header: _ctx.$t('Squeeze.Locators.Description'),
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(slotProps.data.description), 1)
            ])), [
              [
                _directive_tooltip,
                slotProps.data.description,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"9rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "locatorType",
          filterField: "locatorType",
          header: _ctx.$t('Squeeze.Locators.LocatorType'),
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.getLocatorTypeDescription(slotProps.data.locatorType)), 1)
            ])), [
              [
                _directive_tooltip,
                _ctx.getLocatorTypeDescription(slotProps.data.locatorType),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onChange: ($event: any) => (filterCallback()),
              options: _ctx.locatorTypes,
              class: "p-column-filter filter-field",
              optionValue: "value",
              optionLabel: "description",
              showClear: true,
              filter: true,
              autoFilterFocus: true,
              style: {"min-width":"9rem"},
              onShow: _ctx.showDropdownOverlay
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "onShow"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "dataType",
          filterField: "dataType",
          header: _ctx.$t('Squeeze.Locators.DataType'),
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.' + slotProps.data.dataType)), 1)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onChange: ($event: any) => (filterCallback()),
              options: _ctx.dataTypes,
              class: "p-column-filter filter-field",
              optionValue: "id",
              optionLabel: "text",
              showClear: true,
              style: {"min-width":"9rem"}
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "active",
          filterField: "active",
          header: _ctx.$t('Squeeze.Locators.Active'),
          headerClass: "header-center",
          style: {"min-width":"5rem","max-width":"5rem","text-align":"center"}
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_Checkbox, {
              onChange: ($event: any) => (_ctx.onChangeCheckbox(slotProps.data)),
              modelValue: slotProps.data.active,
              "onUpdate:modelValue": ($event: any) => ((slotProps.data.active) = $event),
              binary: true,
              disabled: true
            }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, { style: {"min-width":"3rem","max-width":"3rem","text-align":"right"} }, {
          body: _withCtx((slotProps) => [
            _withDirectives(_createVNode(_component_Button, {
              icon: "mdi mdi-delete-outline",
              class: "p-button-lg p-button-rounded p-button-danger p-button-text",
              onClick: ($event: any) => (_ctx.openDeleteDialog(slotProps.data))
            }, null, 8, ["onClick"]), [
              [
                _directive_tooltip,
                _ctx.$t('Squeeze.Validation.Buttons.Delete'),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "loading", "selection", "onRowSelect", "filters"]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteDialog = false)),
      onOnConfirm: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteEntry()))
    }, null, 8, ["showDialog"])
  ], 64))
}