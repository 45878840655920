
import {defineComponent, onMounted, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default defineComponent({
	name: "DialogLocked",
	components: {
		Dialog,
		Button,
	},
	props: {
		/** Should the dialog be shown? */
		showDialog: {
			type: Boolean,
			default: false,
		},
		/** ID of the blocking user */
		lockingUserId: {
			type: Number,
			default: null,
		},
	},
	emits: ["closeDialog", "unlockDocument", "backToOverview", "showOnly", "nextDocument"],
	setup(props, { emit }) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Vuex-Store */
		const store = useSqueezeStore();

		/** Text of the header */
		const headerText = ref<string>('');

		/** Name of the current user */
		const userName = ref<string>("mir!");

		/** Is user a admin? */
		const isUserAdmin = ref<boolean>(false);

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Triggered when dialog closed */
		const onCloseDialog = () => {
			emit("closeDialog");
		}

		/** Reload user data to get the name of the user that blocked the document */
		const reloadUserData = () => {
			if (props.lockingUserId) {
				userApi.getUserById(props.lockingUserId)
					.then(data => {
						userName.value = data.firstName + " " + data.lastName;
						headerText.value = t('Squeeze.Validation.Dialogs.Locked.DocumentLockedBy', { userName: userName.value });
					})
			}
		}

		onMounted(() =>{
			headerText.value = t('Squeeze.Validation.Dialogs.Locked.DocumentLocked');
			isUserAdmin.value = !!store.state.isAdminUser;
		})

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.lockingUserId,() => {
			reloadUserData();
		})

		return {
			t,
			toast,
			store,
			headerText,
			userName,
			isUserAdmin,
			onCloseDialog,
			reloadUserData,
		};
	},
});

