import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c8011c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid p-input-filled p-formgrid p-grid p-pb-2 p-px-2" }
const _hoisted_2 = { class: "p-field p-col-4 p-ml-auto" }
const _hoisted_3 = {
  key: 0,
  class: "mdi mdi-alert-outline icon-error"
}
const _hoisted_4 = { class: "p-field p-col-12" }
const _hoisted_5 = { for: "username" }
const _hoisted_6 = { class: "p-field p-col-12" }
const _hoisted_7 = { for: "password" }
const _hoisted_8 = { class: "p-col-12 p-mt-2" }
const _hoisted_9 = {
  key: 1,
  class: "p-col-12 p-mt-2"
}
const _hoisted_10 = {
  key: 2,
  class: "p-col-12 p-mt-2"
}
const _hoisted_11 = {
  key: 3,
  class: "p-col-12 p-mt-2"
}
const _hoisted_12 = { for: "forgotPassword" }
const _hoisted_13 = ["href", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Password = _resolveComponent("Password")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_BlockUI, { blocked: _ctx.blocked }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.selectedLanguage,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
            options: _ctx.languages,
            optionLabel: "description",
            optionValue: "countryCode",
            placeholder: _ctx.$t('Squeeze.Language.Selection'),
            onChange: _ctx.changeLanguage
          }, {
            option: _withCtx((slotProps) => [
              _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("span", null, _toDisplayString(slotProps.option.description), 1),
                (!_ctx.SupportedLangs.includes(slotProps.option.countryCode))
                  ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                  : _createCommentVNode("", true)
              ])), [
                [
                  _directive_tooltip,
                  !_ctx.SupportedLangs.includes(slotProps.option.countryCode) ? _ctx.$t('Squeeze.General.LanguageNotAvailable') : '',
                  void 0,
                  { bottom: true }
                ]
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options", "placeholder", "onChange"])
        ]),
        (_ctx.store.state.publicAuthConfig.basicAuthentication)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Squeeze.Login.UserName')), 1),
                _createVNode(_component_InputText, {
                  id: "username",
                  modelValue: _ctx.username,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.username) = $event)),
                  modelModifiers: { trim: true },
                  required: "true",
                  onKeydown: _ctx.onKeyDown,
                  ref: "usernameInput",
                  autofocus: ""
                }, null, 8, ["modelValue", "onKeydown"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Squeeze.Login.Password')), 1),
                _createVNode(_component_Password, {
                  id: "password",
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                  modelModifiers: { trim: true },
                  required: "true",
                  feedback: false,
                  onKeydown: _ctx.onKeyDown
                }, null, 8, ["modelValue", "onKeydown"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_Button, {
                  label: "Login",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.login()))
                })
              ])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.store.state.publicAuthConfig.keyCloakAuthentication)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_Button, {
                label: "Login with Keycloak / SSO",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loginWithKeyCloak()))
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.store.state.publicAuthConfig.portalAuthentication)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_Button, {
                label: "Login with DEXPRO Portal",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loginWithPortal()))
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.store.state.publicAuthConfig.basicAuthentication)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("label", _hoisted_12, [
                _createVNode(_component_router_link, {
                  to: {name: 'Recover'},
                  custom: ""
                }, {
                  default: _withCtx(({navigate, href}) => [
                    _createElementVNode("a", {
                      href: href,
                      class: "p-component p-link",
                      onClick: navigate
                    }, _toDisplayString(_ctx.$t('Squeeze.Login.ForgotPassword')), 9, _hoisted_13)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["blocked"]))
}