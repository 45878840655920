import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3194854"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 child-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentClassExportStates = _resolveComponent("DocumentClassExportStates")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DocumentClassExportStates, {
      exportStates: _ctx.exportStates,
      loading: _ctx.loading,
      paginationInfo: _ctx.pagination,
      onOnPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event))),
      allExportInterfaces: _ctx.allExportInterfaces
    }, null, 8, ["exportStates", "loading", "paginationInfo", "allExportInterfaces"])
  ]))
}