import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d7b1026"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.passwords,
    loading: _ctx.loading,
    class: "p-datatable-sm",
    autoLayout: true,
    columnResizeMode: "fit",
    onRowDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditDialog($event.data))),
    paginator: true,
    rows: 25,
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
    scrollable: true,
    scrollHeight: "flex",
    rowHover: true
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.Imports.Passwords') })), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button",
        onClick: _ctx.onNewEntryClick
      }, null, 8, ["label", "onClick"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "id",
        header: "ID",
        sortable: false,
        class: "id-column",
        style: {"min-width":"4rem","max-width":"6rem"}
      }),
      _createVNode(_component_Column, {
        field: "email",
        header: _ctx.$t('Squeeze.UserManagement.Email'),
        sortable: false,
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(slotProps.data.email), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.email,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "password",
        header: _ctx.$t('Squeeze.Login.Password'),
        style: {"min-width":"7rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(slotProps.data.password), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.password,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        class: "buttonColumn",
        style: {"min-width":"7rem","max-width":"7rem"}
      }, {
        body: _withCtx((password) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.openEditDialog(password.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(password.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "currentPageReportTemplate"]))
}