import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Timeline = _resolveComponent("Timeline")!

  return (_openBlock(), _createBlock(_component_Timeline, {
    value: _ctx.processItems,
    align: "right"
  }, {
    marker: _withCtx((slotProps) => [
      _createElementVNode("span", {
        style: _normalizeStyle(slotProps.item.active ? 'opacity: 1' : 'opacity: 0.5')
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(slotProps.item.icon)
        }, null, 2)
      ], 4)
    ]),
    content: _withCtx((slotProps) => [
      _createElementVNode("div", {
        style: _normalizeStyle(slotProps.item.active ? 'opacity: 1' : 'opacity: 0.5')
      }, _toDisplayString(slotProps.item.status), 5)
    ]),
    _: 1
  }, 8, ["value"]))
}