import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-formgrid p-grid p-input-filled p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-6" }
const _hoisted_3 = { for: "name" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = {
  key: 1,
  class: "p-error"
}
const _hoisted_6 = { class: "p-field p-col-6" }
const _hoisted_7 = { for: "description" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = {
  key: 0,
  class: "p-field p-col-6"
}
const _hoisted_10 = { for: "type" }
const _hoisted_11 = {
  key: 1,
  class: "p-field p-col-6"
}
const _hoisted_12 = { for: "tableField" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Name')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "name",
        modelValue: _ctx.v$.name.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
        modelModifiers: { trim: true },
        class: _normalizeClass({'p-invalid':_ctx.v$.name.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update,
        required: "true",
        autofocus: ""
      }, null, 8, ["modelValue", "class", "onChange"]),
      (_ctx.v$.name.required.$invalid && _ctx.showErrorMessage && !_ctx.v$.name.technicalValidation.$invalid)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.Name')})), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.name.technicalValidation.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.$t('Forms.Val.OnlyAllowAlphaNumericUnderscore', { field: _ctx.$t('Squeeze.DocumentClasses.Name')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Description')) + " *", 1),
      _createVNode(_component_InputText, {
        id: "description",
        modelValue: _ctx.v$.description.$model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.description.$model) = $event)),
        modelModifiers: { trim: true },
        class: _normalizeClass({'p-invalid':_ctx.v$.description.$invalid && _ctx.showErrorMessage}),
        onChange: _ctx.update,
        required: "true"
      }, null, 8, ["modelValue", "class", "onChange"]),
      ((_ctx.v$.description.$invalid && _ctx.showErrorMessage))
        ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.Description')})), 1))
        : _createCommentVNode("", true)
    ]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Type')), 1),
          _createVNode(_component_Dropdown, {
            id: "type",
            style: {"min-width":"250px"},
            modelValue: _ctx.props.fieldEntry.type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.props.fieldEntry.type) = $event)),
            options: _ctx.fieldGroupsTypes,
            optionValue: "id",
            optionLabel: "description"
          }, null, 8, ["modelValue", "options"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.fieldEntry.type === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.TableField')), 1),
          _createVNode(_component_InputText, {
            id: "tableField",
            modelValue: _ctx.props.fieldEntry.tableField,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.props.fieldEntry.tableField) = $event)),
            modelModifiers: { trim: true },
            required: "true"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ]))
}