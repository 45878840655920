import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19cd6af4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "column-cell" }
const _hoisted_6 = {
  key: 0,
  class: "p-fluid p-formgrid p-grid p-input-filled p-input-filled"
}
const _hoisted_7 = { class: "p-field p-col-12" }
const _hoisted_8 = { for: "classificationClasses" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Message = _resolveComponent("Message")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      value: _ctx.queueEntries,
      class: "p-datatable-sm p-datatable-documents",
      lazy: true,
      paginator: true,
      loading: _ctx.loading || _ctx.lockTable,
      paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
      rowsPerPageOptions: [25,50,100],
      rows: _ctx.paginationInfo.pageSize,
      totalRecords: _ctx.paginationInfo.total,
      scrollable: true,
      scrollHeight: "flex",
      rowHover: true,
      selection: _ctx.selectedRows,
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRows) = $event)),
      onRowClick: _ctx.onRowClick,
      onPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPage($event))),
      first: (_ctx.paginationInfo.page * _ctx.paginationInfo.pageSize),
      currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords} ',
      filterDisplay: _ctx.stepName !== 'ErrorBasket' ? 'row' : '',
      filters: _ctx.filters,
      "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
      onFilter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFilter($event))),
      onSort: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSort($event))),
      removableSort: true,
      metaKeySelection: false
    }, {
      loading: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
      ]),
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Queue.Filter.NoEntries')), 1)
      ]),
      paginatorstart: _withCtx(() => [
        _withDirectives(_createVNode(_component_Button, {
          type: "button",
          icon: "mdi mdi-refresh",
          class: "p-button-text",
          onClick: _ctx.onReload,
          disabled: _ctx.loading
        }, null, 8, ["onClick", "disabled"]), [
          [_directive_tooltip, _ctx.$t('Squeeze.General.Refresh')]
        ]),
        _withDirectives(_createVNode(_component_Button, {
          type: "button",
          icon: "mdi mdi-delete-outline",
          class: "p-button-text",
          onClick: _ctx.deleteDocuments,
          disabled: _ctx.loading || !_ctx.selectedRows.length || _ctx.stepName === 'Delete'
        }, null, 8, ["onClick", "disabled"]), [
          [_directive_tooltip, _ctx.$t('Squeeze.Queue.General.DeleteEntries')]
        ]),
        _createVNode(_component_Button, {
          type: "button",
          icon: "mdi mdi-file-refresh-outline",
          class: "p-button-text",
          label: _ctx.$t('Squeeze.Queue.Requeue.Requeue'),
          onClick: _ctx.openMultiRequeueMenu,
          disabled: !_ctx.selectedRows.length || !_ctx.store.state.scopes.sqzRequeue,
          "aria-haspopup": "true",
          "aria-controls": "multi_entry_menu"
        }, null, 8, ["label", "onClick", "disabled"]),
        _createVNode(_component_Menu, {
          id: "multi_entry_menu",
          ref: "multiEntryMenu",
          model: _ctx.steps,
          popup: true
        }, null, 8, ["model"]),
        _createVNode(_component_Button, {
          type: "button",
          icon: "mdi mdi-folder-open",
          class: "p-button-text",
          label: _ctx.$t('Squeeze.Queue.General.GoTo'),
          onClick: _ctx.openGoToMenu,
          "aria-haspopup": "true",
          "aria-controls": "multi_entry_menu_goto"
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Menu, {
          id: "multi_entry_menu_goto",
          ref: "goToMenu",
          model: _ctx.stepsGoTo,
          popup: true
        }, null, 8, ["model"]),
        (_ctx.showBtnClearFilter)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('Squeeze.General.ClearFilters'),
              type: "button",
              icon: "mdi mdi-filter-off-outline",
              class: "p-button-text",
              onClick: _ctx.clearFilters
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      paginatorend: _withCtx(() => []),
      default: _withCtx(() => [
        (_ctx.store.state.scopes.sqzRequeue)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              selectionMode: "multiple",
              headerClass: "header-center",
              style: {"min-width":"3rem","max-width":"3rem","text-align":"center"}
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "documentId",
          header: _ctx.$t('Squeeze.Queue.Fields.DocID'),
          style: {"min-width":"6rem","max-width":"6rem"},
          filterField: "documentId",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.documentId), 1)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"5rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              placeholder: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "id",
          header: _ctx.$t('Squeeze.Queue.Fields.QueueID'),
          style: {"min-width":"6rem","max-width":"6rem"},
          filterField: "id",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.id), 1)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"5rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              placeholder: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "batchClassId",
          header: _ctx.$t('Squeeze.BatchClasses.BatchClass'),
          style: {"min-width":"10rem","max-width":"12rem"},
          filterField: "batchClassId",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(_ctx.getDescriptionFromBatchClass(slotProps.data.batchClassId)), 1)
            ])), [
              [
                _directive_tooltip,
                _ctx.getDescriptionFromBatchClass(slotProps.data.batchClassId),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onChange: ($event: any) => (filterCallback()),
              options: _ctx.batchClasses,
              placeholder: "",
              class: "p-column-filter filter-field",
              optionValue: "id",
              optionLabel: "description",
              showClear: true
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "documentClassId",
          header: _ctx.$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.DocumentClass'),
          style: {"min-width":"10rem","max-width":"12rem"},
          filterField: "documentClassId",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.getDescriptionFromDocumentClass(slotProps.data.documentClassId)), 1)
            ])), [
              [
                _directive_tooltip,
                _ctx.getDescriptionFromDocumentClass(slotProps.data.documentClassId),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onChange: ($event: any) => (filterCallback()),
              options: _ctx.documentClasses,
              placeholder: "",
              class: "p-column-filter filter-field",
              optionValue: "id",
              optionLabel: "description",
              showClear: true
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "status",
          header: _ctx.$t('Squeeze.Queue.Fields.status'),
          style: {"min-width":"10rem","max-width":"15rem"},
          filterField: "status",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", {
              class: _normalizeClass(["column-cell p-py-1", 'status-badge ' + slotProps.data.workflowContext.status.toLowerCase()])
            }, _toDisplayString(_ctx.$t("Squeeze.Queue.States." + slotProps.data.workflowContext.status)), 3)
          ]),
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onChange: ($event: any) => (filterCallback()),
              options: _ctx.statuses,
              placeholder: "",
              class: "p-column-filter filter-field",
              style: {"height":"2.111rem"},
              showClear: true
            }, {
              value: _withCtx((slotProps) => [
                (slotProps.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("Squeeze.Queue.States." + slotProps.value.toUpperCase())), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(slotProps.placeholder), 1))
              ]),
              option: _withCtx((slotProps) => [
                _createElementVNode("span", {
                  class: _normalizeClass('status-badge ' + slotProps.option.toLowerCase())
                }, _toDisplayString(_ctx.$t("Squeeze.Queue.States." + slotProps.option)), 3)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "errorText",
          header: _ctx.$t('Squeeze.General.Comment'),
          style: {"min-width":"10rem"},
          filterField: "errorText",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createTextVNode(_toDisplayString(slotProps.data.workflowContext.errorText), 1)
            ])), [
              [
                _directive_tooltip,
                slotProps.data.workflowContext.errorText,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              placeholder: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "createdTs",
          header: _ctx.$t('Squeeze.WorkflowContext.createdTs'),
          style: {"min-width":"10rem"},
          filterField: "createdTs",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.workflowContext.createdTs)), 1)
          ]),
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_Calendar, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              showIcon: "",
              showButtonBar: "",
              iconDisplay: "input",
              style: {"min-width":"9rem"},
              class: "p-column-filter filter-field",
              manualInput: false,
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              onDateSelect: ($event: any) => (filterCallback()),
              onClearClick: ($event: any) => (filterCallback())
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "onDateSelect", "onClearClick"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "modifiedTs",
          header: _ctx.$t('Squeeze.WorkflowContext.modifiedTs'),
          style: {"min-width":"10rem"},
          filterField: "modifiedTs",
          showFilterMenu: false,
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.workflowContext.modifiedTs)), 1)
          ]),
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_Calendar, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              showIcon: "",
              showButtonBar: "",
              iconDisplay: "input",
              style: {"min-width":"10rem"},
              class: "p-column-filter filter-field",
              manualInput: false,
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              onDateSelect: ($event: any) => (filterCallback()),
              onClearClick: ($event: any) => (filterCallback())
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "onDateSelect", "onClearClick"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          class: "buttonColumn",
          style: {"min-width":"13rem","max-width":"13rem"}
        }, {
          body: _withCtx((slotProps) => [
            (_ctx.store.state.scopes.sqzAdmin && _ctx.store.state.featureSet.documentLog)
              ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  icon: "mdi mdi-script-text-outline",
                  class: "p-button p-button-rounded p-button-text",
                  type: "button",
                  onClick: ($event: any) => (_ctx.openLogDialog(slotProps.data.documentId))
                }, null, 8, ["onClick"])), [
                  [_directive_tooltip, 'Log']
                ])
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_Button, {
              icon: "mdi mdi-folder-download-outline",
              class: "p-button p-button-rounded p-button-text",
              type: "button",
              onClick: ($event: any) => (_ctx.downloadAttachment(slotProps.data))
            }, null, 8, ["onClick"]), [
              [
                _directive_tooltip,
                _ctx.$t('Squeeze.Attachments.ZIPDownlaod'),
                void 0,
                { bottom: true }
              ]
            ]),
            _withDirectives(_createVNode(_component_Button, {
              icon: "mdi mdi-file-refresh-outline",
              class: "p-button p-button-rounded p-button-success p-button-text",
              onClick: ($event: any) => (_ctx.openRequeueMenu($event, slotProps.data)),
              "aria-haspopup": "true",
              "aria-controls": "single_entry_menu",
              disabled: !_ctx.store.state.scopes.sqzRequeue
            }, null, 8, ["onClick", "disabled"]), [
              [
                _directive_tooltip,
                _ctx.$t('Squeeze.Queue.Requeue.Requeue'),
                void 0,
                { left: true }
              ]
            ]),
            _createVNode(_component_Menu, {
              id: "single_entry_menu",
              ref: "singleEntryMenu",
              model: _ctx.steps,
              popup: true
            }, null, 8, ["model"]),
            _withDirectives(_createVNode(_component_Button, {
              icon: "mdi mdi-delete-outline",
              class: "p-button p-button-rounded p-button-danger p-button-text",
              onClick: ($event: any) => (_ctx.deleteDocument(slotProps.data)),
              disabled: _ctx.stepName === 'Delete'
            }, null, 8, ["onClick", "disabled"]), [
              [
                _directive_tooltip,
                _ctx.$t('Squeeze.Validation.Buttons.Delete'),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "loading", "rows", "totalRecords", "selection", "onRowClick", "first", "currentPageReportTemplate", "filterDisplay", "filters"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showRequeue,
      onOnClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showRequeue = false)),
      onOnConfirm: _ctx.confirmRequeue,
      loading: _ctx.loading,
      headerText: _ctx.$t('Squeeze.Queue.Requeue.Forward'),
      saveButtonText: _ctx.$t('Squeeze.General.Yes'),
      abortButtonText: _ctx.$t('Squeeze.General.No')
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: _ctx.loading }, {
          default: _withCtx(() => [
            ((_ctx.requeueStep === 'Validation' || _ctx.requeueStep === 'Extraction'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_Message, {
                    severity: "info",
                    closable: false
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.headerText), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.Queue.Requeue.Information')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.NewDocumentClass')), 1),
                    _createVNode(_component_Dropdown, {
                      id: "classificationClasses",
                      modelValue: _ctx.activeDocumentClass,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.activeDocumentClass) = $event)),
                      options: _ctx.classificationClasses,
                      optionValue: "documentClassId",
                      optionLabel: "description",
                      placeholder: _ctx.$t('Squeeze.Queue.Requeue.KeepCurrentDocumentClass'),
                      showClear: true
                    }, null, 8, ["modelValue", "options", "placeholder"])
                  ])
                ]))
              : (_openBlock(), _createBlock(_component_Message, {
                  key: 1,
                  severity: "info",
                  closable: false
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.headerText), 1)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["blocked"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText", "saveButtonText", "abortButtonText"])
  ], 64))
}