import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentLocatorTestingForm = _resolveComponent("DocumentLocatorTestingForm")!
  const _component_LocatorSubView = _resolveComponent("LocatorSubView")!
  const _component_DocumentLocatorTestingTable = _resolveComponent("DocumentLocatorTestingTable")!
  const _component_Log = _resolveComponent("Log")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DocumentLocatorTestingForm, {
      locators: _ctx.locators,
      loading: _ctx.loading,
      onOnChangeLocator: _ctx.getDataOfLocator,
      onTestLocator: _ctx.testLocator,
      onOpenEntryLocator: _ctx.openEntryLocator,
      onOpenLog: _ctx.openLog,
      ref: "testingForm"
    }, null, 8, ["locators", "loading", "onOnChangeLocator", "onTestLocator", "onOpenEntryLocator", "onOpenLog"]),
    (_ctx.renderComponent && _ctx.entryLocatorId)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle('height: calc(100% - ' + _ctx.testingFormHeight + 'px)')
        }, [
          _createVNode(_component_LocatorSubView, {
            locatorId: _ctx.entryLocatorId,
            showSingleLinedForm: _ctx.showSingleLinedForm
          }, null, 8, ["locatorId", "showSingleLinedForm"])
        ], 4))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          style: _normalizeStyle('height: calc(100% - ' + _ctx.testingFormHeight + 'px)')
        }, [
          _createVNode(_component_DocumentLocatorTestingTable, {
            locatorValues: _ctx.locatorValues,
            loading: _ctx.loading,
            onOnMarkWord: _ctx.onMarkWord
          }, null, 8, ["locatorValues", "loading", "onOnMarkWord"])
        ], 4)),
    _createVNode(_component_Dialog, {
      header: _ctx.$t('Squeeze.Validation.Log.Log'),
      visible: _ctx.showLog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showLog) = $event)),
      modal: true,
      closable: true,
      dismissableMask: true,
      baseZIndex: 1000,
      breakpoints: {'960px': '75vw', '640px': '100vw'},
      style: {width: '50vw'}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Log, {
          logs: _ctx.logs,
          loading: _ctx.loading,
          showSort: false
        }, null, 8, ["logs", "loading"])
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}