
import {defineComponent, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Panel from 'primevue/panel';
import router from "@/router";
import SetupForm from "@/apps/system-administration/components/SetupForm.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {TenantDto} from "@dex/squeeze-system-client-ts";
import {useConfirm} from "primevue/useconfirm";

export default defineComponent({
	name: "SetupFormView",
	components: {
		SetupForm,
		Panel,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const confirm = useConfirm();

		/** Clear tenant field */
		const clearTenantField = ref<boolean>(false);

		/** Tenants API */
		const tenantsApi = ClientManager.getInstance().squeezeSystem.tenants;

		/**
		 * Show warn message when the tenant already exists
		 * @param {string} message
		 * @param {string} newTenant
		 */
		const showWarnMessage = (message: string, newTenant: string) => {
			confirm.require({
				message: message,
				header: t('System-Administration.General.Confirmation'),
				icon: 'mdi mdi-alert-outline',
				acceptLabel: t('System-Administration.General.Confirm'),
				rejectLabel: t('System-Administration.General.Abort'),
				accept: () => {
					router.push({ name: 'ClientProcessView', params: { tenant: newTenant, tenantExists: 'true' }});
				},
				reject: () => {
					clearTenantField.value = true;
				},
			});
		}

		/**
		 * Check if the tenant already exists
		 * @param tenants
		 * @param newTenant
		 */
		const checkIfTenantExists = (tenants: TenantDto[], newTenant: string) => {
			let exists: boolean = false;
			tenants.forEach(tenant => {
				// check if tenant already exists
				if (tenant.id === newTenant) {
					exists = true;
				}
			})

			return exists;
		}

		/** Triggered when the Authentication process is started
		 * @param {string} user
		 * @param {string} key
		 * @param {string} newTenant
		 */
		const sendAuth = async (user: string, key: string, newTenant: string) => {
			try {
				const isLoggedIn = await ClientManager.getInstance().systemAuth(user, key);

				if (!isLoggedIn) {
					ToastManager.showError(toast, t("Squeeze.Login.LoginFailed"), "");
				} else {
					// get all tenants
					tenantsApi.getTenants()
						.then((tenants: TenantDto[]) => {
							const tenantExists = checkIfTenantExists(tenants, newTenant);
							if (tenantExists) {
								const msg: string = t('System-Administration.Tenants.TenantAlreadyExistsMessage', { newTenant: newTenant});
								showWarnMessage(msg, newTenant);
							} else {
								router.push({name: 'ClientProcessView', params: {tenant: newTenant}});
							}
						}).catch((error: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
						}).finally(() => {
							clearTenantField.value = false;
						})
				}
			} catch (response) {
				ToastManager.showError(toast, t("Squeeze.Login.LoginFailed"), response);
			}
		}

		return {
			t,
			toast,
			clearTenantField,
			sendAuth,
			showWarnMessage,
		};
	},
});

