
import {RouteLocationRaw} from "vue-router";
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Avatar from 'primevue/avatar';
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';
import Sidebar from 'primevue/sidebar';
import {DEFAULT_LOCALE, getActiveLanguage, Messages, SupportedLangs} from "@/lang";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {computed, defineComponent, onMounted, PropType, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import router from "@/router";
import {useSqueezeStore} from "@/apps/squeeze/store";
import ResetPasswordForm from "@/components/ResetPasswordForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import DocumentUpload from "@/apps/squeeze/components/DocumentUpload.vue";
const activeLanguage = getActiveLanguage();
import BadgeDirective from 'primevue/badgedirective';
import UserSettings from "@/views/UserSettings.vue";
import News from "@/views/News.vue";
//import TieredMenu from 'primevue/tieredmenu';
import {ScriptDto, TranslationCountry} from "@dex/squeeze-client-ts";
import {usePrimeVue} from "primevue/config";

export interface Link {
	label: string;
	icon: string;
	isAdminRoute: boolean;
	to: RouteLocationRaw;
}

export interface CustomAction extends ScriptDto {
	label?: string;
	icon?: string;
	command?: any;
}

export default defineComponent({
	name: "VerticalNavbar",
	components: {
		UserSettings,
		News,
		Button,
		Avatar,
		OverlayPanel,
		Dialog,
		Dropdown,
		ResetPasswordForm,
		EntryDialog,
		DocumentUpload,
		Sidebar,
	},
	props: {
		links: {
			type: Array as PropType<Link[]>,
			default: () => [],
		},
		hideNavbar: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		badge: BadgeDirective,
	},
	emits: ['onCollapseSidebar', 'userLoggedOut', 'isUploadDialogShown'],
	setup(props, {emit}) {
		const primevue = usePrimeVue();
		const {t} = useI18n();
		const toast = useToast();

		const { locale } = useI18n({ useScope: 'global' })
		const store = useSqueezeStore();
		const op = ref<InstanceType<typeof OverlayPanel>>()

		const opened = ref(false);
		const endLinks= ref<Link[]>([
			{
				label: "Upload",
				icon: "mdi mdi-cloud-upload-outline",
				isAdminRoute: false,
				to: {
					name: "Home",
					params: { lang: activeLanguage },
				},
			},
		]);

		/** Currently selected language */
		const selectedLanguage = ref<string | null>(null);

		/** Languages for UI */
		const languages = ref<TranslationCountry[]>([]);

		/** Access Links for UI */
		const accessibleLinks = computed(() => {
			if(store.state.scopes.sqzAdminView) {
				return props.links;
			} else {
				return props.links!.filter(link => !link.isAdminRoute);
			}
		});

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Public API endpoint */
		const publicApi = ClientManager.getInstance().squeeze.public;

		/** Validation API endpoint */
		//const validationApi = ClientManager.getInstance().squeeze.validation;

		/** Currently logged in user initials */
		const loggedInUserInitials = ref ("");

		/** Currently logged in user is administrator? */
		const loggedInUserIsAdmin = ref(false);

		/** Should the dialog be shown? */
		const showDialog = ref(false);

		/** Should the dialog upload be shown? */
		const showUploadDialog = ref(false);

		/** Should the dialog of settings be shown? */
		const showSettingDialog = ref<boolean>(false);

		/** Should the sidebar of news be shown? */
		const showNewsSidebar = ref<boolean>(false);

		/** Should the loading be shown in the dialog? */
		const loadingDialog = ref(false);

		/** Menu item for custom actions */
		const customActions = ref<ScriptDto[]>([]);

		/** Ref of custom action menu */
		const customActionsMenu = ref();

		/** Returns the initials of first and last name*/
		const getInitialsOfName = (firstName: string | undefined, lastName: string | undefined) => {
			let initials = "";
			if(firstName && firstName.length > 0) {
				initials = firstName.slice(0,1).toUpperCase();
			}

			if(lastName && lastName.length > 0) {
				initials += lastName.slice(0,1).toUpperCase();
			}
			return initials;
		}

		/** Set current Language */
		const setLanguage = (language: TranslationCountry | undefined) => {
			// current primevue translations of a language
			let currentPrimeVueTranslations = Messages[DEFAULT_LOCALE].PrimeVue;

			if (language && language.countryCode) {
				locale.value = language.countryCode;
				selectedLanguage.value = language.countryCode;
				currentPrimeVueTranslations = Messages[language.countryCode].PrimeVue;
			} else {
				locale.value = DEFAULT_LOCALE;
				selectedLanguage.value = DEFAULT_LOCALE;
			}

			// set the config locale of primevue to the current language translations
			// We need to use 'as any', because the regular way we can not write to the local object
			// The changes are some reason lost in transit
			(primevue as any).config.locale = currentPrimeVueTranslations;
		}

		/** Check Language by first load */
		const checkLanguage = () => {
			if (localStorage.getItem('language')) {
				const language = languages.value.find(language => language.countryCode ===  localStorage.getItem('language'));
				setLanguage(language);
			} else {
				// Check browser language if no local storage language
				const language = languages.value.find(language => language.countryCode === navigator.language.substring(0, 2));
				setLanguage(language);
				localStorage.setItem('language', locale.value);
			}
		}

		/** Get all languages for UI */
		const getLanguages = () => {
			publicApi.getActiveCountries(true)
				.then((data: TranslationCountry[]) => {
					if (data.length === 0) {
						throw new Error("Missing languages in list!");
					}
					languages.value = data;
				}).catch(() => {
					// set default languages
					languages.value = [
						{description: t('Squeeze.Language.German'), countryCode: 'de'},
						{description: t('Squeeze.Language.English'), countryCode: 'en'},
					];
				}).finally(() => {
					checkLanguage();
				})
		}

		/** Get Custom Actions */
		/*const getCustomActions = () => {
			validationApi.getCustomValidationActions()
				.then((scripts: ScriptDto[]) => {
					const customScripts: CustomAction[] = scripts.filter(script => script.type === 'custom-global');
					if (customScripts) {
						// get custom action as menuItem object
						const allActions: CustomAction[] = [];
						customScripts.map((action: CustomAction) => {
							allActions.push({
								label: action.description,
								icon: 'mdi mdi-square-small',
								command: () => { console.log(action.name) },
							})
						})

						customActions.value = allActions;
					}
				}).catch((response: any) => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}));
		}*/

		onMounted(() => {
			userApi.getLoggedInUser()
				.then((user) => {
					loggedInUserInitials.value = getInitialsOfName(user.firstName, user.lastName);
					return ClientManager.getInstance().isAdminUser();
				}).then((isAdminUser) => {
					loggedInUserIsAdmin.value = isAdminUser
				}).catch((response: any) => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))

			// check current language before get all languages from api
			if (localStorage.getItem('language')) {
				locale.value = localStorage.getItem('language')!;
			}
			getLanguages();
			//getCustomActions();
		})

		/** Triggered when a row is selected */
		const onCollapseSidebar = () => {
			opened.value = !opened.value;
			emit("onCollapseSidebar");
		}

		/**
		 * Toggle custom actions
		 * @param event
		 */
		const toggleCustomActions = (event: any) => {
			customActionsMenu.value.toggle(event);
		}

		/** Toggle user info at avatar */
		const toggleUserInfo = (event: any) => {
			op.value?.toggle(event);
		}

		/** Triggered when a language is selected */
		const changeLanguage = () => {
			const language = languages.value.find(language => language.countryCode === selectedLanguage.value);
			if (language && language.countryCode && locale.value !== selectedLanguage.value) {
				locale.value = language.countryCode;
				selectedLanguage.value = language.countryCode;
				location.reload();
			}
		}

		/** Triggered when clicked for user logout */
		const logout = () => {
			userApi.logout()
				.then(() => {
					ToastManager.showSuccess(toast, t("Squeeze.Login.LogoutSuccessful"))
					store.commit("setLogin", false);
					ClientManager.getInstance().clearPHPSessionCookie();
					ClientManager.getInstance().resetKeyCloakTokens();
				})
				.catch((err) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.statusText);
				})
				.finally(() => {
					store.dispatch("resetStore");
					emit('userLoggedOut');
					router.push({name: "Login", params: { lang: activeLanguage}})
				});
		}

		/**
		 * Opens the Password Dialog
		 */
		const openResetPasswordDialog = () => {
			showDialog.value = true;
		}

		/** Sends a mail to reset the password to a specific user */
		const resetPassword = (password: string) => {
			loadingDialog.value = true;
			userApi.resetPasswordByUser(store.state.user!.id!, password).then(() => {
				ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Login.ResetSuccess'));
				showDialog.value = false;
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
			})
		}

		/** Click on close button in Setting-Dialog */
		const clickSettingDialog = (closeSettingDialog: boolean) => {
			showSettingDialog.value = closeSettingDialog;
		}

		/** Triggered the click on upload button */
		const openUploadDialog = () => {
			showUploadDialog.value = true;
			emit('isUploadDialogShown', true);
		}

		return {
			opened, op, endLinks, accessibleLinks, loggedInUserInitials, selectedLanguage, languages, showDialog, showSettingDialog, showNewsSidebar, showUploadDialog, loadingDialog, customActions, customActionsMenu, store, SupportedLangs,
			openResetPasswordDialog, resetPassword, onCollapseSidebar, toggleUserInfo, changeLanguage, logout, clickSettingDialog, openUploadDialog, toggleCustomActions,
		}
	},
});
