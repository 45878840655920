import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-input-filled p-formgrid p-grid p-pb-2 p-px-2" }
const _hoisted_2 = { class: "p-col-12" }
const _hoisted_3 = { class: "p-field p-col-12" }
const _hoisted_4 = { for: "password" }
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = { for: "passwordRepeat" }
const _hoisted_7 = { class: "p-col-12 p-mt-2" }
const _hoisted_8 = {
  key: 0,
  class: "p-col-12"
}
const _hoisted_9 = {
  key: 1,
  class: "p-col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Password = _resolveComponent("Password")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.Login.ResetPasswordInfoText')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('Squeeze.Login.Password')), 1),
      _createVNode(_component_Password, {
        id: "password",
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
        modelModifiers: { trim: true },
        required: "true",
        onChange: _ctx.onChangeField,
        feedback: false,
        onKeydown: _ctx.onKeyDown,
        toggleMask: "",
        autofocus: ""
      }, null, 8, ["modelValue", "onChange", "onKeydown"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.Login.PasswordRepeat')), 1),
      _createVNode(_component_Password, {
        id: "passwordRepeat",
        modelValue: _ctx.passwordRepeat,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passwordRepeat) = $event)),
        modelModifiers: { trim: true },
        required: "true",
        feedback: false,
        onChange: _ctx.onChangeField,
        onKeydown: _ctx.onKeyDown,
        toggleMask: ""
      }, null, 8, ["modelValue", "onChange", "onKeydown"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Button, {
        loading: _ctx.loading,
        label: _ctx.$t('Squeeze.Login.Send'),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickSend()))
      }, null, 8, ["loading", "label"])
    ]),
    (_ctx.showErrorMandatory)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_Message, {
            severity: "error",
            closable: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Login.MandatoryPasswords')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.showErrorEqual)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_Message, {
            severity: "error",
            closable: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Login.PasswordsMustBeEqual')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}