import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-107b3eb3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }
const _hoisted_3 = { class: "column-cell" }
const _hoisted_4 = {
  key: 0,
  class: "column-cell"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "column-cell"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "column-cell" }
const _hoisted_9 = { class: "column-cell" }
const _hoisted_10 = { class: "column-cell" }
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.mails,
    loading: _ctx.loading,
    class: "p-datatable-sm",
    autoLayout: true,
    columnResizeMode: "fit",
    onRowDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditDialog($event.data))),
    paginator: true,
    rows: _ctx.tableListPagination.pageSize,
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
    scrollable: true,
    scrollHeight: "flex",
    rowHover: true,
    onPage: _ctx.onChangePage
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.Imports.EmailAccounts') })), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button",
        onClick: _ctx.onNewEntryClick
      }, null, 8, ["label", "onClick"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "documentClassId",
        header: _ctx.$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.DocumentClass'),
        style: {"min-width":"12rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.getDescriptionFromDocumentClass(slotProps.data.documentClassId)), 1)
          ])), [
            [
              _directive_tooltip,
              _ctx.getDescriptionFromDocumentClass(slotProps.data.documentClassId),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "server",
        header: _ctx.$t('Squeeze.Imports.Server'),
        style: {"min-width":"12rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(slotProps.data.server), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.server,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "port",
        header: _ctx.$t('Squeeze.Imports.Port'),
        style: {"min-width":"5rem"}
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "protocol",
        header: _ctx.$t('Squeeze.Imports.Protocol'),
        style: {"min-width":"5rem"}
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "encryption",
        header: _ctx.$t('Squeeze.Imports.Encryption'),
        style: {"min-width":"10rem"}
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "checkCertificate",
        header: _ctx.$t('Squeeze.Imports.CheckCert'),
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(_ctx.$t("Squeeze.General.Boolean." + slotProps.data.checkCertificate)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "user",
        header: _ctx.$t('Squeeze.Login.UserName'),
        style: {"min-width":"12rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(slotProps.data.user), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.user,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "password",
        header: _ctx.$t('Squeeze.Login.Password'),
        style: {"min-width":"7rem"}
      }, {
        body: _withCtx(() => [
          _createTextVNode(" ***** ")
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "clientId",
        header: "Client ID",
        style: {"min-width":"7rem"}
      }, {
        body: _withCtx((slotProps) => [
          (slotProps.data.clientId)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(slotProps.data.clientId), 1)
              ])), [
                [
                  _directive_tooltip,
                  slotProps.data.clientId,
                  void 0,
                  { bottom: true }
                ]
              ])
            : (_openBlock(), _createElementBlock("div", _hoisted_5, " - "))
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "tenantId",
        header: "Tenant ID",
        style: {"min-width":"7rem"}
      }, {
        body: _withCtx((slotProps) => [
          (slotProps.data.tenantId)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createTextVNode(_toDisplayString(slotProps.data.tenantId), 1)
              ])), [
                [
                  _directive_tooltip,
                  slotProps.data.tenantId,
                  void 0,
                  { bottom: true }
                ]
              ])
            : (_openBlock(), _createElementBlock("div", _hoisted_7, " - "))
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "inboxFolder",
        header: _ctx.$t('Squeeze.Imports.Inbox'),
        style: {"min-width":"7rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createTextVNode(_toDisplayString(slotProps.data.inboxFolder), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.inboxFolder,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "validFolder",
        header: _ctx.$t('Squeeze.Imports.Valid'),
        style: {"min-width":"7rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createTextVNode(_toDisplayString(slotProps.data.validFolder), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.validFolder,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "errorFolder",
        header: _ctx.$t('Squeeze.General.Error'),
        style: {"min-width":"7rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createTextVNode(_toDisplayString(slotProps.data.errorFolder), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.errorFolder,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      (_ctx.showAuthenticateButtonColumn)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            class: "buttonColumn",
            style: {"min-width":"3rem","max-width":"3rem"}
          }, {
            body: _withCtx((mail) => [
              (mail.data.protocol === 'microsoft_delegated')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.createUrl(mail.data.id),
                    target: "_self"
                  }, [
                    _withDirectives(_createVNode(_component_Button, {
                      icon: "mdi mdi-card-account-mail-outline",
                      class: "p-button-lg p-button-rounded p-button-text"
                    }, null, 512), [
                      [_directive_tooltip, _ctx.$t('Squeeze.Imports.StartAuthentication')]
                    ])
                  ], 8, _hoisted_11))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Column, {
        class: "buttonColumn",
        style: {"min-width":"13rem","max-width":"13rem"}
      }, {
        body: _withCtx((mail) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-email-check-outline",
            class: "p-button-lg p-button-rounded p-button-text",
            onClick: ($event: any) => (_ctx.testMailConnection(mail.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Imports.TestMailConnection')]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-email-sync-outline",
            class: "p-button-lg p-button-rounded p-button-text",
            onClick: ($event: any) => (_ctx.fetchMails(mail.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Imports.FetchMails')]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.openEditDialog(mail.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(mail.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "currentPageReportTemplate", "onPage"]))
}