import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4060147b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-col-12 button-container p-text-right" }
const _hoisted_3 = {
  style: {"height":"60vh"},
  class: "p-col-12 p-shadow-2"
}
const _hoisted_4 = { class: "p-col-6 button-container p-text-left p-mt-2" }
const _hoisted_5 = { class: "p-col-6 button-container p-text-right p-mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Tree = _resolveComponent("Tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "p-mr-2",
        label: _ctx.$t('System-Administration.General.UnselectAll'),
        type: "button",
        icon: "mdi mdi-close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.unselectedAll()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('System-Administration.General.SelectAll'),
        type: "button",
        icon: "mdi mdi-check-all",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedAll()))
      }, null, 8, ["label"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Tree, {
        value: _ctx.templates,
        expandedKeys: _ctx.expandedKeys,
        loading: _ctx.loading,
        scrollHeight: "flex"
      }, {
        default: _withCtx((slotProps) => [
          _createElementVNode("b", null, _toDisplayString(slotProps.node.label), 1),
          _createVNode(_component_Checkbox, {
            modelValue: slotProps.node.checked,
            "onUpdate:modelValue": ($event: any) => ((slotProps.node.checked) = $event),
            value: slotProps.node.key,
            onChange: ($event: any) => (_ctx.update($event, slotProps.node.key)),
            class: "template-checkbox",
            binary: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value", "onChange"])
        ]),
        child: _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString(slotProps.node.label), 1),
          _createVNode(_component_Checkbox, {
            modelValue: slotProps.node.checked,
            "onUpdate:modelValue": ($event: any) => ((slotProps.node.checked) = $event),
            value: slotProps.node.key,
            onChange: ($event: any) => (_ctx.update($event, slotProps.node.key)),
            class: "child-checkbox",
            binary: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value", "onChange"])
        ]),
        _: 1
      }, 8, ["value", "expandedKeys", "loading"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Button, {
        icon: "mdi mdi-arrow-left",
        class: "p-button-rounded p-button-outlined",
        onClick: _ctx.goToPreviousStep
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Button, {
        icon: "mdi mdi-arrow-right",
        class: "p-button-rounded p-button-outlined",
        onClick: _ctx.goToNextStep
      }, null, 8, ["onClick"])
    ])
  ]))
}