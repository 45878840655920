
import {defineComponent, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {RoleFilter} from "@dex/squeeze-client-ts";
import FilterListView from "@/apps/administration/views/squeeze/roles/tabs/FilterListView.vue";
import RoleFieldConditionsView from "@/apps/administration/views/squeeze/roles/tabs/RoleFieldConditionsView.vue";

export default defineComponent({
	name: "RulesForRole",
	components: {
		FilterListView, RoleFieldConditionsView,
	},
	props: {
		roleId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: ['onLocatorChange'],
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const filterId = ref<number|undefined>(undefined);
		const documentClassId = ref<number|undefined>(undefined);

		/** Triggered when a value list value is chosen */
		const onChangeValue = (value: RoleFilter) => {
			filterId.value = value.id;
			documentClassId.value = value.documentClassId;
		}

		return {t, toast, filterId, documentClassId,
			onChangeValue};
	},
});
