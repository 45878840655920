import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c2b68c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.apiKeys,
    loading: _ctx.loading,
    class: "p-datatable-sm",
    lazy: true,
    scrollable: true,
    scrollHeight: "flex"
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.UserManagement.ApiKey') })), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button",
        onClick: _ctx.onNewEntryClick
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "name",
        header: _ctx.$t('Squeeze.UserManagement.Name'),
        style: {"min-width":"15rem","max-width":"15rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(slotProps.data.name), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.name,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "value",
        header: _ctx.$t('Squeeze.UserManagement.ApiKey'),
        style: {"min-width":"20rem"}
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        class: "buttonColumn",
        style: {"min-width":"7rem","max-width":"7rem"}
      }, {
        body: _withCtx((users) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-content-copy",
            class: "p-button p-button-icon-only p-button-lg p-button-rounded p-button-text",
            onClick: ($event: any) => (_ctx.onClickCopy(users.data.value))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Forms.Hints.CopyClipboard')]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(users.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading"]))
}