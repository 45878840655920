
import { defineComponent } from 'vue';

const squeezeSteps = new Map([
	["InitProcess", "mdi mdi-file-outline"],
	["ImageOptimization", "mdi mdi-file-image-outline"],
	["BarcodeExtraction", "mdi mdi-barcode-scan"],
	["OCR", "mdi mdi-file-eye-outline"],
	["Classification", "mdi mdi-file-tree-outline"],
	["Extraction", "mdi mdi-file-document-outline"],
	["Validation", "mdi mdi-file-check-outline"],
	["Export", "mdi mdi-file-move-outline"],
	["Backup", "mdi mdi-archive-outline"],
	["Delete", "mdi mdi-trash-can-outline"],
]);

export function getSqueezeStepIconMap() {
	return squeezeSteps;
}

export default defineComponent({
	name: 'Squeeze',
	setup() {
		return {}
	},
});
