import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_DocumentClassTableColumnsSubView = _resolveComponent("DocumentClassTableColumnsSubView")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_TableForm = _resolveComponent("TableForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BlockUI, { blocked: _ctx.loading }, {
      default: _withCtx(() => [
        _createVNode(_component_TabView, {
          activeIndex: _ctx.activeTabIndex,
          "onUpdate:activeIndex": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTabIndex) = $event)),
          onTabClick: _ctx.onTabClick
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documentClassTables, (table) => {
              return (_openBlock(), _createBlock(_component_TabPanel, {
                key: table.id
              }, {
                header: _withCtx(() => [
                  _createTextVNode(_toDisplayString(table.description) + "  ", 1),
                  _withDirectives(_createVNode(_component_Button, {
                    onClick: ($event: any) => (_ctx.openCurrentTableDialog(table)),
                    icon: "mdi mdi-pencil-outline",
                    class: "p-button-rounded p-button-plain p-button-text reduceHeight"
                  }, null, 8, ["onClick"]), [
                    [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
                  ]),
                  _withDirectives(_createVNode(_component_Button, {
                    onClick: ($event: any) => (_ctx.openDialogDeleteTable(table)),
                    icon: "mdi mdi-delete-outline",
                    class: "p-button-rounded p-button-plain p-button-text reduceHeight"
                  }, null, 8, ["onClick"]), [
                    [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_DocumentClassTableColumnsSubView, {
                    documentClassTables: _ctx.documentClassTables,
                    columns: table.columns,
                    locators: _ctx.locators,
                    tableId: table.id,
                    documentClassId: _ctx.documentClassId,
                    onGetDocumentClassTables: _cache[0] || (_cache[0] = (showLoadingAnimation) => { _ctx.getDocumentClassTables(_ctx.documentClassId, showLoadingAnimation) })
                  }, null, 8, ["documentClassTables", "columns", "locators", "tableId", "documentClassId"])
                ]),
                _: 2
              }, 1024))
            }), 128)),
            (_ctx.documentClassTables)
              ? (_openBlock(), _createBlock(_component_TabPanel, { key: 0 }, {
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.DocumentClasses.NewTable')) + "  ", 1),
                    _createVNode(_component_Button, {
                      icon: "mdi mdi-plus",
                      class: "p-button-rounded p-button-plain p-button-text reduceHeight"
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["activeIndex", "onTabClick"])
      ]),
      _: 1
    }, 8, ["blocked"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showCurrentTableDialog,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCurrentTableDialog = false, _ctx.showErrorMessage= false, _ctx.isInvalid = true)),
      onOnConfirm: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveTable(true))),
      loading: _ctx.loadingSaveTable,
      message: _ctx.message,
      messageType: _ctx.messageType,
      headerText: _ctx.headerText
    }, {
      content: _withCtx(() => [
        _createVNode(_component_TableForm, {
          fieldEntry: _ctx.documentClassTableEntry,
          locators: _ctx.getLineLocators(_ctx.locators),
          tableField: true,
          showErrorMessage: _ctx.showErrorMessage,
          tablesWithLocators: _ctx.tablesWithLocators,
          allDocumentClasses: _ctx.allDocumentClasses,
          activeTableId: _ctx.activeTableId,
          documentTables: _ctx.documentTables,
          onUpdate: _ctx.onUpdateTable
        }, null, 8, ["fieldEntry", "locators", "showErrorMessage", "tablesWithLocators", "allDocumentClasses", "activeTableId", "documentTables", "onUpdate"])
      ]),
      _: 1
    }, 8, ["show", "loading", "message", "messageType", "headerText"]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.showDialogDeleteTable,
      onOnClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDialogDeleteTable = false)),
      onOnConfirm: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteTable(_ctx.documentClassTableEntry)))
    }, null, 8, ["showDialog"])
  ]))
}