import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.migrations,
    loading: _ctx.loading,
    paginator: true,
    rows: _ctx.tableListPagination.pageSize,
    class: "p-datatable-sm",
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
    autoLayout: true,
    columnResizeMode: "fit",
    scrollable: true,
    scrollHeight: "flex",
    onPage: _ctx.onChangePage
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.System.Migrations') })), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        type: "button",
        label: _ctx.$t('Squeeze.System.RunMigration'),
        icon: "mdi mdi-refresh",
        onClick: _ctx.openEntryDialog,
        disabled: _ctx.loading
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "status",
        header: _ctx.$t('Administration.Migrations.Status'),
        style: {"min-width":"4rem","max-width":"4rem"}
      }, {
        body: _withCtx((migration) => [
          _createElementVNode("div", null, [
            _createElementVNode("i", {
              class: _normalizeClass(migration.data.up ? 'mdi mdi-check' : 'mdi mdi-close')
            }, null, 2)
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "raw",
        header: _ctx.$t('Administration.Migrations.Migration')
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", null, _toDisplayString(slotProps.data.raw), 1)
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "currentPageReportTemplate", "onPage"]))
}