import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51b4fbbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-component" }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = {
  key: 0,
  style: {"width":"100%","background-color":"var(--dex-primary-color)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QueueNavigationCard = _resolveComponent("QueueNavigationCard")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queueDocClasses, (queueDocClass, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "p-col-12 p-md-6 p-lg-4 p-xl-3",
                key: queueDocClass.documentClass.id
              }, [
                _createVNode(_component_QueueNavigationCard, {
                  className: queueDocClass.documentClass.description,
                  currentCount: queueDocClass.count,
                  errorCount: queueDocClass.errorCount,
                  onClick: ($event: any) => (_ctx.showQueueList($event, queueDocClass.documentClass.id, index)),
                  "aria-haspopup": "true",
                  "aria-controls": 'overlay_panel_' + index
                }, null, 8, ["className", "currentCount", "errorCount", "onClick", "aria-controls"]),
                (_ctx.store.state.featureSet.savedDocumentSearches && _ctx.customTiles.filter(tileFilter => tileFilter.documentClassId === queueDocClass.documentClass.id).length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_Dropdown, {
                        class: "queueDropDown",
                        id: 'allSearches_' + index,
                        modelValue: _ctx.op[index],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.op[index]) = $event),
                        options: _ctx.filterForDocumentClass(queueDocClass.documentClass.id),
                        dataKey: "id",
                        optionLabel: "description",
                        optionGroupLabel: "label",
                        optionGroupChildren: "items",
                        filter: _ctx.customTiles.filter(tileFilter => tileFilter.documentClassId === queueDocClass.documentClass.id || tileFilter.id === -1).length > 8,
                        placeholder: _ctx.$t('Squeeze.Queue.General.ShowAll'),
                        onChange: ($event: any) => (_ctx.showQueueList($event, queueDocClass.documentClass.id, index)),
                        onShow: _ctx.onShowDropdown
                      }, {
                        optiongroup: _withCtx((slotProps) => [
                          _createElementVNode("span", null, [
                            _createElementVNode("i", {
                              class: _normalizeClass(slotProps.option.icon)
                            }, null, 2),
                            _createTextVNode(" " + _toDisplayString(slotProps.option.label), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["id", "modelValue", "onUpdate:modelValue", "options", "filter", "placeholder", "onChange", "onShow"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (!_ctx.isValidation)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.customTiles, (tile, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "p-col-12 p-md-6 p-lg-4 p-xl-3",
                    key: tile.id
                  }, [
                    _createVNode(_component_QueueNavigationCard, {
                      className: tile.description,
                      docClassId: tile.documentClassId,
                      searchRequestJson: tile.searchRequestJson,
                      onClick: ($event: any) => (_ctx.showQueueEntry(tile.documentClassId, tile.searchRequestJson)),
                      "aria-haspopup": "true",
                      "aria-controls": 'overlay_panel_' + index
                    }, null, 8, ["className", "docClassId", "searchRequestJson", "onClick", "aria-controls"])
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(8, (idx) => {
            return _createElementVNode("div", {
              class: "p-col-12 p-md-6 p-lg-4 p-xl-3",
              key: idx
            }, [
              _createVNode(_component_Skeleton, {
                height: "5.9rem",
                borderRadius: "1rem"
              })
            ])
          }), 64))
    ])
  ]))
}