import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a67b6de6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-col-12 p-grid p-mr-0 p-ml-0 p-pb-0 p-pt-3" }
const _hoisted_2 = { class: "p-col-8 p-pl-0" }
const _hoisted_3 = { class: "headline p-component p-m-0" }
const _hoisted_4 = { class: "p-col-4 p-as-center p-grid p-jc-end" }
const _hoisted_5 = { class: "p-col-12 p-grid nested-grid p-m-0" }
const _hoisted_6 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled p-col-12" }
const _hoisted_7 = { class: "p-field p-col-12" }
const _hoisted_8 = { for: "email" }
const _hoisted_9 = { class: "p-inputgroup" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.UserSettings.Account.UserAccount')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          class: "p-button p-button-rounded p-button-text",
          icon: "mdi mdi-close",
          onClick: _ctx.clickSettingDialog
        }, null, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('Squeeze.UserSettings.Account.ChangeEmail')), 1),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_InputText, {
              id: "email",
              placeholder: _ctx.store.state.user.email,
              modelValue: _ctx.value.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.email) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.email.$invalid && _ctx.showErrorMessage}),
              onChange: _ctx.onChangeEmail,
              autofocus: "",
              maxLength: "255",
              disabled: _ctx.loading,
              style: {"text-transform":"lowercase"}
            }, null, 8, ["placeholder", "modelValue", "class", "onChange", "disabled"]),
            _withDirectives(_createVNode(_component_Button, {
              icon: "mdi mdi-content-save-outline",
              class: "p-button",
              onClick: _ctx.saveNewEmail,
              disabled: _ctx.loading
            }, null, 8, ["onClick", "disabled"]), [
              [
                _directive_tooltip,
                _ctx.$t('Squeeze.General.Save'),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          (_ctx.showErrorMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_10, [
                (_ctx.v$.email.email.$invalid)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('Forms.Val.Email')), 1)
                    ], 64))
                  : (_ctx.v$.email.$invalid)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.UserManagement.Email')})), 1)
                      ], 64))
                    : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}