import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52dd466f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label-node" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Handle = _resolveComponent("Handle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Handle, {
      type: "target",
      position: _ctx.Position.Left,
      connectable: false,
      style: {"opacity":"0"}
    }, null, 8, ["position"]),
    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
  ]))
}