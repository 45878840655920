import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bdb1786"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguagesList = _resolveComponent("LanguagesList")!
  const _component_LanguageForm = _resolveComponent("LanguageForm")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LanguagesList, {
        loading: _ctx.loading,
        allLanguages: _ctx.allLanguages,
        onOnChangeActiveStatus: _ctx.onChangeActiveStatus,
        onOnEntrySelect: _ctx.onEntrySelect
      }, null, 8, ["loading", "allLanguages", "onOnChangeActiveStatus", "onOnEntrySelect"])
    ]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showEditDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditDialog = false, _ctx.isValidationInvalid = true, _ctx.showValidationMessage = false)),
      onOnConfirm: _ctx.saveLanguage,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText,
      showKeepDialogOpen: false
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: _ctx.loadingDialog }, {
          default: _withCtx(() => [
            _createVNode(_component_LanguageForm, {
              language: _ctx.language,
              showErrorMessage: _ctx.showValidationMessage,
              onUpdate: _ctx.onUpdate
            }, null, 8, ["language", "showErrorMessage", "onUpdate"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText"])
  ], 64))
}