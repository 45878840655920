
/* eslint max-lines: off */
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import {DEFAULT_LOCALE} from "@/lang";
import { i18n } from "@/main";
import {DateTimeOptions} from "@/util/DateTimeOptions";
import {ListDocument} from "@/apps/squeeze/interfaces/ListDocument";
import {DocumentFilterObject, DocumentSortObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {ExportStatus} from "@dex/squeeze-client-ts/dist/api";
import Tooltip from "primevue/tooltip";
import {PaginationDto, QueueEntry} from "@dex/squeeze-client-ts";
import {useSqueezeStore} from "@/apps/squeeze/store";
import { MenuItem } from '@/shims-prime-vue';
import {useConfirm} from "primevue/useconfirm";
import {computed, defineComponent, onMounted, PropType, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import { getSqueezeStepIconMap } from "@/apps/squeeze/App.vue";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import Menu from "primevue/menu";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from "primevue/blockui";
import QueueDeleteForm from "@/apps/administration/components/queue/QueueDeleteForm.vue";
import HelpFunctionalityList from "@/apps/squeeze/components/HelpFunctionalityList.vue";
import OverlayPanel from "primevue/overlaypanel";
import {FieldColumn} from "@/apps/squeeze/interfaces/FieldColumn";

/**
 * @description Uses Elastic Search API endpoint instead of Queue API endpoint
 */
export default defineComponent({
	name: "DocumentList",
	components: {
		OverlayPanel,
		DataTable,
		Column,
		IconField,
		InputIcon,
		InputText,
		Dropdown,
		Menu,
		EntryDialog,
		BlockUI,
		QueueDeleteForm,
		HelpFunctionalityList,
	},
	props: {
		documentClassId: {
			type: Number,
			required: true,
		},
		documentClassName: {
			type: String,
			required: true,
		},
		documents: {
			type: Array as PropType<ListDocument[]>,
			default: () => [],
		},
		fieldColumns: {
			type: Array as PropType<FieldColumn[]>,
			default: () => [],
		},
		loaded: {
			type: Boolean,
			default: false,
		},
		paginationInfo: {
			type: Object as PropType<PaginationDto>,
		},
		filterOptions: {
			type: Object as PropType<DocumentFilterObject>,
		},
		fullText: {
			type: String,
			default: '',
		},
		showSelectSearch: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onRowSelect", "onReload", "onPage", "onFilter", "onSort", "update:fullText", "openSaveSearch", "openSelectSearch", "clearSearch"],
	setup(props, { emit }) {
		const {t} = useI18n();
		const store = useSqueezeStore();
		const toast = useToast();
		const confirm = useConfirm();

		const documentList = ref<any>();

		/** Filters of list */
		const filters = ref<DocumentFilterObject>(props.filterOptions!);

		/** QueueStates for filter selection */
		const statuses =  ref<string[]>([
			'INITIAL', 'WORK', 'WAITING', 'ERROR', 'SUSPEND', 'CLARIFICATION', 'FINISHED',
		]);

		/** QueueStates for filter selection */
		const steps =  ref<string[]>([
			'Backup', 'Delete', 'SplitDocument', 'Validation',
		]);

		/** List of allowed filters */
		const allowedFilters = computed(() => {
			return [{value: FilterMatchMode.EQUALS, label: t('Squeeze.Queue.Filter.Options.EQUALS')},
				{value: FilterMatchMode.CONTAINS, label: t('Squeeze.Queue.Filter.Options.CONTAINS')},
				{value: FilterMatchMode.STARTS_WITH, label: t('Squeeze.Queue.Filter.Options.STARTS_WITH')},
				{value: FilterMatchMode.ENDS_WITH, label: t('Squeeze.Queue.Filter.Options.ENDS_WITH')}]
		})

		/** Currently-Selected row */
		const selection = ref([]);

		/** Current locale */
		const lang = ref(DEFAULT_LOCALE);

		/** Requeue steps to choose in menu */
		const queueEntrySteps = ref<MenuItem[]>([]);

		/** Queue API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Should the table be locked? */
		const lockTable = ref<boolean>(false);

		/** Ref for menu */
		const multiEntryMenu = ref(null);

		/** Is Delete Document Dialog visible? */
		const showDelete = ref<boolean>(false);

		/** Comment for delete of document */
		const comment = ref<string>('');

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Is the Form of the migrations invalid? */
		const isValidationInvalid = ref(true);

		/** Text (delete) of the header in Entry-Dialog */
		const deleteHeaderText = computed(() => {
			if (selection.value.length > 1) {
				return t('Squeeze.Queue.General.DeleteDocuments', { count: selection.value.length});
			}
			return t('Squeeze.Queue.General.DeleteDocument');
		});

		/** Is the Documentation Dialog visible? */
		const showDocumentationDialog = ref<boolean>(false);

		/** Current filter field name*/
		const currentFilterFieldName = ref<string>('');

		/** Current filter field type */
		const currentFilterFieldType = ref<string>('');

		/** Component of the overlay panel for the documentation of all functionalities */
		const overlayFunctionalityList = ref<any>();

		/**
		 * Toggle the overlay panel
		 * Is the overlay panel shown?
		 * @param event
		 */
		const toggleOverlay = (event: any) => {
			overlayFunctionalityList.value.toggle(event);
		}

		/**
		 * Triggered if the filter field help icon is clicked
		 * @param {string} columnName
		 * @param {string} columnType
		 */
		const onClickFilterFieldHelpIcon = (columnName: string, columnType: string) => {
			currentFilterFieldName.value = columnName;
			currentFilterFieldType.value = columnType;
			showDocumentationDialog.value = true;
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: { comment: string }, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			comment.value = data.comment;
		}

		const onFilter = (tableFilters: DocumentFilterObject) => {
			emit("onFilter", tableFilters);
		}

		/**
		 * Triggered when a row is clicked
		 * @param event
		 */
		const onRowClick = (event: { originalEvent: MouseEvent; data: QueueEntry; index: number }) => {
			/* Prevent the row-click in the selection-column with the checkbox
			* Use the selectionMode 'multiple' only at the respective column and not at the table, when you also use the row-click event
			* If the selectionMode is set on the table and the row click event is active, these two events overwrite each other
			*/
			if (event.originalEvent
				&& !(event.originalEvent.target as HTMLElement).matches('.p-selection-column')
				&& !(event.originalEvent.target as HTMLElement).matches('.p-checkbox *')) {
				emit("onRowSelect", event.data);
			}
			return;
		}

		/** Triggered when the next page is selected */
		const onPage = (event: any) => {
			emit("onPage", event)
		}

		/** Triggered on sort a column */
		const onSort = (event: { multiSortMeta: DocumentSortObject[] }) => {
			emit("onSort", event.multiSortMeta)
		}

		/** Triggered when table content should be reloaded */
		const onReload = () => {
			emit("onReload");
		}

		/** Opens the dialog for saving/deleting searches */
		const showSaveSearch = () => {
			documentList.value.onFilterApply();
			emit("openSaveSearch");
		}

		/** Opens the Search Selection */
		const openSelectSearch = () => {
			emit("openSelectSearch");
		}

		/** Clears the current search */
		const clearSearch = () => {
			emit("clearSearch");
		}

		/** Formats date string to desired locale string */
		const formatDate = (dateToFormat: string): string => {
			if(dateToFormat && dateToFormat.length) {
				try {
					const dateNumber = Date.parse(dateToFormat);

					if(!isNaN(dateNumber)) {
						const date = new Date(dateToFormat);
						const options: DateTimeOptions = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
						lang.value = i18n.global.locale;
						return date.toLocaleDateString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), options);
					}
				} catch (err: any) {
					return dateToFormat;
				}
			}
			return dateToFormat;
		}

		/** Formats an amount to locale string (Formats are only defined in the backend) */
		const formatAmounts = (value: string) => {
			value = value.replace(/[^0-9.,-]/g, "");

			if (value.indexOf(",") != -1) {
				value = value.replaceAll(".", "").replace(",", ".");
			}

			if (value.length > 0) {
				return parseFloat(value).toLocaleString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), {minimumFractionDigits: 2});
			} else {
				return "0,00";
			}
		}

		/** Formats given values to data type specific formats */
		const formatValue = (value: string, dataType: string) => {
			if (!value) {
				return "";
			}

			switch(dataType.toLowerCase()) {
			case "amount": return formatAmounts(value);
			//case "date": return formatDate(value);
			default: return value;
			}
		}

		/**
		 * Creates the Tooltip for Exports
		 * @param exports
		 */
		const getExportsForToolTip = (exports: ExportStatus[]) => {
			let toolTip = "";
			exports.forEach(exportData => {
				toolTip += exportData.interfaceName + ":\n " + t("Squeeze.Queue.States." + exportData.status) + "\n " + formatDate(exportData.timeStamp!) + "\n" + exportData.result + "\n\n";
			})

			return toolTip;
		}

		/**
		 * Opens multi requeue menu
		 * @param event
		 */
		const openMultiRequeueMenu = (event: any) => {
			if (!selection.value.length) {
				return;
			}
			const multiMenu = multiEntryMenu.value as any;
			if(multiMenu) {
				multiMenu.toggle(event);
			}
		}

		/**
		 * Requeue the selected entries
		 * @param step
		 * @param comment
		 */
		const requeueSelectedEntries = (step: string, comment?: string) => {
			if (!selection.value || !selection.value.length) {
				return;
			}

			// delete dialog
			if (isValidationInvalid.value && step === 'Delete') {
				showValidationMessage.value = true;
				return;
			}

			lockTable.value = true;
			selection.value.reduce((chain, { id }) => {
				if (id == undefined) {
					return chain;
				}
				return chain.finally(() => documentApi.requeueDocument(id, step, undefined, comment ?? "").catch(() => { /* swallow errors */ }))
			}, Promise.resolve()).finally(() => {
				ToastManager.showSuccess(toast, t('Squeeze.Queue.Requeue.Success'), t('Squeeze.Queue.Steps.' + step));
				selection.value = [];
				lockTable.value = false;
				showDelete.value = false;
				onReload();
			});
		}

		watch(props.filterOptions!, () => {
			Object.assign(filters.value, props.filterOptions);
		})

		onMounted(() => {
			queueEntrySteps.value = [{
				label: t('Squeeze.Queue.General.Steps'),
				items: [],
			}];
			getSqueezeStepIconMap().forEach((icon: string, step: string) => {
				if (step === 'Delete') {
					queueEntrySteps.value[0].items.push({
						label: t('Squeeze.Queue.Steps.' + step),
						icon: icon,
						command: () => {
							showDelete.value = true;
						},
					})
				} else {
					queueEntrySteps.value[0].items.push({
						label: t('Squeeze.Queue.Steps.' + step),
						icon: icon,
						command: (ev: { item: any; originalEvent: MouseEvent }) => {
							let messageText = '';
							let acceptFn = null;

							if ((ev.originalEvent.target as HTMLElement).matches('#multi_entry_menu *')) {
								// If entry in multi_menu is clicked (Requeue-Button under table for multi-selection)
								messageText = t('Squeeze.Queue.Requeue.ForwardEntries');
								acceptFn = () => {
									requeueSelectedEntries(step);
								};
							}
							else {
								return;
							}

							confirm.require({
								message: messageText + ": " + t('Squeeze.Queue.Steps.' + step) + " ?",
								header: t('Squeeze.General.Confirmation'),
								icon: 'pi pi-exclamation-triangle',
								accept: acceptFn,
								reject: () => { /**/ },
							});
						},
					});
				}
			});
		})

		return {
			filters, allowedFilters, steps, statuses, store, queueEntrySteps, selection, multiEntryMenu,
			lockTable, showDelete, comment, showValidationMessage, isValidationInvalid, deleteHeaderText,
			showDocumentationDialog, currentFilterFieldName, currentFilterFieldType, overlayFunctionalityList, toggleOverlay, onClickFilterFieldHelpIcon,
			onReload, onSort, onPage, onRowClick, onFilter, formatDate, emit, onUpdate, requeueSelectedEntries,
			openMultiRequeueMenu, getExportsForToolTip, formatValue, showSaveSearch, openSelectSearch, clearSearch, documentList,
		}
	},
});
