
import {defineComponent, onMounted, PropType, ref} from 'vue';
import { useI18n } from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useSqueezeStore from "@/apps/squeeze/store";
import {getSqueezeStepIconMap} from "@/apps/squeeze/App.vue";
import {DocumentClass, QueueStepDto} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import Chip from "primevue/chip";
import router from "@/router";

export default defineComponent({
	name: "QueueTimelineElement",
	components: {
		Chip,
	},
	props: {
		step: {
			type: Object as PropType<QueueStepDto>,
			required: true,
		},
		allDocumentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: () => [],
		},
	},
	emits: ['onClickQueueStep'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Store point */
		const store = useSqueezeStore();

		/** Set QueueStep icon */
		const icon = ref<string>("mdi mdi-spin mdi-loading");

		/**
		 * Click on queue step
		 * @param {string} stepName
		 * @param {string} status
		 */
		const onClickQueueStep = (stepName: string, status: string) => {
			// check if the stepName is clickable
			if(!store.state.scopes.sqzRequeue && route.name !== 'QueueEntryView' && stepName !== 'Validation') {
				return
			}

			if(!store.state.scopes.sqzRequeue && route.name === 'QueueView' && stepName === 'Validation') {
				return
			}

			// check step errorCount or count
			if (props.step.errorCount >= 1 || props.step.count >= 1) {
				// check route
				if (router.currentRoute.value.fullPath.includes('/app/administration/queue')) {
					router.push({ name: 'QueueEntryView', params: { stepName: stepName, status: status }});
					emit('onClickQueueStep', stepName, status);
				} else if (store.state.scopes.sqzRequeue && stepName !== 'Validation') {
					router.push({ name: 'QueueEntryView', params: { stepName: stepName, status: status }});
				} else {
					if (route.name === "DocumentListValidation" || route.name === "DocumentListValidationWithSearch" && stepName === "Validation") {
						// If you are already in the validation view (document list) when you click on the validation step, then nothing should happen.
						return;
					} else if (props.allDocumentClasses.length > 1) {
						router.push({ name: 'QueueView'});
					} else {
						const documentClassId = props.allDocumentClasses[0].id;
						router.push({name: "DocumentListValidation", params: {documentClassId: (documentClassId as any)}});
					}
				}
			} else {
				ToastManager.showInfo(toast, t('Toasts.Title.Info'), t('Squeeze.Queue.Filter.NoEntries'));
			}
		}

		/** Triggered the mouse leave event on a chip element to remove all visible tooltips */
		const onMouseLeave = () => {
			const tooltips = document.getElementsByClassName("p-tooltip");
			if (tooltips) {
				for (let i = 0; i < tooltips.length; i ++) {
					tooltips[i].remove();
				}
			}
		}

		/** On page mounted */
		onMounted(() =>{
			const stepIcon = getSqueezeStepIconMap().get(props.step.name);
			if (stepIcon) icon.value = stepIcon;
		})

		return {
			t,
			toast,
			route,
			store,
			icon,
			onClickQueueStep,
			onMouseLeave,
		};
	},
});

