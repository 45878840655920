
import {defineComponent, onBeforeUnmount, ref, watch} from 'vue';
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import Checkbox from "primevue/checkbox";

export default defineComponent({
	name: "EntryDialog",
	components: {
		Dialog,
		Message,
		Checkbox,
	},
	props: {
		/** Width of the Edit-Dialog */
		width: {
			type: String,
			default: '40rem',
		},
		/** Prop for Message */
		message: {
			type: String,
			default: '',
		},
		/** Prop for message-type */
		messageType: {
			type: String,
			default: 'success',
		},
		/** Position to show the Dialog (default: top) */
		position: {
			type: String,
			default: "center",
		},
		/** Prop from Show-Dialog */
		show: {
			type: Boolean,
			default: false,
		},
		/** Prop for header-text */
		headerText: {
			type: String,
			default: '',
		},
		/** Should the save-button show loading? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** Should the possibility to keep the dialog open be shown? (default: false) */
		showKeepDialogOpen: {
			type: Boolean,
			default: false,
		},
		showAbortButton: {
			type: Boolean,
			default: true,
		},
		showSaveButton: {
			type: Boolean,
			default: true,
		},
		/** Optional Text for the save button */
		saveButtonText: {
			type: String,
			default: undefined,
		},
		abortButtonText: {
			type: String,
			default: undefined,
		},
		keepDialogOpenText: {
			type: String,
			default: undefined,
		},
		/** Optional maximizable of dialog */
		maximizable: {
			type: Boolean,
			default: false,
		},
		/** Should the keydown event be aborted?
		 * Optional Parameter
		 * Useful when several dialogs are open at the same time
		 */
		abortKeydownEvent: {
			type: Boolean,
			default: false,
		},
		closable: {
			type: Boolean,
			default: true,
		},
	},
	emits: ["onClose", "onConfirm"],
	setup(props, {emit}) {
		/** Save show in local variable, because show is not allowed to me mutated inside. React on change from show from parent */
		const entryDialog = ref<boolean>(props.show);

		/** Should the dialog be kept open? */
		const keepDialogOpen = ref<boolean>(false);

		/** Emit the "onConfirm-Event to parent if "yes" is clicked */
		const saveEntry = () => {
			// Is the option is not shown, this dialog should never be kept open
			if (!props.showKeepDialogOpen) {
				keepDialogOpen.value = false;
			}

			// Only abort the keydown event when more than one dialog is open
			if (props.abortKeydownEvent) {
				return;
			}

			emit("onConfirm", keepDialogOpen.value);
		}

		/** Emit onClose-Event to parent, when "no" or "close" is clicked */
		const closeDialog = () => {
			entryDialog.value = false;
			emit("onClose");
		}

		/**
		 * If key escape, ctrl and S or enter pressed, then close or save the dialog entry
		 * @param event
		 */
		const onKeydown = (event: any) => {
			// Only do something if the dialog is visible
			if (!entryDialog.value) {
				return;
			}

			// by key escape close the dialog, when
			// If more than one dialog was displayed, then check if it does not contain an input field
			// especially for the case during import
			if (event.key === "Escape" && !props.abortKeydownEvent) {
				closeDialog();
			}

			// check if the event parent element is a dropdown element
			const isDropdownElement = event.target.parentElement.classList.contains('p-dropdown');

			// by key STRG + s or enter saved
			if (event.code === "KeyS" && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)
					|| event.key === 'Enter' && !isDropdownElement) {
				event.preventDefault();
				saveEntry();
			}
		}

		/** Is triggered before a Component unmounts. Available since Vue 3.0 */
		onBeforeUnmount(() => {
			document.removeEventListener("keydown", onKeydown);
		})

		watch(() => props.show, () => {
			// Save show in local variable, because show is not allowed to me mutated inside. React on change from show from parent
			entryDialog.value = props.show;

			// not in mounted because mounted is trigger to early
			if (props.show) {
				// Setup keyboard events that are active for the whole view
				document.addEventListener("keydown", onKeydown);
			} else {
				document.removeEventListener("keydown", onKeydown);
			}
		})

		return {
			entryDialog,
			keepDialogOpen,
			saveEntry,
			closeDialog,
			onKeydown,
		};
	},
});

