import {RouteRecordRaw} from "vue-router";
import {defaultSort} from "@/util/SortingHelper";
import StoreListView from "@/apps/freeze/views/StoreListView.vue";
import App from '../App.vue'
import RecordView from "@/apps/freeze/views/RecordView.vue";
import ArchiveSearchView from "@/apps/freeze/views/ArchiveSearchView.vue";

/**
 * Route that goes directly to the Validation-List of a Document-Class
 * @param route
 */
function mapStoreRecords(route: any) {
	return {
		recordId: route.params.recordId ? route.params.recordId : undefined,
		storeId: route.params.storeId,
		searchMaskId: route.params.searchMaskId,
		searchRequest: route.params.searchRequest ? JSON.parse(decodeURIComponent(route.params.searchRequest)) : {},
		tableSortStart: route.params.tableSortStart ? JSON.parse(route.params.tableSortStart) : [defaultSort],
		pagination: route.params.pagination ? JSON.parse(route.params.pagination) : {pageSize: 25,page: 0,total: 0},
	}
}

/**
 * @param pathBase URL path that defines this app.
 */
export function createRoutes(pathBase: string): Array<RouteRecordRaw> {
	return [
		{
			path: pathBase,
			name: "Freeze",
			component: App,
			children: [
				{
					path: "",
					name: "ArchiveSearchView",
					component: ArchiveSearchView,
				},
				{
					path: "store/:storeId/records/:searchMaskId/:searchRequest",
					name: "FreezeRecords",
					component: StoreListView,
					props: mapStoreRecords,
				},
				{
					path: "store/:storeId/record/:recordId",
					name: "FreezeRecord",
					component: RecordView,
					props: mapStoreRecords,
				},
			],
		},
	];
}
