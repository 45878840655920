import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1635d321"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid p-flex-column p-m-0 p-p-4 p-jc-center p-ai-center client-login-background-image" }
const _hoisted_2 = { class: "p-col-12 p-md-6 p-lg-4 p-text-center" }
const _hoisted_3 = { class: "p-component view-title" }
const _hoisted_4 = { class: "p-col-12 p-md-6 p-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SetupForm = _resolveComponent("SetupForm")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('System-Administration.General.TenantSetup')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Panel, { class: "p-shadow-2" }, {
        default: _withCtx(() => [
          _createVNode(_component_SetupForm, {
            clearTenantField: _ctx.clearTenantField,
            onSendAuth: _ctx.sendAuth
          }, null, 8, ["clearTenantField", "onSendAuth"])
        ]),
        _: 1
      })
    ])
  ]))
}