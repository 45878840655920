import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-mb-2 p-p-2" }
const _hoisted_2 = { class: "p-fluid p-formgrid p-grid p-jc-end p-p-2" }
const _hoisted_3 = { class: "p-field p-col-3" }
const _hoisted_4 = {
  key: 0,
  class: "p-field p-col-3"
}
const _hoisted_5 = { class: "p-field p-col-3" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "p-fluid p-formgrid p-grid p-jc-end" }
const _hoisted_9 = { class: "p-field p-col-12" }
const _hoisted_10 = { class: "p-field p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MasterDataTableForm = _resolveComponent("MasterDataTableForm")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BlockUI, { blocked: _ctx.saveLoading }, {
        default: _withCtx(() => [
          _createVNode(_component_MasterDataTableForm, {
            masterDataTable: _ctx.masterDataTable,
            loading: _ctx.loading,
            showErrorMessage: _ctx.showErrorMessage,
            permissions: _ctx.permissions,
            onUpdate: _ctx.onUpdate
          }, null, 8, ["masterDataTable", "loading", "showErrorMessage", "permissions", "onUpdate"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Button, {
                label: _ctx.$t('Squeeze.Validation.Buttons.Delete'),
                type: "button",
                icon: "mdi mdi-delete-outline",
                class: "p-button p-button-danger",
                loading: _ctx.saveLoading,
                onClick: _ctx.openDeleteDialog,
                disabled: _ctx.checkPermissions || _ctx.masterDataTableId === 0
              }, null, 8, ["label", "loading", "onClick", "disabled"])
            ]),
            (_ctx.store.state.isAdminUser)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_ctx.store.state.featureSet.uiConfigExport)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        disabled: _ctx.loading || !_ctx.masterDataTable.id,
                        label: "Export",
                        type: "button",
                        icon: "mdi mdi-database-export",
                        class: "p-button",
                        onClick: _ctx.exportMasterData
                      }, null, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Button, {
                label: _ctx.$t('Squeeze.General.Save'),
                type: "button",
                icon: "mdi mdi-content-save-outline",
                class: "p-button",
                loading: _ctx.saveLoading,
                onClick: _ctx.saveMasterDataTable,
                disabled: _ctx.checkPermissions
              }, null, 8, ["label", "loading", "onClick", "disabled"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["blocked"])
    ]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDialog = false)),
      onOnConfirm: _ctx.deleteEntry
    }, null, 8, ["showDialog", "onOnConfirm"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showExportDialog,
      closable: !_ctx.showProcessBar,
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showExportDialog = false)),
      headerText: "Export",
      showSaveButton: false,
      showAbortButton: !_ctx.showProcessBar,
      abortKeydownEvent: _ctx.showProcessBar,
      abortButtonText: _ctx.$t('Squeeze.General.Abort')
    }, {
      content: _withCtx(() => [
        (_ctx.showProcessBar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_ProgressBar, {
                mode: "indeterminate",
                style: {"height":"6px"}
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.General.SuccessfulExport', { entryName: _ctx.$t('Squeeze.MasterData.MasterDataTable')})), 1),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_Button, {
                    label: _ctx.$t('Squeeze.General.CopyToClipboard'),
                    icon: "mdi mdi-content-copy",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(_ctx.$t, _ctx.$toast, _ctx.allMasterData), _ctx.allMasterData = '', _ctx.showExportDialog = false))
                  }, null, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_Button, {
                    label: _ctx.$t('Squeeze.General.Download'),
                    icon: "mdi mdi-file-download-outline",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.downloadData(_ctx.masterDataTable.name, _ctx.allMasterData), _ctx.allMasterData = '', _ctx.showExportDialog = false))
                  }, null, 8, ["label"])
                ])
              ])
            ]))
      ]),
      _: 1
    }, 8, ["show", "closable", "showAbortButton", "abortKeydownEvent", "abortButtonText"])
  ], 64))
}