import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "netKeywordLocatorId" }
const _hoisted_4 = { class: "p-field p-col-12" }
const _hoisted_5 = { for: "taxKeywordLocatorId" }
const _hoisted_6 = { class: "p-field p-col-12" }
const _hoisted_7 = { for: "totalKeywordLocatorId" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.KeyWords')) + " " + _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.NetAmount')), 1),
      _createVNode(_component_Dropdown, {
        id: "netKeywordLocatorId",
        modelValue: _ctx.value.netKeywordLocatorId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.netKeywordLocatorId) = $event)),
        options: _ctx.locators,
        loading: _ctx.loading,
        showClear: true,
        filter: true,
        autoFilterFocus: true,
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.update,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "loading", "onChange", "onShow"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.KeyWords')) + " " + _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.TaxAmount')), 1),
      _createVNode(_component_Dropdown, {
        id: "taxKeywordLocatorId",
        modelValue: _ctx.value.taxKeywordLocatorId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.taxKeywordLocatorId) = $event)),
        options: _ctx.locators,
        loading: _ctx.loading,
        showClear: true,
        filter: true,
        autoFilterFocus: true,
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.update,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "loading", "onChange", "onShow"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.KeyWords')) + " " + _toDisplayString(_ctx.$t('Squeeze.Locators.Tabs.TotalAmount')), 1),
      _createVNode(_component_Dropdown, {
        id: "totalKeywordLocatorId",
        modelValue: _ctx.value.totalKeywordLocatorId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.totalKeywordLocatorId) = $event)),
        options: _ctx.locators,
        loading: _ctx.loading,
        showClear: true,
        filter: true,
        autoFilterFocus: true,
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.update,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "loading", "onChange", "onShow"])
    ])
  ]))
}