import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1090b546"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.allScripts,
    loading: _ctx.loading,
    paginator: true,
    rows: _ctx.tableListPagination.pageSize,
    class: "p-datatable-sm",
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
    autoLayout: true,
    columnResizeMode: "fit",
    rowHover: true,
    scrollable: true,
    scrollHeight: "flex",
    onPage: _ctx.onChangePage
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.System.Scripts') })), 1)
    ]),
    paginatorstart: _withCtx(() => []),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "name",
        header: _ctx.$t('Squeeze.System.Script'),
        sortable: false,
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(slotProps.data.name), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.name,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "type",
        header: _ctx.$t('Squeeze.System.Type'),
        sortable: false,
        style: {"min-width":"8rem"}
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        class: "buttonColumn",
        style: {"min-width":"4rem","max-width":"4rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-play-speed",
            class: "p-button-lg p-button-rounded p-button-text",
            onClick: ($event: any) => (_ctx.executeScript(slotProps.data)),
            disabled: !_ctx.store.state.scopes.sqzScriptsExecute
          }, null, 8, ["onClick", "disabled"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.System.ExecuteScript'),
              void 0,
              { left: true }
            ]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "currentPageReportTemplate", "onPage"]))
}