
import {computed, defineComponent, inject, onMounted, PropType, ref} from 'vue';
import Dropdown from 'primevue/dropdown';
import {useI18n} from "vue-i18n";
import {showDropdownOverlay} from "@/util/StylesHelper";
import {FreezeDocumentExportType, FreezeSearchMask} from "@/apis/freeze/Types";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {FreezeClientKey} from "@/apps/freeze/DI";

export default defineComponent({
	name: 'DownloadHitList',
	components: {
		Dropdown,
	},
	props: {
		searchMaskId: {
			type: String,
			default: '',
		},
		selectedEntries: {
			type: Array as PropType<any>,
			default: () => [],
		},
		totalEntries: {
			type: Number,
			default: 0,
		},
		pageSize: {
			type: Number,
			default: 0,
		},
	},
	emits: ["downloadDocuments"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Api for Freeze-Requests */
		const freezeApi = inject(FreezeClientKey)!

		/** List of all Stores */
		const searchMasks = ref<any[]>([]);

		/** Currently selected Store */
		const selectedMask = ref<FreezeSearchMask>({
			name: '',
			desc: '',
			fields: [],
		});

		const downloadSelectValue = ref<FreezeDocumentExportType>(FreezeDocumentExportType.Selected);

		/** List of checkCertificates booleans */
		const downloadSelection = computed(() => {
			return [
				{
					id: FreezeDocumentExportType.Selected,
					name: t('Freeze.General.SelectedOnly'),
				},
				{
					id: FreezeDocumentExportType.All,
					name: t('Freeze.General.DownloadHitlist'),
				},
			]
		});

		/** Currently selected delimiter */
		const selectedDelimiter = ref<any>({
			id: 'comma',
			name: t('Freeze.General.Comma'),
		});

		/** List of delimiters for csv export */
		const delimiter = computed(() => {
			return [
				{
					id: 'comma',
					name: t('Freeze.General.Comma'),
				},
				{
					id: 'semicolon',
					name: t('Freeze.General.Semicolon'),
				},
				{
					id: 'pipe',
					name: t('Freeze.General.Pipe') + ' (|)',
				},
				{
					id: 'tab',
					name: t('Freeze.General.Tab'),
				},
				{
					id: 'space',
					name: t('Freeze.General.Space'),
				},
			]
		});

		/**
		 * Get Search Mask
		 * @param name
		 */
		const getSearchMask = async (name: string) => {
			try {
				const data = await freezeApi.getSearchMask(name);
				if (data) {
					selectedMask.value.fields = data.fields;
				}
			} catch(e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			}
		}

		/** Get all Search Masks */
		const getAllSearchMasks = async () => {
			try {
				const data = await freezeApi.getAllSearchMasks();
				searchMasks.value = data;

				if (searchMasks.value[0]) {
					selectedMask.value = searchMasks.value[0];
					await getSearchMask(searchMasks.value[0].name);
				}

				if (props.searchMaskId) {
					const searchMask = searchMasks.value.find(mask => mask.name === props.searchMaskId);

					if (searchMask) {
						selectedMask.value = searchMask;
						await getSearchMask(searchMask.name);
					}
				}
			} catch(e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			}
		}

		/** Emits the trigger to start a download */
		const startDownload = () => {
			emit("downloadDocuments", selectedMask.value, downloadSelectValue.value, selectedDelimiter.value.id);
		}

		onMounted(() => {
			getAllSearchMasks();

			if (!props.selectedEntries.length) {
				downloadSelectValue.value = FreezeDocumentExportType.All;
			} else {
				downloadSelectValue.value = FreezeDocumentExportType.Selected;
			}
		});

		return {
			selectedMask,
			searchMasks,
			downloadSelection,
			downloadSelectValue,
			selectedDelimiter,
			delimiter,
			startDownload,
			showDropdownOverlay,
		};
	},
});

