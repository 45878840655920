import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77498860"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigator-header p-mb-3" }
const _hoisted_2 = { class: "title p-my-2" }
const _hoisted_3 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_4 = { class: "p-field p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-12" }
const _hoisted_5 = {
  key: 0,
  class: "date-item date-item-value"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "p-col-12" }
const _hoisted_8 = {
  id: "originReportChart",
  ref: "canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("Squeeze.Reports.Origin")), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Calendar, {
          id: "range",
          style: {"margin-bottom":"0.15rem"},
          onDateSelect: _ctx.selectDate,
          modelValue: _ctx.selectedDate,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDate) = $event)),
          selectionMode: "range",
          dateFormat: "dd.mm.yy",
          manualInput: false,
          showIcon: true,
          placeholder: _ctx.placeholder
        }, {
          value: _withCtx((slotProps) => [
            (slotProps.selectedDate)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", null, _toDisplayString(slotProps.selectedDate), 1)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(slotProps.placeholder), 1))
          ]),
          _: 1
        }, 8, ["onDateSelect", "modelValue", "placeholder"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("canvas", _hoisted_8, null, 512)
      ])
    ])
  ], 64))
}