import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.currentDocumentationData,
    columnResizeMode: "fit",
    responsiveLayout: "scroll",
    scrollable: true,
    scrollHeight: "flex",
    rowHover: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "operator",
        header: "",
        style: {"min-width":"8rem"}
      }),
      _createVNode(_component_Column, {
        field: "comparator",
        header: _ctx.$t('Squeeze.General.Comparator'),
        headerClass: "header-center",
        style: {"text-align":"center"}
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "example",
        header: _ctx.$t('Squeeze.General.Example'),
        style: {"min-width":"8rem","max-width":"8rem"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.data[_ctx.currentFilterFieldType.toLowerCase()]), 1)
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 1
  }, 8, ["value"]))
}