/**
 * Converts a base64 string to a blob
 * https://stackoverflow.com/questions/58488416/open-base64-encoded-pdf-file-using-javascript-issue-with-file-size-larger-than
 * @param base64
 * @param type
 */
export const base64ToBlob = (base64: string, type: string) => {
	const binStr = atob(base64);
	const len = binStr.length;
	const arr = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		arr[ i ] = binStr.charCodeAt(i);
	}
	return new Blob([ arr ], { type: type });
}

/**
 * Converts a base 64 string to an URL that can be used for lager files too
 *  * https://stackoverflow.com/questions/58488416/open-base64-encoded-pdf-file-using-javascript-issue-with-file-size-larger-than
 * @param base64
 */
export const createPdfUrlPath = (base64: string) => {
	const blob = base64ToBlob(base64, 'application/pdf');
	const url = URL.createObjectURL(blob);
	return url;
}
