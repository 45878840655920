
import {defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Password from 'primevue/password';
import {User} from "@dex/squeeze-client-ts";
import {email, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useSqueezeStore} from "@/apps/squeeze/store";

export default defineComponent({
	name: "UserForm",
	components: {
		InputText, Password,
	},
	props: {
		userId: {
			type: Number,
			default: 0,
		},
		user: {
			type: Object as PropType<User>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useSqueezeStore();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<User>({
			login: '',
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			externalUser: false,
		});

		/** Determines the required rules for validation */
		let rules = {
			login: { required },
			firstName: { required },
			lastName: { required },
			email: { required, email},
			password: { },
		}

		if (!props.userId) {
			rules = {
				login: { required },
				firstName: { required },
				lastName: { required },
				email: { required, email},
				password: { required },
			}
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.user);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.user, async () => {
			Object.assign(value, props.user);
			await nextTick();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			store,
			loading,
			value,
			props,
			v$,
			InputText,
			Password,
			update,
		}
	},
});

