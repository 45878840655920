import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-mb-2 p-p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LookupTableFilters = _resolveComponent("LookupTableFilters")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _component_LookupFiltersForm = _resolveComponent("LookupFiltersForm")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LookupTableFilters, {
        lookupFilters: _ctx.lookupFilters,
        tableColumns: _ctx.tableColumns,
        loading: _ctx.loading,
        documentClassFields: _ctx.documentClassFields,
        showErrorMessage: _ctx.showValidationMessage,
        masterDataTableColumns: _ctx.masterDataTableColumns,
        tableId: _ctx.tableId,
        onOnClickDelete: _ctx.openDeleteDialog,
        onOnEntrySelect: _ctx.onEntrySelect
      }, null, 8, ["lookupFilters", "tableColumns", "loading", "documentClassFields", "showErrorMessage", "masterDataTableColumns", "tableId", "onOnClickDelete", "onOnEntrySelect"])
    ]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDialog = false)),
      onOnConfirm: _ctx.deleteEntry
    }, null, 8, ["showDialog", "onOnConfirm"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false, _ctx.isValidationInvalid = true, _ctx.showValidationMessage = false)),
      onOnConfirm: _ctx.saveFile,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText,
      showKeepDialogOpen: !_ctx.lookupFilter.id
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: _ctx.loadingDialog }, {
          default: _withCtx(() => [
            _createVNode(_component_LookupFiltersForm, {
              lookupFilter: _ctx.lookupFilter,
              documentClassFields: _ctx.documentClassFields,
              masterDataTableColumns: _ctx.masterDataTableColumns,
              onUpdate: _ctx.onUpdate,
              showErrorMessage: _ctx.showValidationMessage,
              tableColumns: _ctx.tableColumns,
              "show-table-rows": true
            }, null, 8, ["lookupFilter", "documentClassFields", "masterDataTableColumns", "onUpdate", "showErrorMessage", "tableColumns"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText", "showKeepDialogOpen"])
  ], 64))
}