import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, withCtx as _withCtx, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cc2d4f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_2 = { class: "p-input-filled" }
const _hoisted_3 = { style: {"margin-bottom":"0.25rem"} }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "p-ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documentClassFields, (documentClassField) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: documentClassField.id,
        class: _normalizeClass([
					'p-field',
					!documentClassField.colSize ? 'p-col-12' : documentClassField.colSize,
					{'low-field': _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields === true},
				])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", {
              class: "p-mb-3",
              for: documentClassField.name
            }, [
              _createTextVNode(_toDisplayString(documentClassField.description) + " ", 1),
              (_ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].alternatives.length > 1)
                ? _withDirectives((_openBlock(), _createBlock(_component_Badge, {
                    key: 0,
                    value: _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].alternatives.length,
                    severity: "warning"
                  }, null, 8, ["value"])), [
                    [_directive_tooltip, _ctx.$t('Squeeze.Validation.General.CountAlternatives', { count: _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].alternatives.length })]
                  ])
                : _createCommentVNode("", true)
            ], 8, _hoisted_4)
          ]),
          ((documentClassField.dataType.toLowerCase() === 'text' || documentClassField.dataType.toLowerCase() === 'date')
								&& (!documentClassField.lookup || documentClassField.lookup.active === false)
								&& _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].alternatives.length <= 1
								|| (_ctx.isReadOnlyMode && _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].alternatives.length > 1)
								|| (_ctx.isReadOnlyMode && documentClassField.dataType.toLowerCase() === 'text' && documentClassField.lookup && documentClassField.lookup.active === true)
								|| (documentClassField.readonly && documentClassField.dataType.toLowerCase() !== 'amount'))
            ? _withDirectives((_openBlock(), _createBlock(_component_InputText, {
                key: 0,
                id: documentClassField.name,
                "aria-describedby": documentClassField.name + '-help',
                type: "text",
                class: _normalizeClass([
								{'validation-field': _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields === false},
								{'p-invalid': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR'},
								{'forceapproval': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL'},
								{'valid': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'OK'},
								{'readonly': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].readonly || _ctx.isReadOnlyMode},
							]),
                modelValue: _ctx.fields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.value,
                "onUpdate:modelValue": ($event: any) => ((_ctx.fields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.value) = $event),
                onFocus: ($event: any) => (_ctx.onFocusField($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])),
                onChange: ($event: any) => (_ctx.emitValidationRequest($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])),
                onKeydown: [
                  _withKeys(($event: any) => (_ctx.onEnter($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])), ["enter"]),
                  _withKeys(($event: any) => (_ctx.onEnter($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])), ["tab"])
                ],
                ref_for: true,
                ref: el => { _ctx.setFieldReference(el, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)]) },
                readonly: _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].readonly || _ctx.isReadOnlyMode
              }, null, 8, ["id", "aria-describedby", "class", "modelValue", "onUpdate:modelValue", "onFocus", "onChange", "onKeydown", "readonly"])), [
                [
                  _directive_tooltip,
                  (_ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state && _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state.toLowerCase() !== 'error') ? '' : _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.errorText,
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true),
          ((!_ctx.isReadOnlyMode && documentClassField.dataType.toLowerCase() === 'text' && documentClassField.lookup && documentClassField.lookup.active === true && !documentClassField.readonly)
								|| (!_ctx.isReadOnlyMode && _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].alternatives.length > 1 && !documentClassField.readonly))
            ? _withDirectives((_openBlock(), _createBlock(_component_AutoComplete, {
                key: 1,
                id: documentClassField.name,
                "aria-describedby": documentClassField.name + '-help',
                type: "text",
                class: _normalizeClass([
								{'validation-field': _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields === false},
								{'p-invalid': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR'},
								{'forceapproval': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL'},
								{'valid': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'OK'},
								{'readonly': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].readonly || _ctx.isReadOnlyMode},
								{'amount-field': documentClassField.dataType.toLowerCase() === 'amount'},
							]),
                inputClass: [
								{'validation-field': _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields === false},
								{'p-invalid': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR'},
								{'forceapproval': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL'},
								{'valid': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'OK'},
								{'readonly': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].readonly || _ctx.isReadOnlyMode},
								{'amount-field': documentClassField.dataType.toLowerCase() === 'amount'},
							],
                modelValue: _ctx.fields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.value,
                "onUpdate:modelValue": ($event: any) => ((_ctx.fields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.value) = $event),
                onFocus: ($event: any) => (_ctx.onFocusField($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])),
                onBlur: ($event: any) => (_ctx.emitValidationRequest($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])),
                onKeydown: [
                  _withKeys(($event: any) => (_ctx.onEnter($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])), ["enter"]),
                  _withKeys(($event: any) => (_ctx.onEnter($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])), ["tab"]),
                  ($event: any) => (_ctx.onKeydown($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)]))
                ],
                ref_for: true,
                ref: el => { _ctx.setFieldReference(el, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)]) },
                readonly: _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].readonly || _ctx.isReadOnlyMode,
                minLength: documentClassField.lookup.minInputLength,
                suggestions: _ctx.filteredValues[documentClassField.name],
                field: "label",
                onClick: ($event: any) => (_ctx.onClickAutocomplete($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])),
                onComplete: ($event: any) => (_ctx.searchAutocomplete($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])),
                onItemSelect: ($event: any) => (_ctx.onItemSelect($event, documentClassField)),
                onItemHover: ($event: any) => (_ctx.onHoverItemAutocomplete($event, documentClassField)),
                forceSelection: (documentClassField.lookup && documentClassField.lookup.active === true) && !documentClassField.lookup.allowCustomValues,
                autoHighlight: true,
                dropdownMode: 'current',
                dropdown: !_ctx.isReadOnlyMode && (_ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].alternatives.length > 1  || _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].lookup.minInputLength === 0)
              }, {
                item: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(slotProps.item.label), 1)
                ]),
                _: 2
              }, 1032, ["id", "aria-describedby", "class", "inputClass", "modelValue", "onUpdate:modelValue", "onFocus", "onBlur", "onKeydown", "readonly", "minLength", "suggestions", "onClick", "onComplete", "onItemSelect", "onItemHover", "forceSelection", "dropdown"])), [
                [
                  _directive_tooltip,
                  (_ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state && _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state.toLowerCase() !== 'error') ? '' : _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.errorText,
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true),
          (documentClassField.dataType.toLowerCase() === 'amount' && _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].alternatives.length <= 1)
            ? _withDirectives((_openBlock(), _createBlock(_component_InputText, {
                key: 2,
                id: documentClassField.name,
                modelValue: _ctx.fields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.value,
                "onUpdate:modelValue": ($event: any) => ((_ctx.fields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.value) = $event),
                class: _normalizeClass([
								{'validation-field': _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields === false},
								{'p-invalid': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR'},
								{'forceapproval': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL'},
								{'valid': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state === 'OK'},
								{'readonly': _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].readonly || _ctx.isReadOnlyMode},
								'amount-field',
							]),
                onFocus: ($event: any) => (_ctx.onFocusField($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])),
                onChange: ($event: any) => (_ctx.emitValidationRequest($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])),
                onKeydown: [
                  _withKeys(($event: any) => (_ctx.onEnter($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])), ["enter"]),
                  _withKeys(($event: any) => (_ctx.onEnter($event, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)])), ["tab"])
                ],
                ref_for: true,
                ref: el => { _ctx.setFieldReference(el, _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)]) },
                readonly: _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].readonly || _ctx.isReadOnlyMode
              }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "class", "onFocus", "onChange", "onKeydown", "readonly"])), [
                [
                  _directive_tooltip,
                  (_ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state && _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.state.toLowerCase() !== 'error') ? '' : _ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].value.errorText,
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true)
        ])
      ], 2)), [
        [_vShow, !_ctx.documentFields[_ctx.getDocumentFieldIndex(documentClassField.name)].hidden]
      ])
    }), 128))
  ]))
}