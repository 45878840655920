
import {computed, defineComponent, onBeforeMount, onMounted, PropType, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import DocumentPositionTrainingTable from "@/apps/squeeze/components/DocumentPositionTrainingTable.vue";
import {DocumentField, DocumentTable, DocumentTableColumn, TableColumnTraining} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import {showDropdownOverlay} from "@/util/StylesHelper";

export interface PositionTrainingWithName extends TableColumnTraining {
	columnName?: string;
	columnHits?: number;
}

export default defineComponent({
	name: "PositionTraining",
	methods: {showDropdownOverlay},
	components: {
		Button,
		InputText,
		Dropdown,
		DocumentPositionTrainingTable,
	},
	props: {
		documentClassId: {
			type: Number,
			required: true,
		},
		documentId: {
			type: Number,
			required: true,
		},
		tables: {
			type: Array as PropType<DocumentTable[]>,
			default: () => [],
		},
		positionTrainingValues: {
			type: Object as PropType<TableColumnTraining>,
			default: () => ({}),
		},
		trainingKeyField: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
		},
	},
	emits: [
		'onChangeValues',
		'onFocusFieldOfPositionTraining',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current training key */
		const trainingKey = reactive<DocumentField>({});

		/** Current Object of table column (position) training */
		const value = reactive<TableColumnTraining>({});

		/** Column region value */
		const columnRegionValue = computed(() => {
			let columnValue = props.positionTrainingValues!.columnRegion!.x0 + ':' + props.positionTrainingValues!.columnRegion!.x1;
			if (columnValue === "0:0") {
				columnValue = "";
			}
			return columnValue;
		})

		/** Columns of the Table */
		const columnValues  = ref<DocumentTableColumn[]>([]);

		/** List of all training values of PositionTraining */
		const tablePositionTrainingValues = ref<PositionTrainingWithName[]>([]);

		/** List of all testing values of PositionTraining */
		const positionTestingValues = ref<TableColumnTraining[]>([]);

		/** Show Button-Text Train OR Retrain */
		const buttonTrainText = ref<boolean>(true);

		/** Show Testing-Text in table */
		const clickOnTestingButton = ref<boolean>(false);

		/** Indicates end of request */
		const loading = ref<boolean>(false);

		/** Document Class API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Resets the form so a new training can be added */
		const resetForm = () => {
			value.id = undefined;
			value.columnRegion = {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			};
			value.valuePattern = '';
			Object.assign(columnRegionValue, '');

			emit('onChangeValues', value);
		}

		/** Get data of training
		 * Promise1 to get all table column values
		 * Promise2 to get all training values for training table
		 * */
		const getDataOfTraining = () => {
			loading.value = true;

			if (!trainingKey.value!.value) {
				loading.value = false;
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Viewer.Error.NoCreditorField'));
				return;
			}

			const promise1 = documentClassApi.getAllDocumentClassTableColumns(props.documentClassId, value.tableId!);
			const promise2 = documentClassApi.getColumnTrainingsOfTable(props.documentClassId, value.tableId!, trainingKey.value!.value);
			// Wait until promises are finished
			Promise.all([promise1, promise2]).then(values => {
				columnValues.value = values[0];

				const positionTrainingsWithName: PositionTrainingWithName[] = values[1];
				positionTrainingsWithName.sort(function(firstValue: any, secondValue: any) {
					return firstValue.columnId - secondValue.columnId;
				})
				positionTrainingsWithName.forEach((tableValue) => {
					columnValues.value.forEach((column) => {
						if (tableValue.columnId === column.id) {
							tableValue.columnName = column.description;
						}
					})
				})
				tablePositionTrainingValues.value = positionTrainingsWithName;
				clickOnTestingButton.value = false;
			}).catch(response => response.json().then ((err: {message: string}) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/** Train or retrain position values */
		const trainPositionValues = () => {
			value.trainingKeyValue = trainingKey.value!.value;
			const rowIds: any[] = [];
			if (value.tableId !== 0) {
				tablePositionTrainingValues.value.forEach((row) => {
					rowIds.push(row.id);
				})
				if (rowIds.includes(value.id)) {
					documentClassApi.retrainDocumentClassTableColumn(props.documentClassId, value.tableId!, value.columnId!, value.id!, value, true)
						.then(() => {
							buttonTrainText.value = true;
							getDataOfTraining();
							resetForm();
						})
						.catch(response => response.json().then ((err: {message: string}) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
						}))
				} else {
					documentClassApi.trainDocumentClassTableColumn(props.documentClassId, value.tableId!, value.columnId!, value, false)
						.then(() => {
							getDataOfTraining();
							resetForm();
						})
						.catch(response => response.json().then ((err: {message: string}) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
						}))
				}
			}
		}

		/** Testing all training columns for training table */
		const testingPositionValues = () => {
			clickOnTestingButton.value = true;
			loading.value = true;
			documentApi.testColumnTrainings(props.documentId, trainingKey.value!.value!)
				.then((trainings: TableColumnTraining[]) => {
					positionTestingValues.value = trainings;
					tablePositionTrainingValues.value.forEach((tableValue) => {
						columnValues.value.forEach((column) => {
							if (tableValue.columnId === column.id) {
								tableValue.columnName = column.description;
							}
						})
					})
					positionTestingValues.value.forEach((testingValue) => {
						tablePositionTrainingValues.value.forEach((tableValue) => {
							if (testingValue.id === tableValue.id) {
								tableValue.columnHits = testingValue.values?.length;
								if (tableValue.columnHits === undefined) {
									tableValue.columnHits = 0;
								}
							}
						})
					})
				})
				.catch(response => response.json().then ((err: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Triggered when a field is focused
		 * @param {string} fieldName
		 */
		const onFocusFieldOfPositionTraining = (fieldName: string) => {
			emit("onFocusFieldOfPositionTraining", fieldName);
		}

		/**
		 * Get training value to change it
		 * @param {TableColumnTraining} rowData
		 */
		const changeTrainingRow = (rowData: TableColumnTraining) => {
			value.id = rowData.id;
			value.columnId = rowData.columnId;
			value.documentClassId = rowData.documentClassId;
			value.valuePattern = rowData.valuePattern;
			value.trainingKeyValue = rowData.trainingKeyValue;
			value.columnRegion = rowData.columnRegion;
			value.tableId = rowData.tableId;

			buttonTrainText.value = false;
		}

		/**
		 * Deletes a row of the training table
		 * @param {TableColumnTraining} rowData
		 */
		const deleteTrainingRow = (rowData: TableColumnTraining) => {
			documentClassApi.deleteDocumentClassTableColumnTraining(props.documentClassId, rowData.tableId!, rowData.columnId!, rowData.id!)
				.then(() => {
					getDataOfTraining();
					resetForm();
				})
				.catch(response => response.json().then ((err: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				}))
		}

		/**
		 * Set marker regions by clicking the Testing-Row
		 * @param event
		 */
		const onMarkRegion = (event: {originalEvent: PointerEvent; data: PositionTrainingWithName; index: number}) => {
			const row = event.data;
			if (clickOnTestingButton.value) {
				const cellRegion: any[] = [];
				positionTestingValues.value.forEach((value) => {
					if (row.id === value.id && value.values) {
						value.values.forEach((value) => {
							cellRegion.push(value.boundingBox);
						});
					}
				});
				emit("onMarkRegion", cellRegion);
			} else {
				emit("onMarkRegion", row.columnRegion);
			}
		}

		watch(props.positionTrainingValues, () => {
			if (props.positionTrainingValues.columnRegion !== value.columnRegion) {
				value.columnRegion = props.positionTrainingValues.columnRegion;
			}

			if (props.positionTrainingValues.valuePattern !== value.valuePattern) {
				value.valuePattern = props.positionTrainingValues.valuePattern;
			}
		})

		onBeforeMount(() => {
			Object.assign(trainingKey, props.trainingKeyField);
		})

		onMounted(() => {
			Object.assign(value, props.positionTrainingValues);
			// reset column region and value pattern
			Object.assign(columnRegionValue, '');
			value.valuePattern = '';

			if (props.tables.length !== 0) {
				value.tableId = props.tables[0].id;
			}

			resetForm();
			getDataOfTraining();
		})

		return {
			t,
			toast,
			trainingKey,
			value,
			columnRegionValue,
			columnValues,
			tablePositionTrainingValues,
			positionTestingValues,
			buttonTrainText,
			clickOnTestingButton,
			loading,
			getDataOfTraining,
			trainPositionValues,
			testingPositionValues,
			onFocusFieldOfPositionTraining,
			changeTrainingRow,
			deleteTrainingRow,
			onMarkRegion,
		}
	},
});
