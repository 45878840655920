import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65639b27"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 child-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentClassExportInterface = _resolveComponent("DocumentClassExportInterface")!
  const _component_ExportInterfaceForm = _resolveComponent("ExportInterfaceForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DocumentClassExportInterface, {
      rows: _ctx.rows,
      loading: _ctx.loading,
      documentClassId: _ctx.documentClassId,
      onOpenEntryDialog: _ctx.openCurrentExportInterfaceDialog,
      allExportInterfaces: _ctx.allExportInterfaces,
      onDeleteEntry: _ctx.openDeleteExportInterface,
      onOnChangeCheckbox: _ctx.onChangeCheckBox,
      onTestExportInterface: _ctx.testExportInterface
    }, null, 8, ["rows", "loading", "documentClassId", "onOpenEntryDialog", "allExportInterfaces", "onDeleteEntry", "onOnChangeCheckbox", "onTestExportInterface"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialogExportInterface,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialogExportInterface = false, _ctx.showErrorMessage= false, _ctx.isInvalid = true)),
      onOnConfirm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveExportInterface(true))),
      headerText: _ctx.headerText,
      loading: _ctx.loadingSave
    }, {
      content: _withCtx(() => [
        _createVNode(_component_ExportInterfaceForm, {
          fieldEntry: _ctx.exportInterfaceEntry,
          allExportInterfaces: _ctx.allExportInterfaces,
          showErrorMessage: _ctx.showErrorMessage,
          onUpdate: _ctx.onUpdateExportInterface
        }, null, 8, ["fieldEntry", "allExportInterfaces", "showErrorMessage", "onUpdate"])
      ]),
      _: 1
    }, 8, ["show", "headerText", "loading"]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.showDeleteDialogExportInterface,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDeleteDialogExportInterface = false)),
      onOnConfirm: _ctx.deleteExportInterface
    }, null, 8, ["showDialog", "onOnConfirm"])
  ]))
}