<template>
  <div class="about">
    <h1>ADMINISTRATION DASHBOARD</h1>
  </div>
</template>

<script>
export default {
	name: "Dashboard",
}
</script>

<style scoped>

</style>
