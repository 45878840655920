import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f71130b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-mb-3" }
const _hoisted_2 = { class: "p-fluid p-formgrid p-grid p-p-2" }
const _hoisted_3 = { class: "p-col-4" }
const _hoisted_4 = {
  class: "p-inputgroup",
  style: {"height":"2.678rem"}
}
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "p-col-4 p-jc-end" }
const _hoisted_7 = { class: "p-col-4 p-jc-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('Squeeze.System.ColorPickerText')), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("input", {
            type: "color",
            value: _ctx.clientMainColor,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChangeColor($event.target.value))),
            class: "input-color"
          }, null, 40, _hoisted_5),
          _createVNode(_component_InputText, {
            id: "color",
            modelValue: _ctx.textOfClientMainColor,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.textOfClientMainColor) = $event)),
            onChange: _ctx.onChangeInput,
            disabled: _ctx.loading
          }, null, 8, ["modelValue", "onChange", "disabled"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createVNode(_component_Button, {
          label: _ctx.$t('Squeeze.System.DefaultColor'),
          type: "button",
          icon: "mdi mdi mdi-restore",
          class: "p-button p-button-danger",
          onClick: _ctx.resetColor,
          loading: _ctx.loading,
          disabled: !_ctx.isRestoreBtnClickable
        }, null, 8, ["label", "onClick", "loading", "disabled"]), [
          [
            _directive_tooltip,
            _ctx.$t('Squeeze.System.DefaultColorMessage'),
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Button, {
          label: _ctx.$t('Squeeze.General.Save'),
          type: "button",
          icon: "mdi mdi-content-save-outline",
          class: "p-button",
          onClick: _ctx.saveColor,
          loading: _ctx.loading,
          disabled: !_ctx.isSaveBtnClickable
        }, null, 8, ["label", "onClick", "loading", "disabled"])
      ])
    ])
  ], 64))
}