
import {defineComponent, onMounted, PropType, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {useRoute} from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import router from "@/router";
import Tooltip from "primevue/tooltip";
import {ExportInterfaceDocumentationDto, ExportInterfaceDto, ExportInterfacePropertyDocumentationDto} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: 'DocumentClassExportInterface',
	components: {
		DataTable,
		Column,
		Checkbox,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All rows */
		rows: {
			type: Array as PropType<ExportInterfaceDto[]>,
			default: () => [],
		},
		/** Currently active document-class */
		documentClassId: {
			type: Number,
			default: 0,
		},
		allExportInterfaces: {
			type: Array as PropType<ExportInterfaceDocumentationDto[]>,
			default: () => [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "testExportInterface", "onChangeCheckbox"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** A single item (used for insert/edit/delete */
		const rowData = reactive<ExportInterfaceDto>({interfaceName: "", configuration: {}});

		/** Array with the expanded rows-data */
		const expandedRows = ref<any>([]);

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Opens the Delete-Dialog
		 * @param {ExportInterfaceDto} row The row to delete
		 */
		const openDeleteDialog = (row: ExportInterfaceDto) => {
			Object.assign(rowData, row);
			emit("deleteEntry", rowData);
		}

		/** Create a new entry */
		const openNewEntry = () => {
			emit("openEntryDialog", { id: undefined, active: true, description: '', configuration: undefined, interfaceName: ''})
		}

		/**
		 * Opens the Edit-Dialog
		 * @param {ExportInterfaceDto} row The Row to edit
		 */
		const openEditDialog = (row: ExportInterfaceDto) => {
			Object.assign(rowData, row);
			emit("openEntryDialog", rowData);
		}

		/**
		 * Gets the properties for an Export-Interface
		 * @param {string} name Name of the Export-Interface
		 */
		const getExportInterfaceProperties = (name: string) => {
			const exportInterface = props.allExportInterfaces.find(exportInterface => exportInterface.id === name);
			if (exportInterface) {
				return exportInterface.properties;
			}

			return {};
		}

		/**
		 * Test the current Export Interface
		 * @param {ExportInterfaceDto} row Current row
		 */
		const testExportInterface = (row: ExportInterfaceDto) => {
			emit("testExportInterface", row.id)
		}

		/**
		 * Get the name of an export interface type instead of id
		 * @param {string} interfaceId Current export interface id
		 */
		const getExportInterfaceTypeName = (interfaceId: string) => {
			const interfaceData = props.allExportInterfaces.find(exportInterface => exportInterface.id === interfaceId);
			if (interfaceData) {
				return interfaceData.name;
			}

			return interfaceId;
		}

		/**
		 * Gets the selected value from a field and returns the name
		 * @param {ExportInterfacePropertyDocumentationDto} properties Property to get the name from
		 * @param {string} value Value to get the name from
		 */
		const getSelectedValueName = (properties: ExportInterfacePropertyDocumentationDto[], value: string) => {
			const property = properties.find(property => property.id === value);
			if (property) {
				return property.name;
			}

			return value;
		}

		/**
		 * Triggered when a checkbox is clicked
		 * @param {ExportInterfaceDto} data Data to edit
		 */
		const onChangeCheckbox = (data: ExportInterfaceDto) => {
			emit("onChangeCheckbox", data);
		}

		/** Triggered to show the ExportStates
		 * @param {ExportInterfaceDto} data
		 */
		const openExportStates = (data: ExportInterfaceDto) => {
			router.push({ name: 'DocumentClassExportStatesSubView', params: { documentClassId: String(data.documentClassId), exportId: String(data.id) }});
		}

		/**
		 * Triggered when page is changed
		 * @param event
		 */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		})

		return {
			t,
			toast,
			route,
			store,
			rowData,
			expandedRows,
			tableListPagination,
			openDeleteDialog,
			openNewEntry,
			openEditDialog,
			getExportInterfaceProperties,
			testExportInterface,
			getExportInterfaceTypeName,
			getSelectedValueName,
			onChangeCheckbox,
			openExportStates,
			onChangePage,
		};
	},
});

