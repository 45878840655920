import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid p-formgrid p-grid p-input-filled p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "classificationClasses" }
const _hoisted_4 = { class: "p-field p-col-12" }
const _hoisted_5 = { for: "trainDocument" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.NewDocumentClass')), 1),
      _createVNode(_component_Dropdown, {
        id: "classificationClasses",
        modelValue: _ctx.documentClass,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.documentClass) = $event)),
        options: _ctx.classificationClasses,
        optionValue: "documentClassId",
        optionLabel: "description",
        onChange: _ctx.onChangeDocumentClass
      }, null, 8, ["modelValue", "options", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.TrainDocument')), 1),
      _createVNode(_component_Dropdown, {
        id: "trainDocument",
        modelValue: _ctx.trainDocumentComponent,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.trainDocumentComponent) = $event)),
        options: _ctx.trainDocumentOptions,
        optionValue: "id",
        optionLabel: "name",
        onChange: _ctx.onChangeDocumentClass
      }, null, 8, ["modelValue", "options", "onChange"])
    ])
  ]))
}