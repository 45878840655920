import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-p-2 inherit-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocatorAttributes = _resolveComponent("LocatorAttributes")!
  const _component_LocatorAttributeForm = _resolveComponent("LocatorAttributeForm")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LocatorAttributes, {
        locatorSettings: _ctx.locatorSettings,
        supportedLocatorSettings: _ctx.supportedLocatorSettings,
        locatorId: _ctx.locatorId,
        loading: _ctx.loading,
        onOpenEntryDialog: _ctx.openEditDialog,
        onOpenDelete: _ctx.openDeleteDialog
      }, null, 8, ["locatorSettings", "supportedLocatorSettings", "locatorId", "loading", "onOpenEntryDialog", "onOpenDelete"])
    ]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showEditDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditDialog = false, _ctx.showErrorMessage= false, _ctx.isInvalid = true)),
      onOnConfirm: _ctx.saveLocatorAttribute,
      message: _ctx.message,
      messageType: _ctx.messageType,
      headerText: _ctx.headerText,
      loading: _ctx.loading
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: _ctx.loading }, {
          default: _withCtx(() => [
            _createVNode(_component_LocatorAttributeForm, {
              fieldEntry: _ctx.entryFieldAttribute,
              supportedLocatorSettings: _ctx.supportedLocatorSettings,
              showErrorMessage: _ctx.showErrorMessage,
              disableSetting: _ctx.disableSetting,
              onUpdate: _ctx.onUpdate
            }, null, 8, ["fieldEntry", "supportedLocatorSettings", "showErrorMessage", "disableSetting", "onUpdate"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "message", "messageType", "headerText", "loading"]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.showDeleteDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDeleteDialog = false)),
      onOnConfirm: _ctx.deleteLocatorAttribute
    }, null, 8, ["showDialog", "onOnConfirm"])
  ], 64))
}