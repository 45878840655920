
import {defineComponent, PropType} from "vue";
import {useI18n} from "vue-i18n";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {PositionTrainingWithName} from "@/apps/squeeze/views/PositionTraining.vue";
import {TableColumnTraining} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "DocumentPositionTrainingTable",
	components: {
		Button,
		DataTable,
		Column,
	},
	props: {
		tablePositionTrainingValues: {
			type: Array as PropType<PositionTrainingWithName[]>,
			default: () => [],
		},
		clickOnTestingButton: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'changeTrainingRow',
		'deleteTrainingRow',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();

		/**
		 * Change a training value
		 * @param {TableColumnTraining} rowData
		 */
		const changeTrainingRow = (rowData: TableColumnTraining) => {
			emit("changeTrainingRow", rowData);
		}

		/**
		 * Deletes a row of the training table
		 * @param {TableColumnTraining} rowData
		 */
		const deleteTrainingRow = (rowData: TableColumnTraining) => {
			emit("deleteTrainingRow", rowData);
		}

		/**
		 * Click a testing row to mark
		 * @param event
		 */
		const onMarkRegion = (event: any) => {
			emit("onMarkRegion", event);
		}

		return {
			t,
			changeTrainingRow,
			deleteTrainingRow,
			onMarkRegion,
		}
	},
});
