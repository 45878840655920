
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import Password from 'primevue/password';
import {MailZipPassword} from "@dex/squeeze-client-ts";
import {email, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "PasswordForm",
	components: {
		InputText, Password,
	},
	props: {
		password: {
			type: Object as PropType<MailZipPassword>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Object of all input-fields */
		const value = reactive<MailZipPassword>({
			id: undefined,
			email: '',
			password: '',
		});

		/** Determines the required rules for validation */
		const rules = {
			email: { required, email},
			password: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.password);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.password, () => {
			Object.assign(value, props.password);
		})

		/** Triggered on change of any field */
		const update = async () => {
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			value,
			props,
			v$,
			update,
		}
	},
});

