import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c293aa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.jobs,
    loading: _ctx.loading,
    paginator: true,
    rows: _ctx.tableListPagination.pageSize,
    class: "p-datatable-sm",
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
    autoLayout: true,
    columnResizeMode: "fit",
    scrollable: true,
    scrollHeight: "flex",
    onRowDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditDialog($event.data))),
    rowHover: true,
    onPage: _ctx.onChangePage
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.System.Jobs') })), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        type: "button",
        label: _ctx.$t('Squeeze.General.NewEntry'),
        icon: "mdi mdi-plus",
        onClick: _ctx.onNewEntryClick,
        disabled: _ctx.loading || (!_ctx.store.state.scopes.sqzJobsManage && _ctx.store.state.scopes.sqzJobsRead)
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "id",
        header: "ID",
        style: {"min-width":"4rem","max-width":"6rem"}
      }),
      _createVNode(_component_Column, {
        field: "name",
        header: _ctx.$t('Squeeze.System.Name'),
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(slotProps.data.name), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.name,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "cronExpression",
        header: _ctx.$t('Squeeze.System.CronExpression'),
        style: {"min-width":"10rem"}
      }, {
        header: _withCtx(() => [
          _createVNode(_component_Badge, {
            class: "mdi mdi-comment-question-outline",
            onClick: _ctx.goToCronGuru
          }, null, 8, ["onClick"])
        ]),
        body: _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString(slotProps.data.cronExpression), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "scriptId",
        header: _ctx.$t('Squeeze.System.Script'),
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.getScriptName(slotProps.data)), 1)
          ])), [
            [
              _directive_tooltip,
              _ctx.getScriptName(slotProps.data),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "active",
        header: _ctx.$t('Squeeze.System.Active'),
        headerClass: "header-center",
        style: {"min-width":"4rem","max-width":"4rem","text-align":"center"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(_ctx.$t("Squeeze.General.Boolean." + slotProps.data.active)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, { style: {"min-width":"10rem","max-width":"10rem","text-align":"right"} }, {
        body: _withCtx((slotProps) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-table-eye",
            class: "p-button-rounded p-button-text",
            onClick: ($event: any) => (_ctx.openJobRuns(slotProps.data))
          }, null, 8, ["onClick"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.System.ShowJobRuns'),
              void 0,
              { bottom: true }
            ]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.openEditDialog(slotProps.data)),
            disabled: !_ctx.store.state.scopes.sqzJobsManage && _ctx.store.state.scopes.sqzJobsRead
          }, null, 8, ["onClick", "disabled"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Locators.Edit'),
              void 0,
              { bottom: true }
            ]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(slotProps.data)),
            disabled: !_ctx.store.state.scopes.sqzJobsManage && _ctx.store.state.scopes.sqzJobsRead
          }, null, 8, ["onClick", "disabled"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Validation.Buttons.Delete'),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "currentPageReportTemplate", "onPage"]))
}