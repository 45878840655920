import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dec24fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DexDataTable = _resolveComponent("DexDataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DexDataTable, {
    value: _ctx.masterDataRows,
    class: "p-datatable-sm editable-cells-table p-datatable-documents",
    loading: _ctx.loading,
    columnResizeMode: "fit",
    lazy: true,
    onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event))),
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    rows: _ctx.paginationInfo.pageSize,
    totalRecords: _ctx.paginationInfo.total,
    paginator: true,
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords} ',
    editMode: "cell",
    onCellEditInit: _ctx.onCellEdit,
    onCellEditComplete: _ctx.onCellCompleteEditing,
    onRowDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openEditDialog($event.data))),
    filterDisplay: "row",
    filters: _ctx.filters,
    "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
    onFilter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFilter($event.filters))),
    scrollable: true,
    scrollHeight: "flex",
    onSort: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSort($event))),
    removableSort: true
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.MasterData.Data') })), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button p-mr-2",
        onClick: _ctx.onNewEntryClick,
        disabled: _ctx.checkPermissions
      }, null, 8, ["label", "onClick", "disabled"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.MasterData.StartExport'),
        icon: "mdi mdi-file-download-outline",
        onClick: _ctx.startExportOfMasterData,
        disabled: !_ctx.store.state.isAdminUser
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.masterDataColumns, (column) => {
        return (_openBlock(), _createBlock(_component_Column, {
          field: column.name,
          filterField: '' + column.id,
          header: column.description,
          key: column.id,
          class: "column-cell",
          style: _normalizeStyle([
					_ctx.masterDataColumns.length > 1 && column.description !== 'ID' ? 'min-width: 12.5rem;': 'min-width: 4rem; max-width: 4rem;',
					_ctx.masterDataColumns.length <= 1 && column.description === 'ID' ? 'max-width: 100%;' : '',
				]),
          showFilterMenu: true,
          sortable: true
        }, _createSlots({
          body: _withCtx(({ data }) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(data[column.name]), 1)
            ])), [
              [
                _directive_tooltip,
                data[column.name],
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"flex":"0 0 4rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              placeholder: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 2
        }, [
          (!_ctx.checkPermissions)
            ? {
                name: "editor",
                fn: _withCtx(({ data }) => [
                  _withDirectives(_createVNode(_component_InputText, {
                    modelValue: data[column.name],
                    "onUpdate:modelValue": ($event: any) => ((data[column.name]) = $event),
                    onDblclick: ($event: any) => (_ctx.openEditDialog(data)),
                    onKeyup: ($event: any) => (_ctx.changeEventInInput(data))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onDblclick", "onKeyup"]), [
                    [
                      _directive_tooltip,
                      data[column.name],
                      void 0,
                      { bottom: true }
                    ]
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["field", "filterField", "header", "style"]))
      }), 128)),
      _createVNode(_component_Column, { style: {"min-width":"7rem","max-width":"7rem","text-align":"right"} }, {
        body: _withCtx((masterDataRow) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.openEditDialog(masterDataRow.data)),
            disabled: _ctx.checkPermissions
          }, null, 8, ["onClick", "disabled"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Locators.Edit'),
              void 0,
              { bottom: true }
            ]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(masterDataRow.data)),
            disabled: _ctx.checkPermissions
          }, null, 8, ["onClick", "disabled"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Validation.Buttons.Delete'),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "rows", "totalRecords", "currentPageReportTemplate", "onCellEditInit", "onCellEditComplete", "filters"]))
}