import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e026768"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "child-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValueListView = _resolveComponent("ValueListView")!
  const _component_SearchValueView = _resolveComponent("SearchValueView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ValueListView, {
      onOnListChange: _ctx.onChangeValue,
      locatorId: _ctx.locatorId
    }, null, 8, ["onOnListChange", "locatorId"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.valueListValue)
        ? (_openBlock(), _createBlock(_component_SearchValueView, {
            key: 0,
            locatorId: _ctx.locatorId,
            valueListValue: _ctx.valueListValue
          }, null, 8, ["locatorId", "valueListValue"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}