import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35f12ef4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container p-p-2" }
const _hoisted_2 = { class: "header p-d-none p-d-md-flex" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QueueTimeline = _resolveComponent("QueueTimeline")!
  const _component_QueueNavigatorView = _resolveComponent("QueueNavigatorView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_QueueTimeline)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_QueueNavigatorView)
    ])
  ]))
}