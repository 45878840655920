import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e29e7c72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-component p-fluid p-formgrid p-grid p-p-2" }
const _hoisted_2 = { class: "p-input-filled" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "p-input-filled" }
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.searchMaskFields && _ctx.searchMaskFields.length >0)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.searchMaskFields, (field) => {
          return (_openBlock(), _createElementBlock("div", {
            key: field.name,
            class: "field p-col-12 p-md-6 p-mb-2"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("label", {
                for: field.name
              }, _toDisplayString(field.desc), 9, _hoisted_3),
              _createVNode(_component_InputText, {
                readonly: true,
                id: field.name,
                value: _ctx.getValueFromSearchMask(field.name)
              }, null, 8, ["id", "value"])
            ])
          ]))
        }), 128))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.fields, (value, name) => {
          return (_openBlock(), _createElementBlock("div", {
            key: name,
            class: "field p-col-12 p-md-6 p-mb-2"
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", { for: name }, _toDisplayString(name), 9, _hoisted_5),
              _createVNode(_component_InputText, {
                readonly: true,
                id: name,
                value: value
              }, null, 8, ["id", "value"])
            ])
          ]))
        }), 128))
  ]))
}