
import {defineComponent, reactive, ref, PropType, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import EntryDialog from "@/components/EntryDialog.vue";
import DocumentClassFieldsLayoutView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassFieldsLayoutView.vue";
import DocumentClassFieldTable from "@/apps/administration/components/documentclasses/DocumentClassFieldTable.vue";
import FieldForm from "@/apps/administration/components/documentclasses/FieldForm.vue";
import {DocumentField, DocumentFieldGroup, DocumentLocator} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";

type FieldsPerGroupCollection = Record<number, DocumentField[]>;

export default defineComponent({
	name: 'DocumentClassFieldsSubView',
	components: {
		FieldForm,
		DocumentClassFieldTable,
		DocumentClassFieldsLayoutView,
		EntryDialog,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
		activeFieldGroup: {
			type: Number,
			default: 0,
		},
		fieldGroups: {
			type: Array as PropType<DocumentFieldGroup[]>,
			default: () => [],
		},
		fieldGroupId: {
			type: Number,
			default: 0,
		},
		fieldsPerFieldGroup: {
			type: Array as PropType<FieldsPerGroupCollection>,
			default: () => [],
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: () => [],
		},
		activeTabIndex: {
			type: Number,
			default: 0,
		},
	},
	emits: ["getDocumentClassFields"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Service for getting the documentclass-data */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Component of the current form */
		const fieldFormElement = ref<any>();

		/** Entry-Data for Document-Class-Fields */
		const documentClassFieldsEntry = reactive<DocumentField>({
			documentClassId: props.documentClassId,
			fieldGroupId: props.activeFieldGroup,
			name: '',
			description: '',
			defaultValue: '',
			locatorId: 0,
			mandatory: false,
			readonly: false,
			sameLineAsPreviousField: false,
			hidden: false,
			forceValidation: false,
			externalName: '',
			alternatives: [],
			dataType: '',
			maxLength: 0,
			truncateType: DocumentField.TruncateTypeEnum.None,
		});

		/** Show the loading for Save-Button of Fields? */
		const loading = ref<boolean>(false);

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref<boolean>(true);

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Message To Show in Entry-Dialogs */
		const message = ref<string>('');

		/** Message-Type to set when showing a message (see: Message-Component in PrimeVue */
		const messageType = ref<string>('none');

		/** Should the Entry-Dialog for fields be shown? */
		const showCurrentFieldDialog = ref<boolean>(false);

		/** Current invalid tab of fieldForm */
		const currentInValidTab = ref<number>(0);

		/** Current tab index of fieldForm dialog */
		const currentDialogTabIndex = ref<number>(0);

		/** Show the fields layout */
		const showFieldsLayout = ref<boolean>(false);

		/**
		 * Triggered on update of attribute-form field
		 * @param {DocumentField} data
		 * @param {boolean} valid
		 * @param {number} activeTabInValid
		 */
		const onUpdateField = (data: DocumentField, valid: boolean, activeTabInValid: number) => {
			currentInValidTab.value = activeTabInValid;
			isInvalid.value = valid;
			Object.assign(documentClassFieldsEntry, data);
		}

		/**
		 * Saves a new field
		 * @param reloadTableData Is the Save triggered from outside the Entry-Dialog?
		 * @param keepDialogOpen
		 */
		const saveField = (reloadTableData: boolean, keepDialogOpen: boolean = false) => {
			if (isInvalid.value && reloadTableData) {
				showErrorMessage.value = true;

				// check current inValid tab
				if(currentInValidTab.value === 1 && currentDialogTabIndex.value !== 1) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.DocumentClasses.ErrorInputAssistance'));
				} else if (currentInValidTab.value === 0 && currentDialogTabIndex.value !== 0) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.DocumentClasses.ErrorFieldAttributes'));
				}
				return;
			}

			loading.value = true;
			if (documentClassFieldsEntry) {
				// If the Dropzone field has been emptied it's set to null by PrimeVue-Default. Set this value to an empty string
				if (documentClassFieldsEntry.subFieldName == null) {
					documentClassFieldsEntry.subFieldName = "";
				}

				// The Api can't save null values, therefore use default 0
				if (documentClassFieldsEntry.lookup?.tableId == null) {
					documentClassFieldsEntry.lookup!.tableId = 0;
				}

				if (documentClassFieldsEntry.lookup && documentClassFieldsEntry.lookup.minInputLength == null) {
					documentClassFieldsEntry.lookup.minInputLength = 1;
				}

				// set always the maxLength to zero (0) as default
				if (documentClassFieldsEntry.maxLength == null) {
					documentClassFieldsEntry.maxLength = 0;
				}
			}

			let promise;

			if (documentClassFieldsEntry && documentClassFieldsEntry.id) {
				// Update existing
				promise = documentClassService.putDocumentClassField(props.documentClassId, Number(documentClassFieldsEntry.id), documentClassFieldsEntry)
			} else {
				const sortOrders = props.documentClassFields.map(documentClass => documentClass.sortOrder);
				// Get highest sort order number and increase it by one
				if (sortOrders.length > 0) {
					const highestSortID = Math.max.apply(0, sortOrders as number[]) + 1;
					documentClassFieldsEntry.sortOrder = highestSortID;
				}
				// Create new
				promise = documentClassService.postDocumentClassField(props.documentClassId, documentClassFieldsEntry)
			}

			promise.then(() => {
				if (!keepDialogOpen) {
					showCurrentFieldDialog.value = false;
				} else {
					if (documentClassFieldsEntry) {
						documentClassFieldsEntry.name = "";
						documentClassFieldsEntry.description = "";
					}
					if (fieldFormElement.value) {
						fieldFormElement.value.$el.querySelector('input').focus();
					}
					isInvalid.value = true;
				}

				if (reloadTableData) {
					emit("getDocumentClassFields", props.documentClassId, false);
				}
			}).catch((err: Response) => {
				message.value = err.statusText;
				messageType.value = "error";

				// If the data should not be reloaded, that means there is no Edit-Dialog, therefore show a toast
				if (!reloadTableData) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.statusText);

					// Undo the current changes, if there is an error
					emit("getDocumentClassFields", props.documentClassId, false);
				}
			}).finally(() => loading.value = false)
		}

		/**
		 * Saves a Field from the dialog and emits if the dialog should be kept open
		 * @param {boolean} keepDialogOpen
		 */
		const saveFieldFromDialog = (keepDialogOpen: boolean) => {
			saveField(true, keepDialogOpen);
		}

		/**
		 * Delete a document class field
		 * @param {DocumentField} rowData Data of the row to delete
		 */
		const deleteField = (rowData: DocumentField) => {
			loading.value = true;
			documentClassService.deleteDocumentClassFieldById(props.documentClassId, Number(rowData.id)).then(() =>{
				showCurrentFieldDialog.value = false;
				emit("getDocumentClassFields", props.documentClassId, false);
			}).catch(response => response.json().then((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.DeleteError') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			});
		}

		/**
		 * Triggered when tab is changed
		 * @param {number} activeTabIndex
		 */
		const onTabChange = (activeTabIndex: number) => {
			currentDialogTabIndex.value = activeTabIndex;
		}

		/** Opens the fields layout */
		const openFieldsLayout = () => {
			showFieldsLayout.value = true;
		}

		/**
		 *  Is triggered when an entry should be edited/inserted from the Document-Class-Fields
		 *  @param {DocumentField} rowData Row to Save
		 *  @param {number} fieldGroupId Field-Group that is currently open
		 */
		const openCurrentFieldDialog = (rowData: DocumentField, fieldGroupId: number) => {
			if (rowData.id) {
				headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.Field') });
			} else {
				headerText.value = t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.DocumentClasses.Field') });
				rowData.documentClassId = props.documentClassId;
				rowData.fieldGroupId = fieldGroupId;
			}
			Object.assign(documentClassFieldsEntry, rowData);
			message.value = "";
			showCurrentFieldDialog.value = true;
		}

		/** Is triggered when a checkbox in the Field-Table is clicked. When such a checkbox is clicked, simply save the entry
		 *  @param {DocumentField} rowData Row to Save
		 *  @param {string} fieldName Name of the field that was clicked
		 */
		const changeCheckboxField = (rowData: DocumentField, fieldName: string) => {
			switch(fieldName) {
			case 'mandatory':
			case 'forceValidation': {
				// If a field is mandatory or "force Validation", it shouldn't be hidden
				if (rowData.mandatory === true || rowData.forceValidation === true) {
					rowData.hidden = false;
				}
				break;
			}
			case 'hidden': {
				// If a field is hidden, forcing the Validation can cause a falsy behavior in the validation
				if (rowData.hidden) {
					rowData.mandatory = false;
					rowData.forceValidation = false;
				}
				break;
			}
			}

			Object.assign(documentClassFieldsEntry, rowData);
			saveField(false);
		}

		/**
		 * Changes the order of document class fields
		 * @param {number} documentClassFields
		 * @param {number} fieldGroupId
		 * @param {boolean} isFilterActive
		 */
		const onChangeSortOrder = (documentClassFields: number[], fieldGroupId: number, isFilterActive: boolean) => {
			if (isFilterActive) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.ChangeError') + ": " + t('Squeeze.DocumentClasses.ActiveFilter'));
				return;
			} else {
				loading.value = true;

				documentClassService.putDocumentClassFieldOrder(props.documentClassId, fieldGroupId, {elements: documentClassFields})
					.then(() => {
						emit("getDocumentClassFields", props.documentClassId, false);
					}).catch(response => response.json().then((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.message);
					})).finally(() => {
						loading.value = false;
					})
			}
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.activeTabIndex, () => {
			showFieldsLayout.value = false;
		})

		return {
			t,
			toast,
			fieldFormElement,
			documentClassFieldsEntry,
			loading,
			showErrorMessage,
			isInvalid,
			headerText,
			message,
			messageType,
			showCurrentFieldDialog,
			currentInValidTab,
			currentDialogTabIndex,
			showFieldsLayout,
			onUpdateField,
			saveField,
			saveFieldFromDialog,
			deleteField,
			onTabChange,
			openFieldsLayout,
			openCurrentFieldDialog,
			changeCheckboxField,
			onChangeSortOrder,
		};
	},
});

