
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {ScriptDto} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {useRoute} from "vue-router";

export default defineComponent({
	name: "ScriptsList",
	components: {DataTable, Column},
	props: {
		allScripts: {
			type: Array as PropType<ScriptDto[]>,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ['executeScript'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Execute the Script
		 * @param script The script to execute
		 */
		const executeScript = (script: ScriptDto) => {
			emit('executeScript', script)
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {store, tableListPagination, executeScript, onChangePage};
	},
});

