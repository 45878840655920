import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, renderList as _renderList, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92e36f6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "p-grid"
}
const _hoisted_2 = {
  class: "p-col",
  style: {"text-align":"center"}
}
const _hoisted_3 = {
  key: 1,
  class: "p-grid"
}
const _hoisted_4 = {
  class: "p-col",
  style: {"text-align":"center"}
}
const _hoisted_5 = {
  key: 0,
  class: "p-grid p-mb-2 p-p-2"
}
const _hoisted_6 = {
  class: "p-col-fixed timestamp",
  style: {"width":"13rem"}
}
const _hoisted_7 = {
  key: 0,
  class: "p-col"
}
const _hoisted_8 = {
  key: 1,
  class: "p-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_BlockUI = _resolveComponent("BlockUI")!

  return (_openBlock(), _createElementBlock("div", {
    class: "p-p-2 p-pb-0 scroll-content",
    style: _normalizeStyle([{"background-color":"black","color":"white"}, _ctx.logs.length !== 0 ? 'height: 70vh !important' : ''])
  }, [
    _createVNode(_component_BlockUI, { blocked: _ctx.loading }, {
      default: _withCtx(() => [
        (_ctx.loading === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
            ]))
          : (_ctx.logsInternal.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('Squeeze.Validation.Log.NoLogs')), 1)
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (_ctx.showSort)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_Button, {
                        label: _ctx.$t('Squeeze.Validation.Log.ChangeSortOrder'),
                        type: "button",
                        icon: "mdi mdi-reload",
                        class: "p-button",
                        onClick: _ctx.changeSortOrder
                      }, null, 8, ["label", "onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ], 64)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logsInternal, (log) => {
          return (_openBlock(), _createElementBlock("div", {
            key: log,
            class: "p-grid"
          }, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatDate(log.time)), 1),
            (log.message.includes('\n'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(log.message.split('\n'), (item) => {
                    return (_openBlock(), _createElementBlock("div", { key: item }, _toDisplayString(item), 1))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(log.message), 1))
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["blocked"])
  ], 4))
}