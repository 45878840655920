import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e5361a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }
const _hoisted_3 = { class: "column-cell" }
const _hoisted_4 = {
  key: 0,
  class: "p-error column-cell"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DexDataTable = _resolveComponent("DexDataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DexDataTable, {
    value: _ctx.masterDataColumns,
    loading: _ctx.loading,
    class: "p-datatable-sm editable-cells-table",
    autoLayout: true,
    columnResizeMode: "fit",
    editMode: "cell",
    onCellEditInit: _ctx.onCellEdit,
    onCellEditComplete: _ctx.onCellCompleteEditing,
    onRowDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditDialog($event.data))),
    paginator: true,
    rows: _ctx.tableListPagination.pageSize,
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
    scrollable: true,
    scrollHeight: "flex",
    onPage: _ctx.onChangePage
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.MasterData.Columns') })), 1)
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button p-mr-2",
        onClick: _ctx.onNewEntryClick,
        disabled: _ctx.checkPermissions
      }, null, 8, ["label", "onClick", "disabled"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.MasterData.CreateResetTable'),
        type: "button",
        icon: "mdi mdi-refresh",
        class: "p-button refreshButton",
        onClick: _ctx.resetTable,
        disabled: _ctx.checkPermissions
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "name",
        header: _ctx.$t('Squeeze.MasterData.Name'),
        class: "column-cell",
        style: {"min-width":"10rem"}
      }, _createSlots({
        body: _withCtx((masterDataColumn) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(masterDataColumn.data.name), 1)
          ])), [
            [
              _directive_tooltip,
              masterDataColumn.data.name,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 2
      }, [
        (!_ctx.checkPermissions)
          ? {
              name: "editor",
              fn: _withCtx((masterDataColumn) => [
                _createVNode(_component_InputText, {
                  modelValue: masterDataColumn.data.name,
                  "onUpdate:modelValue": ($event: any) => ((masterDataColumn.data.name) = $event),
                  onDblclick: ($event: any) => (_ctx.openEditDialog(masterDataColumn.data)),
                  onKeyup: ($event: any) => (_ctx.changeEventInInput(masterDataColumn.data))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onDblclick", "onKeyup"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["header"]),
      _createVNode(_component_Column, {
        field: "description",
        header: _ctx.$t('Squeeze.MasterData.Description'),
        class: "column-cell",
        style: {"min-width":"10rem"}
      }, _createSlots({
        body: _withCtx((masterDataColumn) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(masterDataColumn.data.description), 1)
          ])), [
            [
              _directive_tooltip,
              masterDataColumn.data.description,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 2
      }, [
        (!_ctx.checkPermissions)
          ? {
              name: "editor",
              fn: _withCtx((masterDataColumn) => [
                _createVNode(_component_InputText, {
                  modelValue: masterDataColumn.data.description,
                  "onUpdate:modelValue": ($event: any) => ((masterDataColumn.data.description) = $event),
                  onDblclick: ($event: any) => (_ctx.openEditDialog(masterDataColumn.data)),
                  onKeyup: ($event: any) => (_ctx.changeEventInInput(masterDataColumn.data))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onDblclick", "onKeyup"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["header"]),
      _createVNode(_component_Column, {
        field: "sourceColumn",
        header: _ctx.$t('Squeeze.MasterData.SourceColumn'),
        class: "column-cell",
        style: {"min-width":"10rem"}
      }, _createSlots({
        body: _withCtx((masterDataColumn) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(masterDataColumn.data.sourceColumn), 1)
          ])), [
            [
              _directive_tooltip,
              masterDataColumn.data.sourceColumn,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 2
      }, [
        (!_ctx.checkPermissions)
          ? {
              name: "editor",
              fn: _withCtx((masterDataColumn) => [
                _createVNode(_component_InputText, {
                  modelValue: masterDataColumn.data.sourceColumn,
                  "onUpdate:modelValue": ($event: any) => ((masterDataColumn.data.sourceColumn) = $event),
                  onDblclick: ($event: any) => (_ctx.openEditDialog(masterDataColumn.data)),
                  onKeyup: ($event: any) => (_ctx.changeEventInInput(masterDataColumn.data))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onDblclick", "onKeyup"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["header"]),
      _createVNode(_component_Column, { style: {"min-width":"7rem","max-width":"7rem","text-align":"right"} }, {
        body: _withCtx((masterDataColumn) => [
          (_ctx.isInvalidMasterDataColumn(masterDataColumn.data))
            ? (_openBlock(), _createElementBlock("small", _hoisted_4, [
                (!masterDataColumn.data.name)
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createTextVNode(_toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.MasterData.Name')})), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.MasterData.Name')}),
                        void 0,
                        { bottom: true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                (!masterDataColumn.data.description)
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createTextVNode(_toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.MasterData.Description')})), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.MasterData.Description')}),
                        void 0,
                        { bottom: true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                (!masterDataColumn.data.sourceColumn)
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createTextVNode(_toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.MasterData.SourceColumn')})), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.MasterData.SourceColumn')}),
                        void 0,
                        { bottom: true }
                      ]
                    ])
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, { style: {"min-width":"7rem","max-width":"7rem","text-align":"right"} }, {
        body: _withCtx((masterDataColumn) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.openEditDialog(masterDataColumn.data)),
            disabled: _ctx.checkPermissions
          }, null, 8, ["onClick", "disabled"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Locators.Edit'),
              void 0,
              { bottom: true }
            ]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button-lg p-button-rounded p-button-danger p-button-text",
            onClick: ($event: any) => (_ctx.openDeleteDialog(masterDataColumn.data)),
            disabled: _ctx.checkPermissions
          }, null, 8, ["onClick", "disabled"]), [
            [
              _directive_tooltip,
              _ctx.$t('Squeeze.Validation.Buttons.Delete'),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "onCellEditInit", "onCellEditComplete", "rows", "currentPageReportTemplate", "onPage"]))
}