
import {
	defineComponent, onMounted, reactive, ref, watch
} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from "primevue/blockui";
import {ClientManager} from "@/singletons/ClientManager";
import {FieldLookupFilter, DocumentField, MasterDataColumn} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import LookupFilters from "@/apps/administration/components/documentclasses/LookupFilters.vue";
import LookupFiltersForm from "@/apps/administration/components/documentclasses/LookupFiltersForm.vue";

export default defineComponent({
	name: "LookupFiltersView",
	components: {
		LookupFilters, DialogDelete, EntryDialog, BlockUI, LookupFiltersForm,
	},
	props: {
		/** Currently active document-class */
		documentClassId: {
			type: Number,
			default: 0,
		},
		fieldId: {
			type: Number,
			default: 0,
		},
		tableId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Array with all document-class-fields */
		const documentClassFields = ref<DocumentField[]>([]);

		/** Array with the table columns */
		const masterDataTableColumns = ref<MasterDataColumn[]>([]);

		/** Service for getting the master-data */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** Is the Form of the mails invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** List of all files */
		const lookupFilters = ref<FieldLookupFilter[]>([]);

		/** Currently active mail */
		const lookupFilter = reactive<FieldLookupFilter>({
			documentFieldId: props.fieldId,
			masterDataColumnId: 0,
			valueFieldId: 0,
		})

		/** Should the Entry-Dialog for mails be shown? */
		const showDialog = ref<boolean>(false);

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Service for getting the import-data */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Get all File Import Configs */
		const getLookupFilters = () => {
			documentClassService.getFieldLookupFilters(props.documentClassId, props.fieldId)
				.then(data => {
					lookupFilters.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all File Import Configs */
		const getDocumentClassFields = () => {
			documentClassService.getAllDocumentClassFields(props.documentClassId)
				.then(data => {
					documentClassFields.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all File Import Configs */
		const getMasterDataTableColumns = () => {
			if (props.tableId) {
				masterDataService.getMasterDataTableColumns(props.tableId)
					.then(data => {
						masterDataTableColumns.value = data;
					})
					.catch(response => response.json().then ((err: any) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						loading.value = false;
					})
			}
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: FieldLookupFilter, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(lookupFilter, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.DocumentClasses.Filter') }));

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: FieldLookupFilter) => {
			Object.assign(lookupFilter, row);

			showDialog.value = true;
		}

		onMounted(async () =>{
			await getMasterDataTableColumns();
			await getDocumentClassFields();
			await getLookupFilters();
		})

		/** Saves a file */
		const saveFile = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let fileChangePromise = null;

			// make sure document field is always right
			lookupFilter.documentFieldId = props.fieldId;

			if (!lookupFilter.id) {
				fileChangePromise = documentClassService.postFieldLookupFilters(props.documentClassId, props.fieldId, lookupFilter);
				fileChangePromise.then(() => {
					if (!keepDialogOpen) {
						showDialog.value = false;
					}
					else {
						// Empty values and focus first input-field
						lookupFilter.id = undefined;
						isValidationInvalid.value = true;
					}
					getLookupFilters();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadingDialog.value = false;
					loading.value = false;
				})
			}
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: FieldLookupFilter) => {
			deleteDialog.value = true;
			Object.assign(lookupFilter, row);
		}

		/** Deletes a file */
		const deleteEntry = () => {
			if (lookupFilter && lookupFilter.id) {
				loading.value = true;
				documentClassService.deleteFieldLookupFilter(props.documentClassId, props.fieldId, lookupFilter.id).then(() => {
					getLookupFilters();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.tableId, () => {
			getMasterDataTableColumns();
		})

		return {t, toast, loading, loadingDialog, isValidationInvalid, showValidationMessage,
			lookupFilters, lookupFilter, showDialog, deleteDialog, headerText, documentClassFields, masterDataTableColumns,
			getFileImportConfigs: getLookupFilters, onUpdate, saveFile, onEntrySelect, openDeleteDialog, deleteEntry};
	},
});

