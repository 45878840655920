import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e22132f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-col-12 p-md-6 p-lg-4" }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-col-12 p-text-center" }
const _hoisted_4 = { class: "p-component view-title" }
const _hoisted_5 = { class: "p-component client-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", {
    class: "p-grid p-flex-column p-m-0 p-p-4 login-container p-jc-center p-ai-center",
    style: _normalizeStyle(_ctx.loginBackgroundImageStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Panel, { class: "p-shadow-2" }, {
        header: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle(_ctx.loginLogoStyles)
              }, null, 4))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('Squeeze.Login.SQUEEZE')), 1),
              _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.publicSystemInformation.tenantName), 1)
            ])
          ]),
          _createVNode(_component_LoginForm, { onLoginStatus: _ctx.loginStatus }, null, 8, ["onLoginStatus"])
        ]),
        _: 1
      })
    ])
  ], 4))
}