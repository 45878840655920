
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from "primevue/usetoast";
import MasterDataTablePermissionList from "@/apps/administration/components/masterdata/tabs/MasterDataTablePermissionList.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {MasterDataTablePermission, UserRole} from "@dex/squeeze-client-ts";
import MasterDataTablePermissionForm from "@/apps/administration/components/masterdata/tabs/MasterDataTablePermissionForm.vue";

export default defineComponent({
	name: "MasterDataPermissionView",
	components: {
		MasterDataTablePermissionForm,
		MasterDataTablePermissionList,
		DialogDelete,
		EntryDialog,
		BlockUI,
	},
	props: {
		masterDataTableId: {
			type: Number,
			default: 0,
			required: true,
		},
		permissions: {
			type: Object,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** List of all Permissions */
		const masterDataPermissions = ref<MasterDataTablePermission[]>([]);

		/** Currently active Permission */
		const masterDataPermission = reactive<MasterDataTablePermission>({
			tableId: props.masterDataTableId,
			roleId: undefined,
			canManage: false,
			canEdit: false,
		});

		/** List of all Roles they not selected */
		const checkRoles = ref<UserRole[]>([]);

		/** List of all Roles */
		const allRoles = ref<UserRole[]>([]);

		/** Service for getting the masterData-Data */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** Service for getting the masterData-Data */
		const roleService = ClientManager.getInstance().squeeze.role;

		/** Is the Form of the Column invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Show Loading on Save in Dialog */
		const loadingDialog = ref<boolean>(false);

		/** Should the Entry-Dialog for Columns be shown? */
		const showDialog = ref<boolean>(false);

		/** Which of the Entry-Dialog for Columns be shown? */
		const dialogMode = ref<string>('');

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (masterDataPermission.roleId) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.MasterData.Permission') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.MasterData.Permission') });
		});

		/** Get all masterData Permissions */
		const getMasterDataTablePermissions = () => {
			masterDataService.getTablePermissionsByTableId(props.masterDataTableId)
				.then((data: MasterDataTablePermission[]) => {
					masterDataPermissions.value = data;
				})
				.catch(response => response.json().then ((err: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all roles */
		const getAllRoles = () => {
			roleService.getRoles()
				.then((roles: UserRole[]) => {
					allRoles.value = roles;
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		onMounted(() => {
			getMasterDataTablePermissions();
			getAllRoles();
		})

		/** Check all selected roles to show in Roles-Dropdown unselected roles */
		const checkSelectedRoles = () => {
			checkRoles.value = allRoles.value;

			if (masterDataPermissions.value) {
				masterDataPermissions.value.forEach(tablePermission => {
					const permission = masterDataPermissions.value.find(permission => permission.roleId === tablePermission.roleId);
					if(permission) {
						checkRoles.value = checkRoles.value.filter(filterRoles => filterRoles.id !== permission.roleId);
					}
				})
			}

			if (checkRoles.value.length === 0) {
				showDialog.value = false;
				ToastManager.showInfo(toast, t('Squeeze.General.Info'), t('Squeeze.MasterData.InfoAllSelected'));
				return
			}

			showDialog.value = true;
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: MasterDataTablePermission, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(masterDataPermission, data);
		}

		/** Saves a masterData table permission */
		const saveMasterDataTablePermission = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}

			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let promise = null;

			if (masterDataPermission.tableId) {
				if (dialogMode.value === 'newEntry') {
					promise = masterDataService.postTablePermission(masterDataPermission.tableId, masterDataPermission);
				} else {
					promise = masterDataService.putTablePermission(masterDataPermission.tableId, masterDataPermission);
				}
			}

			promise!.then(() => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				} else {
					// Empty values and focus first input-field
					masterDataPermission.tableId = props.masterDataTableId;
					masterDataPermission.roleId = undefined;
					masterDataPermission.canManage = false;
					masterDataPermission.canEdit = false;
					isValidationInvalid.value = true;
				}
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
				getMasterDataTablePermissions();
			})
		}

		/** Triggered when a checkbox is clicked
		 * @param data
		 */
		const onChangeCheckbox = (data: MasterDataTablePermission) => {
			Object.assign(masterDataPermission, data);
			isValidationInvalid.value = false;
			dialogMode.value = 'edit';
			saveMasterDataTablePermission();
		}

		/**
		 * Triggered when an entry should be shown
		 * @param dialog
		 * @param row
		 */
		const onEntrySelect = (dialog: string, row: MasterDataTablePermission) => {
			if(dialog === 'newEntry') {
				checkSelectedRoles();
			} else {
				showDialog.value = true;
			}

			if(!row.tableId) {
				row.tableId = props.masterDataTableId;
			}
			Object.assign(masterDataPermission, row);
			dialogMode.value = dialog;
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: MasterDataTablePermission) => {
			deleteDialog.value = true;
			Object.assign(masterDataPermission, row);
		}

		/** Deletes a Column */
		const deleteEntry = () => {
			if (masterDataPermission) {
				loading.value = true;

				if (masterDataPermission.tableId) {
					masterDataService.deleteTablePermission(masterDataPermission.tableId, masterDataPermission)
						.then(() => {
							getMasterDataTablePermissions();
						}).catch(response => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						})).finally(() => {
							loading.value = false;
						})
				}
			}
		}

		return { t, toast, loading, masterDataPermissions, masterDataPermission, allRoles, checkRoles, isValidationInvalid, showValidationMessage, loadingDialog, showDialog, dialogMode, deleteDialog, headerText,
			getMasterDataTablePermissions, onUpdate, saveMasterDataTablePermission, onChangeCheckbox, onEntrySelect, openDeleteDialog, deleteEntry,
		}
	},
})
