import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c7ddee5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }
const _hoisted_3 = { class: "p-d-flex p-justify-end p-ai-center" }
const _hoisted_4 = { class: "exportInterfaceExpansionTable p-ml-sm-0 p-ml-md-6" }
const _hoisted_5 = { class: "p-text-bold p-p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.rows,
    loading: _ctx.loading,
    class: "p-datatable-sm",
    autoLayout: true,
    columnResizeMode: "fit",
    expandedRows: _ctx.expandedRows,
    "onUpdate:expandedRows": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expandedRows) = $event)),
    onRowDblclick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openEditDialog($event.data))),
    paginator: true,
    rows: _ctx.tableListPagination.pageSize,
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    rowsPerPageOptions: [25,50,100],
    currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
    scrollable: true,
    scrollHeight: "flex",
    onPage: _ctx.onChangePage
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.DocumentClasses.ExportInterfaces') })), 1)
    ]),
    expansion: _withCtx((slotProps) => [
      _createElementVNode("table", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getExportInterfaceProperties(slotProps.data.interfaceName), (property, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: index,
            class: "p-p-2"
          }, [
            _createElementVNode("td", _hoisted_5, _toDisplayString(property.name) + ": ", 1),
            _createElementVNode("td", null, [
              (property.type === 'string')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(slotProps.data.configuration[property.id]), 1)
                  ], 64))
                : (property.type === 'password')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" *** ")
                    ], 64))
                  : (property.type === 'select')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createTextVNode(_toDisplayString(_ctx.getSelectedValueName(property.options, slotProps.data.configuration[property.id])), 1)
                      ], 64))
                    : (property.type === 'sequenceFromSet')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          (!slotProps.data.configuration[property.id] || !slotProps.data.configuration[property.id].length)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode("-")
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(slotProps.data.configuration[property.id].join('/')), 1)
                              ], 64))
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                          _createTextVNode(_toDisplayString(slotProps.data.configuration[property.id]), 1)
                        ], 64))
            ])
          ]))
        }), 128))
      ])
    ]),
    paginatorstart: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('Squeeze.General.NewEntry'),
        type: "button",
        icon: "mdi mdi-plus",
        class: "p-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openNewEntry()))
      }, null, 8, ["label"])
    ]),
    paginatorend: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        expander: true,
        headerStyle: "width: 3rem",
        style: {"min-width":"4rem","max-width":"4rem"}
      }),
      _createVNode(_component_Column, {
        field: "id",
        header: "ID",
        sortable: false,
        filterMatchMode: "contains",
        style: {"min-width":"4rem","max-width":"4rem"}
      }),
      _createVNode(_component_Column, {
        field: "interfaceName",
        header: _ctx.$t('Squeeze.DocumentClasses.Type'),
        sortable: false,
        filterMatchMode: "contains",
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.getExportInterfaceTypeName(slotProps.data.interfaceName)), 1)
          ])), [
            [
              _directive_tooltip,
              _ctx.getExportInterfaceTypeName(slotProps.data.interfaceName),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "description",
        header: _ctx.$t('Squeeze.DocumentClasses.Description'),
        sortable: false,
        filterMatchMode: "contains",
        style: {"min-width":"10rem"}
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(slotProps.data.description), 1)
          ])), [
            [
              _directive_tooltip,
              slotProps.data.description,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "active",
        header: _ctx.$t('Squeeze.DocumentClasses.Active'),
        sortable: false,
        filterMatchMode: "contains",
        headerClass: "header-center",
        style: {"min-width":"6rem","max-width":"6rem","text-align":"center"}
      }, {
        body: _withCtx((slotProps) => [
          _createVNode(_component_Checkbox, {
            onChange: ($event: any) => (_ctx.onChangeCheckbox(slotProps.data)),
            modelValue: slotProps.data.active,
            "onUpdate:modelValue": ($event: any) => ((slotProps.data.active) = $event),
            binary: true
          }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, { style: {"min-width":"7rem","max-width":"7rem"} }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              label: _ctx.$t('Squeeze.Locators.Testing'),
              class: "p-button p-mr-2",
              onClick: ($event: any) => (_ctx.testExportInterface(slotProps.data))
            }, null, 8, ["label", "onClick"]),
            _withDirectives(_createVNode(_component_Button, {
              icon: "mdi mdi-table-eye",
              class: "p-button-rounded p-button-text p-mr-2",
              onClick: ($event: any) => (_ctx.openExportStates(slotProps.data))
            }, null, 8, ["onClick"]), [
              [_directive_tooltip, _ctx.$t('Squeeze.DocumentClasses.ShowExportStates')]
            ]),
            _withDirectives(_createVNode(_component_Button, {
              icon: "mdi mdi-pencil-outline",
              class: "p-button-rounded p-button-success p-button-text p-mr-2",
              onClick: ($event: any) => (_ctx.openEditDialog(slotProps.data))
            }, null, 8, ["onClick"]), [
              [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
            ]),
            _withDirectives(_createVNode(_component_Button, {
              icon: "mdi mdi-delete-outline",
              class: "p-button-lg p-button-rounded p-button-danger p-button-text",
              onClick: ($event: any) => (_ctx.openDeleteDialog(slotProps.data))
            }, null, 8, ["onClick"]), [
              [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "loading", "expandedRows", "rows", "currentPageReportTemplate", "onPage"]))
}