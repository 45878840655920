
import {
	defineComponent
	// ref,
	// reactive,
	// computed,
	// onMounted,
	// watch
} from 'vue';

export default defineComponent({
	name: 'DashboardTile',
	props: {
		title: { type: String, required: false },
	},
	setup() {
		//
	},
});

