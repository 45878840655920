
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue';
import { useI18n } from 'vue-i18n';
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import BlockUI from 'primevue/blockui';
import {email, minLength, required, sameAs} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default defineComponent({
	name: "ProcessItemAdminUser",
	components: {
		Password,
		InputText,
		Button,
		BlockUI,
	},
	props: {
		adminUser: {
			type: Object,
		},
	},
	emits: ['update', 'goToPreviousOrNextStep'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Current Object of all input-fields */
		const value = reactive<any>(props.adminUser);

		/** Determines the required rules for validation */
		const rules = {
			//login: { required },
			firstname: { required },
			lastname: { required },
			email: { required, email },
			password: {  required, minLength: minLength(6) },
			passwordRepeat: {  required, sameAsPassword: sameAs(computed(()=> value.password)) },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		/** Is loading? */
		const loading = ref<boolean>(false);

		/** Should the ui be blocked? */
		const blocked = ref<boolean>(false);

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered on click */
		const goToNextStep = () => {
			if(v$.value.$invalid) {
				showErrorMessage.value = true;
				return
			}
			emit('goToPreviousOrNextStep', 1);
		}

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		/**
		 * Triggered on keypress
		 * @param event
		 */
		const onKeyDown = (event: {key: string}) => {
			if (event.key === "Enter") {
				goToNextStep();
			}
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.adminUser, () => {
			Object.assign(value, props.adminUser);
			emit("update", value, v$.value.$invalid);
		})

		onMounted(async () => {
			Object.assign(value, props.adminUser);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		return {
			t,
			v$,
			loading,
			blocked,
			showErrorMessage,
			goToNextStep,
			update,
			onKeyDown,
		}
	},
})
