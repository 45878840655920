
import {defineComponent, computed} from "vue";
import TabMenu from "@/components/DexTabMenu.vue";
import {useI18n} from "vue-i18n";
import {MenuItem} from "@/shims-prime-vue";

export default defineComponent({
	name: "MailView",
	components: {
		TabMenu,
	},
	setup() {
		const {t} = useI18n();

		/** List of all Tab-Items */
		const items = computed(() => {
			const itemsList: MenuItem[] = [
				{label: t('Squeeze.Imports.MailConfig'), to: { name: 'MailSubView' }},
				{label: t('Squeeze.Imports.Passwords'), to: { name: 'PasswordListView' }},
			]

			return itemsList;
		});

		return {
			TabMenu,items,
		}
	},
});
