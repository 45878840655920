
import { ClientManager } from '@/singletons/ClientManager';
import { ToastManager } from '@/util/ToastManager';
import { useToast } from 'primevue/usetoast';
import {defineComponent, onMounted, ref} from 'vue';
import {useI18n} from "vue-i18n";

interface NewsDto { // TODO: Replace with API model
	date: string;
	group: string;
	title: string;
	text: string;
}

export default defineComponent({
	name: 'News',
	components: {},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const api = ClientManager.getInstance().squeeze.dexp;

		/** Array of fake data */
		const newsData = ref<any[]>([]); // TODO: Add type

		const fetchData = async () => {
			const news = await api.getDexpNews();

			// sort fakeData by date
			news.sort((a: any ,b: any) => {
				return (new Date(b.date) as any) - (new Date(a.date) as any)
			});

			newsData.value.push(news);
		}

		onMounted(() => {
			fetchData().then(() => {
				// this gives an object with dates as keys
				const dateGroups = newsData.value[0].reduce((dateGroups: any, news: any) => {
					if (!dateGroups[news.date]) {
						dateGroups[news.date] = [];
					}
					dateGroups[news.date].push(news);
					return dateGroups;
				}, {});

				const newsGroups = Object.keys(dateGroups).map((date) => {
					return {
						date,
						allNews: dateGroups[date],
					};
				});

				// this gives an object with groupNames as keys
				newsGroups.forEach((dateGroup: any, index: any) => {
					const groupNameGroup = newsGroups[index].allNews.reduce((nameGroup: any, news: any) => {
						if (!nameGroup[news.group]) {
							nameGroup[news.group] = [];
						}
						nameGroup[news.group].push(news);
						return nameGroup;
					}, {});

					dateGroup.allNews = Object.keys(groupNameGroup).map((group) => {
						return {
							group,
							allNewsByGroup: groupNameGroup[group],
						};
					});
				})

				newsData.value.splice(0);
				newsData.value.push(newsGroups);
			}).catch(err => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})
		})

		return { t, newsData };
	},
});

