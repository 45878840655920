
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import LocatorForm from "@/apps/administration/components/locators/LocatorForm.vue";
import BlockUI from "primevue/blockui";
import EntryDialog from "@/components/EntryDialog.vue";
import useSqueezeStore from "@/apps/squeeze/store";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {DocumentLocator, LocatorType} from '@dex/squeeze-client-ts';
import {ClientManager} from "@/singletons/ClientManager";
import router from "@/router";
import {Clients, ConsoleLogger, LocatorConfig, LocatorExporter} from "@dex/squeeze-configframework";
import {copyToClipboard, downloadData} from "@/util/Export";
import ProgressBar from "primevue/progressbar";

export default defineComponent({
	name: "LocatorSubView",
	methods: {copyToClipboard, downloadData},
	components: {
		ProgressBar,
		LocatorForm,
		BlockUI,
		EntryDialog,
	},
	props: {
		/** Currently active document-class */
		locatorId: {
			type: Number,
			default: 0,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'onLocatorChange',
	],
	setup(props, { emit }) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Vuex-Store */
		const store = useSqueezeStore();

		/** Current Locator */
		const locator = reactive<DocumentLocator>({});

		/** List of all locators **/
		const locators = ref<DocumentLocator[]>([]);

		/** List of all locator types **/
		const locatorTypes = ref<LocatorType[]>([]);

		/** Service for locator */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** Is the page currently loading its data? */
		const loading = ref<boolean>(false);

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref<boolean>(true);

		/** Exported locator data as string  */
		const locatorData = ref<string>('');

		/** Is Export Dialog visible? */
		const showExportDialog = ref<boolean>(false);

		/** Is the Loading Process Bar visible? */
		const showProcessBar = ref<boolean>(false);

		/** Gets a Locator */
		const getLocator = () => {
			const handleErr = (msg: string, err: any) => ToastManager.showError(toast, t('Squeeze.General.Error'), t(msg) + ": " + err);
			loading.value = true;

			let promise1 = undefined;
			if (props.locatorId) {
				promise1 = locatorService.getLocatorById(props.locatorId)
					.then(data => Object.assign(locator, data))
					.catch(err => handleErr('Squeeze.Locators.ErrorLoadLocators', err))
			}

			const promise2 = locatorService.getLocatorTypes()
				.then((data: any) => locatorTypes.value = data)
				.catch((err: any) => handleErr('Squeeze.Locators.ErrorLoadLocatorTypes', err));

			const promise3 = locatorService.getAllLocators()
				.then(data => {
					// add defaultLocator to locators
					const defaultLocator: any = {
						id: 0,
						name: 'NoLocator',
						description: t('Squeeze.Locators.NoLocator'),
					}
					locators.value = data.filter(loc => loc.id !== locator.id); // locators should not be able to reference themselves
					locators.value.unshift(defaultLocator);
				})
				.catch(err => handleErr('Squeeze.Locators.ErrorLoadLocators', err));

			// Wait until promises are finished
			Promise.all([promise1, promise2, promise3]).then(() => loading.value = false);
		}

		/** Saves a Locator */
		const saveLocator = () => {
			if (isInvalid.value) {
				showErrorMessage.value = true;
				return;
			}

			let promise;

			showErrorMessage.value = false;
			loading.value = true;

			// Some old entries do have an empty string as type, which is the same as "Text", but can't be saved as such
			if (locator.dataType === "" || locator.locatorType === 2) {
				//the Locator-Type "Invoice Amounts" only allows "Text"
				locator.dataType = "Text";
			}

			if (!locator.id) {
				// Create New Entry
				promise = locatorService.postLocator(locator)
					.then(data => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.CreateSuccess'))
						if (data.id) {
							router.push({ name: 'LocatorSubView', params: { locatorId: data.id}})
							locator.id = data.id;
						}
					})
			} else {
				// Update Entry
				promise = locatorService.putLocatorById(props.locatorId, locator)
					.then(() => ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess')))
			}

			promise
				.catch((err: { statusText: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.statusText)
				})
				.finally(() => {
					loading.value = false;
				});
		}

		/** Triggered on update of attribute-form
		 * @param data
		 * @param valid
		 */
		const onUpdate = (data: DocumentLocator, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(locator, data);
			emit("onLocatorChange", locator);
		}

		/** Run export of locator */
		const runExportOfLocator = async (locatorExporter: LocatorExporter) => {
			showExportDialog.value = true;
			showProcessBar.value = true;
			loading.value = true;
			locatorData.value = "";

			try {
				const data: LocatorConfig[] = await locatorExporter.run()
					.finally(() => {
						showProcessBar.value = false;
					});
				locatorData.value = JSON.stringify({locators: data}, null, 2);
			} catch (error) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), error);
			} finally {
				loading.value = false;
			}
		}

		/** Export of a locator */
		const exportLocator = async () => {
			const clients: Clients = {
				documentClass: ClientManager.getInstance().squeeze.documentClass,
				masterData: ClientManager.getInstance().squeeze.masterData,
				locator: locatorService,
			}
			const locatorExporter = new LocatorExporter(clients, new ConsoleLogger());

			if (locator.name) {
				locatorExporter.addLocator(locator.name);

				await runExportOfLocator(locatorExporter);
			}
		}

		onMounted(() =>{
			getLocator();
		})

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.locatorId, () => {
			getLocator();
		})

		return {
			t,
			store,
			locator,
			locators,
			locatorTypes,
			loading,
			showErrorMessage,
			isInvalid,
			locatorData,
			showExportDialog,
			showProcessBar,
			getLocator,
			saveLocator,
			onUpdate,
			exportLocator,
			runExportOfLocator,
		};
	},
});

