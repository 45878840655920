import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1aec028d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = {
  key: 0,
  class: "p-field p-col-12"
}
const _hoisted_3 = { for: "ocrEngine" }
const _hoisted_4 = {
  key: 1,
  class: "p-field p-col-6"
}
const _hoisted_5 = { class: "flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputIcon = _resolveComponent("InputIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_IconField = _resolveComponent("IconField")!
  const _component_OverlayHelpDialog = _resolveComponent("OverlayHelpDialog")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (attribute, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (attribute && attribute.settingName === 'OCREngine')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("label", _hoisted_3, _toDisplayString(attribute.settingName) + " *", 1),
                (attribute.schema && attribute.schema.enum)
                  ? (_openBlock(), _createBlock(_component_Dropdown, {
                      key: 0,
                      id: "ocrEngine",
                      modelValue: attribute.value,
                      "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                      options: attribute.schema.enum,
                      optionLabel: _ctx.getSchemaEnums,
                      filter: true,
                      autoFilterFocus: true,
                      onShow: _ctx.showDropdownOverlay,
                      onChange: ($event: any) => (_ctx.update(attribute))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "optionLabel", "onShow", "onChange"]))
                  : _createCommentVNode("", true)
              ]))
            : (attribute && attribute.settingName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("label", null, _toDisplayString(attribute.settingName), 1),
                  _createVNode(_component_IconField, null, {
                    default: _withCtx(() => [
                      (_ctx.showDocumentationDialog && _ctx.currentHelpFieldName === attribute.settingName)
                        ? (_openBlock(), _createBlock(_component_InputIcon, {
                            key: 0,
                            class: "mdi mdi-comment-question-outline",
                            style: {"cursor":"pointer","right":"5rem","z-index":"2"},
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleOverlay($event)))
                          }))
                        : _createCommentVNode("", true),
                      (attribute.schema && attribute.schema.type === 'boolean' && !attribute.schema.enum)
                        ? (_openBlock(), _createBlock(_component_Dropdown, {
                            key: 1,
                            ref_for: true,
                            ref: (el) => _ctx.dropdowns[index] = el,
                            modelValue: attribute.value,
                            "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                            options: _ctx.listOfBooleans,
                            optionValue: "id",
                            optionLabel: "name",
                            showClear: !attribute.schema.default,
                            onChange: ($event: any) => (_ctx.update(attribute)),
                            onShow: _ctx.showDropdownOverlay,
                            onFocus: ($event: any) => (_ctx.onClickFieldHelpIcon(attribute)),
                            disabled: _ctx.isFieldDisabled(attribute)
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "showClear", "onChange", "onShow", "onFocus", "disabled"]))
                        : (attribute.schema && attribute.schema.type === 'string' && !attribute.schema.enum)
                          ? (_openBlock(), _createBlock(_component_InputText, {
                              key: 2,
                              modelValue: attribute.value,
                              "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                              required: "true",
                              onInput: ($event: any) => (_ctx.update(attribute)),
                              placeholder: attribute.schema.example ? attribute.schema.example : '',
                              disabled: _ctx.isFieldDisabled(attribute),
                              onFocus: ($event: any) => (_ctx.onClickFieldHelpIcon(attribute))
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder", "disabled", "onFocus"]))
                          : (attribute.settingName === 'OCRLanguage')
                            ? (_openBlock(), _createBlock(_component_MultiSelect, {
                                key: 3,
                                ref_for: true,
                                ref: (el) => _ctx.dropdowns[index] = el,
                                modelValue: attribute.value,
                                "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                                optionLabel: _ctx.getSchemaEnums,
                                options: attribute.schema.enum,
                                filter: true,
                                autoFilterFocus: true,
                                selectionLimit: 4,
                                display: "chip",
                                closeIcon: "mdi mdi-close",
                                onChange: ($event: any) => (_ctx.update(attribute)),
                                onBeforeShow: ($event: any) => (_ctx.onClickFieldHelpIcon(attribute)),
                                disabled: _ctx.isFieldDisabled(attribute)
                              }, {
                                value: _withCtx((slotProps) => [
                                  _createElementVNode("div", _hoisted_5, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.value, (option, index) => {
                                      return (_openBlock(), _createBlock(_component_Chip, {
                                        key: option,
                                        label: option,
                                        removable: "",
                                        class: "p-mr-2",
                                        onRemove: ($event: any) => (slotProps.value.splice(index, 1), _ctx.update(attribute))
                                      }, null, 8, ["label", "onRemove"]))
                                    }), 128))
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "optionLabel", "options", "onChange", "onBeforeShow", "disabled"]))
                            : (attribute.schema && attribute.schema.enum)
                              ? (_openBlock(), _createBlock(_component_Dropdown, {
                                  key: 4,
                                  ref_for: true,
                                  ref: (el) => _ctx.dropdowns[index] = el,
                                  modelValue: attribute.value,
                                  "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                                  optionLabel: _ctx.getSchemaEnums,
                                  options: attribute.schema.enum,
                                  onChange: ($event: any) => (_ctx.update(attribute)),
                                  filter: true,
                                  autoFilterFocus: true,
                                  showClear: !attribute.schema.default,
                                  onShow: _ctx.showDropdownOverlay,
                                  onFocus: ($event: any) => (_ctx.onClickFieldHelpIcon(attribute)),
                                  disabled: _ctx.isFieldDisabled(attribute)
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "optionLabel", "options", "onChange", "showClear", "onShow", "onFocus", "disabled"]))
                              : (_openBlock(), _createBlock(_component_InputText, {
                                  key: 5,
                                  modelValue: attribute.value,
                                  "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                                  required: "true",
                                  onInput: ($event: any) => (_ctx.update(attribute))
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"]))
                    ]),
                    _: 2
                  }, 1024)
                ]))
              : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _createVNode(_component_OverlayPanel, { ref: "overlayHelpList" }, {
      default: _withCtx(() => [
        _createVNode(_component_OverlayHelpDialog, { currentHelpFieldName: _ctx.currentHelpFieldName }, null, 8, ["currentHelpFieldName"])
      ]),
      _: 1
    }, 512)
  ], 64))
}