import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FileUpload, {
        name: "importTranslationFile",
        customUpload: true,
        multiple: false,
        showCancelButton: true,
        uploadFiles: _ctx.files,
        cancelLabel: _ctx.$t('Squeeze.General.Clear'),
        chooseLabel: _ctx.$t('Squeeze.General.Choose'),
        customProgress: _ctx.progress,
        uploadLabel: _ctx.uploadLabel,
        customUploadDisabled: _ctx.showUploadButton,
        fileContentHeight: '5rem',
        invalidFileSizeMessage: '{0}: ' + _ctx.$t('Squeeze.Validation.General.InvalidFileSize') + ' ' + '{1}',
        onSelect: _ctx.onSelectFiles,
        onUploader: _ctx.fileUploader,
        onClear: _ctx.clearFiles,
        onRemoveFile: _ctx.removeFile,
        accept: '.' + _ctx.value.fileType
      }, null, 8, ["uploadFiles", "cancelLabel", "chooseLabel", "customProgress", "uploadLabel", "customUploadDisabled", "invalidFileSizeMessage", "onSelect", "onUploader", "onClear", "onRemoveFile", "accept"])
    ])
  ]))
}