import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createBlock(_component_TabView, {
    activeIndex: _ctx.activeTab,
    "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
    lazy: ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (tab) => {
        return (_openBlock(), _createBlock(_component_TabPanel, {
          key: tab.label,
          header: tab.label
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(tab.to), { userId: _ctx.userId }, null, 8, ["userId"]))
          ]),
          _: 2
        }, 1032, ["header"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["activeIndex"]))
}