import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fdf62830"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 child-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserList = _resolveComponent("UserList")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _component_UserRoleForm = _resolveComponent("UserRoleForm")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UserList, {
        users: _ctx.users,
        loading: _ctx.loading,
        onOnClickDelete: _ctx.openDeleteDialog,
        onOnEntrySelect: _ctx.onEntrySelect,
        showEditButton: false
      }, null, 8, ["users", "loading", "onOnClickDelete", "onOnEntrySelect"])
    ]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDialog = false)),
      onOnConfirm: _ctx.deleteEntry
    }, null, 8, ["showDialog", "onOnConfirm"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false, _ctx.isValidationInvalid = true, _ctx.showValidationMessage = false)),
      onOnConfirm: _ctx.saveUserToRole,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText,
      showKeepDialogOpen: true
    }, {
      content: _withCtx(() => [
        _createVNode(_component_BlockUI, { blocked: _ctx.loadingDialog }, {
          default: _withCtx(() => [
            _createVNode(_component_UserRoleForm, {
              user: _ctx.user,
              allUsers: _ctx.allUsers,
              showErrorMessage: _ctx.showValidationMessage,
              onUpdate: _ctx.onUpdate
            }, null, 8, ["user", "allUsers", "showErrorMessage", "onUpdate"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText"])
  ], 64))
}