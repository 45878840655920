
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {DocumentField, RoleFilterFieldCondition} from "@dex/squeeze-client-ts";
import {CompareOperations} from "@/util/CompareOperations";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {useRoute} from "vue-router";

export default defineComponent({
	name: "FieldConditions",
	components: {
		DataTable, Column,
	},
	props: {
		fieldConditions: {
			type: Array as PropType<RoleFilterFieldCondition[]>,
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
		operators: {
			type: Array as PropType<CompareOperations[]>,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<RoleFilterFieldCondition>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: RoleFilterFieldCondition) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {operator: 'eq', fieldId: undefined, id: undefined, filterValue: ''})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: RoleFilterFieldCondition) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Gets the Description of a Field
		 * @param row
		 */
		const getDocumentFieldDescription = (row: RoleFilterFieldCondition): string => {
			const field = props.documentClassFields?.find(field => field.id === row.fieldId);

			if (field) {
				return field.description!;
			}

			return String(row.fieldId);
		}

		/**
		 * Gets the Description of a Operator
		 * @param row
		 */
		const getOperatorDescription = (row: RoleFilterFieldCondition): string => {
			const operator = props.operators?.find((op: CompareOperations) => op.id === row.operator);
			if (operator) {
				return operator.description;
			}

			return String(row.operator!);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			entryDataCheck, DataTable, Column, tableListPagination, openDeleteDialog, onNewEntryClick, openEditDialog, getDocumentFieldDescription,
			getOperatorDescription, onChangePage,
		}
	},
});
