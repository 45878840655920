import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f95d178"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-component header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "month-text"
}
const _hoisted_4 = {
  key: 1,
  class: "month-text"
}
const _hoisted_5 = { class: "p-component date-header" }
const _hoisted_6 = { class: "p-component group-header" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('Squeeze.General.News.News')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsData[0], (data, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: data.date,
        class: "p-component p-pb-2"
      }, [
        (_ctx.newsData[0][index -1])
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              (new Date(data.date).toLocaleString('default', { month: 'long' }) !== new Date(_ctx.newsData[0][index -1].date).toLocaleString('default', { month: 'long' }))
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(new Date(data.date).toLocaleString('default', { month: 'long' }).toUpperCase()), 1))
                : _createCommentVNode("", true)
            ]))
          : (index === 0 || !index)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(new Date(data.date).toLocaleString('default', { month: 'long' }).toUpperCase()), 1))
            : _createCommentVNode("", true),
        _createElementVNode("h3", _hoisted_5, _toDisplayString(data.date), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.allNews, (newsGroup) => {
          return (_openBlock(), _createElementBlock("div", {
            key: newsGroup.group
          }, [
            _createElementVNode("span", {
              class: _normalizeClass([
					{'mdi mdi-star-outline': newsGroup.group.includes('new')},
					{'mdi mdi-rocket-outline': newsGroup.group.includes('improved')},
					{'mdi mdi-android-debug-bridge': newsGroup.group === 'Fixes'},
				])
            }, [
              _createElementVNode("h4", _hoisted_6, _toDisplayString(newsGroup.group), 1)
            ], 2),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsGroup.allNewsByGroup, (news) => {
              return (_openBlock(), _createElementBlock("ul", {
                key: news.title
              }, [
                _createElementVNode("li", null, [
                  _createElementVNode("i", null, _toDisplayString(news.title), 1),
                  _createTextVNode(" - " + _toDisplayString(news.text), 1)
                ])
              ]))
            }), 128))
          ]))
        }), 128)),
        (_ctx.newsData[0][index +1])
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
              (new Date(data.date).toLocaleString('default', { month: 'long' }) !== new Date(_ctx.newsData[0][index +1].date).toLocaleString('default', { month: 'long' }))
                ? (_openBlock(), _createElementBlock("hr", _hoisted_8))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 64))
}