
import {DataTableCellEditCompleteEvent} from "primevue/datatable";
import DexDataTable from "@/components/datatable/DexDataTable.vue";
import Column from "primevue/column";
import {defineComponent, nextTick, onMounted, PropType, reactive, ref} from "vue";
import {LocatorSubstitution} from "@dex/squeeze-client-ts";
import InputText from "primevue/inputtext";
import { helpers } from '@vuelidate/validators'
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: "Substitutions",
	components: {
		DexDataTable, Column, InputText,
	},
	props: {
		substitutions: {
			type: Array as PropType<LocatorSubstitution[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onChangeInputField",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<LocatorSubstitution>({});

		/** Is InputField changed?  */
		const changeInputField = ref<boolean>(false);

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: LocatorSubstitution) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {id: 0, orgChar: '', newChar: ''})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: LocatorSubstitution) => {
			emit("onEntrySelect", row);
		}

		/** Check the Substitution is invalid */
		const isInvalidSubstitution = (row: LocatorSubstitution) => {
			return (!helpers.req(row.orgChar) || !helpers.req(row.newChar));
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: DataTableCellEditCompleteEvent|any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
			event.originalEvent.target.childNodes[2].focus();
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * (change-Event in editing cell doesn't work in firefox)
		 * @param event
		 */
		const onCellComplete = (event: DataTableCellEditCompleteEvent) => {
			if (changeInputField.value) {
				emit("onChangeInputField", event, isInvalidSubstitution(event.newData));
				changeInputField.value = false;
			} else {
				Object.assign(entryDataCheck, null);

				// We need to trigger this, so the handler move the focus to the next cell is executed
				document.dispatchEvent(new CustomEvent('reloadDone', { detail: event }));
			}
		}

		/**
		 * Check if input was changed
		 * @param inputData
		 */
		const changeEventInInput = (inputData: unknown) => {
			if (JSON.stringify(inputData) !== JSON.stringify(entryDataCheck)) {
				changeInputField.value = true;
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t,
			toast,
			store,
			tableListPagination,
			entryDataCheck,
			changeInputField,
			openDeleteDialog,
			onNewEntryClick,
			openEditDialog,
			isInvalidSubstitution,
			onCellEdit,
			onCellComplete,
			changeEventInInput,
			onChangePage,
		}
	},
});
