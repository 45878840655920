
import {computed, defineComponent, nextTick, PropType, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";
import MultiSelect from 'primevue/multiselect';
import {showDropdownOverlay} from "@/util/StylesHelper";
import {BatchClassSetting} from "@dex/squeeze-client-ts";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from "primevue/inputtext";
import OverlayPanel from "primevue/overlaypanel";
import OverlayHelpDialog from "@/apps/administration/components/batchclasses/OverlayHelpDialog.vue";
import Chip from "primevue/chip";

export default defineComponent({
	name: "OCRConfigForm",
	methods: {showDropdownOverlay},
	components: {
		Chip,
		OverlayHelpDialog,
		OverlayPanel,
		IconField,
		InputIcon,
		InputText,
		MultiSelect,
		Dropdown,
	},
	props: {
		ocrAttributes: {
			type: Array as PropType<BatchClassSetting[]>,
			default: () => [],
		},
	},
	emits: [
		"update",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Component of the dropdowns */
		const dropdowns = ref<any>({});

		/** Current Array of all input-fields */
		const value = reactive<BatchClassSetting[]>([]);

		/** Is the Documentation Dialog visible? */
		const showDocumentationDialog = ref<boolean>(false);

		/** Current help field name */
		const currentHelpFieldName = ref<string>('');

		/** Component of the overlay panel for the documentation of all functionalities */
		const overlayHelpList = ref<any>();

		/** List of booleans */
		const listOfBooleans = computed(() => {
			return [
				{
					id: 'true',
					name: t('Squeeze.General.Yes'),
				},
				{
					id: 'false',
					name: t('Squeeze.General.No'),
				},
			]
		});

		/**
		 * Return all schema enums for dropdown
		 * @param schemaEnum
		 */
		const getSchemaEnums = (schemaEnum: string): string => {
			return schemaEnum;
		}

		/**
		 * Triggered on change of any field
		 * @param attribute
		 */
		const update = async (attribute: BatchClassSetting) => {
			await nextTick(); // Otherwise the change of the dropdown will not be recognised fast enough
			emit("update", attribute);
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.ocrAttributes, () => {
			const ocrLanguageSetting = props.ocrAttributes.find(att => att.settingName === "OCRLanguage");
			// check type because it can be also an array (this is an array when the multiselect is active)
			if (ocrLanguageSetting && ocrLanguageSetting.value && typeof ocrLanguageSetting.value === 'string') {
				ocrLanguageSetting.value = (ocrLanguageSetting.value.split('+') as any);
			}
			Object.assign(value, props.ocrAttributes);
		})

		/**
		 * Return boolean label of an attribute in a dropdown
		 * @param {string} id
		 */
		const getBooleanDropdownLabel= (id: string) => {
			return listOfBooleans.value.find(bool => bool.id === id)!.name;
		}

		/**
		 * Check if field should be disabled
		 * @param attribute
		 */
		const isFieldDisabled = (attribute: BatchClassSetting) => {
			return props.ocrAttributes[0] && props.ocrAttributes[0].value !== 'default' &&
				(attribute.settingName === 'OCRForce'
				|| attribute.settingName === 'PSM-Mode'
				|| attribute.settingName === 'OCRLanguage'
				|| attribute.settingName === 'OCRTesseractVersion');
		}

		/**
		 * Triggered if the field help icon is clicked
		 * @param {BatchClassSetting} attribute
		 */
		const onClickFieldHelpIcon = (attribute: BatchClassSetting) => {
			currentHelpFieldName.value = attribute.settingName!;
			showDocumentationDialog.value = true;
		}

		/**
		 * Toggle the overlay panel
		 * Is the overlay panel shown?
		 * @param event
		 * @param dropdownIndex
		 */
		const toggleOverlay = (event: Event, dropdownIndex?: number) => {
			if (dropdownIndex)	{
				const dropdown = dropdowns.value[dropdownIndex];

				if (dropdown.overlayVisible && overlayHelpList.value.visible) {
					dropdown.hide();
				} else if (!dropdown.overlayVisible && !overlayHelpList.value.visible) {
					dropdown.show();
				}
			}
			overlayHelpList.value.toggle(event);
		}

		return {
			t, toast,
			dropdowns,
			value,
			showDocumentationDialog,
			currentHelpFieldName,
			overlayHelpList,
			listOfBooleans,
			getSchemaEnums,
			update,
			getBooleanDropdownLabel,
			isFieldDisabled,
			onClickFieldHelpIcon,
			toggleOverlay,
		}
	},
});
