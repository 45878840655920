import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentClassTableColumns = _resolveComponent("DocumentClassTableColumns")!
  const _component_TableColumnForm = _resolveComponent("TableColumnForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DocumentClassTableColumns, {
      rows: _ctx.columns,
      locators: _ctx.locators,
      loading: _ctx.loadingSaveColumn,
      tableId: _ctx.tableId,
      onOpenEntryDialog: _ctx.openCurrentTableColumnDialog,
      onDeleteEntry: _ctx.openDeleteTableColumn,
      onOnChangeCheckbox: _ctx.changeCheckboxTableColumn,
      onOnChangeSortOrder: _ctx.onChangeSortOrder
    }, null, 8, ["rows", "locators", "loading", "tableId", "onOpenEntryDialog", "onDeleteEntry", "onOnChangeCheckbox", "onOnChangeSortOrder"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showCurrentTableColumnDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCurrentTableColumnDialog = false, _ctx.showErrorMessage= false, _ctx.isInvalid = true)),
      onOnConfirm: _ctx.saveColumnFromDialog,
      loading: _ctx.loadingSaveColumn,
      message: _ctx.message,
      headerText: _ctx.headerText,
      showKeepDialogOpen: !_ctx.documentClassTableColumnEntry.id,
      width: '60rem'
    }, {
      content: _withCtx(() => [
        _createVNode(_component_TableColumnForm, {
          ref: "columnFormElement",
          columnEntry: _ctx.documentClassTableColumnEntry,
          locators: _ctx.locators,
          showErrorMessage: _ctx.showErrorMessage,
          documentClassId: _ctx.documentClassId,
          tableColumns: _ctx.currentTableColumns,
          onUpdate: _ctx.onUpdateTableColumn,
          onOnTabChange: _ctx.onTabChange
        }, null, 8, ["columnEntry", "locators", "showErrorMessage", "documentClassId", "tableColumns", "onUpdate", "onOnTabChange"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "message", "headerText", "showKeepDialogOpen"]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.showDeleteTableColumnDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDeleteTableColumnDialog = false)),
      onOnConfirm: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteTableColumn()))
    }, null, 8, ["showDialog"])
  ], 64))
}