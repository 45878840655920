
import {defineComponent, PropType} from "vue";
import {ExportInterfaceDto, PaginationDto} from "@dex/squeeze-client-ts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {ExportStatus} from "@dex/squeeze-client-ts/dist/api";
import {useI18n} from "vue-i18n";
import Tooltip from "primevue/tooltip";

export default defineComponent({
	name: 'DocumentClassExportStates',
	components: {DataTable, Column},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All export states */
		exportStates: {
			type: Array as PropType<ExportStatus>,
			default: () => [],
		},
		paginationInfo: {
			type: Object as PropType<PaginationDto>,
		},
		allExportInterfaces: {
			type: Array as PropType<ExportInterfaceDto[]>,
			default: () => [],
			required: true,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onPage",
	],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Triggered when the next page is selected */
		const onPage = (event: PaginationDto) => {
			emit("onPage", event);
		}

		/**
		 * Gets the Name of an export interface
		 * @param interfaceId Id of the export interface to get the name
		 * @return Name of the export Interface
		 */
		const getNameFromExportInterface = (interfaceId: number) => {
			if (interfaceId == null) {
				return t('Squeeze.General.None');
			}
			const exportInterface = props.allExportInterfaces.find(i => i.id === interfaceId);

			if (!exportInterface) {
				return String(interfaceId)
			}
			return exportInterface.interfaceName
		}

		return {
			t,
			onPage,
			getNameFromExportInterface,
		}
	},
})
