import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee831ea8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-col-12 p-grid p-mr-0 p-ml-0 p-pb-0 p-pt-3" }
const _hoisted_2 = { class: "p-col-8 p-pl-0" }
const _hoisted_3 = { class: "headline p-component p-m-0" }
const _hoisted_4 = { class: "p-col-4 p-as-center p-grid p-jc-end" }
const _hoisted_5 = { class: "p-col-12 scroll-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ApiKeyList = _resolveComponent("ApiKeyList")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _component_ApiKeyForm = _resolveComponent("ApiKeyForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.UserManagement.MyApiKeys')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          class: "p-button p-button-rounded p-button-text",
          icon: "mdi mdi-close",
          onClick: _ctx.clickSettingDialog
        }, null, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ApiKeyList, {
        apiKeys: _ctx.apiKeys,
        loading: _ctx.loading,
        onOnEntrySelect: _ctx.onEntrySelect,
        onOnClickDelete: _ctx.openDeleteDialog
      }, null, 8, ["apiKeys", "loading", "onOnEntrySelect", "onOnClickDelete"])
    ]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.deleteDialog,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDialog = false)),
      onOnConfirm: _ctx.deleteEntry
    }, null, 8, ["showDialog", "onOnConfirm"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false, _ctx.isSettingInvalid = true, _ctx.showValidationMessage = false)),
      onOnConfirm: _ctx.createApiKey,
      loading: _ctx.loadingDialog,
      headerText: _ctx.headerText
    }, {
      content: _withCtx(() => [
        _createVNode(_component_ApiKeyForm, {
          apiKey: _ctx.apiKey,
          onUpdate: _ctx.onUpdate,
          showErrorMessage: _ctx.showValidationMessage
        }, null, 8, ["apiKey", "onUpdate", "showErrorMessage"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText"])
  ], 64))
}