
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import DocumentClassList from "@/apps/administration/components/documentclasses/DocumentClassList.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentClass, ErrorDto} from "@dex/squeeze-client-ts";
import router from "@/router";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: 'DocumentClassListView',
	components: {
		DocumentClassList,
		DialogDelete,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Service for Document-Classes */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Is the page currently loading its data? */
		const loading = ref<boolean>(false);

		/** All Document-Classes */
		const documentClasses = ref<DocumentClass[]>([]);

		/** A single item (used for insert/edit/delete */
		const rowData = reactive<DocumentClass>({name: "", description: ""});

		/** Show delete dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Show edit-Dialog? */
		const editDialog = ref<boolean>(false);

		/** Message to be shown on screen in case of information/error */
		const message = ref<string>('');

		/** Message-Type to be shown: success, info, warn, error */
		const messageType = ref<string>('error');

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Is the Administration Path open? */
		const isAdministrationPathOpen = ref<boolean>(false);

		/**
		 * Opens the Delete-Dialog
		 * @param {DocumentClass} row The row to delete
		 */
		const openDeleteDialog = (row: DocumentClass) => {
			Object.assign(rowData, row);
			deleteDialog.value = true;
		}

		/**
		 * Gets the Document-Classes
		 */
		const getDocumentClasses = async (showLoading: boolean) => {
			if (showLoading) {
				loading.value = true
			}

			try {
				documentClasses.value = await documentClassService.getAllDocumentClasses();
			} catch (response: any) {
				const err = await response.json();
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			} finally {
				loading.value = false;
			}
		}

		/** Deletes an entry */
		const deleteEntry = () => {
			if (!rowData) {
				return;
			}
			loading.value = true;
			const id = rowData.id;
			documentClassService.deleteDocumentClassById(id as number).then(() => {
				deleteDialog.value = false;
				getDocumentClasses(false); // Reload View
			}).catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/**
		 * Is triggered when a row in the table is clicked
		 * @param {number} documentClassId ID of the Document-Class that has been clicked
		 */
		const onRowSelect = (documentClassId: number) => {
			if(isAdministrationPathOpen.value) {
				router.push({ name: 'DocumentClassSubView', params: { documentClassId: documentClassId}})
			} else {
				router.push({name: "DocumentListValidation", params: {documentClassId: documentClassId}});
			}
		}

		/** Opens the DocumentClassSubView-Component */
		const openEntryDialog = () => {
			router.push({ name: 'DocumentClassSubView', params: { documentClassId: 0}});
		}

		onMounted(() => {
			getDocumentClasses(true);
			isAdministrationPathOpen.value = router.currentRoute.value.fullPath === '/app/administration/documentclasses';
		})

		return {
			t,
			toast,
			loading,
			documentClasses,
			rowData,
			deleteDialog,
			editDialog,
			message,
			messageType,
			headerText,
			isAdministrationPathOpen,
			openDeleteDialog,
			getDocumentClasses,
			deleteEntry,
			onRowSelect,
			openEntryDialog,
		};
	},
});

